import './Reports.scss';

import { IndeterminateCheckbox } from 'views/components/IndeterminateCheckbox/IndeterminateCheckbox';
import { EmissionsSelectionCriteria } from 'views/components/EmissionsSelectionCriteria/EmissionsSelectionCriteria';
import { GenerationTypeSelection } from 'views/components/NodeSelection/GenerationTypeSelection';
import { useEmissionsReport } from 'tools/hooks/useEmissionsReport';
import { EmissionsType } from 'enums.d';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';
import styles from './EmissionsReports.module.scss';

export const EmissionsReports = () => {
  const { toggleAllNodes, selectedGenerationTypes, areAllNodesSelected, areSomeNodesSelected, emissionsType } =
    useEmissionsReport();

  const renderSelectionCriteria = () => (
    <EmissionsSelectionCriteria
      areAllNodesSelected={areAllNodesSelected}
      selectedGenerationTypes={selectedGenerationTypes}
    />
  );

  const showNodeTable = emissionsType === EmissionsType.GENERATION;

  return (
    <AccessRestrictionWrapper isInline accessScopeRequired={UserAccessScope.CARBON}>
      <div className="Container">
        <div className="Reports-content">
          {renderSelectionCriteria()}
          {showNodeTable && (
            <GenerationTypeSelection
              renderHeader={() => (
                <h3 className={styles.header}>
                  Generation Type
                  <IndeterminateCheckbox
                    indeterminate={areSomeNodesSelected && !areAllNodesSelected}
                    isSelected={areAllNodesSelected}
                    onSelect={toggleAllNodes}
                    onRemove={toggleAllNodes}
                  />
                </h3>
              )}
            />
          )}
        </div>
      </div>
    </AccessRestrictionWrapper>
  );
};
