import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

import './RCPDPeaksTable.scss';

import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { getRcpdPeaks } from 'redux/modules/nodes/selectors';
import { format } from 'date-fns';
import { REGION_OPTIONS } from '../RCPDSelectionCriteria/RCPDSelectionCriteria';

const MAX_NODES_PER_PAGE = 20;
const MAX_NODES_PER_TABLE = 10;
const MAX_NODE_RANK = 100;

interface IProps {
  region: string;
}

const getRankLabel = (page: number) => {
  if (page === 0) {
    return 'Rank 1 - 20';
  }

  return `Rank ${MAX_NODES_PER_PAGE * page + 1} - ${Math.min(MAX_NODES_PER_PAGE * (page + 1), MAX_NODE_RANK)}`;
};

export const RCPDPeaksTable = ({ region: regionId }: IProps) => {
  const [page, setPage] = useState<number>(0);

  const isViewportAboveTablet = useSelector(isViewportAboveTabletSelector);
  const rcpdPeaks = useSelector(getRcpdPeaks);

  const [isLastPage, setLastPage] = useState(false);

  useEffect(() => {
    setLastPage((page + 2) * MAX_NODES_PER_PAGE > MAX_NODE_RANK);
  }, [page]);

  const columns = useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'ranking',
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Date',
        accessor: (row: any) => format(new Date(row.tradingDate), 'dd MMM yyyy'),
      },
      {
        Header: 'TP',
        accessor: 'tradingPeriod',
      },
      {
        Header: 'MW',
        accessor: 'mw',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: (rcpdPeaks && rcpdPeaks.items) || [],
  });

  if (!rcpdPeaks || Object.keys(rcpdPeaks).length === 0) {
    return null;
  }

  const firstTableData = isViewportAboveTablet
    ? rows.slice(page * MAX_NODES_PER_PAGE, page * MAX_NODES_PER_PAGE + MAX_NODES_PER_TABLE)
    : rows;
  const secondTableData = rows.slice(
    page * MAX_NODES_PER_PAGE + MAX_NODES_PER_TABLE,
    page * MAX_NODES_PER_PAGE + MAX_NODES_PER_PAGE,
  );

  const region = REGION_OPTIONS.find((option) => option.id === regionId);

  return (
    <div className="RCPDPeaksTable">
      <div className="RCPDPeaksTable-header">
        <h2 className="RCPDPeaksTable-header-text">{`${region?.label} Current 100 Peaks`}</h2>
        {isViewportAboveTablet && (
          <div className="RPCDPeakTable-header-paginationButtonWrapper">
            {page !== 0 && (
              <div className="RCPDPeaksTable-header-paginationButton">
                <button type="button" disabled={page === 0} onClick={() => setPage(page - 1)}>
                  {'< Back'}
                </button>
                <span>{getRankLabel(page - 1)}</span>
              </div>
            )}
            {!isLastPage && (
              <div className="RCPDPeaksTable-header-paginationButton RCPDPeaksTable-header-paginationButton--next">
                <span>{getRankLabel(page + 1)}</span>
                <button type="button" onClick={() => setPage(page + 1)}>
                  {'Next >'}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="RCPDPeaksTable-tableWrapper">
        <table className="RCPDPeaksTable-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="RCPDPeaksTable-th" {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstTableData.map((row) => {
              prepareRow(row);
              return (
                <tr className="RCPDPeaks-tr" {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td className="RCPDPeaksTable-td" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isViewportAboveTablet && (
          <table className="RCPDPeaksTable-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="RCPDPeaksTable-th" {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {secondTableData.map((row) => {
                prepareRow(row);
                return (
                  <tr className="RCPDPeaks-tr" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className="RCPDPeaksTable-td" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
