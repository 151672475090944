/* eslint-disable import/no-cycle */
import { INodesState } from 'redux/modules/nodes/reducers';
import { group } from 'd3-array';
import { format } from 'date-fns';

import { createSelector } from 'reselect';

import { INodePriceInterval, INode, INodeLoad } from 'types.d';
import { NodeType } from 'enums.d';

import { IIntervalTableData, ITableRow } from 'views/components/CurrentNodesTable/CurrentNodesTable';
import { IReducerState } from 'redux/main-reducer';
import { formatLastUpdatedTime } from 'tools/utilities/charts';
import { DEFAULT_PRICE_NODES, HVDC_NODES } from './constants';

export const isLoadingIntervals = ({ nodes }: { nodes: INodesState }) => nodes.isLoadingIntervals;
export const getIntervals = ({ nodes }: { nodes: INodesState }) => nodes.intervals;

export const getIntervalsForTable: ({ nodes }: { nodes: INodesState }) => IIntervalTableData | null = ({
  nodes,
}: {
  nodes: INodesState;
}) => {
  const { intervals, currentNodes } = nodes;
  if (!intervals || !intervals.length) {
    return null;
  }

  const currentNodesIntervals = intervals.filter((interval) =>
    currentNodes.some((currentNode) => currentNode === interval.nodeId),
  );
  const intervalsByNodeId: Map<string, INodePriceInterval[]> = group(
    currentNodesIntervals,
    (interval: INodePriceInterval) => interval.nodeId,
  );
  const nodesIds = Array.from(intervalsByNodeId.keys());
  const timeStamps = intervalsByNodeId
    .get(nodesIds[0])
    ?.map((interval: INodePriceInterval) => interval.timeStamp)
    .slice(0, 6);

  const columns = [
    {
      Header: '',
      accessor: 'node',
    },
    {
      Header: (timeStamps && timeStamps[3]) as string,
      accessor: 'price1',
    },
    {
      Header: (timeStamps && timeStamps[4]) as string,
      accessor: 'price2',
    },
    {
      Header: (timeStamps && timeStamps[5]) as string,
      accessor: 'price3',
    },
  ];
  const rows: ITableRow[] = [];

  nodesIds.forEach((nodeId: string) => {
    const currentNodePrices = intervalsByNodeId.get(nodeId);

    rows.push({
      node: nodeId,
      price1: currentNodePrices && currentNodePrices[3]?.price,
      price2: currentNodePrices && currentNodePrices[4]?.price,
      price3: currentNodePrices && currentNodePrices[5]?.price,
    });
  });

  return {
    columns,
    rows,
  };
};

export const isLoadingDetails = ({ nodes }: { nodes: INodesState }) => nodes.isLoadingDetails;
export const isLoadingResiduals = ({ nodes }: { nodes: INodesState }) => nodes.isLoadingResiduals;
export const getCurrentNodesDetails = ({ nodes }: { nodes: INodesState }) => nodes.currentNodesDetails;

export const getCurrentNodes = ({ nodes }: { nodes: INodesState }) => nodes.currentNodes;
export const getCurrentNodeType = ({ nodes }: { nodes: INodesState }) => nodes.currentNodeType;
export const isPriceNodes = ({ nodes }: { nodes: INodesState }) => nodes.currentNodeType === NodeType.PRICE;
export const isLoadNodes = ({ nodes }: { nodes: INodesState }) => nodes.currentNodeType === NodeType.LOAD;

export const getPriceNodes = ({ nodes }: { nodes: INodesState }) => nodes.priceNodes;
export const getGenerationNodes = ({ nodes }: { nodes: INodesState }) => nodes.generationNodes;
export const getLoadNodes = ({ nodes }: { nodes: INodesState }) => nodes.loadNodes;

// HVDC Nodes are a subset of Load Nodes
// CAREFUL using this as a dependancy of a useEffect at the same time as loadNodes as it will
// loop forever
export const getHVDCNodes = ({ nodes }: { nodes: INodesState }) => {
  const { loadNodes } = nodes;

  return loadNodes.filter((loadNode) => HVDC_NODES.includes(loadNode.nodeId));
};

/**
 * We have to get the latest node price from the intervals endpoint
 * and join it with the rest of the price node data so that we can
 * show it on the map labels.
 */
export const getPriceNodesForMap = ({ nodes }: { nodes: INodesState }) => {
  const { intervals } = nodes;
  if (!intervals || !intervals.length) {
    return null;
  }

  const intervalsByNodeId: Map<string, INodePriceInterval[]> = group(
    intervals,
    (interval: INodePriceInterval) => interval.nodeId,
  );

  const selectedPriceNodes: INode[] = nodes.priceNodes.filter((priceNode) =>
    nodes.currentNodes.some((currentNode) => currentNode === priceNode.nodeId),
  );

  return selectedPriceNodes.map((node) => {
    const nodeIntervals = intervalsByNodeId.get(node.nodeId);
    const value = nodeIntervals && nodeIntervals[5].price;

    return {
      ...node,
      value,
      valueForLabelStyle: value,
    };
  });
};

/**
 * We have to get the latest node price from the intervals endpoint
 * and join it with the rest of the price node data so that we can
 * show it on the map labels.
 */
export const getLoadNodesForMap = ({ nodes }: { nodes: INodesState }) => {
  const { recentLoad } = nodes;
  if (!recentLoad || !recentLoad.length) {
    return null;
  }

  const recentLoadByNodeId: Map<string, INodeLoad[]> = group(recentLoad, (load: INodeLoad) => load.nodeId);

  const selectedPriceNodes: INode[] = nodes.loadNodes.filter((loadNode) =>
    nodes.currentNodes.some((currentNode) => currentNode === loadNode.nodeId),
  );

  return selectedPriceNodes.map((node) => {
    const nodeRecentLoad = recentLoadByNodeId.get(node.nodeId);

    return {
      ...node,
      value: nodeRecentLoad && parseFloat(nodeRecentLoad[0]?.scadaMwCurrent.toFixed(2)),
      valueForLabelStyle: nodeRecentLoad && nodeRecentLoad[0]?.pct7Day,
      valueChange: nodeRecentLoad && nodeRecentLoad[0]?.mwChange,
    };
  });
};

export const LAST_UPDATED_TIME_FORMAT = 'EEE d MMM yyy';

/**
 * The last updated time is sourced from the most recent price interval.
 */
export const getLastUpdatedAtTime = ({ nodes }: { nodes: INodesState }) => {
  const { intervals, currentNodeType, recentLoad } = nodes;

  if (currentNodeType === NodeType.LOAD && recentLoad.length > 0) {
    return formatLastUpdatedTime(new Date(recentLoad[0].timestamp));
  }

  if (!intervals || !intervals.length) {
    return null;
  }

  const latestTimeStamp = intervals[5].timeStamp;

  return `${format(new Date(), LAST_UPDATED_TIME_FORMAT)}, ${latestTimeStamp}`;
};

export const getReportContent = ({ nodes }: { nodes: INodesState }) => nodes.reportContent;
export const getReportDownloadTime = ({ nodes }: { nodes: INodesState }): number | undefined =>
  nodes.reportDownloadTime;

export const getReportSelectedNodes = ({ nodes }: { nodes: INodesState }) => nodes.reportSelectedNodes;

export const isValidDateSelector = ({ nodes }: { nodes: INodesState }) => nodes.isValidDateRange;
export const isDownloadVisibleSelector = ({ nodes }: { nodes: INodesState }) => nodes.isDownloadVisible;

export const hasSelectedCustomNodes = ({ nodes }: { nodes: INodesState }) => {
  const { currentNodes } = nodes;

  const isEqual =
    currentNodes.length === DEFAULT_PRICE_NODES.length &&
    currentNodes.every((val) => DEFAULT_PRICE_NODES.includes(val));

  return !isEqual;
};

// ====
// RCPD
// ====

export const getRCPD2hrData = ({ nodes }: IReducerState) => nodes.rcpd2hrData;
export const getRCPD48hrData = ({ nodes }: IReducerState) => nodes.rcpd48hrData;

export const getLatestRCPD2hrDatum = createSelector([getRCPD2hrData], (rpcd2hrData) => {
  if (rpcd2hrData?.period_data) {
    // Avoid mutating the state by creating a copy
    return [...rpcd2hrData.period_data].pop();
  }

  return null;
});

export const getRcpdPeaks = ({ nodes }: IReducerState) => nodes.rcpdPeaks;

/**
 * Dashboard 2.0
 */
export const getHvdcLast24Hrs = ({ nodes }: IReducerState) => nodes.hvdcLast24Hrs;
export const getNzReservesLast24Hrs = ({ nodes }: IReducerState) => nodes.nzReservesLast24Hrs;
export const getNzSummaryLast24Hrs = ({ nodes }: IReducerState) => nodes.nzSummaryLast24Hrs;
export const getResiduals = ({ nodes }: IReducerState) => nodes.residuals;
export const getGenerationByType = ({ nodes }: IReducerState) => nodes.generationByTypeLast24Hrs;
export const getRecentLoad = ({ nodes }: IReducerState) => nodes.recentLoad;

export const hasAttemptedDownloadSelector = ({ nodes }: IReducerState) => nodes.hasAttemptedReportDownload;
