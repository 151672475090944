import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';

import { GWAP_GRAPH_KEYS } from 'tools/utilities/gwap';
import { useSelector } from 'react-redux';
import { getCurrentMedia, isViewportAboveMobile } from 'redux/modules/app/selectors';
import { useFreeGwap } from 'tools/hooks/useFreeGwap';
import { GraphKey } from '../GraphKey/GraphKey';
import { FreeGwapFilters } from './FreeGwapFilters';
import { FreeMobileGwapFilter } from '../MobileGwapFilter/FreeMobileGwapFilter';

import styles from './FreeGWAPGraph.module.scss';
import { GwapGraph } from '../GwapGraph/GwapGraph';

const MediaTypeGraphHeightMap: { [key: string]: number } = {
  largeDesktop: 400,
  widescreen: 450,
  ultraWidescreen: 280,
};

export const FreeGWAPGraph = () => {
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const currentMedia = useSelector(getCurrentMedia) as string;
  const { filteredGwapData } = useFreeGwap();

  const graphHeight = MediaTypeGraphHeightMap[currentMedia] ?? 350;

  const barDimensions = {
    width: 500,
    height: 300,
  };
  const chartDimensions = {
    width: '99%',
    height: graphHeight,
    margins: {
      top: 0,
      right: 6,
      left: 0,
      bottom: 16,
    },
  };

  return (
    <div className={styles.wrapper}>
      {!isViewAboveMobile && (
        <div className={styles.modal}>
          <FreeMobileGwapFilter />
        </div>
      )}
      {isViewAboveMobile && (
        <div className={styles.filters}>
          <FreeGwapFilters />
        </div>
      )}
      <GwapGraph
        data={filteredGwapData}
        chartContainerClassName={styles.chartContainer}
        barDimensions={barDimensions}
        chartDimensions={chartDimensions}
        showXAxis
      >
        <GraphKey classname="GraphKey-gwap" keyItems={GWAP_GRAPH_KEYS} />
      </GwapGraph>
    </div>
  );
};
