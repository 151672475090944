import * as d3 from 'd3';
import { IPriceRegion } from 'tools/hooks/useFreeRegions';

import { INode } from 'types.d';

export const MAP_WIDTH = 828;
export const MAP_HEIGHT = 800;

/**
 * Get the coordinates of a node on the map.
 * @param data The node to get the coordinates of
 * @param projection The current d3 geoprojection
 */
export const getCoordinates = (data: INode | IPriceRegion, projection: d3.GeoProjection) => {
  if (!data) {
    return { x: 0, y: 0 };
  }

  const coordinates = projection([data.longitude, data.latitude]);

  return {
    x: coordinates ? coordinates[0] : 0,
    y: coordinates ? coordinates[1] : 0,
  };
};

/**
 * Gets the northern most node. Used for calculating the bounding box.
 * @param nodes Current nodes on the map
 * @param projection The current d3 geoprojection
 */
const getNothernMostNode = (nodes: any[], projection: d3.GeoProjection) => {
  let northernMostNode: any = null;

  nodes.forEach((node: any) => {
    if (northernMostNode === null) {
      northernMostNode = node;
    }
    const { y } = getCoordinates(node, projection);
    const { y: northernMostNodeY } = getCoordinates(northernMostNode, projection);

    northernMostNode = y <= northernMostNodeY ? node : northernMostNode;
  });

  return northernMostNode;
};

/**
 * Gets the southern most node. Used for calculating the bounding box.
 * @param nodes Current nodes on the map
 * @param projection The current d3 geoprojection
 */
const getSouthernMostNode = (nodes: any[], projection: d3.GeoProjection) => {
  let southernMostNode: any = null;

  nodes.forEach((node: any) => {
    if (southernMostNode === null) {
      southernMostNode = node;
    }
    const { y } = getCoordinates(node, projection);
    const { y: southernMostNodeY } = getCoordinates(southernMostNode, projection);

    southernMostNode = y >= southernMostNodeY ? node : southernMostNode;
  });

  return southernMostNode;
};

/**
 * Gets the eastern most node. Used for calculating the bounding box.
 * @param nodes Current nodes on the map
 * @param projection The current d3 geoprojection
 */
const getEasternMostNode = (nodes: any[], projection: d3.GeoProjection) => {
  let easternMostNode: any = null;

  nodes.forEach((node: any) => {
    if (easternMostNode === null) {
      easternMostNode = node;
    }
    const { x } = getCoordinates(node, projection);
    const { x: easternMostNodeY } = getCoordinates(easternMostNode, projection);

    easternMostNode = x >= easternMostNodeY ? node : easternMostNode;
  });

  return easternMostNode;
};

/**
 * Gets the western most node. Used for calculating the bounding box.
 * @param nodes Current nodes on the map
 * @param projection The current d3 geoprojection
 */
const getWesternMostNode = (nodes: any[], projection: d3.GeoProjection) => {
  let westernMostMarker: any = null;

  nodes.forEach((node: any) => {
    if (westernMostMarker === null) {
      westernMostMarker = node;
    }
    const { x } = getCoordinates(node, projection);
    const { x: westernMostMarkerY } = getCoordinates(westernMostMarker, projection);

    westernMostMarker = x <= westernMostMarkerY ? node : westernMostMarker;
  });

  return westernMostMarker;
};

/**
 * Get the bounding box of the current nodes so that we can zoom the map
 * an appropriate distance.
 * @param currentNodesDetailsWithPrice Nodes to be displayed on map
 * @param projection The current d3 geoprojection
 */
export const getBoundingBox = (currentNodesDetailsWithPrice: any[], projection: d3.GeoProjection) => {
  const northernNode = getNothernMostNode(currentNodesDetailsWithPrice || [], projection);
  const southernNode = getSouthernMostNode(currentNodesDetailsWithPrice || [], projection);
  const easternNode = getEasternMostNode(currentNodesDetailsWithPrice || [], projection);
  const westernNode = getWesternMostNode(currentNodesDetailsWithPrice || [], projection);
  const northernNodeCoordinates = getCoordinates(northernNode, projection);
  const southernNodeCoordinates = getCoordinates(southernNode, projection);
  const easternNodeCoordinates = getCoordinates(easternNode, projection);
  const westernNodeCoordinates = getCoordinates(westernNode, projection);

  const x0 = westernNodeCoordinates.x;
  const x1 = easternNodeCoordinates.x;
  const y0 = northernNodeCoordinates.y;
  const y1 = southernNodeCoordinates.y;

  const boxMargin = 45; // This is to stop the markers being clipped

  return [
    [x0 - boxMargin, y0 - boxMargin],
    [x1 + boxMargin, y1 + boxMargin],
  ];
};

/**
 * Calculates if the point sits on the left or right side of the country.
 * Used for positioning the labels.
 * @param x x coordinate of point
 * @param y y coordinate of point
 */
export const isPointBeforeIntersection = (x: number, y: number) => {
  // use these two points to plot a line on the map to see the middle as a reference:
  // const x1 = 160
  // const x2 = 640
  // const y1 = -1*(m*x1-c)
  // const y2 = -1*(m*x2-c)
  const m = 160 / 122;
  const c = 128000 / 126;
  const xIntersect = (-y + c) / m;

  return x < xIntersect;
};
