import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';

import {
  RESERVES_LAST_24_HRS_KEY_ITEMS,
  RESERVES_LAST_24_COLORS,
} from 'redux/modules/nodes/constants';

import { scaleLinear } from 'd3-scale';
import { ReservesIsland } from 'enums.d';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { FormattedMessage } from 'react-intl';
import { formatTradingPeriodToTime } from 'tools/utilities/date';
import { getNzReservesLast24Hrs } from 'redux/modules/nodes/selectors';
import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { formatEndOfTradingPeriod, formatTimestamp } from 'tools/utilities/charts';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipRow, TooltipWrapper, BoldTooltipText } from '../Tooltip/Tooltip';

const MOBILE_HEIGHT = 600;

const CustomTooltip = ({ payload, label }: any) => {
  if (!payload?.[0]) {
    return null;
  }

  const { mwoffered, mwcleared, timestamp, tradingPeriod } = payload?.[0]?.payload ?? {};

  return (
    <TooltipWrapper>
      <TooltipRow>
        <span>{timestamp && formatTimestamp(new Date(timestamp))} - TP{tradingPeriod}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text={`${label} Offered Reserves`} />
        <span>{mwoffered || mwoffered === 0 ? `${roundToDpWithCommas(mwoffered, 1)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text={`${label} Cleared Reserves`} />
        <span>{mwcleared || mwcleared === 0 ? `${roundToDpWithCommas(mwcleared, 1)} MW` : '-'}</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};

const ReservesLineChart = ({data, label}: any) => {

  const isAboveTablet = useSelector(isViewportAboveTabletSelector);
  const yValues = data.reduce((acc: any, {mwcleared, mwoffered}: any) => ([
    ...acc,
    mwcleared,
    mwoffered,
  ]), [])

  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)
  const offset = (yMax - yMin) / 10
  const yScale = scaleLinear().domain([yMin - offset, yMax + offset])
  const yTicks = yScale.ticks(4)
  const xTicks:any = _.uniq(data.filter(({tradingPeriod}: any) => tradingPeriod % 6 === 0).map(({tradingPeriod}: any) => tradingPeriod))

  return (
    <>
      <h4 className="Dashboard-smallTitle">{label}</h4>
      <br/>
      <ResponsiveContainer
        width="99%"
        aspect={isAboveTablet ? 1 : undefined}
        height={isAboveTablet ? 'unset' : MOBILE_HEIGHT}
      >
        <LineChart
          data={data}
          margin={{
            top: 24,
            right: 0,
            left: 0,
            bottom: 16,
          }}
        >
          <XAxis
            ticks={xTicks}
            tickFormatter={(tick: number) => formatTradingPeriodToTime(tick)}
            tickLine={false}
            axisLine={false}
            width={100}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            dataKey="tradingPeriod"
          />
          <YAxis
            ticks={yTicks}
            tickLine={false}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            axisLine={false}
            type="number"
          />
          <Tooltip
            labelFormatter={(lbl: any, payload: any) => (
              <span>
                {moment(new Date(payload[0]?.payload?.timestamp)).format('DD/MM/YYYY')} - TP{payload[0]?.payload.tp}
              </span>
            )}
            content={({payload}) => <CustomTooltip payload={payload} label={label}/>}
          />
          <Line type="linear" dataKey='mwcleared' stroke={RESERVES_LAST_24_COLORS.CLEARED} dot={false} strokeWidth={2} />
          <Line type="linear" dataKey='mwoffered' stroke={RESERVES_LAST_24_COLORS.OFFERED} dot={false} strokeWidth={2} />

          {xTicks.map((tick: number) => (
            <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
          {yTicks.map((tick: number) => (
            <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export const NzReservesLast24Hrs = () => {

  const nzReservesLast24Hrs = useSelector(getNzReservesLast24Hrs);

  const data = nzReservesLast24Hrs?.items;
  if (!data || !data.length) {
    return null;
  }

  const lastUpdatedTime = formatEndOfTradingPeriod(new Date(data[data.length - 1].timestamp));
  const northIslandData = data.filter((item: any) => item.islandid === ReservesIsland.NORTH_ISLAND);
  const southIslandData = data.filter((item: any) => item.islandid === ReservesIsland.SOUTH_ISLAND);

  return (
    <div>
      <ReservesLineChart label="North Island" data={northIslandData}/>
      <ReservesLineChart label="South Island" data={southIslandData}/>
      <GraphKey keyItems={RESERVES_LAST_24_HRS_KEY_ITEMS} />
      <p className="Dashboard-updated">
        <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
      </p>
    </div>
  )

}