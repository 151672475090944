import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NodeType, DateFilterOption, HVDCGranularity, ReportType } from 'enums.d';
import { IScadaReportCriteria } from 'types.d';

import { getNodesSelectedString } from 'tools/utilities/getNodesSelectedString';

import { HVDC_NODES } from 'redux/modules/nodes/constants';
import { TYPES, actionGetScadaReport } from 'redux/modules/nodes/actions';

import { CriteriaOption, UpdateFiltersFunc } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';
import { SelectionCriteria } from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { CustomDateSelection } from 'views/components/SelectionCriteria/CustomDateSelection/CustomDateSelection';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { actionTrackReportDownload } from 'redux/modules/analytics/actions';
import { getReportContent } from 'redux/modules/nodes/selectors';
import { generateDownload } from 'tools/utilities/file';
import { formatScadaReportFileName } from 'tools/utilities/report';
import { SelectionDownload } from '../SelectionCriteria/SelectionDownload/SelectionDownload';

const NODE_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'Load', id: NodeType.LOAD },
  { label: 'Generation', id: NodeType.GENERATION },
  { label: 'HVDC', id: NodeType.HVDC },
];

const HVDC_OPTIONS: CriteriaOption[] = [
  { label: 'Pole 2 & Pole 3', id: HVDCGranularity.POLE_2_POLE_3 },
  { label: 'Total Flows', id: HVDCGranularity.TOTAL_FLOWS },
];

const NODE_DATE_OPTIONS: CriteriaOption[] = [
  { label: 'Today', id: DateFilterOption.TODAY },
  { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
  { label: 'Previous 7 Days', id: DateFilterOption.PREVIOUS_7_DAYS },
  {
    id: DateFilterOption.CUSTOM,
    label: 'Custom',
    body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
      <CustomDateSelection updateFilters={updateFilters} resetState={resetState} />
    ),
  },
];

interface IProps {
  selectedNodes: string[];
  setNodeType: (nodeType: NodeType) => void;
  areAllNodesSelected: boolean;
  nodeType: NodeType;
}

export const ScadaSelectionCriteria = ({ selectedNodes, setNodeType, areAllNodesSelected, nodeType }: IProps) => {
  const [criteria, setCriteria] = useState<IScadaReportCriteria>({
    nodeType: nodeType || NodeType.LOAD,
    hvdcGranularity: HVDCGranularity.POLE_2_POLE_3,
    dateOption: DateFilterOption.TODAY,
    fromTradingDate: '',
    toTradingDate: '',
  });
  const [isDownloadVisible, setDownloadVisible] = useState(false);

  const dispatch = useDispatch();
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const reportContent = useSelector(getReportContent);

  useEffect(() => {
    if (nodeType === NodeType.HVDC) {
      setDownloadVisible(true);
    } else {
      setDownloadVisible(selectedNodes.length > 0);
    }
  }, [isViewAboveMobile, selectedNodes, nodeType]);

  useEffect(() => {
    if (reportContent) {
      const filename = formatScadaReportFileName(criteria);
      generateDownload(reportContent, `${filename}.csv`, 'text/csv');
      dispatch({ type: TYPES.CLEAN_UP_REPORT });
    }
  }, [reportContent, criteria, dispatch]);

  const updateCriteria = (key: string, filterOptionId: string) => {
    setCriteria({ ...criteria, [key]: filterOptionId });
  };

  const handleNodeTypeSelection = (option: CriteriaOption) => {
    setNodeType(option.id as NodeType);
    updateCriteria('nodeType', option.id);

    // Request new nodes
    if (option.id === NodeType.GENERATION) {
      dispatch({
        type: TYPES.GET_GENERATION_NODES,
      });
    }
  };

  const handleReportDownload = () => {
    const nodes = criteria.nodeType === 'hvdc' ? HVDC_NODES : selectedNodes;

    dispatch(actionGetScadaReport(criteria, nodes, areAllNodesSelected));
    dispatch(
      actionTrackReportDownload({
        reportType: ReportType.SCADA,
        criteria,
      }),
    );
  };

  const downloadMessage =
    criteria.nodeType === 'hvdc' ? '' : getNodesSelectedString(selectedNodes.length, areAllNodesSelected);

  return (
    <SelectionCriteria>
      <CriteriaAccordian
        initialOption={NODE_TYPE_OPTIONS.find(({ id }) => id === criteria.nodeType)}
        options={NODE_TYPE_OPTIONS}
        onChange={handleNodeTypeSelection}
        updateCriteria={updateCriteria}
      />
      {criteria.nodeType === 'hvdc' && (
        <CriteriaAccordian
          initialOption={HVDC_OPTIONS.find(({ id }) => id === criteria.nodeType)}
          options={HVDC_OPTIONS}
          onChange={(option: CriteriaOption) => updateCriteria('hvdcGranularity', option.id)}
          updateCriteria={updateCriteria}
        />
      )}
      <CriteriaAccordian
        initialOption={NODE_DATE_OPTIONS.find(({ id }) => id === criteria.dateOption)}
        options={NODE_DATE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('dateOption', option.id)}
        updateCriteria={updateCriteria}
        headerIcon="ic-calendar"
      />
      <SelectionDownload
        needsSelectedNodesForDownload={criteria.nodeType !== NodeType.HVDC}
        isVisibleOnMobile={isDownloadVisible}
        text={downloadMessage}
        downloadAction={handleReportDownload}
        forceCorrectDateForDownload={criteria.dateOption === DateFilterOption.CUSTOM}
      />
    </SelectionCriteria>
  );
};
