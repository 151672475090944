import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import './SelectionDownload.scss';
import { Button } from 'views/components/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  getReportSelectedNodes,
  isValidDateSelector,
  hasAttemptedDownloadSelector,
} from 'redux/modules/nodes/selectors';
import { Icon } from 'views/components/Icon/Icon';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { actionSetDownloadVisible, actionSetReportDownloadAttempted } from 'redux/modules/nodes/actions';

interface IProps {
  isVisibleOnMobile: boolean;
  text?: string;
  forceCorrectDateForDownload: boolean;
  needsSelectedNodesForDownload?: boolean;
  downloadAction: () => any;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const SelectionDownload = ({
  needsSelectedNodesForDownload = true,
  isVisibleOnMobile,
  forceCorrectDateForDownload,
  text,
  downloadAction,
  isDisabled,
  isLoading,
}: IProps) => {
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const [canDownload, setCanDownload] = useState(false);
  const selectedNodes = useSelector(getReportSelectedNodes);
  const isValidDateRange = useSelector(isValidDateSelector);
  const [isErrorShowing, setErrorShowing] = useState(false);
  const hasAttemptedReportDownload = useSelector(hasAttemptedDownloadSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionSetDownloadVisible(isVisibleOnMobile && !isViewAboveMobile)); // For footer to add margins;
  }, [dispatch, isVisibleOnMobile, isViewAboveMobile]);

  useEffect(() => {
    dispatch(actionSetReportDownloadAttempted(false));
    setCanDownload(false);
    return () => {
      dispatch(actionSetReportDownloadAttempted(false));
    };
  }, [forceCorrectDateForDownload, dispatch]);

  useEffect(() => {
    setCanDownload(true);

    if (forceCorrectDateForDownload) {
      setCanDownload(isValidDateRange); // Allow downloading if the date is also valid
      setErrorShowing(hasAttemptedReportDownload && !isValidDateRange); // Show error if the date is not valid
    } else {
      setErrorShowing(false); // If error was being shown but user switched from custom date
    }

    if (needsSelectedNodesForDownload && selectedNodes.length === 0) {
      setCanDownload(false);
    }
  }, [
    needsSelectedNodesForDownload,
    forceCorrectDateForDownload,
    hasAttemptedReportDownload,
    isValidDateRange,
    selectedNodes,
  ]);

  const attemptDownload = () => {
    dispatch(actionSetReportDownloadAttempted(true));

    if (canDownload) {
      downloadAction();
      dispatch(actionSetReportDownloadAttempted(false));
    }
  };

  return (
    <AnimatePresence>
      {isVisibleOnMobile && !isViewAboveMobile && (
        <motion.div
          className="SelectionDownload--mobile"
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 300, opacity: 0 }}
          transition={{ ease: 'easeIn', duration: 0.8 }}
        >
          {isErrorShowing && (
            <p className="SelectionDownload-errorArea">
              <Icon size="md" name="ic-error" />
              Please enter a valid date range
            </p>
          )}

          {text && (
            <div className="SelectionDownload-downloadArea">
              <p className="">{text}</p>
              <Button
                isLoading={isLoading}
                onClick={attemptDownload}
                label="Download CSV"
                isDisabled={isDisabled || !isVisibleOnMobile}
              />
            </div>
          )}

          {!text && (
            <Button
              isLoading={isLoading}
              onClick={attemptDownload}
              label="Download CSV"
              isDisabled={isDisabled || !isVisibleOnMobile}
            />
          )}
        </motion.div>
      )}

      {isViewAboveMobile && (
        <div className="SelectionDownload">
          <hr />
          {isErrorShowing && (
            <p className="SelectionDownload-errorArea">
              <Icon className="SelectionDownload-errorIcon" name="ic-error" />
              Please enter a valid date range
            </p>
          )}

          {text && (
            <div className="SelectionDownload-downloadArea">
              <p className="">{text}</p>
              <Button
                isLoading={isLoading}
                onClick={attemptDownload}
                label="Download CSV"
                isDisabled={isDisabled || !isVisibleOnMobile}
              />
            </div>
          )}

          {!text && (
            <Button
              isLoading={isLoading}
              onClick={attemptDownload}
              label="Download CSV"
              isDisabled={isDisabled || !isVisibleOnMobile}
            />
          )}
        </div>
      )}
    </AnimatePresence>
  );
};
