import React from 'react';
import { motion } from 'framer-motion';
import { ILegendRange } from 'types';
import { getNodeStyle } from 'views/components/NodeTable/NodeTable';
import { COLOURS } from 'redux/modules/nodes/constants';
import { IPriceRegion } from 'tools/hooks/useFreeRegions';

interface Props {
  geoGenerator: d3.GeoPath<any, d3.GeoPermissibleObjects>;
  gridZones: any;
  transformation?: d3.ZoomTransform;
  ranges: ILegendRange[];
  regions: IPriceRegion[];
}

export const MapLayer = React.memo(({ geoGenerator, gridZones, transformation, ranges, regions }: Props) => (
  <motion.g id="Map-gridZones" transition={{ duration: 0 }}>
    {gridZones.map((zone: any) => {
      const d = geoGenerator(zone);
      const style = getRegionStyle(zone, ranges, regions);
      return d && <path key={zone.id} d={d} stroke="#fff" strokeWidth="1px" fill={style.backgroundColor} />;
    })}
  </motion.g>
));

const getRegionStyle = (gridZone: any, ranges: ILegendRange[], regions: IPriceRegion[]) => {
  const region: IPriceRegion | undefined = regions.find((n) => n.gridZoneId === gridZone.properties.GZ);

  if (!region) {
    return { backgroundColor: COLOURS.HEADER_BLUE, color: '#fff' }; // Default color if no value present for node as discussed wit Emily S.
  }

  return getNodeStyle(region.price, ranges);
};
