/* eslint-disable import/no-cycle */
import React from 'react';
import * as d3 from 'd3';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { isViewportAboveDesktop as isViewportAboveDesktopSelector } from 'redux/modules/app/selectors';

import { getNodeStyle } from 'views/components/NodeTable/NodeTable';
import { ILegendRange } from 'types.d';
import { IPriceRegion } from 'tools/hooks/useFreeRegions';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { useFeature } from 'flagged';
import { v4 } from 'uuid';
import { getCoordinates, isPointBeforeIntersection } from '../map-utilities';

import styles from '../FreeMap.module.scss';

const LABEL_WIDTH = 117;
const LABEL_HEIGHT = 44;
const LABEL_WIDTH_HALVED = LABEL_WIDTH / 2;

const NODE_ID_FONT_SIZE = 14;
const NODE_PRICE_FONT_SIZE = 16;
const NODE_PRICE_FONT_SIZE_SMALL = 28;
const NODE_ID_Y_OFFSET = 5;
const NODE_PRICE_Y_OFFSET = 15;
const NODE_PRICE_Y_OFFSET_SMALL = -12;

const LABEL_OUTER_CIRCLE_RADIUS = 10;
const LABEL_OUTER_CIRCLE_RADIUS_SMALL = 16;
const LABEL_INNER_CIRCLE_RADIUS = 4.5;
const LABEL_INNER_CIRCLE_RADIUS_SMALL = 6;
const LABEL_LINE_STROKE_WIDTH = 1;
const LABEL_LINE_STROKE_WIDTH_SMALL = 2;
const LABEL_RECT_BORDER_RADIUS = 8;

const ARROW_PADDING = 8;
interface Props {
  regions: IPriceRegion[];
  ranges: ILegendRange[];
  projection: d3.GeoProjection;
  transformation: d3.ZoomTransform;
}

export const Labels = React.memo(({ regions, projection, ranges, transformation }: Props) => {
  const intl = useIntl();
  const isViewportAboveDesktop = useSelector(isViewportAboveDesktopSelector);
  const formatLabelCallback = (value: any) =>
    intl.formatNumber(value, {
      currency: 'NZD',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    });

  const { k: scale } = transformation;
  const excludeDropShadow = useFeature(FeatureFlags.IS_FOYER);

  const nodePriceFontSize = isViewportAboveDesktop ? NODE_PRICE_FONT_SIZE : NODE_PRICE_FONT_SIZE_SMALL;
  const nodePriceYOffset = isViewportAboveDesktop ? NODE_PRICE_Y_OFFSET : NODE_PRICE_Y_OFFSET_SMALL;
  const labelInnerCircleRadius = isViewportAboveDesktop ? LABEL_INNER_CIRCLE_RADIUS : LABEL_INNER_CIRCLE_RADIUS_SMALL;
  const labelOuterCircleRadius = isViewportAboveDesktop ? LABEL_OUTER_CIRCLE_RADIUS : LABEL_OUTER_CIRCLE_RADIUS_SMALL;
  const labelLineStrokeWidth = isViewportAboveDesktop ? LABEL_LINE_STROKE_WIDTH : LABEL_LINE_STROKE_WIDTH_SMALL;

  const scaledLabelWidth = LABEL_WIDTH / scale;
  const scaledLabelHeight = LABEL_HEIGHT / scale;
  const scaledNodeIdFontSize = NODE_ID_FONT_SIZE / scale;
  const scaledNodePriceFontSize = nodePriceFontSize / scale;
  const scaledNodeIdYOffset = NODE_ID_Y_OFFSET / scale;
  const scaledNodePriceYOffset = nodePriceYOffset / scale;
  const scaledLabelOuterCircleRadius = labelOuterCircleRadius / scale;
  const scaledLabelInnerCircleRadius = labelInnerCircleRadius / scale;
  const scaledLabelLineStrokeWidth = labelLineStrokeWidth / scale;
  const scaledLabelRectBorderRadius = LABEL_RECT_BORDER_RADIUS / scale;
  const scaledLabelRectYOffset = scaledLabelHeight / 2;
  const scaledArrowWidth = 2 * scaledLabelOuterCircleRadius + (ARROW_PADDING * 2) / scale;

  const OFFSETS = [63, 233, 71, 111, 50, 267, -281, 120, 106, 182, 129, 101, -268, 128];

  return (
    <motion.g
      className="Map-labels"
      transition={{
        duration: 0,
      }}
    >
      {regions.map((region: IPriceRegion) => {
        const { x, y } = getCoordinates(region, projection);
        const isBeforeIntersection = isPointBeforeIntersection(x, y);

        // const offSet =
        //   (Math.random() * (LABEL_LINE_MAX_LENGTH - LABEL_LINE_MIN_LENGTH) + LABEL_LINE_MIN_LENGTH) / scale;
        const offSet = OFFSETS[region.gridZoneId - 1] / scale;
        const lineXOffset = isBeforeIntersection ? -offSet : offSet;
        const rectXOffset = isBeforeIntersection ? -(offSet + scaledLabelWidth) : offSet;
        const delta = isViewportAboveDesktop ? offSet + LABEL_WIDTH / scale : offSet + LABEL_WIDTH_HALVED / scale;

        // Used to remove the offset when there is no arrow displayed e.g. on mobile
        const arrowMultiplier = isViewportAboveDesktop ? 1 : 0;

        const textXOffset = isBeforeIntersection
          ? -delta + scaledArrowWidth * arrowMultiplier
          : delta + (scaledArrowWidth - scaledLabelWidth) * arrowMultiplier;

        const { backgroundColor, color } = getNodeStyle(region.price, ranges);

        return (
          <g
            key={`GRIDZONE${region.gridZoneId}${v4()}`}
            id={`GRIDZONE${region.gridZoneId}`}
            className={styles.label}
            onMouseOver={() => d3.select(`#GRIDZONE${region.gridZoneId}`).raise()}
            onFocus={() => d3.select(`#GRIDZONE${region.gridZoneId}`).raise()}
          >
            <circle className={styles.labelOuterCirlce} r={scaledLabelOuterCircleRadius} fill="#fff" cx={x} cy={y} />
            <line
              id={`Line-${region.gridZoneId}`}
              className={styles.labelLine}
              x1={x}
              y1={y}
              x2={x + lineXOffset}
              y2={y}
              strokeWidth={scaledLabelLineStrokeWidth}
              stroke="#fff"
            />
            <circle
              className={styles.labelInnerCircle}
              r={scaledLabelInnerCircleRadius}
              fill={backgroundColor}
              cx={x}
              cy={y}
            />
            <rect
              className={styles.labelRect}
              x={x + rectXOffset}
              y={y - scaledLabelRectYOffset}
              width={scaledLabelWidth}
              height={scaledLabelHeight}
              rx={scaledLabelRectBorderRadius}
              fill={backgroundColor}
              filter={excludeDropShadow ? '' : 'url(#dropshadow)'}
            />
            {isViewportAboveDesktop ? (
              <>
                <text
                  fontSize={scaledNodeIdFontSize}
                  className={styles.svgTextPrice}
                  fill={color}
                  x={x + textXOffset}
                  y={y - scaledNodeIdYOffset}
                  textAnchor="left"
                >
                  {region.gridZoneName}
                </text>
                <text
                  fontSize={scaledNodePriceFontSize}
                  textAnchor="left"
                  className={styles.svgTextPrice}
                  fill={color}
                  x={x + textXOffset}
                  y={y + scaledNodePriceYOffset}
                >
                  {`${formatLabelCallback(region.price)}`}
                </text>
              </>
            ) : (
              <text
                className={styles.svgTextPrice}
                textAnchor={isViewportAboveDesktop ? 'left' : 'middle'}
                fill={color}
                x={x + textXOffset}
                y={y - scaledNodePriceYOffset}
                fontSize={scaledNodePriceFontSize}
              >
                {`${formatLabelCallback(region.price)}`}
              </text>
            )}
          </g>
        );
      })}
    </motion.g>
  );
});
