import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './Tooltip.module.scss';

export const TooltipWrapper = ({ children, isLarge = false }: { children: React.ReactNode; isLarge?: boolean }) => (
  <div
    className={classNames(styles.wrapper, {
      [styles.large]: isLarge,
    })}
  >
    {children}
  </div>
);

export const TooltipRow = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.row}> {children}</div>
);

export const BoldTooltipText = ({ text, style }: { text: ReactNode; style: React.CSSProperties }) => (
  <div style={style} className={styles.boldText}>
    {text}
  </div>
);

export const TooltipColorKey = ({ color, border }: { color: string; border?: string }) => (
  <div className={styles.colourKey} style={{ backgroundColor: color, border }} />
);

export const TooltipDelimiter = () => <hr />;

export const TooltipKeyArea = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.keyArea}>{children}</div>
);

export const TooltipValueArea = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.valueArea}>{children}</div>
);
