import { useState } from 'react';
import { TRADER_PRICE_NODE_OPTIONS } from 'redux/modules/nodes/constants';
import { RTPFilterContext } from 'tools/hooks/useRTPFilter';

export const RTPFilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedPriceNode, setSelectedPriceNode] = useState(TRADER_PRICE_NODE_OPTIONS[0]);
  const selectedPriceNodeAsAPIString = selectedPriceNode === 'All nodes' ? '' : selectedPriceNode;
  const value = {
    selectedPriceNode,
    setSelectedPriceNode,
    selectedPriceNodeAsAPIString,
  };
  return <RTPFilterContext.Provider value={value}>{children}</RTPFilterContext.Provider>;
};
