import React from 'react';
import './Spinner.scss';

export const Spinner = () => (
  <div className="Spinner">
    <span className="Spinner-element" />
    <span className="Spinner-element" />
    <span className="Spinner-element" />
    <span className="Spinner-element" />
  </div>
);
