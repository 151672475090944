import {
  IRCPDCriteria,
  IScadaReportCriteria,
  IPriceReportCriteria,
  IArcFlowsReportCriteria,
  IReservesReportCriteria,
} from 'types.d';
import { ReportType } from 'enums.d';

export enum TYPES {
  TRACK_PAGE_VIEW_SUCCESS = '[ANALYTICS]-TRACK_PAGE_VIEW_SUCCESS',
  TRACK_PAGE_VIEW_FAIL = '[ANALYTICS]-TRACK_PAGE_VIEW_FAIL',

  TRACK_REPORT_DOWNLOAD = '[ANALYTICS]-TRACK_REPORT_DOWNLOAD',
  TRACK_REPORT_DOWNLOAD_SUCCESS = '[ANALYTICS]-TRACK_REPORT_DOWNLOAD_SUCCESS',
  TRACK_REPORT_DOWNLOAD_FAIL = '[ANALYTICS]-TRACK_REPORT_DOWNLOAD_FAIL',

  TRACK_REPORT_DOWNLOAD_TIME = '[ANALYTICS]-TRACK_REPORT_DOWNLOAD_TIME',
  TRACK_REPORT_DOWNLOAD_TIME_SUCCESS = '[ANALYTICS]-TRACK_REPORT_DOWNLOAD_TIME_SUCCESS',
  TRACK_REPORT_DOWNLOAD_TIME_FAIL = '[ANALYTICS]-TRACK_REPORT_DOWNLOAD_TIME_FAIL',

  TRACK_FAILED_LOGIN = '[ANALYTICS]-TRACK_FAILED_LOGIN',
  TRACK_FAILED_LOGIN_SUCCESS = '[ANALYTICS]-TRACK_FAILED_LOGIN_SUCCESS',
  TRACK_FAILED_LOGIN_FAIL = '[ANALYTICS]-TRACK_FAILED_LOGIN_FAIL',

  TRACK_PASSWORD_RESET = '[ANALYTICS]-TRACK_PASSWORD_RESET',
  TRACK_PASSWORD_RESET_SUCCESS = '[ANALYTICS]-TRACK_PASSWORD_RESET_SUCCESS',
  TRACK_PASSWORD_RESET_FAIL = '[ANALYTICS]-TRACK_PASSWORD_RESET_FAIL',

  TRACK_CUSTOM_NODE_SELECTION = '[ANALYTICS]-TRACK_CUSTOM_NODE_SELECTION',
  TRACK_CUSTOM_NODE_SELECTION_SUCCESS = '[ANALYTICS]-TRACK_CUSTOM_NODE_SELECTION_SUCCESS',
  TRACK_CUSTOM_NODE_SELECTION_FAIL = '[ANALYTICS]-TRACK_CUSTOM_NODE_SELECTION_FAIL',

  TRACK_FTR_FILTER_SELECTION = '[ANALYTICS]-TRACK_FTR_FILTER_SELECTION',
  TRACK_FTR_FILTER_SELECTION_SUCCESS = '[ANALYTICS]-TRACK_FTR_FILTER_SELECTION_SUCCESS',
  TRACK_FTR_FILTER_SELECTION_FAIL = '[ANALYTICS]-TRACK_FTR_FILTER_SELECTION_FAIL',
}

export interface ITrackPageViewSuccess {
  type: typeof TYPES.TRACK_PAGE_VIEW_SUCCESS;
  payload: {
    url: string;
  };
}

export interface ITrackPageViewFail {
  type: typeof TYPES.TRACK_PAGE_VIEW_FAIL;
  payload: {
    error: any;
  };
}

declare type ReportDownloadCriteria =
  | IRCPDCriteria
  | IScadaReportCriteria
  | IPriceReportCriteria
  | IArcFlowsReportCriteria
  | IReservesReportCriteria;

export interface ReportDownloadEvent {
  criteria: ReportDownloadCriteria;
  reportType: ReportType;
}

export interface ITrackReportDownload {
  type: typeof TYPES.TRACK_REPORT_DOWNLOAD;
  payload: ReportDownloadEvent;
}

export interface ITrackReportDownloadSuccess {
  type: typeof TYPES.TRACK_REPORT_DOWNLOAD_SUCCESS;
}

export interface ITrackReportDownloadFail {
  type: typeof TYPES.TRACK_REPORT_DOWNLOAD_FAIL;
  payload: {
    error: any;
  };
}

export interface ITrackReportDownloadTime {
  type: typeof TYPES.TRACK_REPORT_DOWNLOAD_TIME;
  payload: {
    reportType: ReportType;
  };
}

export interface ITrackReportDownloadTimeSuccess {
  type: typeof TYPES.TRACK_REPORT_DOWNLOAD_TIME_SUCCESS;
}

export interface ITrackReportDownloadTimeFail {
  type: typeof TYPES.TRACK_REPORT_DOWNLOAD_TIME_FAIL;
  payload: {
    error: any;
  };
}

export interface ITrackFailedLogin {
  type: typeof TYPES.TRACK_FAILED_LOGIN;
  payload: {
    email: string;
  };
}

export interface ITrackFailedLoginSuccess {
  type: typeof TYPES.TRACK_FAILED_LOGIN_SUCCESS;
}

export interface ITrackFailedLoginFail {
  type: typeof TYPES.TRACK_FAILED_LOGIN_FAIL;
  payload: {
    error: any;
  };
}

export interface ITrackPasswordReset {
  type: typeof TYPES.TRACK_PASSWORD_RESET;
  payload: {
    email: string;
  };
}

export interface ITrackPasswordResetSuccess {
  type: typeof TYPES.TRACK_PASSWORD_RESET_SUCCESS;
}

export interface ITrackPasswordResetFail {
  type: typeof TYPES.TRACK_PASSWORD_RESET_FAIL;
  payload: {
    error: any;
  };
}

export interface ITrackCustomNodeSelection {
  type: typeof TYPES.TRACK_CUSTOM_NODE_SELECTION;
  payload: {
    nodes: string[];
  };
}

export interface ITrackCustomNodeSelectionSuccess {
  type: typeof TYPES.TRACK_CUSTOM_NODE_SELECTION_SUCCESS;
}

export interface ITrackCustomNodeSelectionFail {
  type: typeof TYPES.TRACK_CUSTOM_NODE_SELECTION_FAIL;
  payload: {
    error: any;
  };
}

export interface ITrackFTRFilterSelection {
  type: typeof TYPES.TRACK_FTR_FILTER_SELECTION;
}

export interface ITrackFTRFilterSelectionSuccess {
  type: typeof TYPES.TRACK_FTR_FILTER_SELECTION_SUCCESS;
}

export interface ITrackFTRFilterSelectionFail {
  type: typeof TYPES.TRACK_FTR_FILTER_SELECTION_FAIL;
  payload: {
    error: any;
  };
}

export function actionTrackPageViewSuccess(url: string): ITrackPageViewSuccess {
  return {
    type: TYPES.TRACK_PAGE_VIEW_SUCCESS,
    payload: {
      url,
    },
  };
}

export function actionTrackPageViewFail(error: any): ITrackPageViewFail {
  return {
    type: TYPES.TRACK_PAGE_VIEW_FAIL,
    payload: {
      error,
    },
  };
}

export function actionTrackReportDownload(reportDownloadEventPayload: ReportDownloadEvent): ITrackReportDownload {
  return {
    type: TYPES.TRACK_REPORT_DOWNLOAD,
    payload: reportDownloadEventPayload,
  };
}

export function actionTrackReportDownloadSuccess(): ITrackReportDownloadSuccess {
  return {
    type: TYPES.TRACK_REPORT_DOWNLOAD_SUCCESS,
  };
}

export function actionTrackReportDownloadFail(error: any): ITrackReportDownloadFail {
  return {
    type: TYPES.TRACK_REPORT_DOWNLOAD_FAIL,
    payload: {
      error,
    },
  };
}

export function actionTrackReportDownloadTime(reportType: ReportType): ITrackReportDownloadTime {
  return {
    type: TYPES.TRACK_REPORT_DOWNLOAD_TIME,
    payload: {
      reportType,
    },
  };
}

export function actionTrackReportDownloadTimeSuccess(): ITrackReportDownloadTimeSuccess {
  return {
    type: TYPES.TRACK_REPORT_DOWNLOAD_TIME_SUCCESS,
  };
}

export function actionTrackReportDownloadTimeFail(error: any): ITrackReportDownloadTimeFail {
  return {
    type: TYPES.TRACK_REPORT_DOWNLOAD_TIME_FAIL,
    payload: {
      error,
    },
  };
}

export function actionTrackFailedLogin(email: string): ITrackFailedLogin {
  return {
    type: TYPES.TRACK_FAILED_LOGIN,
    payload: {
      email,
    },
  };
}

export function actionTrackFailedLoginSuccess(): ITrackFailedLoginSuccess {
  return {
    type: TYPES.TRACK_FAILED_LOGIN_SUCCESS,
  };
}

export function actionTrackFailedLoginFail(error: any): ITrackFailedLoginFail {
  return {
    type: TYPES.TRACK_FAILED_LOGIN_FAIL,
    payload: {
      error,
    },
  };
}

export function actionTrackPasswordReset(email: string): ITrackPasswordReset {
  return {
    type: TYPES.TRACK_PASSWORD_RESET,
    payload: {
      email,
    },
  };
}

export function actionTrackPasswordResetSuccess(): ITrackPasswordResetSuccess {
  return {
    type: TYPES.TRACK_PASSWORD_RESET_SUCCESS,
  };
}

export function actionTrackPasswordResetFail(error: any): ITrackPasswordResetFail {
  return {
    type: TYPES.TRACK_PASSWORD_RESET_FAIL,
    payload: {
      error,
    },
  };
}

export function actionTrackCustomNodeSelection(nodes: string[]): ITrackCustomNodeSelection {
  return {
    type: TYPES.TRACK_CUSTOM_NODE_SELECTION,
    payload: {
      nodes,
    },
  };
}

export function actionTrackCustomNodeSelectionSuccess(): ITrackCustomNodeSelectionSuccess {
  return {
    type: TYPES.TRACK_CUSTOM_NODE_SELECTION_SUCCESS,
  };
}

export function actionTrackCustomNodeSelectionFail(error: any): ITrackCustomNodeSelectionFail {
  return {
    type: TYPES.TRACK_CUSTOM_NODE_SELECTION_FAIL,
    payload: {
      error,
    },
  };
}

export function actionTrackFTRFilterSelection(): ITrackFTRFilterSelection {
  return {
    type: TYPES.TRACK_FTR_FILTER_SELECTION,
  };
}

export function actionTrackFTRFilterSelectionSuccess(): ITrackFTRFilterSelectionSuccess {
  return {
    type: TYPES.TRACK_FTR_FILTER_SELECTION_SUCCESS,
  };
}

export function actionTrackFTRFilterSelectionFail(error: any): ITrackFTRFilterSelectionFail {
  return {
    type: TYPES.TRACK_FTR_FILTER_SELECTION_FAIL,
    payload: {
      error,
    },
  };
}
