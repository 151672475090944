import { useSelector } from 'react-redux';
import { isViewportAboveMobile as isViewportAboveMobileSelector } from 'redux/modules/app/selectors';
import { isPointBeforeIntersection } from 'views/components/FreeMap/map-utilities';

interface IUseMapNodesLabelOffSetsProps {
  x: number;
  y: number;
  offSet: number;
  labelWidth: number;
  arrowWidth: number;
  delta: number;
}

/**
 * Returns the offset to be applied to the elements that compose the map label for a certain node
 * @param param0 Coordinates and measures for the map label for a specific node
 * @returns Offset measures to place the rectangle, line and text used for that node's map label
 */
export const useMapNodesLabelOffsets = ({
  x,
  y,
  offSet,
  labelWidth,
  arrowWidth,
  delta,
}: IUseMapNodesLabelOffSetsProps) => {
  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);
  // Used to remove the offset when there is no arrow displayed e.g. on mobile
  const arrowMultiplier = isViewportAboveMobile ? 1 : 0;
  const isBeforeIntersection = isPointBeforeIntersection(x, y);

  if (isBeforeIntersection) {
    return {
      lineXOffset: -offSet,
      rectXOffset: -(offSet + labelWidth),
      textXOffset: -delta + arrowWidth * arrowMultiplier,
    };
  }

  return {
    lineXOffset: offSet,
    rectXOffset: offSet,
    textXOffset: delta + (arrowWidth - labelWidth) * arrowMultiplier,
  };
};
