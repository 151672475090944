import { ReportType } from 'enums.d';
import { ReportDownloadEvent } from 'redux/modules/analytics/actions';
import { IPriceReportCriteria, IReservesReportCriteria, IArcFlowsReportCriteria, IScadaReportCriteria } from 'types.d';

export const setAnalyticsDataForReport = (reportDownload: ReportDownloadEvent) => {
  const { reportType, criteria } = reportDownload;

  if (reportType === ReportType.PRICE) {
    const {
      priceOption,
      dateOption,
      fromTradingDate,
      toTradingDate,
      toTradingPeriod,
      fromTradingPeriod,
      priceRunType,
    } = criteria as IPriceReportCriteria;

    return {
      dimension1: priceOption,
      dimension2: dateOption,
      dimension3: fromTradingDate,
      dimension4: toTradingDate,
      dimension5: toTradingPeriod,
      dimension6: fromTradingPeriod,
      dimension7: priceRunType,
    };
  }

  if (reportType === ReportType.RESERVES) {
    const { dateOption, toTradingDate, fromTradingDate, reservesReserveType, reservesIsland, reservesPowerType } =
      criteria as IReservesReportCriteria;

    return {
      dimension1: dateOption,
      dimension2: toTradingDate,
      dimension3: fromTradingDate,
      dimension4: reservesReserveType,
      dimension5: reservesIsland,
      dimension6: reservesPowerType,
    };
  }

  if (reportType === ReportType.ARC_FLOWS) {
    const { dateOption, fromTradingDate, arcFlowsRunType } = criteria as IArcFlowsReportCriteria;

    return {
      dimension1: dateOption,
      dimension2: fromTradingDate,
      dimension3: arcFlowsRunType,
    };
  }

  // It's SCADA otherwise
  // Don't mind me, just repeat SCADA DATA
  // It's fun, you'll see.
  const { nodeType, hvdcGranularity, dateOption, fromTradingDate, toTradingDate } = criteria as IScadaReportCriteria;

  return {
    dimension1: nodeType,
    dimension2: hvdcGranularity,
    dimension3: dateOption,
    dimension4: fromTradingDate,
    dimension5: toTradingDate,
  };
};
