import { useEffect } from 'react';

export const useToggleBodyClass = (condition: boolean, classes: string[]) => {
  useEffect(() => {
    if (condition) {
      document.body.classList.add(...classes);
    }

    return () => document.body.classList.remove(...classes);
  }, [condition, classes]);
};
