import React from 'react';

import './GraphKey.scss';

import { IKeyItem } from 'types.d';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { InfoTip } from '../InfoTip/InfoTip';

interface IProps {
  keyItems: IKeyItem[];
  classname?: string;
  useSingleColumnAtMobile?: boolean;
  onClick?: Function;
  hiddenSeries?: string[];
  columns?: number;
}

export const GraphKey = ({ keyItems, classname, useSingleColumnAtMobile, onClick=undefined, hiddenSeries=[], columns=undefined }: IProps) => {

  const handleOnClick = (event: any, {item, idx}: any) => {
    event.preventDefault()
    onClick && onClick(item, idx)
  }

  const isViewpAboveMobile = useSelector(isViewportAboveMobile);
  const columnStyle: any = columns ? {columns: isViewpAboveMobile ? columns : 2} : {display: 'flex', flexWrap: 'wrap'}
  const linkStyle: any = onClick ? {cursor: 'pointer'} : {cursor: 'auto'}

  return (
    <div className="GraphKey">
      <div className="GraphKey-body">
        <ul
          className={classNames(`GraphKey-body-list`, classname, {
            'single-column': useSingleColumnAtMobile,
          })}
          style={columnStyle}
        >
          {keyItems.map((item, idx) => {
            const { colour, label, style, fill, key, tooltip } = item
            const hidden = hiddenSeries.includes(key || '')

            return (
              <li key={label} className="GraphKey-body-list-item">
                <a href="" onClick={event => handleOnClick(event, {item, idx})} style={linkStyle}>
                <svg className="GraphKey-svg" viewBox="0 0 30 5">
                  <rect
                    x="0"
                    y="0"
                    rx="5"
                    ry="5"
                    width="30"
                    height="5"
                    fill={fill}
                    opacity={hidden ? 0.3 : 1}
                    stroke={colour}
                    strokeDasharray={style?.strokeDasharray}
                    strokeWidth={style?.strokeWidth}
                  />
                </svg>
                {/* This is for a dashed legend item. Keeping this in case we change it later. */}
                {/* <svg className="GraphKey-svg" viewBox="0 0 30 5"> */}
                {/*   {style?.strokeDasharray ? ( */}
                {/*     <> */}
                {/*       <rect x="0" y="0" rx="2.5" ry="2.5" width="7" height="5" fill={colour || fill} /> */}
                {/*       <rect x="10" y="0" rx="2.5" ry="2.5" width="10" height="5" fill={colour || fill} /> */}
                {/*       <rect x="23" y="0" rx="2.5" ry="2.5" width="7" height="5" fill={colour || fill} /> */}
                {/*     </> */}
                {/*   ) : ( */}
                {/*     <rect */}
                {/*       x="0" */}
                {/*       y="0" */}
                {/*       rx="5" */}
                {/*       ry="5" */}
                {/*       width="30" */}
                {/*       height="5" */}
                {/*       fill={fill} */}
                {/*       stroke={colour} */}
                {/*       strokeDasharray={style?.strokeDasharray} */}
                {/*       strokeWidth={style?.strokeWidth} */}
                {/*     /> */}
                {/*   )} */}
                {/* </svg> */}
                <span className="GraphKey-label">
                  {label}
                </span>
                {tooltip && <div><InfoTip content={tooltip} /></div>}
                </a>
              </li>
            )}
          )}
        </ul>
      </div>
    </div>
  )
}
