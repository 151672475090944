import classNames from 'classnames';
import { v4 } from 'uuid';
import { InfoTip } from '../../InfoTip/InfoTip';
import styles from './RTPLegend.module.scss';
// Yet another legend class to accomade a slightly newer design pattern without breaking the old legends

export interface ILegendItem {
  color: string;
  label: string;
  tooltip?: string;
  dotted?: boolean;
}

const LegendItem = ({ color, label, tooltip, dotted }: ILegendItem) => (
  <div className={styles.legendItem}>
    <div
      className={classNames(styles.legendItemColor, { [styles.dotted]: dotted })}
      style={{ backgroundColor: color }}
    />
    <div className={styles.legendItemLabel}>{label}</div>
    {tooltip && <InfoTip content={tooltip} />}
  </div>
);

interface IRTPLegendProps {
  legendItems: ILegendItem[];
  className?: string;
}

export const RTPLegend = ({ legendItems, className }: IRTPLegendProps) => (
  <div className={classNames(styles.wrapper, className)}>
    {legendItems.map((item) => (
      <LegendItem key={v4()} {...item} />
    ))}
  </div>
);
