import moment from 'moment';
import { useQuery } from 'react-query';
import { makeGetRequest } from 'tools/utilities/ajax';
import { useHalfHourTimer } from './useHalfHourTimer';
import { useRTPFilter } from './useRTPFilter';

export interface INRSSResponse {
  trading_date: string;
  trading_period: number;
  node_id: string;
  run_time: string;
  run_type: string;
  price: number;
}

export interface INodeReading {
  nodeid: string;
  price: number;
}
export interface INewResponse {
  runtime: string;
  timestamp: string;
  nodereadings: INodeReading[];
}

// export interface IDispatchPriceResponse {
//   trading_date: string; // only gives us the DAY of the dispatch price
//   time_stamp: string; // gives us the time of the dispatch price in format HH:mm
//   trading_period: number;
//   node_id: string;
//   price: number;
//   price_tp_average: number; // This should align with the interim data at the end of each TP not given in the
// }
export interface IDispatchPriceResponse {
  trading_date: string; // only gives us the DAY of the dispatch price
  time_stamp: string; // gives us the time of the dispatch price in format HH:mm
  trading_period: number;
  node_id: string;
  price: number;
  price_tp_average: number; // This should align with the interim data at the end of each TP not given in the
}

export interface IInterimPriceResponse {
  trading_date: string;
  trading_period: number;
  node_id: string;
  run_time: string;
  run_type: string;
  price: number;
}

const today = new Date().toLocaleDateString('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

const ONE_MINUTE = 60 * 1000;

const queryConfigs = {
  staleTime: 1,
  refetchInterval: ONE_MINUTE,
  refetchIntervalInBackground: true,
  refetchOnWindowFocus: true,
};

export const useRTPApiData = () => {
  const { selectedPriceNodeAsAPIString } = useRTPFilter();
  const halfHourTimeout = useHalfHourTimer();

  const { data: dispatchData } = useQuery<{
    items: INewResponse[];
    updated: number;
  }>(
    ['rtpprice', selectedPriceNodeAsAPIString, halfHourTimeout],
    () =>
      makeGetRequest(`dispatch/price/?node_id=${selectedPriceNodeAsAPIString}`).then((resp: any) => ({
        ...resp.data,
        updated: moment().valueOf(),
      })),
    queryConfigs,
  );

  const { data: nrssData } = useQuery<{ updated: number; items: INRSSResponse[] }>(
    ['rtppricenrss', selectedPriceNodeAsAPIString, halfHourTimeout],
    () =>
      makeGetRequest(`market_price/${selectedPriceNodeAsAPIString}?run_type=NR&trading_date=${today}`).then(
        (resp: any) => ({
          ...resp.data,
          updated: moment().valueOf(),
        }),
      ),
    queryConfigs,
  );

  const { data: interimData } = useQuery<{
    updated: number;
    items: IInterimPriceResponse[];
  }>(
    ['rtppriceinterim', selectedPriceNodeAsAPIString, halfHourTimeout],
    () =>
      makeGetRequest(`market_price/${selectedPriceNodeAsAPIString}?run_type=T&trading_date=${today}`).then(
        (resp: any) => ({
          ...resp.data,
          updated: moment().valueOf(),
        }),
      ),
    queryConfigs,
  );

  return {
    dispatchData,
    nrssData,
    interimData,
    updated: Math.max(
      dispatchData?.updated || Number.NEGATIVE_INFINITY,
      nrssData?.updated || Number.NEGATIVE_INFINITY,
      interimData?.updated || Number.NEGATIVE_INFINITY,
    ),
    isFetching: !dispatchData?.items || !nrssData?.items || !interimData?.items,
  };
};

const mockInterim = {
  items: [
    {
      trading_date: '13/12/2022',
      trading_period: 1,
      node_id: 'BEN2201',
      run_time: '13/12/2022 00:30:00',
      run_type: 'T',
      price: 18,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 2,
      node_id: 'BEN2201',
      run_time: '13/12/2022 01:00:00',
      run_type: 'T',
      price: 18,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 3,
      node_id: 'BEN2201',
      run_time: '13/12/2022 01:30:00',
      run_type: 'T',
      price: 18,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 4,
      node_id: 'BEN2201',
      run_time: '13/12/2022 02:00:00',
      run_type: 'T',
      price: 17.98,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 5,
      node_id: 'BEN2201',
      run_time: '13/12/2022 02:30:00',
      run_type: 'T',
      price: 17.58,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 6,
      node_id: 'BEN2201',
      run_time: '13/12/2022 03:00:00',
      run_type: 'T',
      price: 10.54,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 7,
      node_id: 'BEN2201',
      run_time: '13/12/2022 03:30:00',
      run_type: 'T',
      price: 7.06,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 8,
      node_id: 'BEN2201',
      run_time: '13/12/2022 04:00:00',
      run_type: 'T',
      price: 1.12,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 9,
      node_id: 'BEN2201',
      run_time: '13/12/2022 04:30:00',
      run_type: 'T',
      price: 4.05,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 10,
      node_id: 'BEN2201',
      run_time: '13/12/2022 05:00:00',
      run_type: 'T',
      price: 7.93,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 11,
      node_id: 'BEN2201',
      run_time: '13/12/2022 05:30:00',
      run_type: 'T',
      price: 13.97,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 12,
      node_id: 'BEN2201',
      run_time: '13/12/2022 06:00:00',
      run_type: 'T',
      price: 16.69,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 13,
      node_id: 'BEN2201',
      run_time: '13/12/2022 06:30:00',
      run_type: 'T',
      price: 26.22,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 14,
      node_id: 'BEN2201',
      run_time: '13/12/2022 07:00:00',
      run_type: 'T',
      price: 39.4,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 15,
      node_id: 'BEN2201',
      run_time: '13/12/2022 07:30:00',
      run_type: 'T',
      price: 55.4,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 16,
      node_id: 'BEN2201',
      run_time: '13/12/2022 08:00:00',
      run_type: 'T',
      price: 120.54,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 17,
      node_id: 'BEN2201',
      run_time: '13/12/2022 08:30:00',
      run_type: 'T',
      price: 149.19,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 18,
      node_id: 'BEN2201',
      run_time: '13/12/2022 09:00:00',
      run_type: 'T',
      price: 80.4,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 19,
      node_id: 'BEN2201',
      run_time: '13/12/2022 09:30:00',
      run_type: 'T',
      price: 191.78,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 20,
      node_id: 'BEN2201',
      run_time: '13/12/2022 10:00:00',
      run_type: 'T',
      price: 99.31,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 21,
      node_id: 'BEN2201',
      run_time: '13/12/2022 10:30:00',
      run_type: 'T',
      price: 122.18,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 22,
      node_id: 'BEN2201',
      run_time: '13/12/2022 11:00:00',
      run_type: 'T',
      price: 120.33,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 23,
      node_id: 'BEN2201',
      run_time: '13/12/2022 11:30:00',
      run_type: 'T',
      price: 119.19,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 24,
      node_id: 'BEN2201',
      run_time: '13/12/2022 12:00:00',
      run_type: 'T',
      price: 119.78,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 25,
      node_id: 'BEN2201',
      run_time: '13/12/2022 12:30:00',
      run_type: 'T',
      price: 201.36,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 26,
      node_id: 'BEN2201',
      run_time: '13/12/2022 13:00:00',
      run_type: 'T',
      price: 143.38,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 27,
      node_id: 'BEN2201',
      run_time: '13/12/2022 13:30:00',
      run_type: 'T',
      price: 95.7,
    },
    {
      trading_date: '13/12/2022',
      trading_period: 28,
      node_id: 'BEN2201',
      run_time: '13/12/2022 14:00:00',
      run_type: 'T',
      price: 109.97,
    },
  ],
  hasMore: false,
  limit: 0,
  offset: 0,
  count: 28,
  links: [
    {
      rel: 'self',
      href: 'https://api.em6.co.nz/ords/em6/data_api/market_price/BEN2201?run_type=T&trading_date=13%2F12%2F2022',
    },
    { rel: 'describedby', href: 'https://api.em6.co.nz/ords/em6/metadata-catalog/data_api/market_price/item' },
  ],
};
