import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, CartesianGrid, YAxis, Tooltip, Line, XAxis } from 'recharts';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isViewportAboveMobile, isViewportAboveTablet } from 'redux/modules/app/selectors';
import { useHistoricGenType } from 'tools/hooks/PaidGwap/useHistoricGenType';
import { ResizeState } from 'tools/providers/ResizeProvider';
import { gwapGenTypeFilterState } from 'tools/state/gwapState';
import { gwapHoveredTimestamp, isAtLeastOneFilterNotSelectedSelector } from 'tools/state/paidGwapState';
import {
  formatGwapXAxisDate,
  getGraphEntryForTimestamp,
  GwapGenerationType,
  GwapTimeInterval,
  GWAP_GENERATION_TYPE_OPTIONS,
  NZ_GENERATION_SUMMARY_LABELS,
  PaidGwapGraphMargins,
  useGwapBarDimensions,
} from 'tools/utilities/gwap';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { GWAPDelimiter } from 'views/pages/GWAP/GWAPDelimiter';
import { LegendBox, LegendBoxRow } from '../Legend/LegendBox';
import { TooltipRow, TooltipWrapper } from '../Tooltip/Tooltip';
import { GraphNotice } from './GraphNotice';
import { GwapChartErrorBoundary } from './GwapChartErrorBoundary';
import styles from './HistoricGenType.module.scss';

const RenderTooltip = ({ graphData }: { graphData: any[] }) => {
  const genTypeFilters = useRecoilValue(gwapGenTypeFilterState);
  const filteredGenTypes = NZ_GENERATION_SUMMARY_LABELS.filter((option) =>
    genTypeFilters.includes(option.generationType),
  );
  const timestamp = useRecoilValue(gwapHoveredTimestamp);
  const hoveredData = getGraphEntryForTimestamp(timestamp, graphData);

  if (!hoveredData) {
    return <></>;
  }

  return (
    <TooltipWrapper>
      {filteredGenTypes.map((label: any) => (
        <TooltipRow key={label.generationType}>
          <span> {label.label} </span>
          <span>{roundToDpWithCommas(getLegendValueByLabel(hoveredData, label), 2) ?? '0.00'} MW</span>
        </TooltipRow>
      ))}
    </TooltipWrapper>
  );
};

export const HistoricGenType = () => {
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const isViewAboveTablet = useSelector(isViewportAboveTablet);
  const setHoveredimestamp = useSetRecoilState(gwapHoveredTimestamp);

  const { historicGenTypes, isFetching } = useHistoricGenType();
  const isOneFilterNotSelected = useRecoilValue(isAtLeastOneFilterNotSelectedSelector);
  const isDataAvailable = historicGenTypes && historicGenTypes.length > 0 && !isOneFilterNotSelected;
  const isResizing = useRecoilValue(ResizeState);
  const { width, height } = useGwapBarDimensions();

  const HistoricGenTypeWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.heading}>NZ Generation (MW)</h3>
        <GWAPDelimiter />
      </div>
      <div className={styles.graphArea}>
        <GwapChartErrorBoundary>{children}</GwapChartErrorBoundary>
      </div>
    </div>
  );

  if (isFetching || isResizing) {
    return (
      <HistoricGenTypeWrapper>
        <GraphNotice message="Loading graph data..." width={width} height={height} />
        {isViewAboveTablet && <Legend graphData={historicGenTypes} />}
      </HistoricGenTypeWrapper>
    );
  }

  if (!isDataAvailable) {
    return (
      <HistoricGenTypeWrapper>
        <GraphNotice width={width} height={height} />
        {isViewAboveTablet && <Legend graphData={historicGenTypes} />}
      </HistoricGenTypeWrapper>
    );
  }

  return (
    <HistoricGenTypeWrapper>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          throttleDelay={50}
          syncId={1}
          width={844}
          data={historicGenTypes}
          margin={PaidGwapGraphMargins(isViewAboveTablet)}
        >
          <CartesianGrid strokeOpacity={0.3} vertical={false} strokeDasharray="3 1" />
          <YAxis
            domain={[
              // (dataMin: any) => Math.max(dataMin - (dataMin % 5) - 10, 0),
              (dataMin: any) => (dataMin < 0 ? 0 : dataMin),
              (dataMax: any) => dataMax - (dataMax % 5) + 10,
            ]}
            tickFormatter={(value: any, index: number) => roundToDpWithCommas(value, 0)}
            tick={{ stroke: 'white', fontSize: '12px', strokeWidth: 0.75 }}
          />
          {!isViewAboveTablet && (
            <XAxis
              tickFormatter={(date) => formatGwapXAxisDate(date, GwapTimeInterval.DAILY)}
              minTickGap={isViewAboveMobile ? 100 : 75}
              tick={{ stroke: 'white', fontSize: '12px', strokeWidth: 0.75 }}
              dataKey="timestamp"
            />
          )}
          <Tooltip
            content={(data: any) => {
              const { timestamp } = data?.payload?.[0]?.payload ?? {};
              setHoveredimestamp(timestamp);

              if (isViewAboveTablet) {
                return '';
              }

              return <RenderTooltip graphData={historicGenTypes} />;
            }}
          />
          <Line
            key="Co-Gen_mw"
            type="linear"
            dataKey="Co-Gen_mw"
            dot={false}
            stroke="rgb(0, 169, 165)"
            fill="rgb(0, 169, 165)"
          />
          <Line
            key="Liquid_mw"
            type="linear"
            dataKey="Liquid_mw"
            dot={false}
            stroke="rgb(207, 0, 89)"
            fill="rgb(207, 0, 89)"
          />
          {GWAP_GENERATION_TYPE_OPTIONS.map((key, value) => (
            <Line
              key={`${key.label}_mw`}
              type="linear"
              dataKey={`${key.label}_mw`}
              dot={false}
              stroke={key.color}
              fill={key.color}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      {isViewAboveTablet && <Legend graphData={historicGenTypes} />}
    </HistoricGenTypeWrapper>
  );
};

const getLegendValueByLabel = (hoveredData: any, label: any) => {
  switch (label.generationType) {
    case GwapGenerationType.COG:
      return hoveredData?.['Co-Gen_mw'];
    case GwapGenerationType.LIQ:
      return hoveredData?.Liquid_mw ?? '';
    default:
      return hoveredData?.[`${label.label}_mw`];
  }
};

const Legend = ({ graphData }: { graphData: any[] }) => {
  const genTypeFilters = useRecoilValue(gwapGenTypeFilterState);
  const filteredGenTypes = NZ_GENERATION_SUMMARY_LABELS.filter((option) =>
    genTypeFilters.includes(option.generationType),
  );
  // Need this secondary array to maintain spacing in the summary table
  const filteredOutGenTypes = NZ_GENERATION_SUMMARY_LABELS.filter(
    (option) => !genTypeFilters.includes(option.generationType),
  );
  const timestamp = useRecoilValue(gwapHoveredTimestamp);
  const hoveredData = getGraphEntryForTimestamp(timestamp, graphData);

  return (
    <LegendBox>
      {!hoveredData && <div className={styles.hoverCTA}>Please hover on the graph for specific readings</div>}
      {hoveredData && (
        <>
          {filteredGenTypes.map((label) => (
            <LegendBoxRow
              colour={label.colour}
              label={label.label}
              suffix="MW"
              value={getLegendValueByLabel(hoveredData, label)}
              key={label.label}
            />
          ))}
          {filteredOutGenTypes.map((label) => (
            <LegendBoxRow isPlaceholder colour="#fff00" label="" value="" key={label.label} />
          ))}
        </>
      )}
    </LegendBox>
  );
};
