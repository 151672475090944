/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import './RCPDGraph.scss';

import { IRCPDCriteria } from 'types.d';

import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { RCPD_2HR_KEY_ITEMS } from 'redux/modules/nodes/constants';
import { getLatestRCPD2hrDatum, getRCPD2hrData } from 'redux/modules/nodes/selectors';

import { formatDataForChart } from 'tools/utilities/charts';
import { useDataPoller } from 'tools/hooks/useDataPoller';
import { actionGet2HrsRcpd } from 'redux/modules/nodes/actions';
import {
  ResponsiveContainer,
  ComposedChart,
  YAxis,
  XAxis,
  Line,
  Area,
  Tooltip,
  TooltipProps,
  ReferenceLine,
} from 'recharts';

import {
  formatMillisecondsToTime,
  getArrayOf15MinuteIntervalsAsMillisForLast2Hours,
  getCurrentTradingPeriod,
} from 'tools/utilities/date';
import { min, max, scaleLinear } from 'd3';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipWrapper, TooltipRow } from '../Tooltip/Tooltip';

interface Props {
  criteria: IRCPDCriteria;
}

interface IRawDataPoint {
  date: Date;
  rcpd_1_mw: number;
  trading_period: number;
  value: number;
}

interface IDataPointWithMillis extends IRawDataPoint {
  millis: number;
  average: number;
  minPeak?: number;
}

const mobileHeight = 500;
const tabletHeight = 800;

const ONE_MINUTE = 60000;
const Y_TRUNCATION_BUFFER = 2;
const Y_DOMAIN_OFFSET = 2;

export const RcpdGraph2hr = ({ criteria }: Props) => {
  const isAboveTablet = useSelector(isViewportAboveTabletSelector);

  const rpcd2hrData = useSelector(getRCPD2hrData);

  useDataPoller(actionGet2HrsRcpd, ONE_MINUTE, criteria);

  if (!rpcd2hrData) {
    return null;
  }

  const periodData = formatDataForChart(rpcd2hrData.period_data, 'rcpd_1_mw');

  const { current_tp_avg } = rpcd2hrData.region_peaks[0];

  const axisData: IRawDataPoint[] = [...periodData];

  // Truncate graph for better readability. See https://transpower.atlassian.net/browse/EM6-54
  // Some values are null, which get converted to zero, so skip them for the minimum
  const minYVal = min(axisData, (d: any) => +d.value || Number.POSITIVE_INFINITY) || 0;
  const maxYVal = max(axisData, (d: any) => +d.value || Number.NEGATIVE_INFINITY) || 0;
  const yBaseline = minYVal ? minYVal - Y_TRUNCATION_BUFFER : 0;

  const yScale = scaleLinear()
    .domain([yBaseline, maxYVal + Y_DOMAIN_OFFSET])
    .range([400 * 0.75, 0]);

  const d3YTicks = yScale.ticks(400 / 90).map((d, i) => d);
  const xTicks = getArrayOf15MinuteIntervalsAsMillisForLast2Hours();

  // map to add millis for x axis
  const dataWithMillis: IDataPointWithMillis[] = axisData.map((d) => ({
    ...d,
    average: current_tp_avg,
    millis: d.date.getTime(),
    minPeak: minYVal,
  }));

  return (
    <div className="RCPDGraph">
      <ResponsiveContainer
        width="99%"
        aspect={isAboveTablet ? 2.5 : undefined}
        height={isAboveTablet ? 'unset' : mobileHeight}
      >
        <ComposedChart margin={{ top: 20 }} data={dataWithMillis}>
          <YAxis
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            ticks={d3YTicks}
            domain={[
              (dataMin: number) => Math.floor((dataMin - Y_TRUNCATION_BUFFER) / 10) * 10,
              (dataMax: number) => Math.ceil((dataMax + Y_TRUNCATION_BUFFER) / 10) * 10,
            ]}
            axisLine={false}
            tickLine={false}
            type="number"
          />
          <XAxis
            tickFormatter={formatMillisecondsToTime}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            ticks={xTicks}
            axisLine={false}
            tickLine={false}
            domain={[(minPoint: number) => minPoint, (maxPoint: number) => maxPoint]}
            type="number"
            dataKey="millis"
          />
          {/* Make a reference line for each yTick */}
          <Area
            strokeWidth={2}
            fill="#B1E9E6"
            stroke="#B1E9E6"
            key="rcpd_1_mw"
            type="monotone"
            dot={false}
            dataKey="value"
          />
          <Line key="average" strokeWidth={2} type="monotone" dot={false} dataKey="average" stroke="#ed8b00" />
          {d3YTicks.map((tick: number) => (
            <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
          {xTicks.map((tick: number) => (
            <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}

          <Tooltip content={CustomTooltip} />
        </ComposedChart>
      </ResponsiveContainer>

      <GraphKey keyItems={RCPD_2HR_KEY_ITEMS} />
    </div>
  );
};

const CustomTooltip = ({ payload }: TooltipProps<string, string>) => {
  if (!payload?.[0]) {
    return null;
  }
  const dataPoint: IDataPointWithMillis = payload[0].payload;
  return (
    <TooltipWrapper>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Time:</strong>
        <span>{formatMillisecondsToTime(dataPoint.millis)}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Reading:</strong>
        <span>{dataPoint.value} MW</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Minimum Peak:</strong>
        <span>{dataPoint.minPeak} MW</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>TP {getCurrentTradingPeriod()} Average:</strong>
        <span>{dataPoint.average} MW</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};
