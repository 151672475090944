import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './Reports.scss';

import { ArcFlowsSelectionCriteria } from 'views/components/ArcFlowsSelectionCriteria/ArcFlowsSelectionCriteria';
import { actionSetSelectedNodes } from 'redux/modules/nodes/actions';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';

export const ArcFlowsReports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionSetSelectedNodes([]));
  }, [dispatch]);

  const renderSelectionCriteria = () => <ArcFlowsSelectionCriteria />;

  return (
    <AccessRestrictionWrapper isInline accessScopeRequired={UserAccessScope.ARC_FLOWS}>
      <div className="Container">
        <div className="Reports-content">{renderSelectionCriteria()}</div>
      </div>
    </AccessRestrictionWrapper>
  );
};
