import { createContext, useContext } from 'react';
import { TRADER_LOAD_NODE_OPTIONS, TRADER_LOAD_REGION_OPTIONS } from 'redux/modules/nodes/constants';

export enum ToggleState {
  REGIONS = 'Regions',
  NODES = 'Nodes',
}

// Rewrite the following code to use the new context:
interface ILoadForecastFilterContext {
  selectedLoadNode: string;
  setSelectedLoadNode: (node: string) => void;
  selectedLoadRegion: string;
  setSelectedLoadRegion: (node: string) => void;
  toggleValue: ToggleState;
  setToggleValue: (value: ToggleState) => void;
}

const defaultValue = {
  selectedLoadNode: TRADER_LOAD_NODE_OPTIONS[0],
  setSelectedLoadNode: () => {},
  selectedLoadRegion: TRADER_LOAD_REGION_OPTIONS[0],
  setSelectedLoadRegion: () => {},
  toggleValue: ToggleState.REGIONS,
  setToggleValue: () => {},
};

export const LoadForecastFilterContext = createContext<ILoadForecastFilterContext>(defaultValue);
export const useLoadForecastFilter = () => useContext(LoadForecastFilterContext);
