import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import './Navigation.scss';

import { isLoggedIn as isLoggedInSelector } from 'redux/modules/session/selectors';
import { TYPES } from 'redux/modules/session/actions';

import { IconName, Icon } from 'views/components/Icon/Icon';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { ROUTE_PATHS } from '../../../constants/routes';
import { NavigationRoute } from './NavigationRoute';
import { IMicroRoute, MicroSecondaryRouteNav } from './MicroSecondaryNav';

interface IRoute {
  url: string;
  label: string;
  iconName: IconName;
  iconActiveName: IconName;
  featureFlag?: FeatureFlags;
  microRoutes?: { primary: IMicroRoute; secondary: IMicroRoute };
  alts?: string[];
}

interface Props {
  isActive?: boolean;
  closeNav?: () => void;
}

const IS_TRADING_DASH_FEATURE_ENABLED = process.env.REACT_APP_FEATURE_FLAG_TRADING_DASHBOARD === 'on';

const LOGGED_IN_ROUTES: IRoute[] = [
  {
    url: ROUTE_PATHS.DASHBOARD,
    label: 'Home',
    iconName: 'ic-menu-home-normal',
    iconActiveName: 'ic-menu-home-selected',
    microRoutes: {
      primary: { url: ROUTE_PATHS.DASHBOARD, label: 'Volume' },
      secondary: { url: ROUTE_PATHS.FREE_DASHBOARD, label: 'Price' },
    },
  },
  {
    url: ROUTE_PATHS.RESIDUALS,
    label: 'Peak Demand',
    iconName: 'ic-menu-load-normal',
    iconActiveName: 'ic-menu-load-selected',
    microRoutes: {
      primary: { url: ROUTE_PATHS.RESIDUALS, label: 'Residuals' },
      secondary: { url: ROUTE_PATHS.RCPD, label: 'RCPD' },
    },
  },
  {
    url: ROUTE_PATHS.GWAP,
    label: 'GWAP',
    iconName: 'ic-menu-generation-normal',
    iconActiveName: 'ic-menu-generation-selected',
    featureFlag: FeatureFlags.GWAP_DASHBOARD,
  },
  {
    url: ROUTE_PATHS.REPORTS,
    label: 'Reports',
    iconName: 'ic-menu-report-normal',
    iconActiveName: 'ic-menu-report-selected',
  },
  {
    url: ROUTE_PATHS.WIND_FORECAST,
    label: 'NI Wind Forecast',
    iconName: 'ic-menu-wind-normal',
    iconActiveName: 'ic-menu-wind-selected',
  },
];

const LOGGED_OUT_ROUTES: IRoute[] = [
  {
    url: ROUTE_PATHS.HOME,
    label: 'Home',
    iconName: 'ic-menu-home-normal',
    iconActiveName: 'ic-menu-home-selected',
  },
  {
    url: ROUTE_PATHS.WIND_FORECAST_PUBLIC,
    label: 'NI Wind Forecast',
    iconName: 'ic-menu-wind-normal',
    iconActiveName: 'ic-menu-wind-selected',
  },
];

const isLocationActiveForRoute = (location: any, route: IRoute) => {
  // if the route has a micro route then check if location is either the primary or secondary route
  if (route.microRoutes) {
    return (
      location.pathname === route.url ||
      location.pathname === route.microRoutes.primary.url ||
      location.pathname === route.microRoutes.secondary.url
    );
  }

  return location.pathname === route.url || !!route.alts?.includes(location.pathname)
};

if (IS_TRADING_DASH_FEATURE_ENABLED) {
  LOGGED_IN_ROUTES.push({
    url: '/trading',
    label: 'Trading',
    iconName: 'ic-menu-rtp-normal',
    iconActiveName: 'ic-menu-rtp-selected',
  });
}

export const Navigation = ({ isActive, closeNav }: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(isLoggedInSelector);

  const routes = isLoggedIn ? LOGGED_IN_ROUTES : LOGGED_OUT_ROUTES;

  if (routes.length === 0) {
    return null;
  }

  const navClasses = classnames('Navigation', {
    'is-open': isActive,
  });

  return (
    <nav className={navClasses}>
      <ul className="Navigation-list">
        {routes.map((routeProps) => (
          <NavigationRoute
            microRouteComponent={
              routeProps.microRoutes ? (
                <MicroSecondaryRouteNav
                  primaryRoute={routeProps.microRoutes.primary}
                  secondaryRoute={routeProps.microRoutes.secondary}
                  // On click delay the close by 300ms using lodash
                  onClick={closeNav}
                />
              ) : undefined
            }
            key={`navlink${routeProps.label}`}
            onClick={closeNav}
            active={isLocationActiveForRoute(location, routeProps)}
            {...routeProps}
          />
        ))}
      </ul>
      {isLoggedIn && (
        <button
          className="Button Navigation-signOut"
          type="button"
          onClick={() => {
            dispatch({ type: TYPES.LOGOUT });

            if (closeNav) {
              closeNav();
            }
          }}
        >
          <Icon name="ic-menu-logout" className="Navigation-icon" />
          <span className="Navigation-signOut-title">
            <FormattedMessage id="SIGN_OUT" />
          </span>
        </button>
      )}
    </nav>
  );
};
