import { FormattedMessage } from 'react-intl';

import './Legend.scss';

import { useLegendRangesConfig } from '../hooks/useLegendRangeConfig';

export const Legend = () => {
  const { ranges, labelId } = useLegendRangesConfig();
  return (
    <div className="Legend">
      {labelId !== "PRICE_MAP_LEGEND" &&
        <div className="Legend-head">
          <h3 className="Legend-head-text">
            <FormattedMessage id={labelId} />
          </h3>
        </div>
      }
      <ul className="Legend-list">
        {ranges.map(({ colour, label }) => (
          <li key={label} className="Legend-list-item">
            <span style={{ backgroundColor: colour }} />
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
};
