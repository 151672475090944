import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { gwapTimeIntervalState, historicGenTypesApiStringSelector } from 'tools/state/paidGwapState';
import { makeGetRequest } from 'tools/utilities/ajax';
import { GwapTimeInterval } from 'tools/utilities/gwap';

export const useHistoricGenType = () => {
  const apiString = useRecoilValue(historicGenTypesApiStringSelector);
  const timeInterval = useRecoilValue(gwapTimeIntervalState);

  const { data, isFetching } = useQuery<any>(['HistoricGenType', apiString], () =>
    makeGetRequest(apiString).then((resp) => resp.data),
  );

  const dataForProcessing = isFetching ? [] : data;
  const normalisedData = normaliseData(dataForProcessing ?? [], timeInterval);

  // Sort the normalisedData by the 'timestamp' property on each item
  const timesortedData = normalisedData.sort((a: any, b: any) => {
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    return 0;
  });
  return { isFetching, historicGenTypes: timesortedData ?? [] };
};

const getDataForInterval = (interval: GwapTimeInterval, data: any) => {
  switch (interval) {
    case GwapTimeInterval.DAILY:
      return data.daily_gen;
    case GwapTimeInterval.MONTHLY:
      return data.monthly_gen;
    default:
      return data.TP_gen;
  }
};

const getTimestampForInterval = (interval: GwapTimeInterval, entry: any) => {
  switch (interval) {
    case GwapTimeInterval.DAILY:
      return entry.trading_date;
    case GwapTimeInterval.MONTHLY:
      return entry.month;
    default:
      return entry.timestamp;
  }
};

//  Turns this
// [
//   { generation_type_name: 'Battery', generation_mw: -44.368, generation_carbon_t: 0 },
//   { generation_type_name: 'Co-Gen', generation_mw: 37187.617, generation_carbon_t: 8375.44 },
//   { generation_type_name: 'Wind', generation_mw: 63232.791, generation_carbon_t: 0 },
//   { generation_type_name: 'NZ', generation_mw: 1119459.626, generation_carbon_t: 78334.04 },
// ]
// Into this
// {
//   timestamp: '2020-12-31T11:00:00Z'
//   battery_mw: 123.22
//   barrery_carbon: 1.2
//   hyd_mw: 222,
//   hyd_carbo: 1.5
//  }
const convertGenArrayToObject = (genArray: any[]) => {
  if (genArray && genArray.length === 0) {
    return {};
  }

  return genArray.reduce((reducedValues, entry) => {
    const reduced = {
      [`${entry.generation_type_name}_mw`]: entry.generation_mw,
      [`${entry.generation_type_name}_carbon`]: entry.generation_carbon_t,
      ...reducedValues,
    };
    return reduced;
  }, {});
};

const normaliseData = (data: any, timeInterval: GwapTimeInterval) => {
  if (data.length === 0) {
    return [];
  }
  const dataForInterval = getDataForInterval(timeInterval, data);
  if (!dataForInterval) {
    return [];
  }

  return dataForInterval.map((entry: any) => {
    const timestamp = getTimestampForInterval(timeInterval, entry);
    const flattenedGenerationTypes = convertGenArrayToObject(entry.generation_type || []);

    return { timestamp, ...flattenedGenerationTypes };
  });
};
