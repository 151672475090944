import {
  COLOURS,
  TRADER_LOAD_NODE_OPTIONS,
  TRADER_LOAD_REGION_OPTIONS,
} from '../../../../redux/modules/nodes/constants';
import { ToggleState } from '../../../../tools/hooks/useLoadForecastFilter';
import { ILegendItem } from '../RTP/RTPLegend';

export const COMING_SOON_TITLE = 'Load forecasting (MW)';
export const COMING_SOON_TEXT = 'New load forecasting graph coming soon.';

export const LOAD_FORECAST_COLOURS = {
  loadActual: COLOURS.WIND_TEAL,
  loadForecast1: COLOURS.ELECTRIC_YELLOW,
  loadForecast2: COLOURS.RADIOACTIVE_GREEN,
  loadForecast3: COLOURS.LOAD_GRAPH_GREEN,
  loadForecast4: COLOURS.MINERAL_TEAL,
  loadForecast5: COLOURS.LOAD_GRAPH_BLUE,
};

export const OPTIONS: { [key in ToggleState]: string[] } = {
  [ToggleState.REGIONS]: TRADER_LOAD_REGION_OPTIONS,
  [ToggleState.NODES]: TRADER_LOAD_NODE_OPTIONS,
};

export const LEGEND_ITEMS: ILegendItem[] = [
  {
    color: LOAD_FORECAST_COLOURS.loadActual,
    label: 'Current load',
  },

  {
    color: LOAD_FORECAST_COLOURS.loadForecast1,
    label: 'NRSL Most recent',
    tooltip: 'The latest Non-Responsive Schedule Long (NRSL) load forecast',
  },
  {
    color: LOAD_FORECAST_COLOURS.loadForecast2,
    label: `NRSL +2 hours`,
    tooltip: 'The Non-Responsive Schedule Long (NRSL) load forecast published 2 hours before the most recent',
  },
  {
    color: LOAD_FORECAST_COLOURS.loadForecast3,
    label: `NRSL +4 hours`,
    tooltip: 'The Non-Responsive Schedule Long (NRSL) load forecast published 4 hours before the most recent',
  },
  {
    color: LOAD_FORECAST_COLOURS.loadForecast4,
    label: `NRSL +6 hours`,
    tooltip: 'The Non-Responsive Schedule Long (NRSL) load forecast published 6 hours before the most recent',
  },
  {
    color: LOAD_FORECAST_COLOURS.loadForecast5,
    label: `NRSL +8 hours`,
    tooltip: 'The Non-Responsive Schedule Long (NRSL) load forecast published 8 hours before the most recent',
  },
];

/**
 * Checks whether the graphs are ready to show.
 *
 * If the toggle is set to regions, then we should be able to show the region graph even if the node is still
 * fetching. This function checks whether the content for the current toggle value is ready.
 * @param isFetchingNode Boolean for whether the node data is being fetched
 * @param isFetchingRegion Boolean for whether the region data is being fetched
 * @param toggleState Toggle value that is currently selected
 */
export function isContentReady(isFetchingNode: boolean, isFetchingRegion: boolean, toggleState: ToggleState): boolean {
  return toggleState === ToggleState.REGIONS ? !isFetchingRegion : !isFetchingNode;
}
