import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import './Residuals.scss';
import { actionGetResiduals } from 'redux/modules/nodes/actions';
import { REGION_LABELS } from 'redux/modules/nodes/constants';
import { Regions, RunType } from 'enums.d';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { ResidualsGraph } from 'views/components/Graphs/ResidualsGraph';
import { SelectionCriteria } from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaOption } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';
import { ErrorBoundary } from 'react-error-boundary';
import { getCurrentTradingPeriodStart } from 'tools/utilities/date';
import { makeGetRequest, makeUnauthorisedGetRequest } from '../../../tools/utilities/ajax';

const NORTH_ISLAND_ZONE_IDS = [1,2,3,4,5,6,7,8]

const DATA_AUTO_REFRESH_MS = 1000 * 60 * 5

const DEFAULT_FILTER = {
  region: '',
  runType: 'NRS',
}

const REGION_OPTIONS: CriteriaOption[] = [
  { label: 'All Regions', id: '' },
  { label: 'Nationwide', id: Regions.NEW_ZEALAND },
  { label: 'North Island', id: Regions.NORTH_ISLAND },
  { label: 'South Island', id: Regions.SOUTH_ISLAND },
];

const RUN_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'All Schedules', id: '' },
  { label: 'NRS', id: RunType.NRS },
  { label: 'PRS', id: RunType.PRS },
  { label: 'RTD', id: RunType.RTD },
  { label: 'WDS', id: RunType.WDS },
];

const ALL_REGIONS = [Regions.NEW_ZEALAND, Regions.NORTH_ISLAND, Regions.SOUTH_ISLAND]
const ALL_RUN_TYPES = [RunType.RTD, RunType.NRS, RunType.PRS, RunType.WDS]

const ResidualsSelectionCriteria = ({ criteria, setCriteria }: any) => {

  const updateCriteria = (key: string, filterOptionId: string) => {
    setCriteria({ ...criteria, [key]: filterOptionId });
  };

  return (
    <SelectionCriteria>
      <CriteriaAccordian
        initialOption={REGION_OPTIONS.find(({ id }) => id === criteria.region)}
        options={REGION_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('region', option.id)}
        updateCriteria={updateCriteria}
      />
      <CriteriaAccordian
        initialOption={RUN_TYPE_OPTIONS.find(({ id }) => id === criteria.runType)}
        options={RUN_TYPE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('runType', option.id)}
        updateCriteria={updateCriteria}
      />
    </SelectionCriteria>
  );
};



export const Residuals = () => {
  const [criteria, setCriteria]   = useState<any>(DEFAULT_FILTER)
  const [currentTp, setCurrentTp] = useState<any>(null)
  const [windForecastData, setWindForecastData]   = useState<any>(null)
  const dispatch = useDispatch()
  const intervalRef = useRef<any>(null)
  const {region, runType} = criteria

  const updateResidualData = (reg: any, rt: any) => {
    dispatch(actionGetResiduals({
      region: reg ? [reg] : [],
      runType: rt ? [rt] : []
    }));
    setCurrentTp(getCurrentTradingPeriodStart())
  }

  const updateWindForecastData = async () => {
    const [capacity, forecast] = await Promise.all([
      makeGetRequest('nodes?generation_type_id=WIN&generation_node=TRUE').then(
        ({data: {items}}: any) => items.reduce(
          (acc: any, curr: any) => acc + (NORTH_ISLAND_ZONE_IDS.includes(curr.grid_zone_id) ? curr.generation_capacity_mw : 0), 0)),
      makeUnauthorisedGetRequest(`windforecast`).then(
        ({data: {items}}: any) => _.groupBy(items, 'timestamp'))
    ])
    setWindForecastData({capacity, forecast})
  }

  useEffect(() => {
    intervalRef.current && clearInterval(intervalRef.current)
    updateResidualData(region, runType)
    updateWindForecastData()
    intervalRef.current = setInterval(() => updateResidualData(region, runType), DATA_AUTO_REFRESH_MS)
    return () => clearInterval(intervalRef.current)
  }, [dispatch, region, runType])

  return (
    <div className="Container--fullWidth">
      <AccessRestrictionWrapper canGoBack isInline accessScopeRequired={UserAccessScope.RCPD}>
        <RouteTransition>
          <div className="Load">
            <div className="box1">
              <ResidualsSelectionCriteria
                criteria={criteria}
                setCriteria={setCriteria}
              />
              <div className="Disclaimer">
                <p>The data on this graph does not relate to, or help determine, Transpower's operational policy and is for information only.</p>
                <p>Official Transpower notices are communicated directly to market participants, and can be found <a target="_blank" href="https://www.transpower.co.nz/system-operator/notices-and-reporting">here</a>.</p>
              </div>
            </div>
            <div className="LoadGraph">
              <h2 className="LoadGraph-title">
                {REGION_LABELS[region] || 'All Regions'} - {runType || 'All Schedules'} - Residual (MW)
              </h2>
              <div className="LoadGraph-graph">
                <ErrorBoundary FallbackComponent={ErrorFallback}>

                  <ResidualsGraph
                    regions={region === '' ? ALL_REGIONS  : [region]}
                    runTypes={runType === '' ? ALL_RUN_TYPES : [runType]}
                    currentTime={runType !== 'RTD' ? currentTp : null}
                    windForecastData={windForecastData}
                  />

                </ErrorBoundary>
              </div>
            </div>
          </div>
        </RouteTransition>
      </AccessRestrictionWrapper>
    </div>
  );
};

const ErrorFallback = (props: any) => <div>There was a problem getting data for this graph.</div>;
