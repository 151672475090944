/* eslint-disable import/no-cycle */
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { createResponsiveStateReducer, responsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';

import appReducer from 'redux/modules/app/reducers';
import { nodesReducer } from 'redux/modules/nodes/reducers';
import { sessionReducer } from 'redux/modules/session/reducers';
import rootSaga from 'redux/modules/main-sagas';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  browser: createResponsiveStateReducer({
    mobile: 768,
    tablet: 1024,
    desktop: 1280,
    largeDesktop: 1366,
    widescreen: 1600,
    ultraWidescreen: 1880,
  }),
  app: appReducer,
  nodes: nodesReducer,
  session: sessionReducer,
  router: connectRouter(history),
});

export type IReducerState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware({});

const middleware = [routerMiddleware(history), sagaMiddleware];

let composeEnhancers = compose;

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  const composeWithDevToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const store = createStore(rootReducer, {}, composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware)));

export default store;

sagaMiddleware.run(rootSaga);
