import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useDataPoller = (action: (payload?: any) => void, time: number, actionPayload?: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(action(actionPayload));
    }, time);

    return () => clearInterval(interval);
  }, [time, action, dispatch, actionPayload]);
};
