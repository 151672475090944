import { GwapGenerationType, GwapLocation } from 'tools/utilities/gwap';
import { useFreeGwap } from 'tools/hooks/useFreeGwap';
import styles from './FreeGwapFilters.module.scss';
import { GwapGenTypeFilter } from '../Filters/GwapGenTypeFilter';
import { GwapLocationFilter } from '../Filters/GwapLocationFilter';

export const FreeGwapFilters = () => {
  const { activeGenTypeFilters, changeGenTypeFilter, activeLocationFilters, changeLocationFilter } = useFreeGwap();

  const onGenTypeFilterChange = (filter: GwapGenerationType[]) => {
    changeGenTypeFilter(filter);
  };

  const onLocationChange = (locations: GwapLocation[]) => {
    changeLocationFilter(locations);
  };

  return (
    <div className={styles.wrapper}>
      <GwapGenTypeFilter
        generationTypesSelected={activeGenTypeFilters}
        setSelectedGenerationTypes={onGenTypeFilterChange}
        isOverlay
      />
      <GwapLocationFilter selectedLocations={activeLocationFilters} setSelectedLocations={onLocationChange} />
    </div>
  );
};
