export const enNZ = {
  404: 'Sorry the page you were looking for can’t be found right now. Please try again later.',
  APP_TITLE: 'Electricity Market Overview',
  BACK: 'Back',
  BY: 'By ',
  CANCEL: 'Cancel',
  CHANGE: 'Change',
  CLEAR_INPUT: 'Clear input. ',
  CLOSED: 'Closed',
  CONTINUE: 'Continue',
  CURRENT_TRADING_PERIOD: 'Current trading period: ',
  CUSTOM_NODES: 'Custom Nodes ($/MWh)',
  DATE_OF_BIRTH: 'Date of birth',
  DATE: 'Date',
  DAY: 'Day',
  DEFAULT_NODES: 'Default Nodes ($/MWh)',
  DEFAULT_LOAD_NODES: 'Default Load Nodes (MW)',
  DEFAULT_PRICE_NODES: 'Default Price Nodes ($/MWh)',
  DELETE: 'Delete',
  DESCRIPTION: 'Description',
  DETAILS: 'Details',
  DONE: 'Done',
  EDIT: 'Edit',
  EMAIL: 'Email',
  EMAIL_SENT: 'Email sent',
  ENTER_YOUR_EMAIL: "Enter your email and we'll send a link to reset your password.",
  ENQUIRE_TODAY: 'Enquire today',
  FACEBOOK: 'Facebook',
  FINISH: 'Finish',
  FOOTER_DISCLAIMER: '**APP_TITLE** © 2018 All Rights Reserved',
  FORGOT_PASSWORD: 'Forgot password?',
  FROM: 'From',
  FULL_NAME: 'Full name',
  GO_TO_LOGIN: 'Go to login',
  GOT_IT: 'Got it!',
  GREETING: 'Hi ',
  HOME: 'Home',
  IF_YOU_DO_NOT_RECEIVE_A_LINK: 'If you do not receive a link please try again or contact us at <a>call@ems.co.nz</a>',
  INTERESTED_IN_AN_ACCOUNT: 'Interested in an account? Contact us at <a>call@ems.co.nz</a>',
  IS: 'Is ',
  LAST_UPDATED: 'Last updated {date}',
  LOADING: 'Loading...',
  LOG_IN: 'Log in',
  LOG_OUT: 'Log out',
  LOGIN: 'Login',
  LOGIN_TO_EM6: 'Login to em6',
  PRICE_MAP_LEGEND: 'Map key',
  LOAD_MAP_LEGEND: "Current load compared to this trading period's 7-day peak",
  MENU: 'Menu',
  NEXT: 'Next',
  NO_ACCESS_HEADING: 'No Access',
  NO_ACCESS_TEXT: 'Sorry, your account permissions do not allow you to access this page.',
  NO_THANKS: 'No thanks',
  NO: 'No',
  NONE: 'None',
  NUMBER: 'Number',
  OF: ' of ',
  OVERVIEW: 'Overview',
  PASSWORD_RESET: 'Password reset',
  PREV: 'Prev',
  PRIVACY: 'Privacy',
  PRICE_LAST_15_MINUTES: 'Price last 15 minutes ($/MWh)',
  REFERENCE_NODES: 'Reference nodes',
  REMOVE: 'Remove',
  RESET_PASSWORD: 'Reset password',
  SEARCH: 'Search',
  SELECTION_CRITERIA: 'Selection criteria',
  SEND: 'Send',
  SIGN_OUT: 'Sign out',
  SITE_DOWN_FOR_MAINTENANCE: 'Sorry the page you were looking for can’t be found right now. Please try again later.',
  SITE_INFORMATION: 'Site information',
  TERMS_OF_USE: 'Terms of use',
  THANK_YOU: 'Thank you',
  THANKS: 'Thanks',
  VERSION: 'Version',
  WELCOME_TO_EM6: 'Welcome to em6',
  WEVE_SENT_A_LINK: "We've sent a link to {userEmail} explaining how to reset your password.",
  YES: 'Yes',
  YOU_CAN_NOW_CREATE_A_CUSTOM_PRICE_MAP:
    'You can now create a custom price map which will display up to date prices and trends for the nodes you are interested in.',

  EMAIL_ERROR: 'Please enter a valid email.',
  PASSWORD_REQUIREMENTS_ERROR: 'Password does not meet the requirements.',
  PASSWORD_MATCH_ERROR: 'Passwords do not match.',
  LOGIN_ERROR: 'Email or password incorrect, please check your details and try again.',

  // Reset/Forgot Password
  LINK_EXPIRED: 'Link expired',
  YOUR_LINK_HAS_EXPIRED: 'Your link has expired, please request a new one.',
  RESET_PASSWORD_EXPIRED_CODE_ERROR: 'Your code has expired, please request a new one.',
  FORGOT_PASSWORD_UNREGISTERED_EMAIL: 'There is no account associated with this email.',

  // Custom node selection
  CLEAR_NODES_MODAL_HEADING: 'Clear nodes?',
  ARE_YOU_SURE_YOU_WANT_TO_CLEAR: 'Are you sure you want to clear all of the selected nodes?',
  CUSTOMISE_PRICE_NODES: 'Customise price nodes',
  RESET_NODES: 'Reset nodes',
  RESET_NODES_MODAL_HEADING: 'Reset nodes?',
  ARE_YOU_SURE_YOU_WANT_TO_RESET: 'Are you sure you want to reset to the preset node selections?',
  YOU_HAVE_SELECTED_THE_MAXIMUM: 'You have selected the maximum number of nodes able to show on the price map.',
  SELECT_UP_TO_20_NODES: 'Select up to 20 nodes from the list below to show on the price map on your dashboard. ',

  // Custom date selection
  BACK_TO_PRESET_DATE_OPTIONS: 'Back to preset date options',

  // RCPD
  LATEST_READING: 'Latest reading',
  TIME: 'Time',
  READING: 'Reading',
  MIN_PEAK: 'Min Peak',
  MINIMUM_PEAK: 'Minimum Peak',
  AVERAGE: 'Average',
  TP_AVERAGE: 'TP Average',
  CURRENT: 'Current',

  // Reports
  NO_NODES_FOUND: 'No nodes found',

  // Latest readings -- From dashboard
  NORTH_ISLAND: 'North Island',
  SOUTH_ISLAND: 'South Island',
  NORTH_ISLAND_MINUS_1: 'NI - 1wk',
  SOUTH_ISLAND_MINUS_1: 'SI - 1wk',

  HYDRO: 'Hydro',
  WIND: 'Wind',
  GEOTHERMAL: 'Geothermal',
  COGEN: 'Cogen',
  GAS: 'Gas',
  COAL: 'Coal',
  DIESEL_OIL: 'Diesel/Oil',

  NORTH_FLOW: 'North Flow',
  SOUTH_FLOW: 'South Flow',

  SOUTH_TO_NORTH: 'South to North',
  NORTH_TO_SOUTH: 'North to South',

  CLEARED: 'Cleared',
  OFFERED: 'Offered',
};
