import { GwapFilterDrawer } from '../Filters/GwapFilterDrawer';
import { PaidGWAPGraph } from '../PaidGwapGraph/PaidGwapGraph';
import { GenerationByLocationGraph } from './GenerationByLocationGraph';
import styles from './GwapTripleGraph.module.scss';
import { HistoricGenType } from './HistoricGenTypeGraph';

export const GwapTripleGraph = () => (
  <div className={styles.wrapper}>
    <PaidGWAPGraph />
    <HistoricGenType />
    <GenerationByLocationGraph />
    <GwapFilterDrawer />
  </div>
);
