import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, BarChart, CartesianGrid, YAxis, Tooltip, Bar, XAxis } from 'recharts';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { IGwapDimensions } from 'tools/hooks/PaidGwap/usePaidGwapGraphDimensions';
import { ResizeState } from 'tools/providers/ResizeProvider';
import { gwapByTypeSlice, isAtLeastOneFilterNotSelectedSelector } from 'tools/state/paidGwapState';
import {
  formatGwapXAxisDate,
  getGwapGenTypeLabel,
  GwapGenerationType,
  GwapTimeInterval,
  GWAP_GRAPH_KEYS,
  IGwapBarDimensions,
} from 'tools/utilities/gwap';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { GraphNotice } from '../GwapTripleGraph/GraphNotice';
import { GwapChartErrorBoundary } from '../GwapTripleGraph/GwapChartErrorBoundary';
import { TooltipRow, TooltipWrapper } from '../Tooltip/Tooltip';

const CustomTooltip = (data: any) => {
  const { payload } = data;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { bat_wap, cg_wap, cog_wap, gas_wap, geo_wap, hyd_wap, win_wap, liq_wap, sol_wap, timestamp } =
    payload?.[0]?.payload ?? {};

  const formattedTime = dayjs(timestamp).format('DD/MM/YYYY');
  return (
    <TooltipWrapper>
      <TooltipRow>
        <strong>Date: </strong>
        <span>{formattedTime}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.LIQ)} </strong>
        <span>{liq_wap ? `$${roundToDpWithCommas(liq_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.BAT)} </strong>
        <span>{bat_wap ? `$${roundToDpWithCommas(bat_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.CG)} </strong>
        <span>{cg_wap ? `$${roundToDpWithCommas(cg_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.WIN)} </strong>
        <span>{win_wap ? `$${roundToDpWithCommas(win_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.HYD)} </strong>
        <span>{hyd_wap ? `$${roundToDpWithCommas(hyd_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.GAS)} </strong>
        <span>{gas_wap ? `$${roundToDpWithCommas(gas_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.COG)} </strong>
        <span>{cog_wap ? `$${roundToDpWithCommas(cog_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.GEO)} </strong>
        <span>{geo_wap ? `$${roundToDpWithCommas(geo_wap, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>{getGwapGenTypeLabel(GwapGenerationType.SOL)} </strong>
        <span>{sol_wap ? `$${roundToDpWithCommas(sol_wap, 2)}` : '-'}</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};

interface IGwapGraphProps {
  chartContainerClassName?: string;
  chartDimensions: IGwapDimensions;
  barDimensions: IGwapBarDimensions;
  showXAxis?: boolean;
  data: any[];
  children?: React.ReactNode;
  hideHover?: boolean;
  isFetching?: boolean;
}

export const GwapGraph = ({
  chartDimensions,
  barDimensions,
  showXAxis,
  chartContainerClassName,
  data,
  children,
  isFetching = false,
  hideHover = false,
}: IGwapGraphProps) => {
  const setRecord = useSetRecoilState(gwapByTypeSlice);
  const { width: barWidth, height: barHeight } = barDimensions;
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const { width, height, margins } = chartDimensions;
  const isOneFilterNotSelected = useRecoilValue(isAtLeastOneFilterNotSelectedSelector);
  const isDataAvailable = data && data.length > 0 && !isOneFilterNotSelected;
  const isResizing = useRecoilValue(ResizeState);

  if (isFetching || isResizing) {
    return (
      <>
        <ResponsiveContainer width={width} height={height} className={chartContainerClassName}>
          <GraphNotice message="Loading graph data..." width={width} height={height} />
        </ResponsiveContainer>
        {children}
      </>
    );
  }

  if (!isDataAvailable) {
    return (
      <>
        <ResponsiveContainer width={width} height={height} className={chartContainerClassName}>
          <GraphNotice width={width} height={height} />
        </ResponsiveContainer>
        {children}
      </>
    );
  }

  return (
    <>
      <GwapChartErrorBoundary>
        <ResponsiveContainer width={width} height={height} className={chartContainerClassName}>
          <BarChart
            reverseStackOrder
            throttleDelay={50}
            stackOffset="sign"
            width={barWidth as number}
            height={barHeight}
            data={data}
            margin={margins}
            barGap={0}
            barCategoryGap={0}
            syncId={1}
          >
            <CartesianGrid strokeOpacity={0.3} vertical={false} strokeDasharray="3 1" />
            {showXAxis && (
              <XAxis
                minTickGap={isViewAboveMobile ? 100 : 75}
                tickLine={false}
                tick={{
                  stroke: 'white',
                  fontSize: '12px',
                  strokeWidth: 0.75,
                  fontFamily: 'Oswald Light oswald-light !important',
                }}
                dataKey="timestamp"
                tickFormatter={(date) => formatGwapXAxisDate(date, GwapTimeInterval.DAILY)}
              />
            )}
            <YAxis
              tickFormatter={(value: any) => roundToDpWithCommas(value, 0)}
              tickLine={false}
              tick={{ stroke: 'white', fontSize: '12px', strokeWidth: 0.75 }}
              axisLine={false}
              domain={[
                // (dataMin: any) => Math.max(dataMin - (dataMin % 5) - 10, 0),
                (dataMin: any) => dataMin,
                (dataMax: any) => (dataMax < 5 ? 5 : dataMax),
              ]}
            />
            {/* 
              This is so we keep the line that points out where you are hovering
              We rely on Tooltip to take care of that and just use an empty fragment as the content.
            */}
            <Tooltip
              cursor={{ fill: '#00314a' }}
              content={(hoverData: any) => {
                setRecord(hoverData?.payload?.[0]?.payload);
                if (hideHover) {
                  return <></>;
                }
                return CustomTooltip(hoverData);
              }}
              labelStyle={{ color: 'black' }}
              itemStyle={{ color: 'black' }}
            />

            {GWAP_GRAPH_KEYS.map((key) => (
              <Bar key={key.label} dataKey={`${key.graphName}_mwh`} stackId={1} fill={key.fill} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </GwapChartErrorBoundary>
      {children}
    </>
  );
};
