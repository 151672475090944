import React, { useRef, useEffect } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';

import classNames from 'classnames';
import styles from './IndeterminateCheckbox.module.scss';

import { Icon } from '../Icon/Icon';

interface IIndeterminateCheckboxProps {
  onSelect?: any;
  onToggle?: any;
  onRemove?: any;
  indeterminate?: boolean;
  row?: any;
  isSelected?: boolean;
  hasCustomIcon?: any;
}

export const IndeterminateCheckbox = React.forwardRef(
  (
    { indeterminate, onToggle, onSelect, onRemove, row, isSelected, hasCustomIcon }: IIndeterminateCheckboxProps,
    ref: any,
  ) => {
    const triCheckState = indeterminate ? 'indeterminate' : !!isSelected;

    return (
      <Checkbox.Root
        className={classNames(styles.wrapper, { checked: indeterminate || isSelected })}
        checked={triCheckState}
        onCheckedChange={(checked: Checkbox.CheckedState) => {
          // This is some gross conditional logic.
          // This was created as this new version of the component needed to be adapted to handle an umber of use cases
          // That i didnt have the time to rearchitect so kept legacy options available.
          // Highly recommend cleaning this up in the future.
          if (onToggle) {
            onToggle(row);
          }

          if (onSelect && onRemove) {
            if (checked === true) {
              onSelect(row?.original.nodeId);
            } else {
              onRemove(row?.original.nodeId);
            }
          }
        }}
      >
        <Checkbox.Indicator>
          {hasCustomIcon && <Icon name={isSelected ? 'ic-remove' : 'ic-add'} size="md" />}
          {!hasCustomIcon && triCheckState === 'indeterminate' && (
            <Icon name="ic-indeterminate" className={styles.checkMark} />
          )}
          {!hasCustomIcon && triCheckState === true && <Icon name="ic-tick" className={styles.checkMark} />}
        </Checkbox.Indicator>
      </Checkbox.Root>
    );
  },
);
