import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getTradingPeriodForDate } from 'tools/utilities/date';

const calculateTradingPeriod = (): number => {
  const date = new Date();
  return getTradingPeriodForDate(date);
};

export const TradingPeriod = () => {
  const [tradingPeriod, setTradingPeriod] = useState<number>(calculateTradingPeriod());

  useEffect(() => {
    const interval = setInterval(() => {
      setTradingPeriod(calculateTradingPeriod());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <FormattedMessage id="CURRENT_TRADING_PERIOD" />
      <span id="tradingPeriod">{tradingPeriod}</span>
    </>
  );
};
