import { camelize } from 'humps';

/**
 * These are the API booleans, OK?
 * Not my fault.
 */
const API_FALSE = 'FALSE';
const API_TRUE = 'TRUE';

const formatApiValue = (value: any) => {
  if (value === API_FALSE) {
    return false;
  }

  if (value === API_TRUE) {
    return true;
  }

  return value;
};

export const formatApiData = (data: any[]) =>
  data.map((datum: any) => {
    const keys = Object.keys(datum);
    const formattedDatum: { [keyof: string]: any } = {};

    keys.forEach((key: string) => {
      const value = datum[key];
      const formattedKey = camelize(key);

      formattedDatum[formattedKey] = formatApiValue(value);
    });

    return formattedDatum;
  });
