import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';

import {
  HVDC_LAST_24_HRS_KEY_ITEMS,
  HVDC_LAST_24_COLORS,
} from 'redux/modules/nodes/constants';

import { scaleLinear } from 'd3-scale';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { FormattedMessage } from 'react-intl';
import { formatTradingPeriodToTime } from 'tools/utilities/date';
import { getHvdcLast24Hrs } from 'redux/modules/nodes/selectors';
import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { formatEndOfTradingPeriod, formatTimestamp } from 'tools/utilities/charts';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipRow, TooltipWrapper, BoldTooltipText } from '../Tooltip/Tooltip';
import { Icon } from '../Icon/Icon';

const MOBILE_HEIGHT = 620;

export const HvdcLast24HrHeadingData = () => {
  const hdvcLast24Hr = useSelector(getHvdcLast24Hrs);

  if (!hdvcLast24Hr) {
    return null;
  }

  const latestHDVC = hdvcLast24Hr.items[hdvcLast24Hr.items.length - 1];

  const northFlow = latestHDVC.dcnFlow;
  const southFlow = latestHDVC.dcsFlow;

  /**
   * South flow is negative, so need to sum northFlow and southFlow to see
   * the direction of change.
   */
  const netFlow = northFlow + southFlow; // Negative value is south flow.

  return (
    <h3 className="RCPD-netFlowData">
      {netFlow > 0 && <Icon className="RCPDGraph-netFlowIcon" name="ic-up-arrow" />}
      {netFlow < 0 && <Icon className="RCPDGraph-netFlowIcon" name="ic-down-arrow" />}
      {roundToDpWithCommas(netFlow, 0)} MW
    </h3>
  );
};

const CustomTooltip = ({ payload }: any) => {
  if (!payload?.[0]) {
    return null;
  }

  const { dcnFlow, dcsFlow, northLimitMw, southLimitMw, direction, timestamp, tradingPeriod } = payload?.[0]?.payload ?? {};

  return (
    <TooltipWrapper>
      <TooltipRow>
        <span>{timestamp && formatTimestamp(new Date(timestamp))} - TP{tradingPeriod}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="HVDC Transfer North:" />
        <span>{dcnFlow || dcnFlow === 0 ? `${roundToDpWithCommas(dcnFlow, 1)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="North Flow Limit:" />
        <span>{northLimitMw || northLimitMw === 0 ? `${roundToDpWithCommas(northLimitMw, 0)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="HVDC Transfer South:" />
        <span>{dcsFlow || dcsFlow === 0  ? `${roundToDpWithCommas(dcsFlow, 1)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="South Flow Limit:" />
        <span>{southLimitMw || southLimitMw === 0 ? `${roundToDpWithCommas(southLimitMw, 0)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="Direction:" />
        <span>{direction || '-'}</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};


export const HvdcLast24Hr = () => {

  const hdvcLast24Hr = useSelector(getHvdcLast24Hrs);
  const isAboveTablet = useSelector(isViewportAboveTabletSelector);

  const data = hdvcLast24Hr?.items;
  if (!data || !data.length) {
    return null;
  }

  const lastUpdatedTime = formatEndOfTradingPeriod(new Date(data[data.length - 1].timestamp));

  const yValues = data.reduce((acc: any, {dcnFlow, dcsFlow}: any) => ([
    ...acc,
    dcnFlow,
    dcsFlow,
  ]), [])

  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)
  const offset = (yMax - yMin) / 10
  const yScale = scaleLinear().domain([yMin - offset, yMax + offset])
  const yTicks = yScale.ticks(4)
  const xTicks:any = _.uniq(data.filter(({tradingPeriod}: any) => tradingPeriod % 6 === 0).map(({tradingPeriod}: any) => tradingPeriod))

  return (
    <div>
      <ResponsiveContainer
        width="99%"
        aspect={isAboveTablet ? 1 : undefined}
        height={isAboveTablet ? 'unset' : MOBILE_HEIGHT}
      >
        <LineChart
          data={data}
          margin={{
            top: 24,
            right: 0,
            left: 0,
            bottom: 16,
          }}
        >
          <XAxis
            ticks={xTicks}
            tickFormatter={(tick: number) => formatTradingPeriodToTime(tick)}
            tickLine={false}
            axisLine={false}
            width={100}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            dataKey="tradingPeriod"
          />
          <YAxis
            ticks={yTicks}
            tickLine={false}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            axisLine={false}
            type="number"
          />
          <Tooltip
            labelFormatter={(label: any, payload: any) => (
              <span>
                {moment(new Date(payload[0]?.payload?.timestamp)).format('DD/MM/YYYY')} - TP{payload[0]?.payload.tp}
              </span>
            )}
            content={CustomTooltip}
          />
          <Line type="linear" dataKey='dcnFlow' stroke={HVDC_LAST_24_COLORS.SOUTH_TO_NORTH} dot={false} strokeWidth={2} />
          <Line type="linear" dataKey='dcsFlow' stroke={HVDC_LAST_24_COLORS.NORTH_TO_SOUTH} dot={false} strokeWidth={2} />

          {xTicks.map((tick: number) => (
            <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
          {yTicks.map((tick: number) => (
            <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <GraphKey keyItems={HVDC_LAST_24_HRS_KEY_ITEMS} />
      <p className="Dashboard-updated">
        <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
      </p>
    </div>
  )

}