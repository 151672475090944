import classNames from 'classnames';
import { useFeature } from 'flagged';
import { Link } from 'react-router-dom';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { Icon, IconName } from '../Icon/Icon';

interface INavigationRouteProps {
  url: string;
  label: string;
  active: boolean;
  iconName: IconName;
  iconActiveName: IconName;
  featureFlag?: FeatureFlags;
  microRouteComponent?: React.ReactNode;
  onClick?: () => void;
}

export const NavigationRoute = ({
  url,
  label,
  active,
  iconName,
  iconActiveName,
  featureFlag,
  onClick,
  microRouteComponent,
}: INavigationRouteProps) => {
  const isRouteEnabled = useFeature(featureFlag ?? '');

  if (!!featureFlag && !isRouteEnabled) {
    return null;
  }
  const navItemClassName = classNames('Navigation-listItem', {
    'is-active': active,
  });

  const iconTitle: IconName = active ? iconActiveName : iconName;

  return (
    <li key={url} className={navItemClassName}>
      <Link to={url} className="Navigation-listItem-link" onClick={onClick}>
        <Icon name={iconTitle} className="Navigation-icon" />
        <span className="Navigation-listItem-linkTitle">{label}</span>
      </Link>
      <div className="Navigation-microRoute">{microRouteComponent}</div>
    </li>
  );
};
