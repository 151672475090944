/* eslint-disable import/no-cycle */
import { fork, all } from 'redux-saga/effects';

import { appSaga } from './app/sagas';
import { nodesSaga } from './nodes/sagas';
import { sessionSaga } from './session/sagas';
import { analyticsSaga } from './analytics/sagas';

export default function* root() {
  yield all([fork(appSaga), fork(nodesSaga), fork(sessionSaga), fork(analyticsSaga)]);
}
