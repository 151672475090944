import { ErrorBoundary } from 'react-error-boundary';
import { GraphNotice } from './GraphNotice';

interface IGwapChartErrorBoundaryProps {
  children: React.ReactNode;
}

export const GwapChartErrorBoundary = ({ children }: IGwapChartErrorBoundaryProps) => (
  <ErrorBoundary FallbackComponent={GwapChartError}>{children}</ErrorBoundary>
);

const GwapChartError = () => (
  <GraphNotice
    width="100%"
    height="100%"
    message="There is no generation for the selected region and generation type"
  />
);
