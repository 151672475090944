import _ from 'lodash';
import moment from 'moment/moment';
import {
  CartesianGrid,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Area,
  ReferenceArea,
} from 'recharts';
import { ILegendItem, RTPLegend } from '../../components/TradingComponents/RTP/RTPLegend';
import styles from './WindForecast.module.scss';
import { roundToDpWithCommas } from '../../../tools/utilities/numberFormat';
import { COLOURS } from '../../../redux/modules/nodes/constants';
import { WindDataPointNullable } from '../../../tools/hooks/useWindForecastData';
import {
  BoldTooltipText,
  TooltipColorKey,
  TooltipKeyArea,
  TooltipRow,
  TooltipValueArea,
  TooltipWrapper,
} from '../../components/Tooltip/Tooltip';

type WindForecastGraphKey = {
  label: string;
  color: string;
  tooltip: string; // the label in the tooltip when you hover the graph
  infoTip?: string; // the little (i) next to the legend key
  isDashed?: boolean;
};

type WindForecastGraphKeys = {
  generationmw: WindForecastGraphKey;
  percentile_90: WindForecastGraphKey;
  percentile_50: WindForecastGraphKey;
  percentile_10: WindForecastGraphKey;
  offered: WindForecastGraphKey;
  discrepancy: WindForecastGraphKey;
};

const WIND_FORECAST_GRAPH_KEYS: WindForecastGraphKeys = {
  generationmw: {
    color: COLOURS.ELECTRIC_YELLOW,
    label: 'Actual generation',
    tooltip: 'Actual generation',
  },
  percentile_90: {
    color: COLOURS.WIND_TEAL,
    label: '90th percentile projection',
    tooltip: '90th percentile',
    infoTip:
      'Based off weather forecasts, there is a 90% likelihood that power generated at NI wind farms will be below this line',
    isDashed: true,
  },
  percentile_50: {
    color: 'white',
    label: '50th percentile projection',
    tooltip: '50th percentile',
    infoTip:
      'Based off weather forecasts, there is a 50% likelihood that power generated at NI wind farms will be either above or below this line',
  },
  percentile_10: {
    color: COLOURS.MINERAL_TEAL,
    label: '10th percentile projection',
    tooltip: '10th percentile',
    infoTip:
      'Based off weather forecasts, there is a 10% likelihood that power generated at NI wind farms will be below this line (90% chance it will be above)',
    isDashed: true,
  },
  offered: {
    color: COLOURS.RADIOACTIVE_GREEN,
    label: 'Wind offered',
    tooltip: 'Wind offered',
    infoTip: 'Aggregated NI Wind Farm forecasted generation',
  },
  discrepancy: {
    color: COLOURS.SOLAR_ORANGE,
    label: 'Discrepancy',
    tooltip: 'Discrepancy',
    infoTip: '50th percentile minus wind offered',
  },
};

const legendItems: ILegendItem[] = Object.entries(WIND_FORECAST_GRAPH_KEYS).map(([_key, val]) => ({
  color: val.color,
  label: val.label,
  tooltip: val.infoTip,
}));

interface IWindPriceGraphProps {
  data?: WindDataPointNullable[];
  lastUpdated?: number;
  isLoading: boolean;
}

export const WindForecastGraph = ({ data, lastUpdated, isLoading }: IWindPriceGraphProps) => {
  if (isLoading) {
    return <div style={{ marginTop: '1rem' }}>Loading...</div>;
  }

  // Technically this can never be reached because of `isLoading`.
  // Added because it helps TypeScript know that `data` is defined.
  if (!data) {
    return <div className={styles.replacementText}>There was a problem getting data for this graph.</div>;
  }

  const xReferenceLine = data.find((item) => item.offered !== null)?.timestamp;

  // uses the fact that `data` is sorted by timestamps, so get first and last items of array
  const [earliestTimestamp, latestTimestamp] = [data[0].timestamp, data[data.length - 1].timestamp];

  return (
    <div className={styles.FreePriceGraph} data-testid="WindPriceGraph">
      {isLoading ? null : (
        <ResponsiveContainer width="99%" height={550}>
          <ComposedChart
            data={data}
            margin={{
              top: 24,
              right: 0,
              left: -24,
              bottom: 16,
            }}
          >
            <CartesianGrid strokeOpacity={0.3} vertical={false} strokeDasharray="3 1" />
            <XAxis
              tick={{
                stroke: 'white',
                fontSize: '12px',
                strokeWidth: 0.75,
                fontFamily: 'Oswald Light oswald-light !important',
              }}
              ticks={getXAxisTicksForTimestamps(earliestTimestamp, latestTimestamp)}
              tickFormatter={(value: any) => moment(value).format('HH:mm')}
              tickLine={false}
              axisLine={false}
              dataKey="timestamp"
              type="number"
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              tick={{
                stroke: 'white',
                fontSize: '12px',
                strokeWidth: 0.75,
                fontFamily: 'Oswald Light oswald-light !important',
              }}
              ticks={getYTicks(data)}
              tickLine={false}
              // For some reason I must include the `domain` and set minimum to -100 otherwise it goes too low.
              domain={[(min: number) => Math.min(-100, min), (max: number) => max]}
              tickFormatter={(value: any, index: number) => (index === 0 ? ' ' : Math.round(Number(value)).toString())} // hide first tick
              axisLine={false}
            />

            {/* This Area gives the area below the baseline a darker colour to show that it is negative. This is
             after feedback that it wasn't clear enough that the 0 line wasn't the bottom of the graph. */}
            <Area
              type="monotone"
              dataKey={() => _.min(getYTicks(data))}
              fill={COLOURS.HEADER_BLUE}
              stroke={COLOURS.HEADER_BLUE}
              isAnimationActive={false}
            />

            <ReferenceLine y={0} stroke="white" />

            <Tooltip
              labelFormatter={(label: any, payload: any) => (
                <span>
                  {moment(new Date(payload[0]?.payload?.timestamp)).format('DD/MM/YYYY')} - TP
                  {payload[0]?.payload.tp}
                </span>
              )}
              content={CustomTooltip}
            />

            <defs>
              <linearGradient id="percentileRangeGradient" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor={COLOURS.WIND_TEAL} stopOpacity={0.3} />
                <stop offset="100%" stopColor={COLOURS.WIND_TEAL} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area dataKey="percentileRange" fill="url(#percentileRangeGradient)" strokeWidth={0} />

            {Object.entries(WIND_FORECAST_GRAPH_KEYS).map(([dataKey, val]) => (
              <Line
                type="linear"
                dataKey={dataKey}
                key={val.label}
                stroke={val.color}
                dot={false}
                strokeDasharray={val.isDashed ? '5 4' : undefined}
                strokeWidth={2}
              />
            ))}

            <defs>
              <linearGradient id="historicalFill" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor={COLOURS.WIND_TEAL} stopOpacity={0} />
                <stop offset="100%" stopColor={COLOURS.WIND_TEAL} stopOpacity={0.4} />
              </linearGradient>
            </defs>
            {xReferenceLine && (
              <>
                <ReferenceArea x1={data[0].timestamp} x2={xReferenceLine} fill="url(#historicalFill)" />
                <ReferenceLine x={xReferenceLine} stroke="white" />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
      <RTPLegend legendItems={legendItems} className={styles.legend} />
      {lastUpdated && <div>Last updated {moment(lastUpdated).format('ddd DD MMM YYYY, HH:mm')}</div>}
    </div>
  );
};

const CustomTooltip = (data: any) => {
  const { payload } = data;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { percentile_90, percentile_50, percentile_10, discrepancy, offered, timestamp, generationmw } =
    payload?.[0]?.payload ?? {};

  const TooltipEntry = ({ colour, label, value }: { colour: string; label: string; value: number }) => {
    if (value === null) return null;

    return (
      <TooltipRow>
        <TooltipKeyArea>
          <TooltipColorKey color={colour} />
          <BoldTooltipText style={{ marginRight: 48 }} text={label} />
        </TooltipKeyArea>

        <TooltipValueArea>
          <span>{formatReading(value)}</span>
        </TooltipValueArea>
      </TooltipRow>
    );

    function formatReading(n: number): string {
      return n !== 0 ? `${roundToDpWithCommas(n, 2)} MW` : '-';
    }
  };

  return (
    <TooltipWrapper>
      <svg width="20" height="2">
        <defs>
          <pattern id="dashed" width="5" height="2" patternUnits="userSpaceOnUse">
            <line x1="0" y1="5" x2="10" y2="5" stroke="#000000" strokeWidth="2" />
          </pattern>
        </defs>
        <rect width="100" height="100" fill="url(#dashed)" />
      </svg>
      <TooltipRow>
        <TooltipKeyArea>
          <TooltipColorKey color="transparent" />
          <BoldTooltipText style={{ marginRight: 48 }} text="Time" />
        </TooltipKeyArea>

        <TooltipValueArea>
          <span>{moment(timestamp).format('ddd DD MMM YYYY, HH:mm')}</span>
        </TooltipValueArea>
      </TooltipRow>
      <TooltipEntry
        colour={WIND_FORECAST_GRAPH_KEYS.generationmw.color}
        label={WIND_FORECAST_GRAPH_KEYS.generationmw.tooltip}
        value={generationmw}
      />
      <TooltipEntry
        colour={WIND_FORECAST_GRAPH_KEYS.percentile_90.color}
        label={WIND_FORECAST_GRAPH_KEYS.percentile_90.tooltip}
        value={percentile_90}
      />
      <TooltipEntry
        colour={WIND_FORECAST_GRAPH_KEYS.percentile_50.color}
        label={WIND_FORECAST_GRAPH_KEYS.percentile_50.tooltip}
        value={percentile_50}
      />
      <TooltipEntry
        colour={WIND_FORECAST_GRAPH_KEYS.percentile_10.color}
        label={WIND_FORECAST_GRAPH_KEYS.percentile_10.tooltip}
        value={percentile_10}
      />
      <TooltipEntry
        colour={WIND_FORECAST_GRAPH_KEYS.offered.color}
        label={WIND_FORECAST_GRAPH_KEYS.offered.tooltip}
        value={offered}
      />
      <TooltipEntry
        colour={WIND_FORECAST_GRAPH_KEYS.discrepancy.color}
        label={WIND_FORECAST_GRAPH_KEYS.discrepancy.tooltip}
        value={discrepancy}
      />
    </TooltipWrapper>
  );
};

// A function that takes two unix timestamp values as arguments and returns an array of ticks
function getXAxisTicksForTimestamps(start: number, end: number): number[] {
  const startMoment: moment.Moment = moment(start).startOf('hour');
  const endMoment: moment.Moment = moment(end).endOf('hour');

  if (startMoment.isAfter(endMoment)) return [];

  const result: number[] = [];
  const current: moment.Moment = startMoment;

  while (current.isSameOrBefore(endMoment)) {
    result.push(current.valueOf());
    current.add(1, 'hour');
  }

  return result;
}

/**
 * Returns the ticks for the YAxis.
 *
 * The ticks are multiples of 100.
 *
 * @param data Wind forecast data points to get Y ticks for
 */
function getYTicks(data: WindDataPointNullable[]): number[] {
  return generateTicksFor(calculateDomain(getYMin(data), getYMax(data)));

  type Domain = {
    max: number;
    min: number;
  };
  function calculateDomain(minDataVal: number, maxDataVal: number): Domain {
    // The minimum is always -100 unless a value is smaller.
    // The maximum is always 300 unless a value is bigger.
    const SMALLEST_DOMAIN = {
      min: -100,
      max: 300,
    };

    if (minDataVal === Number.NEGATIVE_INFINITY || maxDataVal === Number.POSITIVE_INFINITY) {
      // infinities mean there is no data
      return {
        min: SMALLEST_DOMAIN.min,
        max: SMALLEST_DOMAIN.max,
      };
    }

    return {
      min: Math.min(floorToNearest100(minDataVal), SMALLEST_DOMAIN.min),
      max: Math.max(ceilToNearest100(maxDataVal), SMALLEST_DOMAIN.max),
    };
  }
  function generateTicksFor(domain: Domain) {
    const result: number[] = [];
    for (let current = domain.min; current <= domain.max; current += 100) {
      result.push(current);
    }
    return result;
  }
}

function getYMin(data: WindDataPointNullable[]): number {
  return data.reduce((acc, val) => {
    if (val.generationmw !== null) {
      // Historical data
      return Math.min(acc, val.generationmw);
    }
    if (val.percentile_10 !== null && val.percentile_50 !== null && val.percentile_90 !== null) {
      // Forecast data
      return Math.min(
        acc,
        val.offered || Number.POSITIVE_INFINITY, // could be null because it might not go as far as the forecast
        val.discrepancy || Number.POSITIVE_INFINITY, // same as above
        val.percentile_10,
        val.percentile_50,
        val.percentile_90,
      );
    }
    return acc;
  }, Number.POSITIVE_INFINITY);
}

function getYMax(data: WindDataPointNullable[]): number {
  return data.reduce((acc, val) => {
    if (val.generationmw !== null) {
      // Historical data
      return Math.max(acc, val.generationmw);
    }
    if (val.percentile_10 !== null && val.percentile_50 !== null && val.percentile_90 !== null) {
      // Forecast data
      return Math.max(
        acc,
        val.offered || Number.NEGATIVE_INFINITY, // could be null because it might not go as far as the forecast
        val.discrepancy || Number.NEGATIVE_INFINITY, // same as above
        val.percentile_10,
        val.percentile_50,
        val.percentile_90,
      );
    }
    return acc;
  }, Number.NEGATIVE_INFINITY);
}

function ceilToNearest100(n: number) {
  return Math.ceil(n / 100) * 100;
}

function floorToNearest100(n: number) {
  return Math.floor(n / 100) * 100;
}
