import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IPriceReportCriteria } from 'types.d';
import { PriceOption, PriceRunType, DateFilterOption, ReportType } from 'enums.d';

import { getNodesSelectedString } from 'tools/utilities/getNodesSelectedString';

import { CriteriaOption, UpdateFiltersFunc } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';
import { actionGetPriceReport, TYPES } from 'redux/modules/nodes/actions';

import { SelectionCriteria } from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { CustomDateSelection } from 'views/components/SelectionCriteria/CustomDateSelection/CustomDateSelection';
import { actionTrackReportDownload } from 'redux/modules/analytics/actions';

import { getReportContent } from 'redux/modules/nodes/selectors';
import { formatPriceReportFilename } from 'tools/utilities/report';
import { generateDownload } from 'tools/utilities/file';
import { SelectionDownload } from '../SelectionCriteria/SelectionDownload/SelectionDownload';

const PRICE_OPTIONS: CriteriaOption[] = [
  { label: 'Average', id: PriceOption.AVERAGE },
  { label: 'Market', id: PriceOption.MARKET },
];

const AVERAGE_PRICE_DATE_OPTIONS: CriteriaOption[] = [
  { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
  { label: 'Previous 7 Days', id: DateFilterOption.PREVIOUS_7_DAYS },
  { label: 'Month to Date', id: DateFilterOption.MONTH_TO_DATE },
  { label: 'Previous Month', id: DateFilterOption.PREVIOUS_MONTH },
  {
    id: DateFilterOption.CUSTOM,
    label: 'Custom',
    body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
      <CustomDateSelection updateFilters={updateFilters} resetState={resetState} />
    ),
  },
];

const RUN_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'Provisional', id: PriceRunType.PROVISIONAL },
  { label: 'Interim', id: PriceRunType.INTERIM },
  { label: 'Final', id: PriceRunType.FINAL },
  { label: 'Price Responsive - Latest', id: PriceRunType.PRICE_RESPONSIVE },
  { label: 'Non Response - Latest', id: PriceRunType.NON_RESPONSE },
];

interface IProps {
  selectedNodes: string[];
  areAllNodesSelected: boolean;
}

export const PriceSelectionCriteria = ({ selectedNodes, areAllNodesSelected }: IProps) => {
  const [criteria, setCriteria] = useState<IPriceReportCriteria>({
    priceOption: PriceOption.AVERAGE,
    dateOption: DateFilterOption.YESTERDAY,
    fromTradingDate: '',
    toTradingDate: '',
    fromTradingPeriod: '1',
    toTradingPeriod: '48',
    priceRunType: PriceRunType.FINAL, // Default as per EM40 (https://docs.google.com/spreadsheets/d/1JizNQ2u0VDFS9mkszR38Dwlet60wyt6T5ScXR7QlWQY/edit?ts=5ee95870#gid=1116945343)
  });

  const MARKET_PRICE_DATE_OPTIONS: CriteriaOption[] = [
    { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
    {
      id: DateFilterOption.CUSTOM,
      label: 'Custom',
      body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
        <CustomDateSelection
          updateFilters={updateFilters}
          resetState={resetState}
          showSingleDate
          showTradingPeriods
          canBeFutureDate={
            criteria.priceRunType === PriceRunType.NON_RESPONSE ||
            criteria.priceRunType === PriceRunType.PRICE_RESPONSIVE
          }
        />
      ),
    },
  ];

  const dispatch = useDispatch();
  const reportContent = useSelector(getReportContent);

  useEffect(() => {
    if (reportContent) {
      const filename = formatPriceReportFilename(criteria);
      generateDownload(reportContent, `${filename}.csv`, 'text/csv');
      dispatch({ type: TYPES.CLEAN_UP_REPORT });
    }
  }, [reportContent, criteria, dispatch]);

  const updateCriteria = (key: string, filterOptionId: string) => {
    setCriteria({ ...criteria, [key]: filterOptionId });
  };

  const handleReportDownload = () => {
    const finalCriteria: IPriceReportCriteria = {
      ...criteria,
    };

    if (criteria.priceOption === PriceOption.MARKET) {
      finalCriteria.priceRunType = criteria.priceRunType || PriceRunType.FINAL;
    }

    dispatch(actionGetPriceReport(finalCriteria, selectedNodes, areAllNodesSelected));
    dispatch(
      actionTrackReportDownload({
        reportType: ReportType.PRICE,
        criteria,
      }),
    );
  };

  const dateOptions =
    criteria.priceOption === PriceOption.AVERAGE ? AVERAGE_PRICE_DATE_OPTIONS : MARKET_PRICE_DATE_OPTIONS;

  return (
    <SelectionCriteria>
      <CriteriaAccordian
        initialOption={PRICE_OPTIONS.find(({ id }) => id === criteria.priceOption)}
        options={PRICE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('priceOption', option.id)}
        updateCriteria={updateCriteria}
      />
      <CriteriaAccordian
        key={`${criteria.priceOption}${criteria.priceRunType}`}
        initialOption={dateOptions.find(({ id }) => id === criteria.dateOption)}
        options={dateOptions}
        onChange={(option: CriteriaOption) => updateCriteria('dateOption', option.id)}
        updateCriteria={updateCriteria}
        headerIcon="ic-calendar"
      />
      {criteria.priceOption === PriceOption.MARKET && (
        <CriteriaAccordian
          initialOption={RUN_TYPE_OPTIONS.find(({ id }) => id === criteria.priceRunType)}
          options={RUN_TYPE_OPTIONS}
          onChange={(option: CriteriaOption) => updateCriteria('priceRunType', option.id)}
          updateCriteria={updateCriteria}
        />
      )}

      <SelectionDownload
        isVisibleOnMobile={selectedNodes.length > 0}
        downloadAction={handleReportDownload}
        text={getNodesSelectedString(selectedNodes.length, areAllNodesSelected)}
        forceCorrectDateForDownload={criteria.dateOption === DateFilterOption.CUSTOM}
      />
    </SelectionCriteria>
  );
};
