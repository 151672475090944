import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import './Dashboard.scss';

import {
  actionGetHvdcLast24,
  actionGetNzReservesLast24,
  actionGetNzSummaryLast24,
  actionGetGenerationByTypeLast24,
  actionGetRecentLoad,
  actionUpdateCurrentNodeType,
  actionGetLoadNodes,
  actionGetPriceNodes,
  actionGetIntervals,
} from 'redux/modules/nodes/actions';
import {
  getCurrentNodes,
  getCurrentNodeType,
  hasSelectedCustomNodes as hasSelectedCustomNodesSelector,
  getPriceNodesForMap,
  getLoadNodesForMap,
} from 'redux/modules/nodes/selectors';
import { useDataPoller } from 'tools/hooks/useDataPoller';

import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { Map } from 'views/components/Map/Map';

import { NzIsLandLoadLast24Hr } from 'views/components/Graphs/NzIsLandLoadLast24Hr';
import { HvdcLast24Hr, HvdcLast24HrHeadingData } from 'views/components/Graphs/HvdcLast24Hr'
import { NzReservesLast24Hrs } from 'views/components/Graphs/NzReservesLast24Hrs';
import { GenerationByTypeLast24Hr } from 'views/components/Graphs/GenerationByTypeLast24Hr';
import { Link } from 'react-router-dom';
import { NodeType } from 'enums.d';
import { Radio } from 'views/components/Radio/Radio';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';
import { ROUTE_PATHS } from '../../../constants/routes';

const FIVE_MINUTES = 300000;

export const Dashboard = () => {
  const dispatch = useDispatch();

  const currentNodes = useSelector(getCurrentNodes);
  const currentNodeType = useSelector(getCurrentNodeType);
  const hasSelectedCustomNodes = useSelector(hasSelectedCustomNodesSelector);
  const isCurrentNodeTypePrice = currentNodeType === NodeType.PRICE;

  const nodesForMap = useSelector(isCurrentNodeTypePrice ? getPriceNodesForMap : getLoadNodesForMap);
  const actionForPolling = isCurrentNodeTypePrice ? actionGetPriceNodes : actionGetRecentLoad;
  const supplementaryActionForPolling = isCurrentNodeTypePrice ? actionGetIntervals : () => {};

  // Poll for map data, different action depending on the type of node active
  useDataPoller(actionForPolling, FIVE_MINUTES, currentNodes);
  useDataPoller(supplementaryActionForPolling, FIVE_MINUTES, currentNodes);

  // All of the charts should update every 30 minutes as per em70 to 73.
  // See https://docs.google.com/spreadsheets/d/1JizNQ2u0VDFS9mkszR38Dwlet60wyt6T5ScXR7QlWQY/edit#gid=1116945343&range=A78
  useDataPoller(actionGetNzSummaryLast24, FIVE_MINUTES);
  useDataPoller(actionGetGenerationByTypeLast24, FIVE_MINUTES);
  useDataPoller(actionGetHvdcLast24, FIVE_MINUTES);
  useDataPoller(actionGetNzReservesLast24, FIVE_MINUTES);

  useEffect(() => {
    // Fetch data for price map
    if (currentNodeType === NodeType.PRICE) {
      dispatch(actionGetPriceNodes(currentNodes));
      dispatch(actionGetIntervals(currentNodes));
    }

    // Fetch data for load map
    if (currentNodeType === NodeType.LOAD) {
      dispatch(actionGetLoadNodes(currentNodes));
      dispatch(actionGetRecentLoad(currentNodes));
    }
  }, [dispatch, currentNodeType, currentNodes]);

  // Fetch data for charts
  useEffect(() => {
    dispatch(actionGetHvdcLast24());
    dispatch(actionGetNzReservesLast24());
    dispatch(actionGetNzSummaryLast24());
    dispatch(actionGetGenerationByTypeLast24());
  }, [dispatch]);

  let mapTitle = 'DEFAULT_PRICE_NODES';

  if (!isCurrentNodeTypePrice) {
    mapTitle = 'DEFAULT_LOAD_NODES';
  } else if (hasSelectedCustomNodes) {
    mapTitle = 'CUSTOM_NODES';
  }

  return (
    <AccessRestrictionWrapper accessScopeRequired={UserAccessScope.PREMIUM_DASH}>
      <RouteTransition>
        <div className="Dashboard">
          <div className="Dashboard-body">
            <div className="Map-content">
              <div className="Dashboard-content">
                <div className="Dashboard-content-head">
                  <h3>
                    <FormattedMessage id={mapTitle} />
                  </h3>
                  <div className="Dashboard-mapType">
                    <Radio
                      label="Price"
                      id="node-type-price"
                      isChecked={currentNodeType === NodeType.PRICE}
                      name="node-type-price"
                      onChange={() => dispatch(actionUpdateCurrentNodeType(NodeType.PRICE))}
                      value={NodeType.PRICE as NodeType}
                    />
                    <Radio
                      label="Load"
                      id="node-type-load"
                      isChecked={currentNodeType === NodeType.LOAD}
                      name="node-type-*load"
                      onChange={() => dispatch(actionUpdateCurrentNodeType(NodeType.LOAD))}
                      value={NodeType.LOAD as NodeType}
                    />
                  </div>
                </div>
                {isCurrentNodeTypePrice && (
                  <Link to={ROUTE_PATHS.CUSTOM_NODE_SELECTION}>
                    <span className="Dashboard-map-customise">Customise price nodes ›</span>
                  </Link>
                )}
                <Map nodes={nodesForMap} />
              </div>
            </div>
            <div className="Dashboard-small-graph">
              <div className="Dashboard-content">
                <div className="Dashboard-content-head">
                  <h3>NZ Island Load (MW)</h3>
                </div>
                <div className="Dashboard-graph">
                  <NzIsLandLoadLast24Hr />
                </div>
              </div>
              <div className="Dashboard-content">
                <div className="Dashboard-content-head">
                  <h3>NZ Generation (MW)</h3>
                </div>
                <div className="Dashboard-graph">
                  <GenerationByTypeLast24Hr />
                </div>
              </div>
            </div>
            <div className="Dashboard-small-graph">
              <div className="Dashboard-content">
                <div className="Dashboard-content-head">
                  <h3>HVDC</h3>
                  <HvdcLast24HrHeadingData />
                </div>
                <div className="Dashboard-graph">
                  <HvdcLast24Hr />
                </div>
              </div>
              <div className="Dashboard-content">
                <div className="Dashboard-content-head">
                  <h3>NZ Reserves (MW)</h3>
                </div>
                <div className="Dashboard-graph">
                  <NzReservesLast24Hrs/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RouteTransition>
    </AccessRestrictionWrapper>
  );
};
