import React from 'react';
import { motion } from 'framer-motion';
import classnames from 'classnames';

import './Modal.scss';

import { useToggleBodyClass } from 'tools/hooks/useToggleClass';

import { Button } from 'views/components/Button/Button';

type ButtonConfig = {
  label: string;
  onClick: () => void;
};

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  primaryButtonConfig: ButtonConfig;
  secondaryButtonConfig?: ButtonConfig;
  isSecondaryFirst?: boolean;
}

export const Modal = ({
  isOpen = false,
  children,
  primaryButtonConfig,
  secondaryButtonConfig,
  isSecondaryFirst,
}: Props) => {
  useToggleBodyClass(isOpen, ['noScroll']);

  const handlePrimaryButtonClick = () => {
    primaryButtonConfig.onClick();
  };

  const handleSecondaryButtonClick = () => {
    if (secondaryButtonConfig) {
      secondaryButtonConfig.onClick();
    }
  };

  const modalClasses = classnames('Modal', { 'is-open': isOpen });

  return (
    <>
      <Overlay show={isOpen} />
      <motion.div
        className={modalClasses}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ ease: 'easeIn', duration: 0.1 }}
      >
        <div className="Modal-body">
          {children}
          <div className="Modal-body-buttons">
            {isSecondaryFirst ? (
              <>
                {secondaryButtonConfig && (
                  <Button
                    label={secondaryButtonConfig.label}
                    onClick={handleSecondaryButtonClick}
                    className="Button--secondary"
                  />
                )}
                <Button label={primaryButtonConfig.label} onClick={handlePrimaryButtonClick} />
              </>
            ) : (
              <>
                <Button label={primaryButtonConfig.label} onClick={handlePrimaryButtonClick} />
                {secondaryButtonConfig && (
                  <Button
                    label={secondaryButtonConfig.label}
                    onClick={handleSecondaryButtonClick}
                    className="Button--secondary"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

interface OverlayProps {
  show: boolean;
}

const Overlay = ({ show }: OverlayProps) => (
  <motion.div
    initial={false}
    animate={{ opacity: show ? 1 : 0 }}
    transition={{ duration: 0.1 }}
    style={{ pointerEvents: show ? 'auto' : 'none' }}
    className="Overlay"
  />
);
