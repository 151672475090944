/* eslint-disable import/no-cycle */
import { takeEvery, put, select } from 'redux-saga/effects';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { TYPES as TYPES_APP } from 'redux/modules/app/actions';
import {
  TYPES as TYPES_ANALYTICS,
  ITrackReportDownload,
  actionTrackReportDownloadSuccess,
  actionTrackReportDownloadFail,
  ITrackReportDownloadTime,
  ITrackFailedLogin,
  actionTrackFailedLoginSuccess,
  actionTrackFailedLoginFail,
  ITrackPasswordReset,
  ITrackCustomNodeSelection,
  actionTrackPageViewSuccess,
  actionTrackPageViewFail,
  ITrackFTRFilterSelection,
  actionTrackFTRFilterSelectionFail,
  actionTrackFTRFilterSelectionSuccess,
} from 'redux/modules/analytics/actions';

import { setAnalyticsDataForReport } from 'tools/utilities/analytics';
import { getReportDownloadTime } from 'redux/modules/nodes/selectors';
import { getUser } from 'redux/modules/session/selectors';
import { TYPES as TYPES_SESSION } from 'redux/modules/session/actions';

ReactGA.initialize(process.env.REACT_APP_GA_ID || 'UA-146351330-2');
ReactGA4.initialize(process.env.REACT_APP_GA4_ID || 'G-6ZZ686MMG4'); // fallback is dev tag

// This one does not need error handling
function* setAnalyticsUser() {
  const { id } = yield select(getUser);

  ReactGA.set({ userId: id });
  ReactGA4.set({ userId: id });
}

function* trackPageView() {
  try {
    const { pathname, search } = document.location;
    const url = pathname + search;

    ReactGA.pageview(url);
    // Google Analytics 4 automatically handles page views so no need for ReactGA4 call here

    yield put(actionTrackPageViewSuccess(url));
  } catch (e) {
    yield put(actionTrackPageViewFail(e));
  }
}

function* trackReportDownload(action: ITrackReportDownload) {
  const { reportType } = action.payload;

  try {
    const eventInfo = {
      category: 'Report',
      action: 'Download',
      label: reportType.toString(),
    };

    const eventPayload = {
      ...eventInfo,
      ...setAnalyticsDataForReport(action.payload),
    };

    ReactGA.event(eventPayload);
    ReactGA4.event(eventPayload);

    yield put(actionTrackReportDownloadSuccess());
  } catch (e) {
    yield put(actionTrackReportDownloadFail(e));
  }
}

function* trackReportDownloadTime(action: ITrackReportDownloadTime) {
  const { reportType } = action.payload;

  try {
    const reportDownloadTime: number | undefined = yield select(getReportDownloadTime);

    if (reportDownloadTime === undefined) {
      throw new Error('Undefined reporting time');
    }

    const timingPayload = {
      category: 'Report',
      action: 'Download',
      value: reportDownloadTime,
      variable: `${reportType.toString()} Report Download time`,
    };

    ReactGA.timing(timingPayload);
    // react-ga4 has no built-in timing event like react-ga, so we have to use the _gaCommandSendTiming method
    // eslint-disable-next-line no-underscore-dangle
    ReactGA4._gaCommandSendTiming(
      timingPayload.category,
      timingPayload.variable,
      timingPayload.value,
      timingPayload.action,
    );

    yield put(actionTrackReportDownloadSuccess());
  } catch (e) {
    yield put(actionTrackReportDownloadFail(e));
  }
}

function* trackFTRFilterSelection(action: ITrackFTRFilterSelection) {
  try {
    const eventPayload = {
      category: 'Report',
      action: 'Filter',
      label: 'FTR',
    };

    ReactGA.event(eventPayload);
    ReactGA4.event(eventPayload);

    yield put(actionTrackFTRFilterSelectionSuccess());
  } catch (e) {
    yield put(actionTrackFTRFilterSelectionFail(e));
  }
}

function* trackFailedLogin(action: ITrackFailedLogin) {
  const { email } = action.payload;

  try {
    const emailFormattedForAnalytics = email.replace(/@/g, ' at ');
    const eventPayload = {
      category: 'Failed login',
      action: `Login attempt failed for ${emailFormattedForAnalytics}`,
      label: emailFormattedForAnalytics,
    };

    ReactGA.event(eventPayload);
    ReactGA4.event(eventPayload);

    yield put(actionTrackFailedLoginSuccess());
  } catch (e) {
    yield put(actionTrackFailedLoginFail(e));
  }
}

function* trackPasswordReset(action: ITrackPasswordReset) {
  const { email } = action.payload;

  try {
    const emailFormattedForAnalytics = email.replace(/@/g, ' at ');
    const eventPayload = {
      category: 'Password reset',
      action: `Password reset by ${emailFormattedForAnalytics}`,
      label: emailFormattedForAnalytics,
    };

    ReactGA.event(eventPayload);
    ReactGA4.event(eventPayload);

    yield put(actionTrackFailedLoginSuccess());
  } catch (e) {
    yield put(actionTrackFailedLoginFail(e));
  }
}

function* trackCustomNodeSelection(action: ITrackCustomNodeSelection) {
  const { nodes } = action.payload;

  try {
    const { email } = yield select(getUser);
    const emailFormattedForAnalytics = email.replace(/@/g, ' at ');
    const eventPayload = {
      category: 'Custom node selection',
      action: `Custom nodes selected by ${emailFormattedForAnalytics}`,
      label: `${emailFormattedForAnalytics} selected nodes ${JSON.stringify(nodes)}`,
    };

    ReactGA.event(eventPayload);
    ReactGA4.event(eventPayload);

    yield put(actionTrackFailedLoginSuccess());
  } catch (e) {
    yield put(actionTrackFailedLoginFail(e));
  }
}

export function* analyticsSaga() {
  yield takeEvery(TYPES_SESSION.LOGIN_SUCCESS, setAnalyticsUser);
  yield takeEvery(TYPES_SESSION.VERIFY_SESSION_SUCCESS, setAnalyticsUser);

  yield takeEvery(TYPES_APP.LOCATION_CHANGE, trackPageView);
  yield takeEvery(TYPES_ANALYTICS.TRACK_REPORT_DOWNLOAD, trackReportDownload);
  yield takeEvery(TYPES_ANALYTICS.TRACK_REPORT_DOWNLOAD_TIME, trackReportDownloadTime);
  yield takeEvery(TYPES_ANALYTICS.TRACK_FAILED_LOGIN, trackFailedLogin);
  yield takeEvery(TYPES_ANALYTICS.TRACK_PASSWORD_RESET, trackPasswordReset);
  yield takeEvery(TYPES_ANALYTICS.TRACK_CUSTOM_NODE_SELECTION, trackCustomNodeSelection);
  yield takeEvery(TYPES_ANALYTICS.TRACK_FTR_FILTER_SELECTION, trackFTRFilterSelection);
}
