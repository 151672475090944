export enum UserAccessScope {
  FREE = 'EM6_FREE',
  PREMIUM_DASH = 'EM6_PUBL',
  GWAP = 'EM6_GWAP',

  CARBON = 'EM6_CARB',
  SCADA = 'EM6_SCAD',
  PRICE = 'EM6_PRIC',
  TRADING = 'EM6_PRIC', // Still uses PRIC as it doesn't have a unique access group yet.
  ARC_FLOWS = 'EM6_NTWK',
  RESERVES = 'EM6_RSRV',

  RCPD = 'EM6_RCPD',
}
