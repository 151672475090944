import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { isValid, isFuture } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import './CustomDateSelection.scss';

import { MaskedInput } from 'views/components/MaskedInput/MaskedInput';
import { Icon } from 'views/components/Icon/Icon';
import { TextInput } from 'views/components/TextInput/TextInput';
import { useSelector, useDispatch } from 'react-redux';
import { hasAttemptedDownloadSelector } from 'redux/modules/nodes/selectors';
import { actionValidateDateRange } from 'redux/modules/nodes/actions';
import { MIN_TRADING_PERIOD, MAX_TRADING_PERIOD } from 'redux/modules/nodes/constants';
import { UpdateFiltersFunc } from '../SelectionCriteriaTypes';

export const formatDate = (date: string) => {
  const dateWithNoPlaceholderChars = date.replace(/_/g, '');
  const splitDate = dateWithNoPlaceholderChars.split('/');
  const formattedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

  return formattedDate.length === 10 ? formattedDate : '';
};

interface Props {
  resetState: () => void;
  updateFilters: UpdateFiltersFunc;
  showSingleDate?: boolean;
  showTradingPeriods?: boolean;
  setInvalid?: (state: boolean) => void;
  canBeFutureDate?: boolean;
  defaultStartDate?: string;
  defaultEndDate?: string;
}

export const CustomDateSelection = ({
  resetState,
  updateFilters,
  showSingleDate,
  showTradingPeriods,
  canBeFutureDate = false,
  defaultStartDate,
  defaultEndDate,
}: Props) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(defaultStartDate || '');
  const [endDate, setEndDate] = useState(defaultEndDate || '');
  const [toTradingPeriod, setToTradingPeriod] = useState<number>(48);
  const [fromTradingPeriod, setFromTradingPeriod] = useState<number>(1);
  const [isValidStartTP, setValidStartTP] = useState(true);
  const [isValidEndTP, setValidEndTP] = useState(true);
  const [isValidTPRange, setValidTPRange] = useState(true);
  const [isValidStartDate, setValidStartDate] = useState(false);
  const [isValidEndDate, setValidEndDate] = useState(false);

  const hasAttemptedReportDownload = useSelector(hasAttemptedDownloadSelector);
  const dateLabel = showSingleDate ? 'Trading Date' : 'Start Date';
  const inputWrapperClassname = classnames('CustomDateSelection-inputWrapper', {
    showTradingPeriods,
  });

  // Checks that the date ranges in correct order.
  // I.e The end date is not before the start date
  useEffect(() => {
    if (isValidStartDate && isValidEndDate && !showSingleDate) {
      const start = new Date(formatDate(startDate));
      const end = new Date(formatDate(endDate));
      const isValidDateRange = start <= end;

      // Invalidates the start or end date fields if the range is invalid
      setValidEndDate(isValidDateRange);
      setValidStartDate(isValidDateRange);
    }
  }, [startDate, endDate, isValidStartDate, isValidEndDate, showSingleDate]);

  // Resets the valid date range state in redux to false on custom date selection
  // being loaded
  useEffect(() => {
    dispatch(actionValidateDateRange(false));

    return () => {
      dispatch(actionValidateDateRange(false));
    };
  }, [dispatch]);

  // if a date has changed (from or to), validate that all dates are still valid
  useEffect(() => {
    const isTPValid = isValidTPRange && isValidEndTP && isValidStartTP;
    const isSingleDateValid = isValidStartDate && isTPValid;

    const isBothDatesValid = isValidStartDate && isValidEndDate && isTPValid;

    const isOverallDateValid = showSingleDate ? isSingleDateValid : isBothDatesValid;
    dispatch(actionValidateDateRange(isOverallDateValid));
  }, [isValidEndDate, isValidStartDate, isValidStartTP, isValidEndTP, isValidTPRange, showSingleDate, dispatch]);

  // handle a single date input change, check it's valid, and let it be set
  const handleDateChange = (dateValue: string, key: 'fromTradingDate' | 'toTradingDate') => {
    const formattedDate = formatDate(dateValue);
    const date = new Date(formattedDate);

    // the time in the parsed date gets set to 12pm, which was causing the date to be
    // an invalid future date when using the custom date picker on the day of and before 12.
    // We reset the hours to check on the date only.
    date.setHours(0);

    const isValidDate = isValid(date);
    const isFutureDate = isFuture(date);

    // Don't allow future dates if we dont want them
    const canProceedWithDate = isValidDate && (!isFutureDate || canBeFutureDate);

    if (key === 'fromTradingDate') {
      setValidStartDate(canProceedWithDate);
      setStartDate(dateValue);
    } else {
      setValidEndDate(canProceedWithDate);
      setEndDate(dateValue);
    }

    if (canProceedWithDate) {
      updateFilters(key, dateValue);
    } else {
      updateFilters(key, '');
    }
  };

  const handleTradingPeriodChange = (key: 'fromTradingPeriod' | 'toTradingPeriod', newTP: number) => {
    const isValidTP = newTP >= MIN_TRADING_PERIOD && newTP <= MAX_TRADING_PERIOD;

    // Check together it makes sense. If not fail both of them.
    if (key === 'fromTradingPeriod') {
      if (newTP > toTradingPeriod) {
        setValidTPRange(false);
      } else {
        setValidTPRange(true);
      }
    } else if (newTP < fromTradingPeriod) {
      setValidTPRange(false);
    } else {
      setValidTPRange(true);
    }

    // Assign the values
    if (key === 'fromTradingPeriod') {
      setFromTradingPeriod(newTP);
      setValidStartTP(isValidTP);
    } else {
      setToTradingPeriod(newTP);
      setValidEndTP(isValidTP);
    }

    updateFilters(key, `${newTP}`);
  };

  return (
    <div className="CustomDateSelection">
      <div className={inputWrapperClassname}>
        <div className="CustomDateSelection-dates">
          <MaskedInput
            label={dateLabel}
            id="dateFrom"
            className="CustomDateSelection-input"
            mask="99/99/9999"
            placeholder="01/01/2020"
            onChange={(e) => handleDateChange(e.target.value, 'fromTradingDate')}
            type="tel"
            isErrorShowing={hasAttemptedReportDownload && !isValidStartDate}
            defaultValue={defaultStartDate}
          />
          {/* Only shown if there is a start and end date required */}
          {!showSingleDate && (
            <MaskedInput
              label="End Date"
              id="dateTo"
              className="CustomDateSelection-input"
              mask="99/99/9999"
              placeholder="04/01/2020"
              onChange={(e) => handleDateChange(e.target.value, 'toTradingDate')}
              type="tel"
              defaultValue={defaultEndDate}
              isErrorShowing={hasAttemptedReportDownload && !isValidEndDate}
            />
          )}
        </div>

        {showTradingPeriods && (
          <div className="CustomDateSelection-tradingPeriods">
            <TextInput
              className="CustomDateSelection-input"
              id="startTP"
              label="TP"
              type="number"
              placeholder=""
              onChange={(value) => {
                handleTradingPeriodChange('fromTradingPeriod', parseInt(value, 10));
              }}
              value={`${fromTradingPeriod}`}
              maxLength={2}
              showErrorOutLine={!isValidTPRange || !isValidStartTP}
            />
            <TextInput
              className="CustomDateSelection-input"
              id="endTP"
              label="TP"
              type="number"
              placeholder=""
              onChange={(value) => {
                handleTradingPeriodChange('toTradingPeriod', parseInt(value, 10));
              }}
              value={`${toTradingPeriod}`}
              maxLength={2}
              showErrorOutLine={!isValidTPRange || !isValidEndTP}
            />
          </div>
        )}
      </div>
      <button className="CustomDateSelection-backButton" type="button" onClick={resetState}>
        <FormattedMessage id="BACK_TO_PRESET_DATE_OPTIONS" />
        <Icon name="ic-undo" size="sm" />
      </button>
    </div>
  );
};
