/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';

import './StyleGuide.scss';

import { ICONS } from 'redux/modules/app/constants';
import { Icon, IconName } from 'views/components/Icon/Icon';
import { Spinner } from 'views/components/Spinner/Spinner';

export const StyleGuide = () => (
  <div className="SG">
    <section className="SG-section" />
    <section className="SG-swatches">
      <h2>Swatches</h2>
      <ul>
        <li />
      </ul>
    </section>
    <section className="SG-type">
      <h2>Typography</h2>
      <h1>Heading 1 - Waistcoat hexagon blue bottle drinking vinegar irure.</h1>
      <h2>Heading 2 - Crucifix four dollar toast dolore, qui activated charcoal freegan banjo.</h2>
      <h3>
        Heading 3 - Normcore aesthetic chicharrones flannel. Microdosing do mollit green juice tote bag wayfarers
        shoreditch.
      </h3>
      <h4>
        Heading 4 - Whatever enamel pin deep Williamsburg plaid pokémon live-edge tilde dolore salvia readymade
        single-origin coffee man bun pop-up.
      </h4>
      <h5>
        Heading 5 - Woke artisan selfies, portland fingerstache aliqua waistcoat sriracha ut crucifix celiac velit
        whatever jean shorts ramps. Affogato edison bulb do mumblecore.
      </h5>
      <h6>
        Heading 6 - Cornhole woke street art etsy, whatever post-ironic roof party ad chartreuse. Microdosing
        single-origin coffee kale chips ullamco succulents. Street art adipisicing lumbersexual, yuccie tofu.
      </h6>
      <p className="lead">
        Lead -Swag cardigan reprehenderit shoreditch keffiyeh palo santo gluten-free kogi dolor commodo.
      </p>
      <p>
        Paragraph - Hexagon cardigan truffaut you probably haven't heard of them. Food truck esse pok pok, chillwave
        typewriter roof party tote bag hashtag chia asymmetrical trust fund do raw denim offal laborum. Exercitation
        street art slow-carb venmo DIY velit yr swag fam adipisicing YOLO. Four loko yr consectetur, gastropub nisi
        incididunt exercitation chartreuse typewriter. Microdosing wolf affogato paleo brunch. Put a bird on it
        dreamcatcher sriracha 8-bit sartorial scenester synth, air plant man bun sunt. Truffaut lumbersexual brooklyn
        messenger bag dolor. IPhone sed pitchfork kitsch bitters. Excepteur meditation tofu, irony ullamco trust fund
        try-hard occaecat tilde crucifix flexitarian velit shaman in cliche.
      </p>
      <p className="small">
        Small - Hexagon cardigan truffaut you probably haven't heard of them. Food truck esse pok pok, chillwave
        typewriter roof party tote bag hashtag chia asymmetrical trust fund do raw denim offal laborum. Exercitation
        street art slow-carb venmo DIY velit yr swag fam adipisicing YOLO. Four loko yr consectetur, gastropub nisi
        incididunt exercitation chartreuse typewriter. Microdosing wolf affogato paleo brunch. Put a bird on it
        dreamcatcher sriracha 8-bit sartorial scenester synth, air plant man bun sunt. Truffaut lumbersexual brooklyn
        messenger bag dolor. IPhone sed pitchfork kitsch bitters. Excepteur meditation tofu, irony ullamco trust fund
        try-hard occaecat tilde crucifix flexitarian velit shaman in cliche.
      </p>
    </section>
    <section className="SG-buttons">
      <h1>Buttons</h1>
      <div className="SG-buttons-wrapper">
        <section className="SG-buttons-section">
          <h2 className="SG-buttons-section-title">Primary buttons -- light bg</h2>
          <button type="button" className="Button">
            Normal
          </button>
          <button type="button" className="Button pressed">
            Pressed
          </button>
          <button type="button" disabled className="Button">
            Disabled
          </button>
          <button type="button" className="Button hover">
            Hover
          </button>
          <button type="button" className="Button is-loading">
            <Spinner />
          </button>
        </section>
        <section className="SG-buttons-section SG-buttons-section--dark">
          <h2 className="SG-buttons-section-title">Secondary buttons -- dark bg</h2>
          <button type="button" className="Button Button--secondary Button--secondary--dark">
            Normal
          </button>
          <button type="button" className="Button Button--secondary Button--secondary--dark pressed">
            Pressed
          </button>
          <button type="button" disabled className="Button Button--secondary Button--secondary--dark">
            Disabled
          </button>
          <button type="button" className="Button Button--secondary Button--secondary--dark hover">
            Hover
          </button>
          <button type="button" className="Button Button--secondary Button--secondary--dark is-loading">
            <Spinner />
          </button>
        </section>
        <section className="SG-buttons-section">
          <h2 className="SG-buttons-section-title">Secondary buttons -- light bg</h2>
          <button type="button" className="Button Button--secondary">
            Normal
          </button>
          <button type="button" className="Button Button--secondary pressed">
            Pressed
          </button>
          <button type="button" disabled className="Button Button--secondary">
            Disabled
          </button>
          <button type="button" className="Button Button--secondary hover">
            Hover
          </button>
          <button type="button" className="Button Button--secondary is-loading">
            <Spinner />
          </button>
        </section>
      </div>
    </section>
    <section className="SG-forms">
      <h2>Forms</h2>
      <fieldset>
        <label htmlFor="demo_1">
          Heading
          <input type="text" name="demo_1" placeholder="Hint" />
        </label>
      </fieldset>
    </section>
    <section className="SG-icons">
      <h2>Icons</h2>
      <p>{`There are ${Object.keys(ICONS).length} icons`}</p>
      <ul className="SG-icons-list">
        {Object.keys(ICONS).map((iconName) => (
          <li key={iconName} className="SG-icons-listItem">
            <h3>{iconName}</h3>
            <div className="SG-icons-wrapper">
              <Icon name={iconName as IconName} className="SG-icon" />
            </div>
          </li>
        ))}
      </ul>
    </section>
  </div>
);
