import styles from './GraphNotice.module.scss';

interface IGraphNoticeProps {
  width: string | number;
  height: string | number;
  message?: string;
}

const DEFAULT_MESSAGE = 'There is no generation for the selected region and generation type';

export const GraphNotice = ({ width, height, message }: IGraphNoticeProps) => (
  <div
    className={styles.wrapper}
    style={{
      width,
      height,
    }}
  >
    {message || DEFAULT_MESSAGE}
  </div>
);
