import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelect } from 'downshift';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FormattedMessage } from 'react-intl';
import { isValid, isFuture, parse } from 'date-fns';

import {
  DefaultDateRangeFilterState,
  gwapTimeIntervalState,
  paidDateRangeFilterState,
  paidFilterErrorsState,
} from 'tools/state/paidGwapState';
import { getFromAndToTradingDates } from 'redux/modules/nodes/api';
import { DateFilterOption } from 'enums.d';
import {
  ALLOWED_INTERVALS_FOR_DATE_RANGE,
  IGwapDateFilterOption,
  GWAP_DATE_FILTER_OPTIONS,
} from 'tools/utilities/gwap';

import { Icon } from '../Icon/Icon';
import sharedStyles from './GwapDropDownFilters.module.scss';
import styles from './GwapDateRangeFilter.module.scss';
import { MaskedInput } from '../MaskedInput/MaskedInput';
import { formatDate } from '../SelectionCriteria/CustomDateSelection/CustomDateSelection';

export const GwapDateRangeFilter = () => {
  const [dateFilter, setDateFilter] = useRecoilState(paidDateRangeFilterState);
  const [interval, setInterval] = useRecoilState(gwapTimeIntervalState);
  const [fromDateValid, setFromDateValid] = useState<boolean>(true);
  const [toDateValid, setToDateValid] = useState<boolean>(true);
  const setErrors = useSetRecoilState(paidFilterErrorsState);

  const customFromInputRef = useRef<any>(null);
  const customToInputRef = useRef<any>(null);

  // Todo glam up these errors at some point if we get some more budget to get a design worked out
  useEffect(() => {
    if (!fromDateValid) {
      setErrors(['Please enter a valid from date']);
    }
    if (!toDateValid) {
      setErrors(['Please enter a valid to date']);
    }
    if (toDateValid && fromDateValid) {
      setErrors([]);
    }
  });

  useEffect(() => {
    const { selectedOption } = dateFilter;
    // If the current allowed interval is in the list of allowed intervals for the selectedoption then return
    if (ALLOWED_INTERVALS_FOR_DATE_RANGE[selectedOption].includes(interval)) {
      return;
    }

    const allowedInterval = ALLOWED_INTERVALS_FOR_DATE_RANGE[selectedOption][0];
    setInterval(() => allowedInterval);
  }, [dateFilter, interval, setInterval]);

  const { getToggleButtonProps, isOpen, getMenuProps, getItemProps } = useSelect<IGwapDateFilterOption>({
    items: GWAP_DATE_FILTER_OPTIONS,
    defaultHighlightedIndex: 0,
    defaultIsOpen: dateFilter.selectedOption === DateFilterOption.CUSTOM,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: changes.selectedItem?.value === DateFilterOption.CUSTOM,
            highlightedIndex: state.highlightedIndex,
            selectedIndex: null,
          };
        // Keep the menu open while the user still has "custom" selected
        case useSelect.stateChangeTypes.ToggleButtonClick:
        case useSelect.stateChangeTypes.MenuBlur:
        case useSelect.stateChangeTypes.FunctionToggleMenu:
        case useSelect.stateChangeTypes.FunctionCloseMenu:
          if (dateFilter.selectedOption === DateFilterOption.CUSTOM) {
            return {
              ...changes,
              isOpen: true,
            };
          }

          return changes;
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem: selected }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!selected) {
            break;
          }

          handleDateOptionSelected(selected.value);
          break;
        default:
          break;
      }
    },
  });

  const handleDateOptionSelected = (selectedOption: DateFilterOption) => {
    if (selectedOption === 'custom') {
      setDateFilter({
        selectedOption,
        from: dateFilter.from,
        to: dateFilter.to,
      });
    } else {
      const optionToDates = getFromAndToTradingDates(selectedOption);
      setFromDateValid(true);
      setToDateValid(true);

      setDateFilter({
        selectedOption,
        from: optionToDates.fromTradingDate,
        to: optionToDates.toTradingDate,
      });
    }
  };

  const handleCustomDateBlur: React.FocusEventHandler<any> = (e) => {
    const { from, to } = dateFilter;
    const parsedFrom = parse(from || '', 'dd/MM/yyyy', new Date());
    const parsedTo = parse(to || '', 'dd/MM/yyyy', new Date());
    const newValue = e.currentTarget.value;
    const formattedDate = formatDate(newValue);
    const newDate = parse(formattedDate, 'yyyy-MM-dd', new Date());
    const isFromDate = e.currentTarget.id === 'dateFrom';
    let isValidDate = isValid(newDate);

    // Should never be a future date.
    isValidDate = isValidDate && !isFuture(newDate);

    // If the date is from date make sure it is not before the to date
    if (isFromDate) {
      isValidDate = isValidDate && newDate <= parsedTo;
    } else {
      isValidDate = isValidDate && newDate >= parsedFrom;
    }

    if (isValidDate) {
      if (isFromDate) {
        setFromDateValid(true);
      } else {
        setToDateValid(true);
      }

      setDateFilter({
        selectedOption: dateFilter.selectedOption,
        from: isFromDate ? newValue : dateFilter.from,
        to: isFromDate ? dateFilter.to : newValue,
      });

      return;
    }

    if (isFromDate) {
      setFromDateValid(false);
      customFromInputRef.current?.getInputDOMNode().focus();
    } else {
      setToDateValid(false);
      customToInputRef.current?.getInputDOMNode().focus();
    }
  };

  const handleResetDateFilter: React.MouseEventHandler = () => {
    setDateFilter(DefaultDateRangeFilterState());
  };

  const isCustomSelected = dateFilter.selectedOption === DateFilterOption.CUSTOM;

  return (
    <div className={sharedStyles.downshift_wrapper}>
      <button type="button" className={sharedStyles.multi_select_button} {...getToggleButtonProps()}>
        {GWAP_DATE_FILTER_OPTIONS.find((o) => o.value === dateFilter.selectedOption)?.label}

        <div className={classNames([sharedStyles.arrow, isOpen && sharedStyles.arrow_open])}>
          <Icon name="ic-chevron" size="sm" />
        </div>
      </button>

      <div
        className={classNames([
          sharedStyles.downshift_dropdown_content,
          isCustomSelected && styles.CustomDropdownContent,
          !isOpen && !isCustomSelected && sharedStyles.dropdown_is_closed,
        ])}
        {...getMenuProps()}
      >
        {isCustomSelected && (
          <div className={styles.CustomDateWrapper}>
            <MaskedInput
              label="Start Date"
              id="dateFrom"
              mask="99/99/9999"
              placeholder="01/01/2020"
              onBlur={handleCustomDateBlur}
              type="tel"
              defaultValue={dateFilter.from || ''}
              className={styles.CustomDateInput}
              ref={customFromInputRef}
              isErrorShowing={!fromDateValid}
            />

            <MaskedInput
              label="End Date"
              id="dateTo"
              mask="99/99/9999"
              placeholder="04/01/2020"
              onBlur={handleCustomDateBlur}
              type="tel"
              defaultValue={dateFilter.to || ''}
              className={styles.CustomDateInput}
              ref={customToInputRef}
              isErrorShowing={!toDateValid}
            />

            <button className={styles['CustomDateInput-backButton']} type="button" onClick={handleResetDateFilter}>
              <FormattedMessage id="BACK_TO_PRESET_DATE_OPTIONS" />
              <Icon name="ic-undo" size="sm" />
            </button>
          </div>
        )}

        {isOpen &&
          dateFilter.selectedOption !== DateFilterOption.CUSTOM &&
          GWAP_DATE_FILTER_OPTIONS.map((dateOption: IGwapDateFilterOption, index) => {
            const isOptionSelected = dateFilter.selectedOption === dateOption.value;

            return (
              <div
                className={classNames([
                  sharedStyles.downshift_dropdown_option,
                  styles.DropdownItem,
                  isOptionSelected && styles.isSelected,
                ])}
                {...getItemProps({ item: dateOption, index })}
                key={`${dateOption.label}`}
              >
                {dateOption.label}
              </div>
            );
          })}
      </div>
    </div>
  );
};
