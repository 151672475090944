import React, { useState } from 'react';
import classNames from 'classnames';

import { MINIMUM_PASSWORD_LENGTH } from 'tools/utilities/validators';
import { Icon } from '../Icon/Icon';
import './PasswordInput.scss';

interface Props {
  id: string;
  label: string;
  placeholder: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
  value?: string;
  className?: string;
  error?: string | boolean;
  showErrorOutLine?: boolean; // Case where we need the ability to show error outline if the input is 1 of 2 inputs
  isReadonly?: boolean;
  maxLength?: number; // Restricts number of characters that can be entered into the input field
  allowHints?: boolean;
}

export const PasswordInput = (props: Props) => {
  const {
    id,
    label,
    placeholder,
    onBlur = () => {},
    onChange,
    value,
    className,
    error,
    showErrorOutLine,
    isReadonly,
    maxLength,
    allowHints,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showHint, setShowHint] = useState<boolean>(false);

  const passwordIcon = showPassword ? 'ic-hide-password' : 'ic-see-password';
  const hasContent = value && value.length > 0;

  return (
    <div
      className={classNames('PasswordInput', className, {
        hasValue: !!value && !error,
        hasError: !!error || showErrorOutLine,
        isDisabled: isReadonly,
        'PasswordHint--visible': showHint,
      })}
    >
      <label htmlFor={id}>
        <span className="PasswordInput-label">{label}</span>
        <span className="PasswordInput-inputWrapper">
          <input
            id={id}
            name={id}
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value || '');
            }}
            value={value || ''}
            readOnly={isReadonly}
            disabled={isReadonly}
            onFocus={() => {
              if (allowHints) {
                setShowHint(true);
              }
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              setShowHint(false);
              onBlur(event);
            }}
            maxLength={maxLength}
            className={classNames('PasswordInput-input', {
              'PasswordInput-input-hintVisible': showHint,
            })}
          />
          {hasContent && (
            <button
              tabIndex={-1}
              type="button"
              className="Button PasswordInput-password-reveal"
              onClick={() => setShowPassword(!showPassword)}
            >
              <Icon name={passwordIcon} className="Icon--md" />
            </button>
          )}
        </span>

        {/* Error message */}
        {error && (
          <span className="PasswordInput-error">
            <Icon name="ic-error" className="PasswordInput-error-icon" size="sm" />
            {error}
          </span>
        )}
      </label>
      {showHint && (
        <div className="PasswordInput-hint">
          <p>Must be at least {MINIMUM_PASSWORD_LENGTH} characters, consisting of 3 of the following character sets:</p>
          <ul className="PasswordInput-hint-list">
            <li>
              <p>• Lower case characters (a-z)</p>
            </li>
            <li>
              <p>• Upper case characters (A-Z)</p>
            </li>
            <li>
              <p>• Digits (0-9)</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
