import { useQuery } from 'react-query';
import { makeUnauthorisedGetRequest } from 'tools/utilities/ajax';

interface ICarbonEmissionRecord {
  currentMonthAvgGkwh: number;
  currentYearAvgGkwh: number;
  max24hrsGkwh: number;
  min24hrsGkwh: number;
  nzCarbonChangeGkwh: number;
  nzCarbonGkwh: number;
  nzCarbonGkwhPrev: number;
  nzCarbonT: number;
  nzRenewable: number;
  pctCurrentYearGkwh: number;
  timestamp: string;
  tradingDate: string;
  tradingPeriod: number;
}

export const useCarbonEmissions = () => {
  const { isLoading, data } = useQuery<any>(
    'carbonEmissions',
    () => makeUnauthorisedGetRequest('current_carbon_intensity'),
    {
      staleTime: 1,
      refetchInterval: 5 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );

  const latestCarbonEmissionRecordDto = data?.data?.items?.[0];
  // const latestCarbonEmissionRecordDto = mockRawData().items[0];

  const latestCarbonEmissionRecord: ICarbonEmissionRecord = {
    currentMonthAvgGkwh: latestCarbonEmissionRecordDto?.current_month_avg_gkwh ?? 0,
    currentYearAvgGkwh: latestCarbonEmissionRecordDto?.current_year_avg_gkwh ?? 0,
    max24hrsGkwh: latestCarbonEmissionRecordDto?.max_24hrs_gkwh ?? 0,
    min24hrsGkwh: latestCarbonEmissionRecordDto?.min_24hrs_gkwh ?? 0,
    nzCarbonChangeGkwh: latestCarbonEmissionRecordDto?.nz_carbon_change_gkwh ?? 0,
    nzCarbonGkwh: latestCarbonEmissionRecordDto?.nz_carbon_gkwh ?? 0,
    nzCarbonGkwhPrev: latestCarbonEmissionRecordDto?.nz_carbon_gkwh_prev ?? 0,
    nzCarbonT: latestCarbonEmissionRecordDto?.nz_carbon_t ?? 0,
    nzRenewable: latestCarbonEmissionRecordDto?.nz_renewable ?? 0,
    pctCurrentYearGkwh: latestCarbonEmissionRecordDto?.pct_current_year_gkwh ?? 0,
    timestamp: latestCarbonEmissionRecordDto?.timestamp ?? 'UNDEFINED',
    tradingDate: latestCarbonEmissionRecordDto?.trading_date ?? 'UNDEFINED',
    tradingPeriod: latestCarbonEmissionRecordDto?.trading_period ?? 0,
  };

  return { isLoading, latestCarbonEmissionRecord, carbonEmissions: data && data.data ? data.data.items : [] };
};

// const mockRawData = () => ({
//   items: [
//     {
//       trading_date: '2021-08-10T12:00:00Z',
//       trading_period: 19,
//       timestamp: '2021-08-10T21:00:00Z',
//       nz_carbon_t: 458.38,
//       nz_carbon_gkwh: 139.55,
//       nz_carbon_gkwh_prev: 137.12,
//       nz_carbon_change_gkwh: 2.43,
//       nz_renewable: 79.94,
//       max_24hrs_gkwh: 146.09,
//       min_24hrs_gkwh: 85.92,
//       current_month_avg_gkwh: 119.2,
//       current_year_avg_gkwh: 169.88,
//       pct_current_year_gkwh: 49.92,
//     },
//     {
//       trading_date: '2021-08-10T12:00:00Z',
//       trading_period: 18,
//       timestamp: '2021-08-10T20:30:00Z',
//       nz_carbon_t: 461.82,
//       nz_carbon_gkwh: 137.12,
//       nz_carbon_gkwh_prev: 136.37,
//       nz_carbon_change_gkwh: 0.75,
//       nz_renewable: 80.24,
//       max_24hrs_gkwh: 146.09,
//       min_24hrs_gkwh: 85.92,
//       current_month_avg_gkwh: 119.2,
//       current_year_avg_gkwh: 169.88,
//       pct_current_year_gkwh: 49.05,
//     },
//     {
//       trading_date: '2021-08-10T12:00:00Z',
//       trading_period: 17,
//       timestamp: '2021-08-10T20:00:00Z',
//       nz_carbon_t: 464.05,
//       nz_carbon_gkwh: 136.37,
//       nz_carbon_gkwh_prev: 135.82,
//       nz_carbon_change_gkwh: 0.55,
//       nz_renewable: 80.4,
//       max_24hrs_gkwh: 146.09,
//       min_24hrs_gkwh: 85.92,
//       current_month_avg_gkwh: 119.2,
//       current_year_avg_gkwh: 169.88,
//       pct_current_year_gkwh: 48.78,
//     },
//   ],
//   hasMore: false,
//   limit: 100,
//   offset: 0,
//   count: 3,
//   links: [
//     { rel: 'self', href: 'https://uat-api.em6.co.nz/ords/em6/data_api/current_carbon_intensity/' },
//     {
//       rel: 'describedby',
//       href: 'https://uat-api.em6.co.nz/ords/em6/metadata-catalog/data_api/current_carbon_intensity/',
//     },
//     { rel: 'first', href: 'https://uat-api.em6.co.nz/ords/em6/data_api/current_carbon_intensity/' },
//   ],
// });
