import moment from 'moment';
import { INRSSResponse, IInterimPriceResponse, INewResponse } from 'tools/hooks/useRTPApiData';
import {
  getTpAsMillis,
  getCurrentTime,
  getCurrentTradingPeriod,
  getCurrentTradingPeriodStart,
  getPreviousTradingPeriod,
  getNextTradingPeriod,
  getPreviousTradingPeriodStart,
  getTradingPeriodForDate,
} from '../date';

export interface GraphableDataPoint {
  trading_period: number;
  NRSS_price: number | null;
  dispatch_price: number | null;
  current_tp_dispatch_rolling_average: number | null;
  last_tp_dispatch_rolling_average: number | null;
  interim_price?: number | null;
  timestamp_milliseconds: number;
}

const transformNrssPointToGraphableDataPoint = (nrssDataPoint: INRSSResponse): GraphableDataPoint => ({
  trading_period: nrssDataPoint.trading_period,
  NRSS_price: nrssDataPoint.price,
  dispatch_price: null,
  current_tp_dispatch_rolling_average: null,
  last_tp_dispatch_rolling_average: null,
  interim_price: null,
  timestamp_milliseconds: moment().add(1, 'second').valueOf(),
});

export const appendGraphableNrsDataPoints =
  (nrsDataPoints: INRSSResponse[]) =>
  (accGraphableData: GraphableDataPoint[]): GraphableDataPoint[] => {
    if (nrsDataPoints.length === 0) return accGraphableData;

    // Need to add data point 1 second from now so the line starts from the curren time
    // The orange nrss line needs to have a starting point too!
    const firstNrssPointAsGraphableDataPoint = transformNrssPointToGraphableDataPoint(nrsDataPoints[0]);

    // Iterate each NRSS data point and push it to the array
    // Even though we manually add the nrs value to each entry we also need to add these points, otherwise the graph would finish too early!
    const mappedData = nrsDataPoints.map(
      (nrssDataPoint: INRSSResponse): GraphableDataPoint =>
        // mockNRSSResponse.items.forEach((nrssDataPoint: NRSSResponse) => {
        ({
          trading_period: nrssDataPoint.trading_period,
          NRSS_price: nrssDataPoint.price,
          dispatch_price: null,
          current_tp_dispatch_rolling_average: null,
          last_tp_dispatch_rolling_average: null,
          interim_price: null,
          timestamp_milliseconds: getTpAsMillis(nrssDataPoint.trading_period + 1), // Adding one as the prediction represents what value the trading period is going to end not start
        }),
    );

    return accGraphableData.concat([firstNrssPointAsGraphableDataPoint, ...mappedData]);
  };

const getMostRecentDispatchPriceForTime = (time: number, graphableData: GraphableDataPoint[]) => {
  const dispatchDataForTime = graphableData.filter(
    (graphableDataPoint: GraphableDataPoint) =>
      graphableDataPoint.timestamp_milliseconds <= time && graphableDataPoint.dispatch_price,
  );

  if (dispatchDataForTime.length === 0) {
    return null;
  }

  const sortedDispatchDataForTime = dispatchDataForTime.sort(
    (a: GraphableDataPoint, b: GraphableDataPoint) => b.timestamp_milliseconds - a.timestamp_milliseconds,
  );

  return sortedDispatchDataForTime[0].dispatch_price;
};

export const appendGraphableDispatchPoints =
  (dispatchDataPoints: INewResponse[]) =>
  (accGraphableData: GraphableDataPoint[]): GraphableDataPoint[] => {
    const mappedData = dispatchDataPoints.map(
      (dispatchDataPoint: INewResponse): GraphableDataPoint => ({
        trading_period: getTradingPeriodForDate(new Date(dispatchDataPoint.runtime)),
        NRSS_price: null,
        dispatch_price: dispatchDataPoint.nodereadings[0].price, // TODO update this to include all nodes potentially.
        // dispatch_rolling_average: dispatchDataPoint.price_tp_average,
        current_tp_dispatch_rolling_average: null,
        last_tp_dispatch_rolling_average: null,
        interim_price: null,
        // timestamp_milliseconds: moment(dispatchDataPoint.timestamp).valueOf(),
        timestamp_milliseconds: moment(dispatchDataPoint.runtime).valueOf(),
      }),
    );

    // Lastly cap it of with an extra reading inserted into the exact current time.
    // This is so that the graph will show the current value of the dispatch price
    // and so the trendline can calculate up until the current time until a new value is inserted
    const mostRecentDispatchPrice = getMostRecentDispatchPriceForTime(getCurrentTime(), accGraphableData);
    const mostRecentDispatchPriceGraphableDataPoint: GraphableDataPoint = {
      trading_period: getCurrentTradingPeriod(),
      dispatch_price: mostRecentDispatchPrice,
      current_tp_dispatch_rolling_average: null,
      last_tp_dispatch_rolling_average: null,
      timestamp_milliseconds: getCurrentTime(),
      interim_price: null,
      NRSS_price: null,
    };

    return accGraphableData.concat([mostRecentDispatchPriceGraphableDataPoint, ...mappedData]);
  };

export const appendGraphableCurrentTradingPeriodAverages =
  (minutesSinceStartOfCurrentTradingPeriod: number) =>
  (accGraphableData: GraphableDataPoint[]): GraphableDataPoint[] => {
    let currentTPTotal = 0;
    return accGraphableData.concat(
      Array.from({ length: minutesSinceStartOfCurrentTradingPeriod }).map((_, i) => {
        const timestamp = moment(getCurrentTradingPeriodStart()).add(i, 'minutes').valueOf();
        const mostRecentDispatch = getMostRecentDispatchPriceForTime(timestamp, accGraphableData);
        currentTPTotal += mostRecentDispatch || 0;
        const average = currentTPTotal / (i + 1);
        return {
          timestamp_milliseconds: timestamp,
          trading_period: getCurrentTradingPeriod(),
          dispatch_price: mostRecentDispatch,
          current_tp_dispatch_rolling_average: average,
          last_tp_dispatch_rolling_average: null,
          interim_price: null,
          NRSS_price: null,
        };
      }),
    );
  };

export const filterGraphableDataToRelevantTradingPeriods = (
  graphableData: GraphableDataPoint[],
): GraphableDataPoint[] =>
  graphableData.filter(
    (graphableDataPoint: GraphableDataPoint) =>
      graphableDataPoint.trading_period >= getPreviousTradingPeriod() &&
      graphableDataPoint.trading_period <= getNextTradingPeriod(),
  );

export const sortGraphableData = (graphableData: GraphableDataPoint[]): GraphableDataPoint[] =>
  graphableData.sort(
    (a: GraphableDataPoint, b: GraphableDataPoint) => a.timestamp_milliseconds - b.timestamp_milliseconds,
  );

export const appendGraphablePreviousTradingPeriodAverages = (
  accGraphableData: GraphableDataPoint[],
): GraphableDataPoint[] => {
  let previousTPTotal = 0;
  const minutesSinceStartOfPreviousTradingPeriod = 29;

  return accGraphableData.concat(
    Array.from({ length: minutesSinceStartOfPreviousTradingPeriod }).map((_, i) => {
      const timestamp = moment(getPreviousTradingPeriodStart()).add(i, 'minutes').valueOf();
      const mostRecentDispatch = getMostRecentDispatchPriceForTime(timestamp, accGraphableData);
      previousTPTotal += mostRecentDispatch || 0;
      const average = previousTPTotal / (i + 1);
      return {
        timestamp_milliseconds: timestamp,
        trading_period: getCurrentTradingPeriod(),
        dispatch_price: mostRecentDispatch,
        current_tp_dispatch_rolling_average: null,
        last_tp_dispatch_rolling_average: average,
        interim_price: null,
        NRSS_price: null,
      };
    }),
  );
};

export const trimNrssData = (nrssData: INRSSResponse[]) =>
  nrssData.filter(
    (nrssDataPoint: INRSSResponse) =>
      nrssDataPoint.trading_period >= getCurrentTradingPeriod() &&
      nrssDataPoint.trading_period <= getNextTradingPeriod(),
  );

export const getNrssDataForTradingPeriod = (graphableData: GraphableDataPoint[], tradingPeriod: number) =>
  graphableData.find((data: GraphableDataPoint) => data.trading_period === tradingPeriod && data.NRSS_price);

export const extractLastTradingPeriodInterimPrice = (interimData: IInterimPriceResponse[]) => {
  const mostRecentInterimPrice = interimData.find(
    (interimPrice: IInterimPriceResponse) => interimPrice.trading_period === getPreviousTradingPeriod(),
  );
  if (!mostRecentInterimPrice) {
    return undefined;
  }
  return mostRecentInterimPrice.price;
};

export const appendGraphableTradingPeriodInterimPrice =
  (interimPrice?: number) =>
  (accGraphableData: GraphableDataPoint[]): GraphableDataPoint[] => {
    const mostRecentDispatchPrice = getMostRecentDispatchPriceForTime(getCurrentTradingPeriodStart(), accGraphableData);
    return accGraphableData.concat([
      {
        timestamp_milliseconds: getCurrentTradingPeriodStart(),
        trading_period: getPreviousTradingPeriod(),
        dispatch_price: mostRecentDispatchPrice,
        current_tp_dispatch_rolling_average: null,
        last_tp_dispatch_rolling_average: interimPrice ?? null,
        interim_price: interimPrice,
        NRSS_price: null,
      },
    ]);
  };

export const deduplicateGraphableDataByTimestamp = (graphableData: GraphableDataPoint[]): GraphableDataPoint[] => {
  const graphableDataPointsByTimestamp = new Map<number, GraphableDataPoint>();
  graphableData.forEach((dataPoint) => {
    graphableDataPointsByTimestamp.set(dataPoint.timestamp_milliseconds, dataPoint);
  });
  return Array.from(graphableDataPointsByTimestamp.values());
};

export const dataPointCountSinceStartOfCurrentTradingPeriod = () => {
  const minutesSinceStartOfCurrentTradingPeriod = moment().diff(moment(getCurrentTradingPeriodStart()), 'minutes');
  return minutesSinceStartOfCurrentTradingPeriod + 1;
};
