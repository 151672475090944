import { useQuery } from 'react-query';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  generationByLocationApiStringSelector,
  generationByLocationLoadingState,
  gwapByTypeGraphDataState,
  gwapTimeIntervalState,
} from 'tools/state/paidGwapState';
import { makeGetRequest } from 'tools/utilities/ajax';
import { GwapGenerationType, GwapTimeInterval } from 'tools/utilities/gwap';

export const generationByLocationApiState = atom({ key: 'generationByLocationApiState', default: {} });

export const useGenerationByLocation = () => {
  const setGenApiData = useSetRecoilState(generationByLocationApiState);
  const setGwapByTypeGraphData = useSetRecoilState<any[]>(gwapByTypeGraphDataState);
  const setLoadingState = useSetRecoilState(generationByLocationLoadingState);
  const timeInterval = useRecoilValue(gwapTimeIntervalState);
  const apiString = useRecoilValue(generationByLocationApiStringSelector);

  const { data, isFetching } = useQuery<any>(
    ['generationByLocation', apiString],
    () => makeGetRequest(apiString).then((resp) => resp.data),
    { staleTime: 1 },
  );

  // Need 3 states

  // No data was found - the data array is empty

  // Loading state - isFetching is true

  // Have data - the array has data

  const isFinishedLoadingWithoutResult = !isFetching && (!data || !data.length);
  setLoadingState({ isFetching, isFinishedLoadingWithoutResult });

  const dataForProcessing = isFetching ? [] : data;

  setGenApiData(dataForProcessing);
  const normalisedData = getFlatEntryForTimestampForTotals(dataForProcessing ?? [], timeInterval);
  setGwapByTypeGraphData(normalisedData);

  return {
    isFetching,
    generationByLocation: flattenLocationGenTypes(dataForProcessing ?? [], timeInterval),
  };
};

const getDataForInterval = (interval: GwapTimeInterval, data: any) => {
  switch (interval) {
    case GwapTimeInterval.DAILY:
      return data.daily_gwap;
    case GwapTimeInterval.MONTHLY:
      return data.monthly_gwap;
    default:
      return data.TP_gwap;
  }
};

const BLANK_TOTAL_RECORD_FOR_GRAPH = () => ({
  bat_mwh: 0,
  bat_wap: 0,
  cg_mwh: 0,
  cg_wap: 0,
  cog_mwh: 0,
  cog_wap: 0,
  gas_mwh: 0,
  gas_wap: 0,
  geo_mwh: 0,
  geo_wap: 0,
  hyd_mwh: 0,
  hyd_wap: 0,
  win_mwh: 0,
  win_wap: 0,
  liq_mwh: 0,
  liq_wap: 0,
  sol_mwh: 0,
  sol_wap: 0,
});
enum GenerationUnit {
  WAP = 'WAP',
  MW = 'MW',
  MWH = 'MWH',
}
interface IGenerationDto {
  value: number;
  generation_type: GwapGenerationType;
  unit: GenerationUnit;
}

const flattenTotals = (totals: IGenerationDto[], timestamp: string) =>
  totals.reduce((flattened: any, total) => {
    const key: any = `${total.generation_type}_${total.unit}`.toLowerCase();
    const newFlatened = { ...flattened, [key]: flattened[key] + total.value, timestamp };
    return newFlatened;
  }, BLANK_TOTAL_RECORD_FOR_GRAPH());

const getFlatEntryForTimestampForTotals = (rawData: any, interval: GwapTimeInterval) => {
  const rawRecords = getDataForInterval(interval, rawData);

  if (!rawRecords) {
    return [];
  }

  return rawRecords.map((rawRecord: { locations: any[]; totals: any[]; trading_date: string }) => {
    // Flatten all totals
    const totalsAsOneArray = rawRecord.totals.reduce((newArray, entry) => [...newArray, ...entry], []);
    const flatTotals = flattenTotals(totalsAsOneArray, rawRecord.trading_date);
    return flatTotals;
  });
};

const flattenLocationGenTypes = (rawData: any, interval: GwapTimeInterval) => {
  const rawRecords = getDataForInterval(interval, rawData);

  if (!rawRecords) {
    return [];
  }
  return rawRecords.map((rawRecord: { locations: any[]; totals: any[]; trading_date: string }) =>
    rawRecord.locations.reduce((flatLocations, rawLocationData) => {
      const locationData = rawLocationData[0];
      const locationName = locationData.location_name;
      const flat = locationData.generation_type.reduce((flatLevelTwo: any, rawGenTypeData: any) => {
        const reduced = rawGenTypeData.reduce((flattened: any, genTypeRecord: IGenerationDto) => {
          const key: any = `${locationName}_${genTypeRecord.generation_type}_${genTypeRecord.unit}`.toLowerCase();
          const newFlatened = { ...flattened, [key]: genTypeRecord.value, timestamp: rawRecord.trading_date };
          return newFlatened;
        }, {});
        return { ...reduced, ...flatLevelTwo };
      }, {});
      return { ...flatLocations, ...flat };
    }, {}),
  );
};
