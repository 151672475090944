import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import './CriteriaAccordian.scss';

import { useAccordian } from 'tools/hooks/useAccordian';
import { IconName, Icon } from 'views/components/Icon/Icon';
import { CriteriaOption, UpdateFiltersFunc } from '../SelectionCriteriaTypes';

interface IProps {
  initialOption?: CriteriaOption;
  onChange?: (option: CriteriaOption) => void;
  options: CriteriaOption[];
  updateCriteria: UpdateFiltersFunc;
  headerIcon?: IconName;
  resetToDefaultOnOptionsChange?: boolean;
}

export const CriteriaAccordian = ({
  initialOption,
  onChange,
  options,
  updateCriteria,
  headerIcon,
  resetToDefaultOnOptionsChange,
}: IProps) => {
  const [selection, setSelection] = useState<CriteriaOption>(initialOption || options[0]);
  const { height, isOpen, accordianBodyRef, toggleAccordian, setIsOpen, setHeight } = useAccordian();

  const className = classnames('CriteriaAccordian', { 'is-open': isOpen });
  useEffect(() => {
    if (isOpen) {
      setHeight(accordianBodyRef.current.scrollHeight);
    }
  }, [selection, isOpen, accordianBodyRef, setHeight]);

  useEffect(() => {
    if (resetToDefaultOnOptionsChange) {
      setSelection(options[0]);
    }
  }, [options, resetToDefaultOnOptionsChange, setSelection]);

  const resetState = () => {
    setSelection(options[0]);
  };

  const handleOptionChange = (option: CriteriaOption) => {
    setSelection(option);

    if (onChange) {
      onChange(option);

      if (!option.body) {
        // Close accordian on selection unless we have a custom body
        setIsOpen(false);
      }
    }
  };

  return (
    <div className={className}>
      <button onClick={() => toggleAccordian()} className="CriteriaAccordian-head" type="button">
        {headerIcon && <Icon name={headerIcon} size="sm" />}
        {selection.label}
        <Icon name="ic-chevron" size="sm" />
      </button>

      <div className="CriteriaAccordian-body" style={{ height, overflow: 'hidden' }} ref={accordianBodyRef}>
        {selection.body ? (
          selection.body(resetState, updateCriteria)
        ) : (
          <ul className="CriteriaAccordian-body-list">
            {options.map((option) => {
              const itemClassName = classnames('CriteriaAccordian-body-list-item', {
                'is-selected': selection.id === option.id,
              });

              return (
                <li className={itemClassName} key={option.label}>
                  <button type="button" onClick={() => handleOptionChange(option)}>
                    {option.label}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
