import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './Reports.scss';

import { getGenerationNodes, getLoadNodes, getReportSelectedNodes } from 'redux/modules/nodes/selectors';

import { ScadaSelectionCriteria } from 'views/components/ScadaSelectionCriteria/ScadaSelectionCriteria';
import { NodeSelection } from 'views/components/NodeSelection/NodeSelection';
import { IndeterminateCheckbox } from 'views/components/IndeterminateCheckbox/IndeterminateCheckbox';
import { actionSetSelectedNodes } from 'redux/modules/nodes/actions';
import { NodeType } from 'enums.d';
import { HVDC_NODES } from 'redux/modules/nodes/constants';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';
import { CustomFilterGroupModal } from 'views/components/CustomFilterGroupModal/CustomFilterGroupModal';

export const ScadaReports = () => {
  const selectedNodes = useSelector(getReportSelectedNodes);
  const [areAllNodesSelected, setAreAllNodesSelected] = useState<boolean>(false);
  const [areSomeNodesSelected, setAreSomeNodesSelected] = useState<boolean>(false);
  // For determining the nodes displayed in the table
  const [nodeType, setNodeType] = useState<NodeType>(NodeType.LOAD);

  const dispatch = useDispatch();

  const generationNodes = useSelector(getGenerationNodes);
  const loadNodes = useSelector(getLoadNodes);

  const [nodesForTable, setNodesForTable] = useState(loadNodes);

  useEffect(() => {
    if (nodeType === NodeType.GENERATION) {
      setNodesForTable(generationNodes);
    } else if (nodeType === NodeType.LOAD) {
      setNodesForTable(loadNodes);
    } else {
      setNodesForTable(loadNodes.filter((loadNode) => HVDC_NODES.includes(loadNode.nodeId)));
    }
  }, [nodeType, generationNodes, loadNodes, dispatch]);

  useEffect(() => {
    // Set the default nodes to be selected for each tab
    if (nodesForTable.length > 0 && nodeType !== NodeType.HVDC) {
      dispatch(actionSetSelectedNodes(nodesForTable.map((node) => node.nodeId)));
    } else {
      dispatch(actionSetSelectedNodes([]));
    }
  }, [dispatch, nodesForTable, nodeType]);

  useEffect(() => {
    setAreAllNodesSelected(selectedNodes.length === nodesForTable.length);
    setAreSomeNodesSelected(selectedNodes.length > 0);
  }, [selectedNodes, nodesForTable]);

  const selectNode = (nodeId: string) => {
    const newSelectedNodesState = [...selectedNodes, nodeId];
    dispatch(actionSetSelectedNodes(newSelectedNodesState));
  };

  const removeNode = (nodeId: string) => {
    dispatch(actionSetSelectedNodes(selectedNodes.filter((node) => node !== nodeId)));
  };

  const toggleAllNodes = () => {
    dispatch(actionSetSelectedNodes(areAllNodesSelected ? [] : nodesForTable.map((node) => node.nodeId)));
  };

  const renderSelectionCriteria = () => (
    <ScadaSelectionCriteria
      areAllNodesSelected={areAllNodesSelected}
      setNodeType={setNodeType}
      selectedNodes={selectedNodes}
      nodeType={nodeType}
    />
  );

  const isNodeTypeHdvc = nodeType === NodeType.HVDC;
  const nodeHeading = nodeType;

  return (
    <AccessRestrictionWrapper isInline accessScopeRequired={UserAccessScope.SCADA}>
      <div className="Container">
        <div className="Reports-content">
          {renderSelectionCriteria()}
          <NodeSelection
            hideSearch={isNodeTypeHdvc}
            hideSelectionColumn={isNodeTypeHdvc}
            nodeData={nodesForTable}
            nodeType={nodeHeading}
            onSelect={selectNode}
            onRemove={removeNode}
            selectedNodes={isNodeTypeHdvc ? [] : selectedNodes}
            renderHeader={() => (
              <div className="ReportHeaderFilterRow-single">
                <IndeterminateCheckbox
                  indeterminate={areSomeNodesSelected && !areAllNodesSelected}
                  isSelected={areAllNodesSelected}
                  onSelect={toggleAllNodes}
                  onRemove={toggleAllNodes}
                />
              </div>
            )}
          />
        </div>
      </div>
    </AccessRestrictionWrapper>
  );
};
