import React from 'react';
import './FormContainer.scss';
import classNames from 'classnames';

interface IFormContainer {
  children: React.ReactNode;
  className?: string;
}

export const FormContainer = ({ children, className }: IFormContainer) => {
  const FormContainerClasses = classNames('FormContainer', className);

  return <div className={FormContainerClasses}>{children}</div>;
};
