import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { ColorKeyDot } from './ColorKeyDot';
import styles from './LegendBox.module.scss';

export const LegendBox = ({ children }: { children?: React.ReactNode }) => (
  <div className={styles.wrapper}>{children}</div>
);

export const CTATextBox = ({ ctaText }: { ctaText: string }) => <div className={styles.cta_text_box}>{ctaText}</div>;

export const LegendBoxRow = ({
  children,
  colour,
  label,
  value,
  prefix = '',
  suffix = '',
  isPlaceholder = false,
}: {
  children?: React.ReactNode;
  colour: string;
  label: string;
  value: string;
  prefix?: string;
  suffix?: string;
  isPlaceholder?: boolean;
}) => (
  <div className={styles.row}>
    <div className={styles.labelArea}>
      <ColorKeyDot color={colour} />
      <div>{!isPlaceholder && label}</div>
    </div>
    <div>
      {!isPlaceholder && (
        <>
          {prefix}
          {roundToDpWithCommas(parseFloat(value), 2)} {suffix}
        </>
      )}
    </div>
  </div>
);
