import { UserAccessScope } from 'constants/sessionConstants';
import { useFeature } from 'flagged';
import { Redirect } from 'react-router';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { GwapPieGraph } from 'views/components/GwapPieGraph/GwapPieGraph';
import { GwapSummaryTable } from 'views/components/GwapSummaryTable/GwapSummaryTable';
import { GwapTripleGraph } from 'views/components/GwapTripleGraph/GwapTripleGraph';
import { PaidMobileGwapFilter } from 'views/components/MobileGwapFilter/PaidMobileGwapFilter';

import styles from './GWAP.module.scss';

export const GWAP = () => {
  const gwapFeatureEnabled = useFeature(FeatureFlags.GWAP_DASHBOARD);

  if (!gwapFeatureEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <AccessRestrictionWrapper accessScopeRequired={UserAccessScope.GWAP}>
      <div className={styles.wrapper}>
        <PaidMobileGwapFilter />
        <GwapTripleGraph />
        <GwapPieGraph />
        <GwapSummaryTable />
      </div>
    </AccessRestrictionWrapper>
  );
};
