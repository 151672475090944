import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './Reports.scss';

import { getPriceNodes, getReportSelectedNodes } from 'redux/modules/nodes/selectors';

import { PriceSelectionCriteria } from 'views/components/PriceSelectionCriteria/PriceSelectionCriteria';
import { NodeSelection } from 'views/components/NodeSelection/NodeSelection';
import { IndeterminateCheckbox } from 'views/components/IndeterminateCheckbox/IndeterminateCheckbox';
import { actionSetSelectedNodes } from 'redux/modules/nodes/actions';
import { DEFAULT_PRICE_NODES, FTR_NODES } from 'redux/modules/nodes/constants';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';
import { CustomFilterGroupModal } from 'views/components/CustomFilterGroupModal/CustomFilterGroupModal';
import { actionTrackFTRFilterSelection } from 'redux/modules/analytics/actions';

export const PriceReports = () => {
  const selectedNodes = useSelector(getReportSelectedNodes);
  const [areAllNodesSelected, setAreAllNodesSelected] = useState<boolean>(false);
  const [areSomeNodesSelected, setAreSomeNodesSelected] = useState<boolean>(false);
  const [isFirstLoad, setFirstLoad] = useState(true);
  const dispatch = useDispatch();
  const priceNodes = useSelector(getPriceNodes);

  useEffect(() => {
    if (priceNodes.length > 0 && isFirstLoad) {
      dispatch(actionSetSelectedNodes(DEFAULT_PRICE_NODES));
      setFirstLoad(false);
    }
  }, [dispatch, isFirstLoad, priceNodes]);

  useEffect(() => {
    setAreAllNodesSelected(selectedNodes.length === priceNodes.length);
    setAreSomeNodesSelected(selectedNodes.length > 0);
  }, [selectedNodes, priceNodes]);

  const selectNode = (nodeId: string) => {
    const newSelectedNodesState = [...selectedNodes, nodeId];
    dispatch(actionSetSelectedNodes(newSelectedNodesState));
  };

  const removeNode = (nodeId: string) => {
    dispatch(actionSetSelectedNodes(selectedNodes.filter((node) => node !== nodeId)));
  };

  const toggleAllNodes = () => {
    dispatch(actionSetSelectedNodes(areAllNodesSelected ? [] : priceNodes.map((node) => node.nodeId)));
  };

  const selectAllFTRNodes = () => {
    dispatch(
      actionSetSelectedNodes(priceNodes.filter((node) => FTR_NODES.includes(node.nodeId)).map((node) => node.nodeId)),
    );

    dispatch(actionTrackFTRFilterSelection());
  };

  const renderSelectionCriteria = () => (
    <PriceSelectionCriteria areAllNodesSelected={areAllNodesSelected} selectedNodes={selectedNodes} />
  );

  const isScadaActive = false;
  const isNodeTypeHdvc = false;
  const showNodeTable = true;
  const nodeHeading = 'price';

  return (
    <AccessRestrictionWrapper isInline accessScopeRequired={UserAccessScope.PRICE}>
      <div className="Container">
        <div className="Reports-content">
          {renderSelectionCriteria()}
          {showNodeTable && (
            <NodeSelection
              hideSearch={isNodeTypeHdvc}
              hideSelectionColumn={isNodeTypeHdvc && isScadaActive}
              nodeData={priceNodes}
              nodeType={nodeHeading}
              onSelect={selectNode}
              onRemove={removeNode}
              selectedNodes={isNodeTypeHdvc ? [] : selectedNodes}
              renderHeader={() => (
                <div className="ReportHeaderFilterRow-double">
                  <IndeterminateCheckbox
                    isSelected={areAllNodesSelected}
                    onSelect={toggleAllNodes}
                    onRemove={toggleAllNodes}
                    indeterminate={areSomeNodesSelected && !areAllNodesSelected}
                  />
                  <CustomFilterGroupModal
                    options={[
                      { label: 'All', onSelect: toggleAllNodes },
                      { label: 'FTR nodes', onSelect: selectAllFTRNodes },
                    ]}
                  />
                </div>
              )}
            />
          )}
        </div>
      </div>
    </AccessRestrictionWrapper>
  );
};
