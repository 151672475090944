import { FormattedMessage } from 'react-intl';
import { useCarbonEmissions } from 'tools/hooks/useCarbonEmissions';
import { formatEndOfTradingPeriod } from 'tools/utilities/charts';
import moment from 'moment';
import styles from './CarbonEmissionsTile.module.scss';
import { Dial } from '../Dial/Dial';
import { ColorKeyDot } from '../Legend/ColorKeyDot';

const GREEN_RANGE = 80;
const YELLOW_RANGE = 160;

const getColorForRange = (value: number) => {
  if (value <= GREEN_RANGE) {
    return '#CBE612';
  }
  if (value <= YELLOW_RANGE) {
    return '#F6C580';
  }
  return '#E780AC';
};

export const CarbonEmissionsTile = () => {
  const { latestCarbonEmissionRecord, isLoading } = useCarbonEmissions();

  const {
    currentMonthAvgGkwh,
    nzCarbonChangeGkwh,
    currentYearAvgGkwh,
    max24hrsGkwh,
    min24hrsGkwh,
    nzCarbonGkwh,
    nzCarbonT,
    timestamp,
  } = latestCarbonEmissionRecord;

  if (isLoading) {
    return <span> Loading...</span>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        <div style={{ gridArea: 'graph' }}>
          <Dial
            halfHourTotal={nzCarbonT}
            changeSinceLastReading={nzCarbonChangeGkwh}
            currentEmissions={nzCarbonGkwh}
            maxEmissions24HR={max24hrsGkwh}
            minEmissions24HR={min24hrsGkwh}
          />
        </div>
        <div style={{ gridArea: 'year' }} className={styles.tile}>
          <h3 className={styles.headingArea}>Average this year</h3>
          <div className={styles.averageArea}>
            <ColorKeyDot size={11} color={getColorForRange(currentYearAvgGkwh)} />
            <span>
              <span className={styles.averageReading}>{Math.round(currentYearAvgGkwh)}&nbsp;</span>
              <span className={styles.averageUnits}>g/kWh</span>
            </span>
          </div>
        </div>

        <div style={{ gridArea: 'month' }} className={styles.tile}>
          <h3 className={styles.headingArea}>Average this {moment().format('MMMM')} </h3>
          <div className={styles.averageArea}>
            <ColorKeyDot size={11} color={getColorForRange(currentMonthAvgGkwh)} />

            <span>
              <span className={styles.averageReading}>{Math.round(currentMonthAvgGkwh)}&nbsp;</span>
              <span className={styles.averageUnits}>g/kWh</span>
            </span>
          </div>
        </div>
        {/* <div style={{ gridArea: 'halfHour' }} className={styles.tile}>
          <div className={styles.halfHour}>
            <div className={styles.totalHeading}>
              Total C0<sub>2</sub> in last half hour
            </div>
            <div className={styles.halfHourReading}>
              <span className={styles.totalReading}>{parseFloat(`${nzCarbonT}`).toFixed(1)} </span>
              <span className={styles.totalUnits}> tonnes</span>
            </div>
          </div>
        </div> */}
      </div>
      <p className="Dashboard-updated" style={{ marginTop: 0 }}>
        <FormattedMessage
          id="LAST_UPDATED"
          values={{ date: timestamp ? formatEndOfTradingPeriod(new Date(timestamp)) : 'Date not found' }}
        />
      </p>
    </div>
  );
};
