import { ILegendRange } from 'types.d';
import styles from './FreeLegend.module.scss';

interface IProps {
  ranges: ILegendRange[];
}

export const Legend = ({ ranges }: IProps) => (
  // const currentNodeType = useSelector(getCurrentNodeType);
  // const labelID = currentNodeType === NodeType.PRICE ? 'PRICE_MAP_LEGEND' : 'LOAD_MAP_LEGEND';

  <div className={styles.wrapper}>
    <div className={styles.list}>
      {ranges.map(({ colour, label }) => (
        <div key={label} className={styles.listItem}>
          <span style={{ backgroundColor: colour }} />
          {label}
        </div>
      ))}
    </div>
  </div>
);
