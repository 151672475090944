import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { useState } from 'react';
import { Icon } from '../Icon/Icon';

import styles from './CustomFilterGroupModal.module.scss';

interface ICustomFilterGroupModalProps {
  options: { label: string; onSelect: any }[];
}

export const CustomFilterGroupModal = (props: ICustomFilterGroupModalProps) => {
  const { options } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover.Root onOpenChange={setIsOpen}>
      <Popover.Trigger className={classNames(styles.trigger, { [styles['trigger-open']]: isOpen })}>
        <Icon name="ic-chevron-blue" size="sm" />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="end" sideOffset={8} className={styles.wrapper}>
          {options.map((option) => (
            <button
              onClick={option.onSelect}
              type="button"
              className={styles.option}
              key={`buttontogglefor${option.label}`}
            >
              {option.label}
            </button>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
