import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DateFilterOption, ArcFlowRunType, ReportType } from 'enums.d';
import { IArcFlowsReportCriteria } from 'types.d';

import { CriteriaOption, UpdateFiltersFunc } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';
import { actionGetArcFlowsReport, TYPES } from 'redux/modules/nodes/actions';

import { SelectionCriteria } from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { CustomDateSelection } from 'views/components/SelectionCriteria/CustomDateSelection/CustomDateSelection';
import { actionTrackReportDownload } from 'redux/modules/analytics/actions';

import { getReportContent } from 'redux/modules/nodes/selectors';
import { formatArcFlowsReportFilename } from 'tools/utilities/report';
import { generateDownload } from 'tools/utilities/file';
import { SelectionDownload } from '../SelectionCriteria/SelectionDownload/SelectionDownload';

const DATE_OPTIONS: CriteriaOption[] = [
  { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
  {
    id: DateFilterOption.CUSTOM,
    label: 'Custom',
    body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
      <CustomDateSelection updateFilters={updateFilters} resetState={resetState} showSingleDate />
    ),
  },
];

const RUN_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'Latest', id: ArcFlowRunType.LATEST },
  { label: 'Provisional', id: ArcFlowRunType.PROVISIONAL },
  { label: 'Interim', id: ArcFlowRunType.INTERIM },
  { label: 'Final', id: ArcFlowRunType.FINAL },
];

export const ArcFlowsSelectionCriteria = () => {
  const [criteria, setCriteria] = useState<IArcFlowsReportCriteria>({
    dateOption: DateFilterOption.YESTERDAY,
    fromTradingDate: '',
    arcFlowsRunType: ArcFlowRunType.LATEST,
  });

  const dispatch = useDispatch();
  const reportContent = useSelector(getReportContent);

  useEffect(() => {
    if (reportContent) {
      const filename = formatArcFlowsReportFilename(criteria);
      generateDownload(reportContent, `${filename}.csv`, 'text/csv');
      dispatch({ type: TYPES.CLEAN_UP_REPORT });
    }
  }, [reportContent, criteria, dispatch]);

  const updateCriteria = (key: string, filterOptionId: string) => {
    setCriteria({ ...criteria, [key]: filterOptionId });
  };

  const handleReportDownload = () => {
    dispatch(actionGetArcFlowsReport(criteria));
    dispatch(
      actionTrackReportDownload({
        reportType: ReportType.ARC_FLOWS,
        criteria,
      }),
    );
  };

  return (
    <SelectionCriteria>
      <CriteriaAccordian
        initialOption={DATE_OPTIONS.find(({ id }) => id === criteria.dateOption)}
        options={DATE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('dateOption', option.id)}
        updateCriteria={updateCriteria}
        headerIcon="ic-calendar"
      />
      <CriteriaAccordian
        initialOption={RUN_TYPE_OPTIONS.find(({ id }) => id === criteria.arcFlowsRunType)}
        options={RUN_TYPE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('arcFlowsRunType', option.id)}
        updateCriteria={updateCriteria}
      />
      <SelectionDownload
        isVisibleOnMobile
        forceCorrectDateForDownload={criteria.dateOption === DateFilterOption.CUSTOM}
        needsSelectedNodesForDownload={false}
        downloadAction={handleReportDownload}
      />
    </SelectionCriteria>
  );
};
