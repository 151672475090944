import classNames from 'classnames';
import { useFeature } from 'flagged';

import './Icon.scss';

import { ICONS } from 'redux/modules/app/constants';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { SyntheticEvent } from 'react';

const ICON_SIZES = {
  xs: 'Icon--xs',
  sm: 'Icon--sm',
  md: 'Icon--md',
  lg: 'Icon--lg',
};

type IconSize = keyof typeof ICON_SIZES;

export type IconName = keyof typeof ICONS;

interface Props {
  name: IconName;
  size?: IconSize;
  className?: string;
  altText?: string;
  flip?: boolean;
}

export const Icon = ({ name, className, size, altText, flip }: Props) => {
  const useImagesForIcons = useFeature(FeatureFlags.IS_FOYER);
  const icon = ICONS[name];

  if (!icon) {
    // eslint-disable-next-line
    console.warn(`No icon found for ${name}`);
    return null;
  }

  /**
   * The free dashboard is displayed at Transpower offices on TV's at their entrance hall.
   * These TV's can't render SVG. Hence, what's below.
   * For certain icons, there's a png duplicate and according to a feature flag (only enabled
   * for the Foyer builds), the component renders an `img` or a `span`.
   * **NOTE**: This component assumes png and svg to have the same filename. If there's not
   * an image with that icon name, then the element is hidden.
   */
  if (useImagesForIcons) {
    const hideImage = (image: SyntheticEvent<HTMLImageElement>) => {
      image.currentTarget.style.display = 'none';
    };

    return (
      <img
        className={classNames('Icon', size && ICON_SIZES[size], className, {
          'Icon--flip': flip,
        })}
        src={`assets/icons/${name}.png`}
        alt={altText}
        onError={hideImage}
      />
    );
  }

  return (
    <span className={classNames('Icon', className, size && ICON_SIZES[size], { 'Icon--flip': flip })} data-icon={name}>
      {icon}
    </span>
  );
};
