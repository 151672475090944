import { useState, useEffect, useRef } from 'react';

interface IAccordian {
  height: number;
  isOpen: boolean;
  accordianBodyRef: any;
  toggleAccordian: () => void;
  setIsOpen: (isOpen: boolean) => void;
  setHeight: (height: number) => void;
}

export const useAccordian = (): IAccordian => {
  const [height, setHeight] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const accordianBodyRef = useRef<any>(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(accordianBodyRef?.current?.scrollHeight || 0);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const toggleAccordian = () => setIsOpen(!isOpen);

  return {
    height,
    isOpen,
    accordianBodyRef,
    toggleAccordian,
    setIsOpen,
    setHeight,
  };
};
