/* eslint-disable quotes */
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { RenewableEnergyPie } from 'views/components/RenewableEnergyPie/RenewableEnergyPie';
import { FreeGWAPGraph } from 'views/components/FreeGWAPGraph/FreeGWAPGraph';
import { FreePriceGraph } from 'views/components/FreePriceGraph/FreePriceGraph';
import { FreeMap } from 'views/components/FreeMap/FreeMap';
import { CarbonEmissionsTile } from 'views/components/CarbonEmissionsTile/CarbonEmissionsTile';
import { CTATile } from 'views/components/CTATile/CTATile';
import { InfoTip } from 'views/components/InfoTip/InfoTip';
import { FreeDashComponentId, TOOLTIP_FOR_FREEDASH_COMPONENTS } from 'constants/FreedashTooltips';
import { isLoggedIn as isLoggedInSelector } from 'redux/modules/session/selectors';

import './FreeDashboard.scss';
import { useFeature } from 'flagged';
import { Redirect } from 'react-router';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { useSelector } from 'react-redux';
import { isDuringOutage } from 'tools/utilities/isDuringOutagePeriod';
import { ROUTE_PATHS } from '../../../constants/routes';

export const FreeDashboard = () => {
  const freeFeatureEnabled = useFeature(FeatureFlags.FREE_DASHBOARD);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const showOutageText = isDuringOutage();

  if (!freeFeatureEnabled) {
    if (isLoggedIn) {
      return <Redirect to={ROUTE_PATHS.DASHBOARD} />;
    }
    return <Redirect to={ROUTE_PATHS.LOGIN} />;
  }

  return (
    <RouteTransition>
      <div className="FreeDashboard">
        <div className="FreeDashboard-body">
          <div className="FreeDashboard-map">
            <div className="FreeDashboard-content">
              <div className="FreeDashboard-content-head">
                <h3>
                  NZ Regional Price Overview ($/MWh)
                  {/* <FormattedMessage id={mapTitle} /> */}
                </h3>
              </div>
              <FreeMap />
            </div>
          </div>
          <div className="FreeDashboard-topPanelContainer">
            <div className="FreeDashboard-content">
              <div className="FreeDashboard-content-head">
                <h3>GWAP by Generation Type ($/MWh)</h3>
                <InfoTip content={TOOLTIP_FOR_FREEDASH_COMPONENTS[FreeDashComponentId.GWAP_GRAPH]} />
              </div>
              <div className="FreeDashboard-graph">
                <FreeGWAPGraph />
              </div>
            </div>
          </div>
          <div className="FreeDashboard-topPanelContainer2">
            <div className="FreeDashboard-content">
              <div className="FreeDashboard-content-head">
                <h3>Price last 24 hours ($/MWh)</h3>
              </div>
              <div className="FreeDashboard-graph">
                <FreePriceGraph />
              </div>
            </div>
          </div>
          <div className="FreeDashboard-bottomPanelContainer">
            <div className="FreeDashboard-content stretch-md">
              <div className="FreeDashboard-content-head">
                <h3>Carbon emissions</h3>
                <InfoTip content={TOOLTIP_FOR_FREEDASH_COMPONENTS[FreeDashComponentId.CARBON_EMISSIONS]} />
              </div>
              <div className="FreeDashboard-graph">
                <CarbonEmissionsTile />
              </div>
            </div>
            <div className="FreeDashboard-content">
              <div className="FreeDashboard-content-head">
                <h3>Renewable energy</h3>
                <InfoTip content={TOOLTIP_FOR_FREEDASH_COMPONENTS[FreeDashComponentId.RENEWABLE_PIE]} />
              </div>

              <div className="FreeDashboard-graph">
                <RenewableEnergyPie />
              </div>
            </div>
            <div className="FreeDashboard-content">
              <div className="FreeDashboard-content-head">
                {showOutageText ? <h3>Planned outage 16 August</h3> : <h3>Want more insights?</h3>}
              </div>
              <div className="FreeDashboard-graph">
                <CTATile />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};
