import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import './CustomNodes.scss';

import { useToggleBodyClass } from 'tools/hooks/useToggleClass';

import { TYPES, actionGetPriceNodes } from 'redux/modules/nodes/actions';
import { getCurrentNodes, getPriceNodes } from 'redux/modules/nodes/selectors';
import { IReducerState } from 'redux/main-reducer';
import { DEFAULT_PRICE_NODES } from 'redux/modules/nodes/constants';

import { NodeSelection } from 'views/components/NodeSelection/NodeSelection';
import { Button } from 'views/components/Button/Button';
import { Icon } from 'views/components/Icon/Icon';
import { Modal } from 'views/components/Modal/Modal';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { actionTrackCustomNodeSelection } from 'redux/modules/analytics/actions';

const getNodesSelectedString = (numberOfNodesSelected: number): string => {
  if (numberOfNodesSelected === 1) {
    return '1 node selected';
  }

  return `${numberOfNodesSelected} nodes selected`;
};

const MAX_NODES = 20;

export const CustomNodeSelection = () => {
  const currentNodes = useSelector(getCurrentNodes);
  const priceNodes = useSelector(getPriceNodes);
  const isViewportMobile = useSelector((state: IReducerState) => state.browser.is.mobile);

  const [selectedNodes, setSelectedNodes] = useState<string[]>(currentNodes);
  const [isNodeSelectionExpanded, setIsNodeSelectionExpanded] = useState<boolean>(false);
  const [isResetNodesModalShowing, setIsResetNodesModalShowing] = useState<boolean>(false);
  const [isClearNodesModalShowing, setIsClearNodesModalShowing] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useToggleBodyClass(isNodeSelectionExpanded, ['noScroll']);

  useEffect(() => {
    dispatch(actionGetPriceNodes());
  }, [dispatch]);

  const hasSelectedMaxAmountOfNodes = selectedNodes.length === MAX_NODES;

  const selectNode = (nodeId: string) => {
    if (hasSelectedMaxAmountOfNodes) {
      return;
    }

    const newState = [...selectedNodes, nodeId];
    setSelectedNodes(newState);
  };

  const removeNode = (nodeId: string) => {
    setSelectedNodes(selectedNodes.filter((node) => node !== nodeId));
  };

  const clearSelectedNodes = () => {
    setSelectedNodes([]);
    setIsClearNodesModalShowing(false);
  };

  const resetToPresetNodes = () => {
    setSelectedNodes(DEFAULT_PRICE_NODES);
    setIsResetNodesModalShowing(false);
  };

  const updateMap = () => {
    dispatch({
      type: TYPES.UPDATE_CURRENT_NODES,
      payload: {
        nodes: selectedNodes,
      },
    });
    dispatch(actionTrackCustomNodeSelection(selectedNodes));
    history.push('/');
  };

  const selectedNodesClassName = classnames('CustomNodes-selected', {
    'is-expanded': isNodeSelectionExpanded,
  });

  const subtitleMessageId = hasSelectedMaxAmountOfNodes ? 'YOU_HAVE_SELECTED_THE_MAXIMUM' : 'SELECT_UP_TO_20_NODES';

  return (
    <div className="Container">
      <RouteTransition>
        <div className="CustomNodes">
          <Modal
            isSecondaryFirst={!isViewportMobile}
            isOpen={isResetNodesModalShowing}
            primaryButtonConfig={{ label: 'Reset nodes', onClick: resetToPresetNodes }}
            secondaryButtonConfig={{ label: 'Cancel', onClick: () => setIsResetNodesModalShowing(false) }}
          >
            <h2>
              <FormattedMessage id="RESET_NODES_MODAL_HEADING" />
            </h2>
            <p>
              <FormattedMessage id="ARE_YOU_SURE_YOU_WANT_TO_RESET" />
            </p>
          </Modal>
          <Modal
            isSecondaryFirst={!isViewportMobile}
            isOpen={isClearNodesModalShowing}
            primaryButtonConfig={{ label: 'Clear nodes', onClick: clearSelectedNodes }}
            secondaryButtonConfig={{ label: 'Cancel', onClick: () => setIsClearNodesModalShowing(false) }}
          >
            <h2>
              <FormattedMessage id="CLEAR_NODES_MODAL_HEADING" />
            </h2>
            <p>
              <FormattedMessage id="ARE_YOU_SURE_YOU_WANT_TO_CLEAR" />
            </p>
          </Modal>
          <div className="CustomNodes-header">
            <h2 className="CustomNodes-header-title">
              <FormattedMessage id="CUSTOMISE_PRICE_NODES" />
            </h2>
            <p className="CustomNodes-header-byline">
              {hasSelectedMaxAmountOfNodes && (
                <Icon className="CustomNodes-header-bylineIcon" name="ic-error-warn" size="sm" />
              )}
              <FormattedMessage id={subtitleMessageId} />
            </p>
          </div>
          <div className="CustomNodes-body">
            <div className={selectedNodesClassName}>
              <div className="CustomNodes-selected-wrapper">
                <div className="CustomNodes-body-header">
                  <p className="CustomNodes-selected-amount">
                    <span>{getNodesSelectedString(selectedNodes.length)}</span>
                    {isViewportMobile && (
                      <button type="button" onClick={() => setIsNodeSelectionExpanded(!isNodeSelectionExpanded)}>
                        <Icon name={isNodeSelectionExpanded ? 'ic-collapse' : 'ic-expand'} size="sm" />
                        <span className="ScreenReadersOnly">{isNodeSelectionExpanded ? 'Minimise' : 'Expand'}</span>
                      </button>
                    )}
                  </p>
                  {selectedNodes.length > 0 && (
                    <p className="CustomNodes-clear CustomNodes-clear--desktop">
                      <span>Clear all</span>
                      <button onClick={() => setIsClearNodesModalShowing(true)} type="button">
                        <Icon name="ic-remove-all" size="md" />
                        <span className="ScreenReadersOnly">Clear all selected nodes</span>
                      </button>
                    </p>
                  )}
                </div>

                <div className="CustomNodes-selected-body">
                  {selectedNodes.length > 0 ? (
                    <ul className="CustomNodes-selected-body-list">
                      <li className="CustomNodes-clear CustomNodes-clear--mobile">
                        <p className="CustomNodes-selected-description">
                          <span>Clear all selected nodes</span>
                          <button onClick={() => setIsClearNodesModalShowing(true)} type="button">
                            <Icon name="ic-remove-all" size="md" />
                            <span className="ScreenReadersOnly">Clear all selected nodes</span>
                          </button>
                        </p>
                      </li>
                      {selectedNodes.map((node) => (
                        <li key={node} className="CustomNodes-selected-body-listItem ">
                          <span>{node}</span>
                          <button
                            onClick={() => {
                              removeNode(node);
                            }}
                            type="button"
                          >
                            <Icon name="ic-remove" size="md" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="CustomNodes-resetNodes">
                      <p>Select the nodes you want to see on your map or reset to the preset nodes</p>
                      <button
                        className="CustomNodes-resetNodes-button Button Button--secondary--dark"
                        type="button"
                        onClick={() => setIsResetNodesModalShowing(true)}
                      >
                        Reset nodes
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="CustomNodes-CTAs">
                <Button
                  className="Button Button--secondary--dark"
                  label="Cancel"
                  onClick={() => {
                    history.push('/');
                  }}
                />
                <Button isDisabled={selectedNodes.length === 0} className="" label="Update map" onClick={updateMap} />
              </div>
            </div>
            <NodeSelection
              nodeType="price"
              nodeData={priceNodes}
              hasCustomIcon
              selectedNodes={selectedNodes}
              onSelect={selectNode}
              onRemove={removeNode}
              renderHeader={() => <div />}
            />
          </div>
        </div>
      </RouteTransition>
    </div>
  );
};
