import Dialog from '@reach/dialog';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isViewportAboveMobile as isViewportAboveMobileSelector } from 'redux/modules/app/selectors';
import { useFreeGwap } from 'tools/hooks/useFreeGwap';
import { FreeGwapFilters } from '../FreeGWAPGraph/FreeGwapFilters';
import { Icon } from '../Icon/Icon';

import styles from './MobileGwapFilter.module.scss';

export const FreeMobileGwapFilter = () => {
  const { genTypeAndLocationSummary } = useFreeGwap();
  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);

  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  if (isViewportAboveMobile) {
    return null;
  }

  /**
   * Dialogue needs to show drop downs filters. Exact same functionality except adapted to stack on top of eachother.
   */

  return (
    <>
      <Dialog
        style={{
          width: '100vw',
          backgroundColor: '#0C3345',
          padding: '24px 32px 4px 32px',
          marginTop: 0,
          borderBottom: '1px solid #00A9A5',
        }}
        isOpen={showDialog}
        onDismiss={close}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h2 style={{ margin: 0, marginBottom: '14px' }}>Filter graph</h2>

          <button type="button" className={`close-button ${styles.modalButton}`} onClick={close}>
            <span aria-hidden>
              {/* <img src="/assets/icons/ic-filter.svg" style={{ width: '16px' }} /> */}
              <Icon name="ic-close" size="sm" />
            </span>
          </button>
        </div>
        <FreeGwapFilters />
        <p className={styles.description}>Graphs will auto update based on your selection</p>
      </Dialog>
      <button type="button" onClick={open} className={styles.wrapper}>
        <div className={styles.iconArea}>
          <img alt="GWAP Filters Icon" src="/assets/icons/ic-filter.svg" style={{ width: '16px' }} />
        </div>
        <span className={styles.summaryArea}>{genTypeAndLocationSummary}</span>
      </button>
    </>
  );
};
