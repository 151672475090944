import { max, min, scaleLinear } from 'd3';
import moment from 'moment';
import { getCurrentTradingPeriod, getNextTradingPeriod, getPreviousTradingPeriodStart } from 'tools/utilities/date';
import {
  deduplicateGraphableDataByTimestamp,
  extractLastTradingPeriodInterimPrice,
  getNrssDataForTradingPeriod,
  GraphableDataPoint,
  appendGraphableCurrentTradingPeriodAverages,
  appendGraphableDispatchPoints,
  appendGraphableNrsDataPoints,
  appendGraphablePreviousTradingPeriodAverages,
  appendGraphableTradingPeriodInterimPrice,
  trimNrssData,
  filterGraphableDataToRelevantTradingPeriods,
  sortGraphableData,
  dataPointCountSinceStartOfCurrentTradingPeriod,
} from 'tools/utilities/RTP/rtpUtils';
import lodash from 'lodash';
import { useRTPApiData } from './useRTPApiData';

interface IUseRtp {
  isFetching: boolean;
  graphableData: GraphableDataPoint[];
  xTicks: number[];
  nrssDataPointForEndOfCurrentTradingPeriod?: GraphableDataPoint;
  nrssDataPointForEndOfNextTradingPeriod?: GraphableDataPoint;
  yTicks: number[];
  minYValue: number;
  maxYValue: number;
  lastUpdated: number;
  priceAtEndOfCurrentTradingPeriod?: number | null;
  priceAtEndOfNextTradingPeriod?: number | null;
  lastTradingPeriodInterimPrice?: number | null;
}

export const useRTP: () => IUseRtp = () => {
  const { dispatchData, nrssData, interimData, isFetching, updated } = useRTPApiData();
  // If these are not defined in here then they will go stale unfortunately
  const xTicks = Array.from({ length: 7 }).map((_, i) =>
    moment(getPreviousTradingPeriodStart())
      .add(15 * i, 'minutes')
      .valueOf(),
  );

  // We only want NRSS items going forward so trim it on its own
  // Only going forward 3 trading periods
  const nrssDataTrimmed = trimNrssData(nrssData?.items || []);

  const graphableData: GraphableDataPoint[] = lodash.flow(
    appendGraphableNrsDataPoints(nrssDataTrimmed),
    appendGraphableDispatchPoints(dispatchData?.items ?? []),
    appendGraphableCurrentTradingPeriodAverages(dataPointCountSinceStartOfCurrentTradingPeriod()),
    appendGraphablePreviousTradingPeriodAverages,
    appendGraphableTradingPeriodInterimPrice(extractLastTradingPeriodInterimPrice(interimData?.items ?? [])),
    deduplicateGraphableDataByTimestamp,
    filterGraphableDataToRelevantTradingPeriods,
    sortGraphableData,
  )([]);

  const nrssDataPointForEndOfCurrentTradingPeriod = getNrssDataForTradingPeriod(
    graphableData,
    getCurrentTradingPeriod(),
  );
  const nrssDataPointForEndOfNextTradingPeriod = getNrssDataForTradingPeriod(graphableData, getNextTradingPeriod());

  const Y_TRUNCATION_BUFFER = 2;
  const Y_DOMAIN_OFFSET = 2;

  const minYValue =
    min(graphableData, (d: GraphableDataPoint) =>
      Math.min(
        d.dispatch_price || Number.POSITIVE_INFINITY,
        d.current_tp_dispatch_rolling_average || Number.POSITIVE_INFINITY,
        d.last_tp_dispatch_rolling_average || Number.POSITIVE_INFINITY,
        d.NRSS_price || Number.POSITIVE_INFINITY,
        Number.POSITIVE_INFINITY,
      ),
    ) || 0;
  const maxYValue =
    max(graphableData, (d: GraphableDataPoint) =>
      Math.max(
        d.dispatch_price || Number.NEGATIVE_INFINITY,
        d.current_tp_dispatch_rolling_average || Number.NEGATIVE_INFINITY,
        d.last_tp_dispatch_rolling_average || Number.NEGATIVE_INFINITY,
        d.NRSS_price || Number.NEGATIVE_INFINITY,
        Number.NEGATIVE_INFINITY,
      ),
    ) || 0;

  const yBaseline = minYValue ? minYValue - Y_TRUNCATION_BUFFER : 0;

  const yScale = scaleLinear()
    .domain([yBaseline, maxYValue + Y_DOMAIN_OFFSET])
    .range([400 * 0.75, 0]);

  const range = maxYValue - minYValue;
  // We don't want to show less ticks than the total range
  const yTickCount = Math.min(range, 7);
  const yTicks = yScale.ticks(yTickCount).map((d, i) => d);

  return {
    graphableData,
    xTicks,
    isFetching,
    nrssDataPointForEndOfCurrentTradingPeriod,
    nrssDataPointForEndOfNextTradingPeriod,
    yTicks,
    maxYValue,
    minYValue,
    lastUpdated: updated,
    priceAtEndOfCurrentTradingPeriod: getNrssDataForTradingPeriod(graphableData, getCurrentTradingPeriod())?.NRSS_price,
    priceAtEndOfNextTradingPeriod: getNrssDataForTradingPeriod(graphableData, getNextTradingPeriod())?.NRSS_price,
    lastTradingPeriodInterimPrice: extractLastTradingPeriodInterimPrice(interimData?.items ?? []),
  };
};
