import moment from 'moment';
import { useQuery } from 'react-query';
import { makeUnauthorisedGetRequest } from 'tools/utilities/ajax';
import { FIVE_MINUTES } from './useFreeGwap';

const NUMBER_OF_TRADING_PERIODS = 48;

export const useFreePriceData = () => {
  const { data, isLoading } = useQuery<any>(
    ['FreePriceData'],
    () =>
      makeUnauthorisedGetRequest('price/24hrs/OTA2201,WKM2201,HAY2201,KIK2201,ISL2201,BEN2201').then(
        (resp) => resp.data,
      ),
    {
      staleTime: 1,
      refetchInterval: FIVE_MINUTES,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );

  const normalisedData = data?.items ? normaliseData(data.items) : [];
  return {
    normalisedData,
    isLoading,
  };
};

const normaliseData = (rawData: any[]) => {
  const normalisedData = [];

  for (let tradingPeriod = 0; tradingPeriod <= NUMBER_OF_TRADING_PERIODS; tradingPeriod += 1) {
    const filtered = rawData.filter((d) => d.trading_period === tradingPeriod);
    if (filtered.length > 0) {
      // const batch: any = { name: `TP ${tradingPeriod}`, timestamp: filtered[0].timestamp };
      // const batch: any = { name: moment(new Date(filtered[0].timestamp)).format('DD/MM'), tp: tradingPeriod };
      const batch: any = {
        name: moment(new Date(filtered[0].timestamp)).format('h A'),
        timestamp: filtered[0].timestamp,
        tp: tradingPeriod,
      };
      filtered.forEach((f) => {
        batch[f.node_id] = f.price;
      });
      normalisedData.push(batch);
    }
  }

  const sorted = normalisedData.sort((a, b) => moment(new Date(a.timestamp)).diff(moment(new Date(b.timestamp))));

  return sorted;
};

// const mockData = () => [
//   {
//     name: 'TP 1',
//     BEN2201: 124.05,
//     HAY2201: 127.69,
//     ISL2201: 133.61,
//     KIK2201: 137.49,
//     OTA2201: 136,
//     WKM2201: 128.97,
//   },
//   {
//     name: 'TP 2',
//     BEN2201: 135.41,
//     HAY2201: 136.57,
//     ISL2201: 145.8,
//     KIK2201: 148.42,
//     OTA2201: 143.26,
//     WKM2201: 136.16,
//   },
//   {
//     name: 'TP 3',
//     BEN2201: 138.89,
//     HAY2201: 141.2,
//     ISL2201: 147.43,
//     KIK2201: 149.09,
//     OTA2201: 148.77,
//     WKM2201: 140.98,
//   },
//   {
//     name: 'TP 4',
//     BEN2201: 138.87,
//     HAY2201: 143.43,
//     ISL2201: 147.43,
//     KIK2201: 149.09,
//     OTA2201: 151.51,
//     WKM2201: 143.79,
//   },
//   {
//     name: 'TP 5',
//     BEN2201: 138.58,
//     HAY2201: 143.13,
//     ISL2201: 147.16,
//     KIK2201: 148.82,
//     OTA2201: 150.72,
//     WKM2201: 143.21,
//   },
//   {
//     name: 'TP 6',
//     BEN2201: 138.13,
//     HAY2201: 142.66,
//     ISL2201: 146.67,
//     KIK2201: 148.33,
//     OTA2201: 149.49,
//     WKM2201: 142.62,
//   },
//   {
//     name: 'TP 7',
//     BEN2201: 138.94,
//     HAY2201: 143.5,
//     ISL2201: 147.39,
//     KIK2201: 149.06,
//     OTA2201: 151.21,
//     WKM2201: 143.71,
//   },
//   {
//     name: 'TP 8',
//     BEN2201: 139,
//     HAY2201: 143.57,
//     ISL2201: 147.42,
//     KIK2201: 149.09,
//     OTA2201: 151.45,
//     WKM2201: 144.15,
//   },
//   {
//     name: 'TP 9',
//     BEN2201: 139,
//     HAY2201: 143.66,
//     ISL2201: 147.43,
//     KIK2201: 149.1,
//     OTA2201: 152.02,
//     WKM2201: 144.34,
//   },
//   {
//     name: 'TP 10',
//     BEN2201: 138.99,
//     HAY2201: 143.98,
//     ISL2201: 147.41,
//     KIK2201: 149.07,
//     OTA2201: 152.47,
//     WKM2201: 144.75,
//   },
//   {
//     name: 'TP 11',
//     BEN2201: 139.19,
//     HAY2201: 144.34,
//     ISL2201: 147.51,
//     KIK2201: 150.27,
//     OTA2201: 155.9,
//     WKM2201: 147.24,
//   },
//   {
//     name: 'TP 12',
//     BEN2201: 138.77,
//     HAY2201: 145.04,
//     ISL2201: 148.75,
//     KIK2201: 152.79,
//     OTA2201: 159.41,
//     WKM2201: 150.5,
//   },
//   {
//     name: 'TP 13',
//     BEN2201: 138.91,
//     HAY2201: 145.31,
//     ISL2201: 148.28,
//     KIK2201: 154.61,
//     OTA2201: 158.67,
//     WKM2201: 150.92,
//   },
//   {
//     name: 'TP 14',
//     BEN2201: 160.33,
//     HAY2201: 170.47,
//     ISL2201: 173.66,
//     KIK2201: 182.13,
//     OTA2201: 191.98,
//     WKM2201: 180.51,
//   },
//   {
//     name: 'TP 15',
//     BEN2201: 232.15,
//     HAY2201: 247.86,
//     ISL2201: 252.46,
//     KIK2201: 265.01,
//     OTA2201: 276.93,
//     WKM2201: 258.42,
//   },
//   {
//     name: 'TP 16',
//     BEN2201: 519.26,
//     HAY2201: 554.86,
//     ISL2201: 569.58,
//     KIK2201: 597.83,
//     OTA2201: 603.56,
//     WKM2201: 558.97,
//   },
//   {
//     name: 'TP 17',
//     BEN2201: 518.2,
//     HAY2201: 553.72,
//     ISL2201: 568.41,
//     KIK2201: 596.69,
//     OTA2201: 600.9,
//     WKM2201: 558.14,
//   },
//   {
//     name: 'TP 18',
//     BEN2201: 325.26,
//     HAY2201: 347.56,
//     ISL2201: 356.78,
//     KIK2201: 374.48,
//     OTA2201: 381.82,
//     WKM2201: 354.94,
//   },
//   {
//     name: 'TP 19',
//     BEN2201: 190.34,
//     HAY2201: 203.39,
//     ISL2201: 208.79,
//     KIK2201: 219.14,
//     OTA2201: 224.95,
//     WKM2201: 210.39,
//   },
//   {
//     name: 'TP 20',
//     BEN2201: 232.67,
//     HAY2201: 253.21,
//     ISL2201: 255.17,
//     KIK2201: 267.83,
//     OTA2201: 282.71,
//     WKM2201: 262.94,
//   },
//   {
//     name: 'TP 21',
//     BEN2201: 142.5,
//     HAY2201: 153.34,
//     ISL2201: 154.78,
//     KIK2201: 162.33,
//     OTA2201: 170.74,
//     WKM2201: 160.01,
//   },
//   {
//     name: 'TP 22',
//     BEN2201: 160.81,
//     HAY2201: 174.82,
//     ISL2201: 174.63,
//     KIK2201: 183.14,
//     OTA2201: 195.19,
//     WKM2201: 182.82,
//   },
//   {
//     name: 'TP 23',
//     BEN2201: 139.33,
//     HAY2201: 151.47,
//     ISL2201: 150.16,
//     KIK2201: 157.48,
//     OTA2201: 169.71,
//     WKM2201: 159.65,
//   },
//   {
//     name: 'TP 24',
//     BEN2201: 127.63,
//     HAY2201: 138.74,
//     ISL2201: 137.31,
//     KIK2201: 144.07,
//     OTA2201: 155.46,
//     WKM2201: 146.24,
//   },
//   {
//     name: 'TP 25',
//     BEN2201: 103.16,
//     HAY2201: 112.14,
//     ISL2201: 109.84,
//     KIK2201: 115.25,
//     OTA2201: 126.76,
//     WKM2201: 118.87,
//   },
//   {
//     name: 'TP 26',
//     BEN2201: 139,
//     HAY2201: 148.53,
//     ISL2201: 148.16,
//     KIK2201: 154.67,
//     OTA2201: 165.3,
//     WKM2201: 155.01,
//   },
//   {
//     name: 'TP 27',
//     BEN2201: 126.73,
//     HAY2201: 135.42,
//     ISL2201: 135.08,
//     KIK2201: 141.02,
//     OTA2201: 149.58,
//     WKM2201: 141.79,
//   },
//   {
//     name: 'TP 28',
//     BEN2201: 90.64,
//     HAY2201: 97.01,
//     ISL2201: 95.73,
//     KIK2201: 99.94,
//     OTA2201: 107.37,
//     WKM2201: 101.73,
//   },
//   {
//     name: 'TP 29',
//     BEN2201: 92.83,
//     HAY2201: 99.35,
//     ISL2201: 98.05,
//     KIK2201: 100.91,
//     OTA2201: 113.02,
//     WKM2201: 107.23,
//   },
//   {
//     name: 'TP 30',
//     BEN2201: 86.92,
//     HAY2201: 93.04,
//     ISL2201: 91.71,
//     KIK2201: 94.39,
//     OTA2201: 106.44,
//     WKM2201: 100.94,
//   },
//   {
//     name: 'TP 31',
//     BEN2201: 84.18,
//     HAY2201: 90.11,
//     ISL2201: 88.82,
//     KIK2201: 91.42,
//     OTA2201: 103.3,
//     WKM2201: 98.02,
//   },
//   {
//     name: 'TP 32',
//     BEN2201: 92.83,
//     HAY2201: 99.2,
//     ISL2201: 98.05,
//     KIK2201: 100.91,
//     OTA2201: 111.84,
//     WKM2201: 106.16,
//   },
//   {
//     name: 'TP 33',
//     BEN2201: 107.87,
//     HAY2201: 114.26,
//     ISL2201: 113.92,
//     KIK2201: 118.06,
//     OTA2201: 126.63,
//     WKM2201: 119.99,
//   },
//   {
//     name: 'TP 34',
//     BEN2201: 105.59,
//     HAY2201: 111.85,
//     ISL2201: 112.57,
//     KIK2201: 117.51,
//     OTA2201: 124.31,
//     WKM2201: 116.44,
//   },
//   {
//     name: 'TP 35',
//     BEN2201: 96.41,
//     HAY2201: 102.12,
//     ISL2201: 102.78,
//     KIK2201: 107.79,
//     OTA2201: 113.94,
//     WKM2201: 106.27,
//   },
//   {
//     name: 'TP 36',
//     BEN2201: 139,
//     HAY2201: 147.24,
//     ISL2201: 150.98,
//     KIK2201: 158.34,
//     OTA2201: 164.35,
//     WKM2201: 153.31,
//   },
//   {
//     name: 'TP 37',
//     BEN2201: 139.41,
//     HAY2201: 148.97,
//     ISL2201: 151.45,
//     KIK2201: 158.98,
//     OTA2201: 167.09,
//     WKM2201: 155.61,
//   },
//   {
//     name: 'TP 38',
//     BEN2201: 139.34,
//     HAY2201: 148.89,
//     ISL2201: 151.35,
//     KIK2201: 158.88,
//     OTA2201: 166.63,
//     WKM2201: 155.12,
//   },
//   {
//     name: 'TP 39',
//     BEN2201: 139.35,
//     HAY2201: 147.62,
//     ISL2201: 151.37,
//     KIK2201: 158.89,
//     OTA2201: 165.17,
//     WKM2201: 153.77,
//   },
//   {
//     name: 'TP 40',
//     BEN2201: 139.35,
//     HAY2201: 147.62,
//     ISL2201: 151.37,
//     KIK2201: 158.89,
//     OTA2201: 165.26,
//     WKM2201: 153.84,
//   },
//   {
//     name: 'TP 41',
//     BEN2201: 139.34,
//     HAY2201: 148.89,
//     ISL2201: 150.77,
//     KIK2201: 158.12,
//     OTA2201: 166.56,
//     WKM2201: 154.94,
//   },
//   {
//     name: 'TP 42',
//     BEN2201: 139.47,
//     HAY2201: 149.09,
//     ISL2201: 150.36,
//     KIK2201: 157.69,
//     OTA2201: 167.26,
//     WKM2201: 155.62,
//   },
//   {
//     name: 'TP 43',
//     BEN2201: 139.47,
//     HAY2201: 149.03,
//     ISL2201: 150.34,
//     KIK2201: 156.87,
//     OTA2201: 166.58,
//     WKM2201: 155.02,
//   },
//   {
//     name: 'TP 44',
//     BEN2201: 139.54,
//     HAY2201: 149.15,
//     ISL2201: 148.76,
//     KIK2201: 155.22,
//     OTA2201: 165.33,
//     WKM2201: 156.51,
//   },
//   {
//     name: 'TP 45',
//     BEN2201: 139.4,
//     HAY2201: 147.66,
//     ISL2201: 147.26,
//     KIK2201: 151.45,
//     OTA2201: 164.7,
//     WKM2201: 156.06,
//   },
//   {
//     name: 'TP 46',
//     BEN2201: 138.96,
//     HAY2201: 143.53,
//     ISL2201: 147.18,
//     KIK2201: 149.77,
//     OTA2201: 154.76,
//     WKM2201: 147.81,
//   },
//   {
//     name: 'TP 47',
//     BEN2201: 115.14,
//     HAY2201: 118.93,
//     ISL2201: 122.14,
//     KIK2201: 125.67,
//     OTA2201: 126.91,
//     WKM2201: 121.94,
//   },
//   {
//     name: 'TP 48',
//     BEN2201: 92.49,
//     HAY2201: 95.52,
//     ISL2201: 98.1,
//     KIK2201: 100.94,
//     OTA2201: 101.55,
//     WKM2201: 97.62,
//   },
// ];
//
