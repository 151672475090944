import * as Tooltip from '@radix-ui/react-tooltip';
import { Icon } from '../Icon/Icon';
import { ColorKeyDot } from '../Legend/ColorKeyDot';
import styles from './Dial.module.scss';

interface IDialProps {
  currentEmissions: number;
  maxEmissions24HR: number;
  minEmissions24HR: number;
  changeSinceLastReading: number;
  halfHourTotal: number;
}

// How far we can rotate the needle to either end of the arc before it starts getting cropped.
const MIN_ROTATION = -75;
const MAX_ROTATION = 75;

export const Dial = (props: IDialProps) => {
  const { currentEmissions, maxEmissions24HR, minEmissions24HR, changeSinceLastReading } = props;

  const arrowDirection = changeSinceLastReading > 0 ? 'up' : 'down';

  // value can be between 0 and 180.
  // When val is 0 rotate dial -90deg
  // When val is 180 rotate dial 90deg
  const emissionsPerDegree = 76 / 60; // Degrees in each third
  // Make sure we don't go over 180 or below 0
  const dialRotation = Math.min(Math.max(currentEmissions / emissionsPerDegree - 90, MIN_ROTATION), MAX_ROTATION);
  const upperDotsRotation = Math.min(Math.max(maxEmissions24HR / emissionsPerDegree - 90, MIN_ROTATION), MAX_ROTATION);
  const lowerDotsRotation = Math.min(Math.max(minEmissions24HR / emissionsPerDegree - 90, MIN_ROTATION), MAX_ROTATION);

  return (
    <div className={styles.wrapper}>
      <div className={styles.crop}>
        <div className={styles.center}>
          <div className={styles.circle} />
          <div className={styles.cutout} />
          <div style={{ rotate: `${dialRotation}deg` }} className={styles.indicatorWrapper}>
            {/* <HalfHourTotalTooltip total={halfHourTotal} /> */}
            <div className={styles.indicatorLine} />
          </div>
          <div style={{ rotate: `${upperDotsRotation}deg` }} className={styles.dottedLineContainer}>
            <div className={styles.dottedLine}>
              <DottedTooltip align="start" topText="Max (24hrs)" reading={Math.round(maxEmissions24HR)} />
            </div>
          </div>

          <div style={{ rotate: `${lowerDotsRotation}deg` }} className={styles.dottedLineContainer}>
            <div className={styles.dottedLine}>
              <DottedTooltip align="end" topText="Min (24hrs)" reading={Math.round(minEmissions24HR)} />
            </div>
          </div>
        </div>
        <div className={styles.middleWriting}>
          <div className={styles.topText}>
            Current CO<sub>2</sub>
          </div>
          <div className={styles.bottomText}>
            <div style={{ rotate: arrowDirection === 'up' ? '90deg' : '-90deg', marginRight: '5px' }}>
              <Icon size="md" name="ic-triangle" />
            </div>
            <span>
              <span className={styles.currentReading}>{Math.round(currentEmissions)}</span>{' '}
              <span className={styles.currentUnits}>g/kWh</span>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.legendWrapper}>
        {/* #cbe612 #f6c580  #e780ac  */}
        <span>
          <ColorKeyDot color="#cbe612" /> LOW
        </span>
        <span>
          <ColorKeyDot color="#f6c580" /> MODERATE
        </span>
        <span>
          <ColorKeyDot color="#e780ac" /> HIGH
        </span>
      </div>
    </div>
  );
};

interface IDottedTooltipProps {
  topText: string;
  reading: number;
  align: 'start' | 'center' | 'end';
}
const DottedTooltip = (props: IDottedTooltipProps) => {
  const { topText, reading, align } = props;
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild>
          <button type="button" aria-label="view reading" className={styles.hollowCircle} />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content align={align} side="bottom">
            <Tooltip.Arrow fill="white" />

            <div className={styles.tooltipContent}>
              <div>{topText}</div>
              <div className={styles.tooltipReading}>
                {reading} <span className={styles.tooltipUnits}>g/kWh</span>
              </div>
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
