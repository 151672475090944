// import { createSelector } from 'reselect';

// export const getComponentProps = (state, props) => props;
// export const getIdFromPath = createSelector(
//   [getComponentProps],
//   (props) => {
//     if (!props || !props.match) {
//       return null;
//     }

//     return props.match.params.id;
//   },
// );

export const isLoading = (state) => state.app.isLoading;
export const isMenuOpen = (state) => state.app.isMenuOpen;

export const isViewportAboveMobile = (state) => state.browser.greaterThan.mobile;
export const isViewportAboveTablet = (state) => state.browser.greaterThan.tablet;
export const isViewportAboveDesktop = (state) => state.browser.greaterThan.desktop;
export const isViewportAboveWidescreen = (state) => state.browser.greaterThan.widescreen;
export const isViewportAboveUltraWidescreen = (state) => state.browser.greaterThan.ultraWidescreen;
export const isViewportBelowTablet = (state) => state.browser.lessThan.tablet;
export const getCurrentMedia = (state) => state.browser.mediaType;

// export const getAddressSuggestions = (state) => state.app.addressSuggestions;
// export const getFilteredAddressSuggestions = createSelector(
//   [getAddressSuggestions],
//   (addressSuggestions) => {
//     if (!addressSuggestions || addressSuggestions.length === 0) {
//       return [];
//     }

//     return addressSuggestions.filter((address) => {
//       const fullAddress = address.fullAddress.toLowerCase();
//       const isPOBoxOrPrivateBag = /po box|private bag/g.test(fullAddress);

//       return !isPOBoxOrPrivateBag;
//     });
//   },
// );
