import { useSelector } from 'react-redux';
import {
  isViewportAboveTablet,
  isViewportAboveDesktop,
  isViewportAboveWidescreen,
  isViewportAboveUltraWidescreen,
} from 'redux/modules/app/selectors';

export interface IGwapDimensions {
  width: number | string;
  height: number;
  margins: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
}

export const usePaidGwapGraphDimensions = (): IGwapDimensions => {
  const isViewAboveTablet = useSelector(isViewportAboveTablet);
  const isViewAboveDesktop = useSelector(isViewportAboveDesktop);
  const isViewAboveWidescreen = useSelector(isViewportAboveWidescreen);
  const isViewAboveUltraWidescreen = useSelector(isViewportAboveUltraWidescreen);

  if (isViewAboveUltraWidescreen) {
    return {
      width: 840,
      height: 246,
      margins: {
        top: 0,
        right: 24,
        left: 24,
        bottom: 32,
      },
    };
  }
  if (isViewAboveWidescreen) {
    return {
      width: 600,
      height: 246,
      margins: {
        top: 0,
        right: 24,
        left: 24,
        bottom: 32,
      },
    };
  }
  if (isViewAboveDesktop) {
    return {
      width: 600,
      height: 246,
      margins: {
        top: 0,
        right: 24,
        left: 24,
        bottom: 32,
      },
    };
  }
  if (isViewAboveTablet) {
    return {
      width: 500,
      height: 246,
      margins: {
        top: 0,
        right: 24,
        left: 24,
        bottom: 32,
      },
    };
  }

  // if (isViewAboveMobile) {
  //   return {
  //     width: 500,
  //     height: 246,
  //     margins: {
  //       top: 0,
  //       right: 24,
  //       left: 24,
  //       bottom: 32,
  //     },
  //   };
  // }

  return {
    width: '100%',
    height: 190,
    margins: {
      top: 0,
      right: 24,
      left: 0,
      bottom: 30,
    },
  };
};
