import { useQuery } from 'react-query';
import { makeUnauthorisedGetRequest } from 'tools/utilities/ajax';
import { formatEndOfTradingPeriod } from 'tools/utilities/charts';
import { FIVE_MINUTES } from './useFreeGwap';

export interface IPriceRegion {
  timestamp: string;
  tradingPeriod: number;
  gridZoneId: number;
  gridZoneName: string;
  price: number;
  latitude: number;
  longitude: number;
}

interface IFreeRegionDto {
  timestamp: string;
  trading_period: number;
  grid_zone_id: number;
  grid_zone_name: string;
  price: number;
}
export const useFreeRegions = () => {
  const { data } = useQuery<any>(
    ['RegionPriceData'],
    () => makeUnauthorisedGetRequest('region/price/1,2,3,4,5,6,7,8,9,10,11,12,13,14').then((resp) => resp.data),
    {
      staleTime: 1,
      refetchInterval: FIVE_MINUTES,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );

  const regionsFromApi: IFreeRegionDto[] = data?.items ?? [];
  const priceRegions = applyLocationToRegions(regionsFromApi);
  const formattedDate = data?.items[0]?.timestamp
    ? formatEndOfTradingPeriod(new Date(data?.items[0]?.timestamp))
    : 'Date not found';

  return { priceRegions, formattedDate };
};

const applyLocationToRegions = (regions: IFreeRegionDto[]) =>
  regions.map((region) => ({
    timestamp: region.timestamp,
    tradingPeriod: region.trading_period,
    gridZoneId: region.grid_zone_id,
    gridZoneName: region.grid_zone_name,
    price: region.price,
    ...mappings[region.grid_zone_id],
  }));

const mappings: GridLocationMapping = {
  1: { latitude: -35.3962, longitude: 173.8106 },
  2: { latitude: -36.9046, longitude: 174.8169 },
  3: { latitude: -37.4159, longitude: 175.7749 },
  4: { latitude: -38.4191, longitude: 175.8004 },
  5: { latitude: -38.8054, longitude: 177.1514 },
  6: { latitude: -39.3344, longitude: 174.3205 },
  7: { latitude: -40.2813, longitude: 175.6405 },
  8: { latitude: -41.1182, longitude: 175.4373 },
  9: { latitude: -41.6734, longitude: 172.8739 },
  10: { latitude: -43.5364, longitude: 172.5117 },
  11: { latitude: -44.2515, longitude: 170.8004 },
  12: { latitude: -42.8317, longitude: 171.5648 },
  13: { latitude: -44.5664, longitude: 170.1946 },
  14: { latitude: -46.1151, longitude: 168.9117 },
};

type GridLocationMapping = {
  [key: number]: { longitude: number; latitude: number };
};

// const mockRawData = () => ({
//   items: [
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 2,
//       grid_zone_name: 'Auckland',
//       price: 199.8,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 7,
//       grid_zone_name: 'Bunnythorpe',
//       price: 181.7,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 11,
//       grid_zone_name: 'Canterbury',
//       price: 170.73,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 10,
//       grid_zone_name: 'Christchurch',
//       price: 186.8,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 4,
//       grid_zone_name: 'Edgecumbe',
//       price: 183.99,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 3,
//       grid_zone_name: 'Hamilton',
//       price: 192.23,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 5,
//       grid_zone_name: 'Hawkes Bay',
//       price: 187.97,
//     },
//     { timestamp: '2021-08-10T21:00:00Z', trading_period: 19, grid_zone_id: 9, grid_zone_name: 'Nelson', price: 193.38 },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 1,
//       grid_zone_name: 'Northland',
//       price: 203.58,
//     },
//     { timestamp: '2021-08-10T21:00:00Z', trading_period: 19, grid_zone_id: 13, grid_zone_name: 'Otago', price: 167.47 },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 14,
//       grid_zone_name: 'Southland',
//       price: 158.35,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 6,
//       grid_zone_name: 'Taranaki',
//       price: 174.2,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 8,
//       grid_zone_name: 'Wellington',
//       price: 180.41,
//     },
//     {
//       timestamp: '2021-08-10T21:00:00Z',
//       trading_period: 19,
//       grid_zone_id: 12,
//       grid_zone_name: 'West Coast',
//       price: 178.01,
//     },
//   ],
//   hasMore: false,
//   limit: 0,
//   offset: 0,
//   count: 14,
//   links: [
//     { rel: 'self', href: 'https://uat-api.em6.co.nz/ords/em6/data_api/region/price/' },
//     { rel: 'describedby', href: 'https://uat-api.em6.co.nz/ords/em6/metadata-catalog/data_api/region/price/' },
//   ],
// });
