import React from 'react';
import { useSelector } from 'react-redux';

import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { useTable } from 'react-table';
import { FormattedMessage, useIntl } from 'react-intl';

import { PRICE_RANGES } from 'redux/modules/nodes/constants';
import { ILegendRange } from 'types.d';

interface INodeTableProps {
  data: any;
  columns: any;
  titleId?: string;
  titleValues?: any;
}

export const getNodeStyle = (value: number, ranges: ILegendRange[] = PRICE_RANGES) => {
  const range = ranges.find(({ max }) => value <= max);

  return {
    backgroundColor: range?.colour || 'transparent',
    color: range?.textColour || '#ffffff',
  };
};

export const NodeTable = ({ data, columns, titleId, titleValues }: INodeTableProps) => {
  const isViewportAboveTablet = useSelector(isViewportAboveTabletSelector);
  const intl = useIntl();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <div className="NodeTable">
      {isViewportAboveTablet && (
        <p className="NodeTable-lastUpdated">
          <FormattedMessage id={titleId || 'PRICE_LAST_15_MINUTES'} values={titleValues || {}} />
        </p>
      )}
      <div className="NodeTable-wrapper">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    const isNodeCell = i === 0;
                    let nodeStyle = {};
                    if (isNodeCell) {
                      nodeStyle = getNodeStyle(row.cells[3].value);
                    }

                    return (
                      <td style={nodeStyle} {...cell.getCellProps()}>
                        {`${
                          isNodeCell
                            ? cell.value
                            : intl.formatNumber(cell.value, {
                                currency: 'NZD',
                                currencyDisplay: 'symbol',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: 'currency',
                              })
                        }`}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
