import { ListboxInput, ListboxButton, ListboxPopover, ListboxList, ListboxOption } from '@reach/listbox';
import classnames from 'classnames';
import { Icon } from 'views/components/Icon/Icon';

import styles from './Shared.module.scss';

interface IProps {
  options: string[];
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  className?: string;
}

export const NodeSelection = (props: IProps) => {
  const { options, selectedOption, setSelectedOption, className } = props;
  const labelId = `node-select`;
  return (
    <ListboxInput
      className={classnames(styles.dropdown, className)}
      aria-labelledby={labelId}
      value={selectedOption}
      onChange={(v) => setSelectedOption(v)}
    >
      <ListboxButton
        className={styles.dropdownButton}
        arrow={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon size="sm" name="ic-chevron" />
          </div>
        }
      />
      <ListboxPopover className={styles.dropdownPopover}>
        <ListboxList>
          {options.map((option) => (
            <ListboxOption key={option} value={option} className={styles.dropdownOption}>
              {option}
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  );
};
