import dayjs from 'dayjs';

import { makeGetRequest } from 'tools/utilities/ajax';
import {
  IScadaReportCriteria,
  IPriceReportCriteria,
  IReservesReportCriteria,
  IArcFlowsReportCriteria,
  IRCPDCriteria,
} from 'types.d';
import {
  NodeType,
  PriceRunType,
  PriceOption,
  DateFilterOption,
  ArcFlowRunType,
  ReservesIsland,
  ReservesPowerType,
  ReservesReservesType,
  HVDCGranularity,
  RegionLoadOption,
  ReservesSubtype,
} from 'enums.d';
import { IGetResidualsPayload } from 'redux/modules/nodes/actions'
import { AxiosResponse } from 'axios';

const API_TRUE = 'TRUE';

const PriceRunTypeForParam: { [key in PriceRunType]?: string } = {
  [PriceRunType.FINAL]: 'F',
  [PriceRunType.INTERIM]: 'T',
  [PriceRunType.PROVISIONAL]: 'V',
  [PriceRunType.PRICE_RESPONSIVE]: 'PR',
  [PriceRunType.NON_RESPONSE]: 'NR',
};

export const getIntervals = (ids: string[]): Promise<AxiosResponse<unknown>> => {
  const idsUrlFragment = ids && ids.length ? `${ids.join(',')}` : '';

  return makeGetRequest(`price/${idsUrlFragment}`);
};

export const getNodesDetails = (ids: string[], nodeType?: NodeType) => {
  const idsUrlFragment = ids && ids.length ? ids.join(',') : '';

  // If id's are specified for the request, then the node type param shouldn't be sent.
  // The first condition is to avoid our mock server to return every node it has.
  if (idsUrlFragment) {
    return makeGetRequest(`nodes/${idsUrlFragment}`);
  }

  let nodeTypeParam = '';
  switch (nodeType) {
    case NodeType.LOAD:
      nodeTypeParam = `load_node=${API_TRUE}`;
      break;
    case NodeType.GENERATION:
      nodeTypeParam = `generation_node=${API_TRUE}`;
      break;
    case NodeType.PRICE:
      nodeTypeParam = `pricing_node=${API_TRUE}`;
      break;
    default:
      nodeTypeParam = '';
  }

  return makeGetRequest(`nodes/${idsUrlFragment}?${nodeTypeParam}`);
};

export const getFromAndToTradingDates = (dateOption: DateFilterOption) => {
  const DATE_FORMAT = 'DD/MM/YYYY';
  const today = dayjs(new Date());
  const todayFormatted = today.format(DATE_FORMAT);

  const previousMonth = today.subtract(1, 'month');

  switch (dateOption) {
    case DateFilterOption.ALL:
      return {
        fromTradingDate: dayjs('01/06/2019', 'DD/MM/YYYY').format(DATE_FORMAT),
        toTradingDate: todayFormatted,
      };
    case DateFilterOption.YESTERDAY:
      return {
        fromTradingDate: today.subtract(1, 'day').format(DATE_FORMAT),
        toTradingDate: today.subtract(1, 'day').format(DATE_FORMAT),
      };
    case DateFilterOption.PREVIOUS_3_DAYS:
      return {
        fromTradingDate: today.subtract(3, 'day').format(DATE_FORMAT),
        toTradingDate: todayFormatted,
      };
    case DateFilterOption.PREVIOUS_7_DAYS:
      return {
        fromTradingDate: today.subtract(7, 'day').format(DATE_FORMAT),
        toTradingDate: todayFormatted,
      };
    case DateFilterOption.MONTH_TO_DATE:
      return {
        fromTradingDate: today.set('date', 1).format(DATE_FORMAT), // 1st of this month
        toTradingDate: todayFormatted, // currentDate
      };
    case DateFilterOption.PREVIOUS_MONTH:
      return {
        fromTradingDate: previousMonth.set('date', 1).format(DATE_FORMAT), // 1st of previous month
        toTradingDate: previousMonth.set('date', previousMonth.daysInMonth()).format(DATE_FORMAT), // Last date of previous month
      };
    case DateFilterOption.YEAR_TO_DATE:
      return {
        fromTradingDate: today.startOf('year').format(DATE_FORMAT),
        toTradingDate: todayFormatted,
      };
    case DateFilterOption.PREVIOUS_YEAR:
      return {
        fromTradingDate: today.subtract(1, 'year').startOf('year').format(DATE_FORMAT),
        toTradingDate: today.subtract(1, 'year').endOf('year').format(DATE_FORMAT),
      };
    case DateFilterOption.TODAY:
    default:
      return {
        fromTradingDate: todayFormatted,
        toTradingDate: todayFormatted,
      };
  }
};

const setReportCriteriaForRequest = (criteria: {
  dateOption: DateFilterOption;
  fromTradingDate?: string;
  toTradingDate?: string;
}) => {
  if (criteria.dateOption === DateFilterOption.CUSTOM) {
    return {
      fromTradingDate: criteria.fromTradingDate,
      toTradingDate: criteria.toTradingDate,
    };
  }

  return getFromAndToTradingDates(criteria.dateOption);
};

export const getScadaReport = (criteria: IScadaReportCriteria, selectedNodes: string[], downloadAll: boolean) => {
  const { fromTradingDate, toTradingDate } = setReportCriteriaForRequest(criteria);
  const { nodeType, hvdcGranularity } = criteria;

  const getIdsUrlFragment = () => {
    if (nodeType === NodeType.HVDC) {
      // See em047 requirement
      return hvdcGranularity === HVDCGranularity.POLE_2_POLE_3 ? '' : '/DCN3501,DCS3501';
    }

    return selectedNodes && selectedNodes.length && !downloadAll ? `/${selectedNodes.join(',')}` : '';
  };

  return makeGetRequest(
    `scada_${nodeType}_csv${getIdsUrlFragment()}?from_trading_date=${fromTradingDate}&to_trading_date=${toTradingDate}`,
  );
};

export const getPriceReport = (criteria: IPriceReportCriteria, selectedNodes: string[], downloadAll: boolean) => {
  const { fromTradingDate, toTradingDate } = setReportCriteriaForRequest(criteria);
  const { priceOption, priceRunType, fromTradingPeriod, toTradingPeriod } = criteria;

  const idsUrlFragment = selectedNodes && selectedNodes.length && !downloadAll ? `/${selectedNodes.join(',')}` : '';

  let additionalParameter = '';

  const isMarketPrice = priceOption === PriceOption.MARKET;

  if (isMarketPrice) {
    additionalParameter = `&run_type=${PriceRunTypeForParam[priceRunType]}`;
  }

  const dateUrlFragment = isMarketPrice
    ? `trading_date=${fromTradingDate}`
    : `from_trading_date=${fromTradingDate}&to_trading_date=${toTradingDate}`;
  const tradingPeriodUrlFragment = isMarketPrice
    ? `&from_trading_period=${fromTradingPeriod}&to_trading_period=${toTradingPeriod}`
    : '';

  return makeGetRequest(
    `${priceOption}_price_csv${idsUrlFragment}?${dateUrlFragment}${tradingPeriodUrlFragment}${additionalParameter}`,
  );
};

const RESERVES_URL_PREFIX_BY_SUB_TYPE = {
  [ReservesSubtype.AGGREGATED_RESERVES]: 'reserves_agg_csv',
  [ReservesSubtype.RESERVE_AMOUNT_BY_SOURCE]: 'res_amount_source_csv',
  [ReservesSubtype.RESERVES_EXTRACT]: 'reserves_extract_csv',
};

export const getReservesReport = (criteria: IReservesReportCriteria) => {
  const { fromTradingDate, toTradingDate } = setReportCriteriaForRequest(criteria);

  const { reservesIsland, reservesPowerType, reservesReserveType, reserveSubtype } = criteria;

  const islandUrlFragment = reservesIsland === ReservesIsland.ALL ? '' : `&island=${reservesIsland}`;
  const powerTypeUrlFragment = reservesPowerType === ReservesPowerType.ALL ? '' : `&power_type=${reservesPowerType}`;
  const reserveTypeUrlFragment =
    reservesReserveType === ReservesReservesType.ALL ? '' : `&reserve_type=${reservesReserveType}`;

  const prefix = RESERVES_URL_PREFIX_BY_SUB_TYPE[reserveSubtype];

  return makeGetRequest(
    `${prefix}/?from_trading_date=${fromTradingDate}&to_trading_date=${toTradingDate}${islandUrlFragment}${powerTypeUrlFragment}${reserveTypeUrlFragment}`,
  );
};

export const getArcFlowsReport = (criteria: IArcFlowsReportCriteria) => {
  const { fromTradingDate } = setReportCriteriaForRequest(criteria);
  const { arcFlowsRunType } = criteria;

  const runTypeUrlFragment = arcFlowsRunType !== ArcFlowRunType.LATEST ? `&run_type=${arcFlowsRunType}` : '';

  return makeGetRequest(`arc_flows_csv?trading_date=${fromTradingDate}${runTypeUrlFragment}`);
};

export const getRcpdReport = (criteria: IRCPDCriteria) => {
  const { region, regionLoadOption, yearRange } = criteria;

  const isPeaks = regionLoadOption === RegionLoadOption.REGION_LOAD_PEAKS;
  const rcpdTypeForUrl = isPeaks ? 'peaks' : 'load';

  const getQueryParamsForRcpdReport = () => {
    if (isPeaks) {
      return `region=${region}&cm_year=${yearRange}`;
    }

    return `region=${region}`;
  };

  return makeGetRequest(`rcpd_${rcpdTypeForUrl}_csv?${getQueryParamsForRcpdReport()}`);
};

export const getRcpd48Hrs = (criteria: IRCPDCriteria) => makeGetRequest(`rcpd_load?region=${criteria.region}`);
export const getRcpd2Hrs = (criteria: IRCPDCriteria) => makeGetRequest(`rcpd_1min?region=${criteria.region}`);
export const getRcpdPeaks = (criteria: IRCPDCriteria) =>
  makeGetRequest(`rcpd_peaks?region=${criteria.region}&cm_year=${criteria.yearRange}`);

/**
 * Dashboard 2.0 API calls
 */
export const getHvdcLast24 = () => makeGetRequest('hvdc/24hrs');
export const getNzReservesLast24 = () => makeGetRequest('reserves_nz/24hrs/');
export const getNzSummaryLast24 = () => makeGetRequest('nz/24hrs/');
export const getGenerationByType = () => makeGetRequest('generation_type/24hrs/');

export const getRecentLoad = (ids: string[]) => {
  const idsUrlFragment = ids && ids.length ? `${ids.join(',')}` : '';
  return makeGetRequest(`recent_load/${idsUrlFragment}`);
};

export const getResiduals = ({region, runType}: IGetResidualsPayload) => {
  const queryParams: any = []
  region && queryParams.push(`region=${region.join(',')}`)
  runType && queryParams.push(`run_type=${runType.join(',')}`)
  const qs = queryParams.length ? `?${queryParams.join('&')}` : ''
  return makeGetRequest(`residuals${qs}`);
};

// BRB0331,OTA2201,HAM2201,WKM2201,RDF2201,TUI1101,SFD2201,BPE2201,HAY2201,KIK2201,ISL2201,DOB0661,BEN2201,INV2201,HWB2201
// MPE1101,PEN0331,HAM0331,EDG0331,RDF0331,TUI1101,SFD0331,BPE0331,CPK0331,STK0331,ISL0661,GYM0661,OAM0331,INV0331,HWB0331
