import '@reach/listbox/styles.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { TRADER_PRICE_NODE_OPTIONS } from 'redux/modules/nodes/constants';
import { useRTP } from 'tools/hooks/useRTP';
import { useRTPFilter } from 'tools/hooks/useRTPFilter';
import {
  getCurrentTradingPeriodStart,
  getCurrentTradingPeriodEnd,
  getNextTradingPeriodEnd,
  getCurrentTradingPeriod,
  getNextTradingPeriod,
  getPreviousTradingPeriod,
} from 'tools/utilities/date';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { GraphableDataPoint } from 'tools/utilities/RTP/rtpUtils';
import { TooltipWrapper, TooltipRow, BoldTooltipText } from 'views/components/Tooltip/Tooltip';
import { isViewportAboveDesktop as isViewportAboveDesktopSelector } from '../../../../redux/modules/app/selectors';
import { NodeSelection } from '../NodeSelection';
import styles from '../Shared.module.scss';
import { TitleArea } from '../TitleArea';
import { ILegendItem, RTPLegend } from './RTPLegend';

const legendItems: ILegendItem[] = [
  {
    color: '#B1E9E6',
    label: 'Dispatch price',
    tooltip: 'The most recent published Real-Time Dispatch price for the relevant node',
  },
  {
    color: '#CBE612',
    label: 'Interim price trendline',
    tooltip:
      'The time-weighted average price for the trading period based on the Real-Time Dispatch prices received so far for the relevant node',
  },
  {
    color: '#ED8B00',
    label: 'NRSS forecast',
    tooltip: 'The Non-Responsive Schedule Short Forecast price for the relevant node',
  },
];

export const RTP = () => {
  const currentTime = moment().valueOf();
  const {
    isFetching,
    xTicks,
    graphableData,
    lastUpdated,
    priceAtEndOfCurrentTradingPeriod,
    priceAtEndOfNextTradingPeriod,
    lastTradingPeriodInterimPrice,
  } = useRTP();

  const isViewportAboveDesktop = useSelector(isViewportAboveDesktopSelector);

  const [interim1XPos, setInterim1XPos] = useState(0);
  const [interim2XPos, setInterim2XPos] = useState(0);
  const [interim3XPos, setInterim3XPos] = useState(0);

  const { setSelectedPriceNode, selectedPriceNode } = useRTPFilter();

  const animationRef = useRef<number>(0);
  useEffect(() => {
    let lastTime = performance.now();
    const handleResize = (time: number) => {
      if (time - lastTime < 100) {
        animationRef.current = requestAnimationFrame(handleResize);
        return;
      }

      lastTime = time;

      // find document with id interimLine1 and get its x position
      const interimLine1 = document.getElementById('interimLine1');
      const interimLine2 = document.getElementById('interimLine2');
      const interimLine3 = document.getElementById('interimLine3');
      if (interimLine1) {
        const lineParent = interimLine1.parentElement;
        setInterim1XPos(lineParent?.getBoundingClientRect().x ?? 0);
      }
      if (interimLine2) {
        const lineParent = interimLine2.parentElement;
        setInterim2XPos(lineParent?.getBoundingClientRect().x ?? 0);
      }
      if (interimLine3) {
        const lineParent = interimLine3.parentElement;
        setInterim3XPos(lineParent?.getBoundingClientRect().x ?? 0);
      }

      animationRef.current = requestAnimationFrame(handleResize);
    };
    requestAnimationFrame(handleResize);
    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  if (isFetching) {
    // Todo add a better loader screen
    return <div>Loading...</div>;
  }
  return (
    <div>
      <TitleArea title="Dispatch and interim price ($/MW)">
        <NodeSelection
          selectedOption={selectedPriceNode}
          setSelectedOption={setSelectedPriceNode}
          options={TRADER_PRICE_NODE_OPTIONS}
        />
      </TitleArea>
      <div style={{ width: '100%', fontSize: 12 }}>
        {priceAtEndOfNextTradingPeriod && (
          <div
            style={{
              lineHeight: '1.2',
              paddingRight: '5px',
              position: 'absolute',
              transform: 'translate(-100%, 5px)',
              left: `${interim3XPos}px`,
              textAlign: 'right',
              color: '#ed8b00',
            }}
          >
            <div>TP {getNextTradingPeriod()} </div>{' '}
            <div>
              <strong>${roundToDpWithCommas(priceAtEndOfNextTradingPeriod, 2)}</strong>
            </div>
          </div>
        )}
        {priceAtEndOfCurrentTradingPeriod && (
          <div
            style={{
              lineHeight: '1.2',
              paddingRight: '5px',
              position: 'absolute',
              transform: 'translate(-100%, 5px)',
              left: `${interim2XPos}px`,
              textAlign: 'right',
              color: '#ed8b00',
            }}
          >
            <div>TP {getCurrentTradingPeriod()}</div>{' '}
            <div>
              <strong>${roundToDpWithCommas(priceAtEndOfCurrentTradingPeriod, 2)}</strong>
            </div>
          </div>
        )}
        {lastTradingPeriodInterimPrice && (
          <div
            style={{
              lineHeight: '1.2',
              paddingRight: '5px',
              position: 'absolute',
              transform: 'translate(-100%, 5px)',
              left: `${interim1XPos}px`,
              textAlign: 'right',
              color: '#CBE612',
            }}
          >
            <div>TP {getPreviousTradingPeriod()}</div>{' '}
            <div>
              <strong>${roundToDpWithCommas(lastTradingPeriodInterimPrice, 2)}</strong>
            </div>
          </div>
        )}
      </div>
      <ResponsiveContainer
        className={styles.graphArea}
        aspect={isViewportAboveDesktop ? 1.5 : undefined}
        width="99%"
        height={isViewportAboveDesktop ? undefined : 392}
      >
        <LineChart margin={{ left: 0, top: 20 }} data={graphableData}>
          <YAxis
            style={{ fontSize: 12 }}
            domain={[
              (min: number) => Math.max(0, Math.round(min - 2)),
              (max: number) => Math.max(5, Math.round(max * 1.1)),
            ]}
            tickFormatter={(value: any, index: number) => (index === 0 ? ' ' : `$${value}`)} // hide first tick
            tickLine={false}
            className={styles.yAxis}
            tickCount={8}
            stroke="#fff"
            type="number"
            axisLine={false}
          />
          <XAxis
            style={{ fontSize: 12 }}
            tickLine={false}
            axisLine={false}
            ticks={xTicks}
            domain={['dataMin', (max: number) => moment(max).add(15, 'minutes').valueOf()]}
            stroke="#fff"
            dataKey="timestamp_milliseconds"
            tickFormatter={(unixTime: number) => moment(unixTime).format('HH:mm')}
            type="number"
          />
          <ReferenceArea
            x1={getCurrentTradingPeriodStart()}
            x2={currentTime}
            strokeOpacity={0.3}
            fill="#87E6E5"
            fillOpacity={0.3}
            style={{
              fill: 'url(#currentTPSoFar)',
            }}
          />
          <ReferenceArea
            x1={currentTime}
            x2={getCurrentTradingPeriodEnd()}
            fill="#87E6E5"
            strokeOpacity={0.3}
            fillOpacity={0.3}
            style={{
              fill: 'url(#currentTP)',
            }}
          />
          <Line connectNulls type="stepAfter" dot={false} strokeWidth={2} stroke="#B1E9E6" dataKey="dispatch_price" />
          <Line
            // connectNulls
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            type="stepBefore"
            stroke="#ed8b00"
            dataKey="NRSS_price"
          />
          <Line
            connectNulls
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            type="monotone"
            stroke="#cbe612"
            dataKey="current_tp_dispatch_rolling_average"
          />
          <Line
            connectNulls
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            type="monotone"
            stroke="#cbe612"
            dataKey="last_tp_dispatch_rolling_average"
          />
          {/* TODO add a reference dot for the end of the previous trading period. */}
          {priceAtEndOfCurrentTradingPeriod && (
            <ReferenceDot
              x={getCurrentTradingPeriodEnd()}
              y={priceAtEndOfCurrentTradingPeriod}
              r={3}
              fill="#ed8b00"
              stroke="#ed8b00"
            />
          )}
          {priceAtEndOfNextTradingPeriod && (
            <ReferenceDot
              x={getNextTradingPeriodEnd()}
              y={priceAtEndOfNextTradingPeriod}
              r={3}
              fill="#ed8b00"
              stroke="#ed8b00"
            />
          )}
          {lastTradingPeriodInterimPrice && (
            <ReferenceDot
              x={getCurrentTradingPeriodStart()}
              y={lastTradingPeriodInterimPrice}
              r={3}
              fill="#cbe612"
              stroke="#cbe612"
            />
          )}
          <CartesianGrid opacity={0.5} strokeDasharray="4 3" vertical={false} />
          <Tooltip content={CustomTooltip} />
          <defs>
            <linearGradient id="currentTP" x1="0.5" y1="0" x2="0.5" y2="0.7">
              <stop offset="30%" stopColor="#87E6E5" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#0C3345" stopOpacity={0.5} />
            </linearGradient>
            <linearGradient id="currentTPSoFar" x1="0.5" y1="0" x2="0.5" y2="1">
              <stop offset="30%" stopColor="#87E6E5" stopOpacity={1} />
              <stop offset="95%" stopColor="#0C3345" stopOpacity={0.5} />
            </linearGradient>
          </defs>

          <ReferenceLine id="interimLine1" x={getCurrentTradingPeriodStart()} stroke="#cbe612" />
          <ReferenceLine id="interimLine2" x={getCurrentTradingPeriodEnd()} stroke="#ed8b00" />
          <ReferenceLine id="interimLine3" x={getNextTradingPeriodEnd()} stroke="#ed8b00" />
        </LineChart>
      </ResponsiveContainer>
      <RTPLegend legendItems={legendItems} />
      <div>Last updated {moment(lastUpdated).format('ddd DD MMM YYYY, HH:mm')}</div>
    </div>
  );
};

const CustomTooltip = ({ payload }: TooltipProps<string, string>) => {
  if (!payload?.[0]) {
    return null;
  }
  const dataPoint: GraphableDataPoint = payload[0].payload;
  return (
    <TooltipWrapper>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="Time:" />
        <span>{moment(dataPoint.timestamp_milliseconds).format('HH:mm')}</span>
      </TooltipRow>
      {!dataPoint.dispatch_price ? (
        <></>
      ) : (
        <TooltipRow>
          <BoldTooltipText style={{ marginRight: 20 }} text="Dispatch price:" />
          <span>{dataPoint?.dispatch_price ? `$${Math.round(dataPoint.dispatch_price * 100) / 100}` : '-'}</span>
        </TooltipRow>
      )}
      {!dataPoint?.last_tp_dispatch_rolling_average ? (
        <></>
      ) : (
        <TooltipRow>
          <BoldTooltipText style={{ marginRight: 20 }} text="Interim price trendline:" />

          <span>
            {dataPoint?.last_tp_dispatch_rolling_average
              ? `$${Math.round(dataPoint.last_tp_dispatch_rolling_average * 100) / 100}`
              : '-'}
          </span>
        </TooltipRow>
      )}
      {!dataPoint?.current_tp_dispatch_rolling_average ? (
        <></>
      ) : (
        <TooltipRow>
          <BoldTooltipText style={{ marginRight: 20 }} text="Interim price trendline:" />
          <span>
            {dataPoint?.current_tp_dispatch_rolling_average
              ? `$${Math.round(dataPoint.current_tp_dispatch_rolling_average * 100) / 100}`
              : '-'}
          </span>
        </TooltipRow>
      )}
      {!dataPoint?.interim_price ? (
        <></>
      ) : (
        <TooltipRow>
          <BoldTooltipText style={{ marginRight: 20 }} text="Interim price:" />
          <span>{`$${Math.round(dataPoint.interim_price * 100) / 100}`}</span>
        </TooltipRow>
      )}
      {!dataPoint.NRSS_price ? (
        <></>
      ) : (
        <TooltipRow>
          <BoldTooltipText style={{ marginRight: 20 }} text="NRSS Forecast:" />
          <span>{dataPoint.NRSS_price ? `$${dataPoint.NRSS_price}` : '-'}</span>
        </TooltipRow>
      )}
    </TooltipWrapper>
  );
};
