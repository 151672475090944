export enum FeatureFlags {
  FREE_DASHBOARD = 'FREE_DASHBOARD',
  GWAP_DASHBOARD = 'GWAP_DASHBOARD',
  NZ_EMISSIONS_REPORT = 'NZ_EMISSIONS_REPORT',
  TEST_REFRESH_TIMES = 'TEST_REFRESH_TIMES',
  IS_FOYER = 'IS_FOYER',
  TRADING_DASHBOARD = 'TRADING_DASHBOARD',
}

export const featureFlagState: { [key in FeatureFlags]: boolean } = {
  [FeatureFlags.FREE_DASHBOARD]:
    !!process.env.REACT_APP_FEATURE_FLAG_FREE_DASHBOARD && process.env.REACT_APP_FEATURE_FLAG_FREE_DASHBOARD === 'on',
  [FeatureFlags.GWAP_DASHBOARD]:
    !!process.env.REACT_APP_FEATURE_FLAG_GWAP_DASHBOARD && process.env.REACT_APP_FEATURE_FLAG_GWAP_DASHBOARD === 'on',
  [FeatureFlags.NZ_EMISSIONS_REPORT]:
    !!process.env.REACT_APP_FEATURE_FLAG_EMISSIONS_REPORT &&
    process.env.REACT_APP_FEATURE_FLAG_EMISSIONS_REPORT === 'on',
  [FeatureFlags.TEST_REFRESH_TIMES]:
    !!process.env.REACT_APP_FEATURE_FLAG_USE_TEST_REFRESH_TIMES &&
    process.env.REACT_APP_FEATURE_FLAG_USE_TEST_REFRESH_TIMES === 'on',
  [FeatureFlags.IS_FOYER]:
    !!process.env.REACT_APP_FEATURE_FLAG_IS_FOYER && process.env.REACT_APP_FEATURE_FLAG_IS_FOYER === 'on',
  [FeatureFlags.TRADING_DASHBOARD]:
    !!process.env.REACT_APP_FEATURE_FLAG_TRADING_DASHBOARD &&
    process.env.REACT_APP_FEATURE_FLAG_TRADING_DASHBOARD === 'on',
};
