import { useSelector } from 'react-redux';
import {
  isViewportAboveMobile as isViewportAboveMobileSelector,
  isViewportAboveTablet as isViewportAboveTabletSelector,
} from 'redux/modules/app/selectors';

import Dialog from '@reach/dialog';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { gwapGenTypeFilterState, gwapLocationFilterState } from 'tools/state/gwapState';
import { getGenTypeAndLocationSummary } from 'tools/hooks/useFreeGwap';
import styles from './PaidMobileGwapFilter.module.scss';
import { Icon } from '../Icon/Icon';
import { GwapDropDownFilters } from '../Filters/GwapDropdownFilters';

import './StyleForIcon.scss';

export const PaidMobileGwapFilter = () => {
  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);
  const isViewportAboveTablet = useSelector(isViewportAboveTabletSelector);
  const isViewportBetweenMobileAndTablet = !isViewportAboveTablet && isViewportAboveMobile;
  const [showDialog, setShowDialog] = useState(false);

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const locationFilters = useRecoilValue(gwapLocationFilterState);
  const generationTypesSelected = useRecoilValue(gwapGenTypeFilterState);

  if (isViewportAboveTablet) {
    return null;
  }

  return (
    <>
      <Dialog
        aria-label="Filter modal"
        style={{
          width: isViewportBetweenMobileAndTablet ? '92vw' : '100vw',
          height: '100vh',
          position: 'fixed',
          overflow: 'scroll',
          backgroundColor: '#003b59',
          padding: '24px 32px 4px 32px',
          marginTop: '60px',
          marginLeft: isViewportBetweenMobileAndTablet ? '8vw' : '0',
        }}
        isOpen={showDialog}
        onDismiss={close}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '14px' }}>
          <h2 style={{ margin: 0, marginBottom: 0 }}>Filter graph</h2>

          <button type="button" className={`close-button ${styles.modalButton}`} onClick={close}>
            <span aria-hidden style={{ display: 'flex', alignItems: 'center' }}>
              <Icon className="GwapIconWrapper" name="ic-close" size="sm" />
            </span>
          </button>
        </div>
        <GwapDropDownFilters />
        <p className={styles.description}>Graphs will auto update based on your selection</p>
      </Dialog>
      <button type="button" onClick={open} className={styles.wrapper}>
        <div className={styles.iconArea}>
          <img alt="GWAP Filters Icon" src="/assets/icons/ic-filter.svg" style={{ width: '16px' }} />
        </div>
        <span className={styles.summaryArea}>
          {getGenTypeAndLocationSummary(generationTypesSelected, locationFilters)}
        </span>
      </button>
    </>
  );
};
