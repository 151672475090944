import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import './Login.scss';

import { actionLogin, LoginError, LOGIN_ERROR_CODES } from 'redux/modules/session/actions';
import { validateEmail, MINIMUM_PASSWORD_LENGTH, validatePassword } from 'tools/utilities/validators';
import { isLoggedIn as isLoggedInSelector, isLoading, getLoginError } from 'redux/modules/session/selectors';

import { Button } from 'views/components/Button/Button';
import { TextInput } from 'views/components/TextInput/TextInput';
import { PasswordInput } from 'views/components/PasswordInput/PasswordInput';
import { ROUTE_PATHS } from '../../../constants/routes';

import { FormContainer } from '../FormContainer/FormContainer';
import { Icon } from '../Icon/Icon';

const getAPIErrorMessage = (error: LoginError): string => {
  switch (error.code) {
    case LOGIN_ERROR_CODES.USER_NOT_FOUND_EXCEPTION:
      return 'LOGIN_ERROR';
    default:
      return 'LOGIN_ERROR';
  }
};

const resolveValue = (selector: string) => {
  const input = document.querySelector(selector) as HTMLInputElement;

  if (!input) {
    return '';
  }

  return input.value;
};

const resolveEmailValue = () => resolveValue('[name="email"]');

const resolvePasswordValue = () => resolveValue('[name="password"]');

/**
 * EM6 keeps the site in display at their buildings in screens they call _Foyer Displays_.
 * The way this is displayed is controlled _globally_ for all of their offices and they need
 * the login form to support authentication by running a script that by using DOM queries
 * fills up the needed values and submits the form.
 * This means that the form validation and value "fetching" needs to cater for the case when the
 * values are not entered by a user, but through a script.
 * This is how the form works to be able to handle population through a script:
 * 1. On email change, the password value is set by using a dom query and getting what's in the input (`resolvePasswordValue`).
 * 2. On password change, the email value is set by using a dom query and getting what's in the input (`resolveEmailValue`).
 * 3. On submission, if there's not a value set for email, it's attempted to be set again by using `resolveEmail`.
 * 4. On submission, if there's not a value set for password, it's attempted to be set again by using `resolvePassword`.
 *
 * This ensures that the form will work as needed for both user and scripted input.
 */
export const Login = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>(resolveEmailValue());
  const [password, setPassword] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);

  const isLoggedIn = useSelector(isLoggedInSelector);
  const isLoadingSession = useSelector(isLoading);
  const loginError = useSelector(getLoginError);

  useEffect(() => {
    const loginButton = document.getElementById('loginButton');
    if (loginButton) {
      loginButton.focus();
    }
  }, [loginError]);

  if (isLoggedIn) {
    return <Redirect to={ROUTE_PATHS.DASHBOARD} />;
  }

  const onEmailChange = (value: string) => {
    setEmail(value);
    setPassword(resolvePasswordValue());
    setIsEmailValid(validateEmail(value));
  };

  const onPasswordChange = (value: string) => {
    setEmail(resolveEmailValue());
    setPassword(value);
    setIsPasswordValid(validatePassword(value));
  };

  const onSubmit = (event: any) => {
    event.preventDefault();

    let emailToUse = email;
    let passwordToUse = password;

    if (!emailToUse) {
      emailToUse = resolveEmailValue();
      setEmail(emailToUse);
    }

    if (!passwordToUse) {
      passwordToUse = resolvePasswordValue();
      setPassword(passwordToUse);
    }

    if (!hasAttemptedSubmit) {
      setHasAttemptedSubmit(true);
    }

    if (!validateEmail(emailToUse) || !validatePassword(passwordToUse)) {
      return;
    }

    dispatch(actionLogin({ email: emailToUse, password: passwordToUse }));
  };

  const showPasswordError = hasAttemptedSubmit && !loginError && !isPasswordValid;
  const showEmailError = hasAttemptedSubmit && !loginError && !isEmailValid;

  return (
    <div className="Login">
      <FormContainer>
        <h2 className="Form-title">
          <FormattedMessage id="LOGIN_TO_EM6" />
        </h2>
        <form className="Form-form" onSubmit={onSubmit}>
          <TextInput
            type="text"
            label="Email"
            placeholder="e.g. name@domain.co.nz"
            onChange={onEmailChange}
            id="email"
            value={email}
            error={showEmailError ? 'Please enter a valid email' : ''}
            showErrorOutLine={!!loginError}
          />

          <div className="Login-password">
            <PasswordInput
              label="Password"
              placeholder={`at least ${MINIMUM_PASSWORD_LENGTH} characters`}
              onChange={onPasswordChange}
              id="password"
              value={password}
              error={showPasswordError ? 'Please enter your password.' : ''}
              showErrorOutLine={!!loginError}
              allowHints={!isPasswordValid}
            />
            <Link className="Login-password-reset" to={ROUTE_PATHS.FORGOT_PASSWORD}>
              Forgot password
            </Link>
          </div>
          {loginError && hasAttemptedSubmit && (
            <p className="Login-error">
              <FormattedMessage id={getAPIErrorMessage(loginError)} />
              <Icon name="ic-error" className="Login-error-icon Icon--sm" />
            </p>
          )}
          <Button
            className="Button Form-button"
            label="Login"
            buttonType="submit"
            isLoading={isLoadingSession}
            id="loginButton"
            onClick={onSubmit}
          />
        </form>

        <p className="Login-help">
          <FormattedMessage
            id="INTERESTED_IN_AN_ACCOUNT"
            values={{
              // @ts-ignore
              a: (...chunks: any) => <a href="mailto:call@ems.co.nz?subject=em6 | Account Request">{chunks}</a>,
            }}
          />
        </p>
      </FormContainer>
    </div>
  );
};
