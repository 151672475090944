import React from 'react';
import { FormattedMessage } from 'react-intl';

import './SelectionCriteria.scss';

interface IProps {
  children: React.ReactNode;
}

export const SelectionCriteria = ({ children }: IProps) => (
  <div className="SelectionCriteria">
    <h2 className="SelectionCriteria-title">
      <FormattedMessage id="SELECTION_CRITERIA" />
    </h2>
    <div className="SelectionCriteria-body">{children}</div>
  </div>
);
