import numeral from 'numeral';

// Sample outputs
// (1000, 0) => '1,000'
// (1000.234, 1) => '1,000.2'

export const roundToDpWithCommas = (n: number, dp: number): string => {
  let formatString = '0,0';

  if (dp > 0) {
    formatString = `${formatString}.${'0'.repeat(dp)}`;
  }

  return numeral(n).format(formatString);
};
