import React from 'react';

import { ForgotPassword as ForgotPasswordComponent } from 'views/components/ForgotPassword/ForgotPassword';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';

export const ForgotPassword = () => (
  <div className="Container">
    <RouteTransition>
      <ForgotPasswordComponent />
    </RouteTransition>
  </div>
);
