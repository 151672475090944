import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';

import {
  RESIDUAL_COLORS,
  RESIDUAL_LABELS,
  REGION_LABELS,
  COLOURS,
} from 'redux/modules/nodes/constants';

import { scaleLinear } from 'd3-scale';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { FormattedMessage } from 'react-intl';
import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { getResiduals, isLoadingResiduals } from 'redux/modules/nodes/selectors';
import { formatLastUpdatedTime, formatTimestamp } from 'tools/utilities/charts';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipRow, TooltipWrapper, BoldTooltipText } from '../Tooltip/Tooltip';

const MOBILE_HEIGHT = 620;
const LOW_RESIDUAL_TRESHOLD = 200;

const CustomReferenceLabel = (props: any) => {
  const {
    fill, value,
    fontSize, viewBox, dy, dx,
  } = props;
  const x = viewBox.width + viewBox.x;
  const y = viewBox.y - 8;
  return (
    <text
      x={x}
      y={y}
      dy={dy}
      dx={dx}
      fill={fill}
      fontSize={fontSize || 14}
      textAnchor="end">
      {value}
    </text>
  )
}

const CustomTooltip = ({ payload, keys }: any) => {
  if (!payload?.[0]) {
    return null;
  }

  const { timestamp, tradingPeriod, ...values } = payload?.[0]?.payload ?? {};

  return (
    <TooltipWrapper>
      <TooltipRow>
        <span>{timestamp && formatTimestamp(new Date(timestamp))} - TP{tradingPeriod}</span>
      </TooltipRow>

      {keys.map((key: any) => {
        const [region, runType] = key.split('_')
        const value = values[key]
        const wind = values[`${key}_wind`]

        return value ? (
          <React.Fragment key={key}>
            <TooltipRow>
              <BoldTooltipText style={{ marginRight: 20 }} text={`${REGION_LABELS[region]} ${runType}`} />
              <span>{value ? `${roundToDpWithCommas(value, 2)} MW` : '-'}</span>
            </TooltipRow>
            {wind && <TooltipRow>
              <BoldTooltipText style={{ marginRight: 20 }} text="Residual minus Wind Risk" />
              <span>{`${roundToDpWithCommas(wind, 2)} MW`}</span>
            </TooltipRow>}
          </React.Fragment>
        ) : null
      })}
    </TooltipWrapper>
  );
};

// we only have forecast wind data for NI
const validWindRiskCombo = (region: any, runType: any) => (region === 'NI' || region === 'NZ') && ['NRS', 'PRS', 'WDS'].includes(runType)

// hide all wind risk when too many regions/runtypes combinations are being shown
const shouldRenderWindRisk = (regions: any, runTypes: any) => regions.concat(runTypes).length < 3

const calculateWindRisk = (item: any, timestamp: any, windForecastData: any) => {
  const { residual, runType, region } = item
  if (validWindRiskCombo(region, runType)){
    const forecast = (windForecastData?.forecast || {})[timestamp]
    const offered = forecast && forecast[0]?.offered
    const capacity = windForecastData?.capacity
    const variableA = 7.66
    const variableB = 0.09

    if (offered && capacity && residual){
      const windRisk = offered - Math.max(0, ((100 * offered / capacity) - variableA) / variableB)
      return {
        [`${region}_${runType}_wind`]: residual - windRisk,
      }
    }
  }
  return null
}

const buildGraphKeyItems = (keys: any, regions: any, runTypes: any) => {
  const graphKeys: any = []
  keys.forEach((key: any) => {
    graphKeys.push({
      label: RESIDUAL_LABELS[key],
      colour: RESIDUAL_COLORS[key],
      fill: RESIDUAL_COLORS[key]
    })
    const [region, runType] = key.split('_')
    if (shouldRenderWindRisk(regions, runTypes) && validWindRiskCombo(region, runType)){
      graphKeys.push({
        label: 'Residual minus Wind Risk',
        colour: RESIDUAL_COLORS[key],
        fill: 'none',
        style: {strokeDasharray: '3px', strokeWidth: '1px', fill: 'none'},
        tooltip: 'Wind risk is an em6 estimate for the potential largest possible drop in actual wind generation against forecast. It does not reflect any other resultant changes that may affect residuals.',
      })
    }
  })
  return graphKeys
}

export const ResidualsGraph = ({regions, runTypes, currentTime, windForecastData}: any) => {
  const residuals = useSelector(getResiduals);
  const isAboveTablet = useSelector(isViewportAboveTabletSelector);
  const isLoading = useSelector(isLoadingResiduals);
  const renderWindRisk = shouldRenderWindRisk(regions, runTypes)

  const data = residuals?.items;
  if (!data || !data.length) {
    return null;
  }

  // API returns multiple items with the same timestamp for different regions/runTypes
  // Recharts wants one item per timestamp wiith key/value per region/runType
  const dataByTimestamp = _.groupBy(data, 'timestamp')
  const residualsData = Object.keys(dataByTimestamp).map(
    timestamp => ({
      timestamp,
      unixTime: moment(timestamp).valueOf(),
      ...(dataByTimestamp[timestamp].reduce((acc: any, curr) => ({
        ...acc,
        tradingPeriod: curr.tradingPeriod,
        [`${curr.region}_${curr.runType}`]: curr.residual,
        ...(renderWindRisk ? calculateWindRisk(curr, timestamp, windForecastData)  : {}),
      }), {}))
    })
  )

  const yValues = data.reduce((acc: any, {residual}: any) => ([
    ...acc,
    residual,
  ]), [])

  const runTimes = data.reduce((acc: any, {runTime}: any) => ([
    ...acc,
    moment(runTime).valueOf(),
  ]), [])

  const yMin = Math.min(...yValues, LOW_RESIDUAL_TRESHOLD)
  const yMax = Math.max(...yValues)

  const xMin = residualsData[0].unixTime
  const xMax = residualsData[residualsData.length-1].unixTime

  const offset = (yMax - yMin) / 10
  const yScale = scaleLinear().domain([yMin - offset, yMax + offset])
  const yTicks = yScale.ticks(6)

  const xTicks:any = []
  let ts = moment(xMin).startOf('hour').valueOf()
  while(ts <= xMax){
    xTicks.push(ts)
    ts = moment(ts).add(6, 'hours').valueOf()
  }

  const keys = regions.map((region: any) => runTypes.map((runType: any) => `${region}_${runType}`)).flat()
  const maxRunTime = Math.max(...runTimes)
  const lastUpdatedTime = formatLastUpdatedTime(new Date(maxRunTime));
  const graphKeyItems = buildGraphKeyItems(keys, regions, runTypes)

  return (
    <div>
      { isLoading && <div> <br/> Loading... </div> }
      <ResponsiveContainer
        aspect={isAboveTablet ? 2 : undefined}
        width="99%"
        height={isAboveTablet ? 'unset' : MOBILE_HEIGHT}
      >
        <LineChart
          data={residualsData}
          margin={{
            top: 24,
            right: 0,
            left: 0,
            bottom: 16,
          }}
        >
          <XAxis
            ticks={xTicks}
            tickFormatter={(tick: number) => moment(tick).format('hh A')}
            tickLine={false}
            domain={[xMin, xMax]}
            axisLine={false}
            width={100}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            type="number"
            dataKey="unixTime"
          />
          <YAxis
            ticks={yTicks}
            tickLine={false}
            domain={[yMin - offset, yMax + offset]}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            axisLine={false}
            type="number"
          />

          <defs>
            <linearGradient id="lowResidualGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor='#0C3345' stopOpacity={1} />
              <stop offset="100%" stopColor='#0C3345' stopOpacity={0} />
            </linearGradient>
            <linearGradient id="currentTimeGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor={COLOURS.WIND_TEAL} stopOpacity={0} />
              <stop offset="100%" stopColor={COLOURS.WIND_TEAL} stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <ReferenceArea y2={LOW_RESIDUAL_TRESHOLD} fill="url(#lowResidualGradient)" />
          <ReferenceLine
            y={LOW_RESIDUAL_TRESHOLD}
            stroke="white"
            strokeDasharray="5 4"
            strokeWidth={2}
            label={<CustomReferenceLabel fill="#fff" value={`${LOW_RESIDUAL_TRESHOLD} MW`}/>}
          />

          {currentTime &&
            <>
              <ReferenceArea x2={currentTime} fill="url(#currentTimeGradient)" />
              <ReferenceLine
                x={currentTime}
                stroke="white"
              />
            </>
          }

          <Tooltip
            labelFormatter={(label: any, payload: any) => (
              <span>
                {moment(new Date(payload[0]?.payload?.timestamp)).format('DD/MM/YYYY')} - TP{payload[0]?.payload.tradingPeriod}
              </span>
            )}
            content={args => CustomTooltip({...args, keys})}
          />

          {keys.map((key: any) =>
            <Line key={key} type="linear" dataKey={key} stroke={RESIDUAL_COLORS[key]} dot={false} strokeWidth={2} />)}

          {renderWindRisk && keys.map((key: any) =>
            <Line key={`${key}_wind`} type="linear" dataKey={`${key}_wind`} stroke={RESIDUAL_COLORS[key]} strokeDasharray="5 5" dot={false} strokeWidth={2} />)}

          {xTicks.map((tick: number) => (
            <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}

          {yTicks.map((tick: number) => (
            <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
        </LineChart>

      </ResponsiveContainer>

      <GraphKey keyItems={graphKeyItems} />
      <p className="Dashboard-updated">
        <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
      </p>

    </div>
  )
}