import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRCPDCriteria } from 'types.d';
import { Regions, RegionLoadOption, ReportType } from 'enums.d';
import {
  actionGet48HrsRcpd,
  actionGet2HrsRcpd,
  actionGetRcpdPeaks,
  actionGetRcpdReport,
  TYPES,
} from 'redux/modules/nodes/actions';

import { CriteriaOption } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';
import {
  SelectionCriteria,
  // SelectionCriteriaButtonConfig,
} from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { actionTrackReportDownload } from 'redux/modules/analytics/actions';

import { getReportContent } from 'redux/modules/nodes/selectors';
import { generateDownload } from 'tools/utilities/file';
import { formatRcpdReportFilename } from 'tools/utilities/report';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { Button } from '../Button/Button';
import { ROUTE_PATHS } from '../../../constants/routes';

export const REGION_OPTIONS: CriteriaOption[] = [
  { label: 'Upper North Island - UNI', id: Regions.UPPER_NORTH_ISLAND },
  { label: 'Lower North Island - LNI', id: Regions.LOWER_NORTH_ISLAND },
  { label: 'Upper South Island - USI', id: Regions.UPPER_SOUTH_ISLAND },
  { label: 'Lower South Island - LSI', id: Regions.LOWER_SOUTH_ISLAND },
];

const REGION_TYPE_OPTIONS: CriteriaOption[] = [
  {
    label: 'Region Load - last 48hrs',
    id: RegionLoadOption.REGION_LOAD_LAST_48HRS,
  },
  {
    label: 'Region Load - last 2hrs',
    id: RegionLoadOption.REGION_LOAD_LAST_2HRS,
  },
  { label: 'Region Peaks', id: RegionLoadOption.REGION_LOAD_PEAKS },
];

const GRAPH_OPTIONS: CriteriaOption[] = [
  { label: 'Residual', id: ROUTE_PATHS.RESIDUALS },
  { label: 'RCPD', id: ROUTE_PATHS.RCPD },
];

// Create the same data above but dynamically based on what year it is currently
// Format should be YYYY - YYYY (e.g. 2021 - 2022). Going back to the 2015 - 2016 year
// EM6-350 & EM6-390 - RCPD year runs from 1 September to 31 August
const RCPD_YEAR_CUTOVER_MONTH = 8 // September (January is 0)
const CURRENT_RCPD_YEAR = new Date().getMonth() >= RCPD_YEAR_CUTOVER_MONTH ? new Date().getFullYear() : new Date().getFullYear() - 1
export const REGION_PEAK_OPTIONS_DYNAMIC: CriteriaOption[] = [];
const YEARS_SINCE_2015 = CURRENT_RCPD_YEAR - 2015;
for (let i = 0; i < YEARS_SINCE_2015 + 1; i += 1)  {
  const year = 2015 + i;
  const yearLabel = `${year} - ${year + 1}`;
  const yearId = `${year}-${year + 1}`;
  REGION_PEAK_OPTIONS_DYNAMIC.push({ label: yearLabel, id: yearId });
}
// Reverse this array
REGION_PEAK_OPTIONS_DYNAMIC.reverse();

interface IProps {
  setGraphHeading: (heading: string) => void;
  setCriteria: (criteria: IRCPDCriteria) => void;
  criteria: IRCPDCriteria;
  setHasGraphUpdated: (hasUpdated: boolean) => void;
}

export const RCPDSelectionCriteria = ({ setGraphHeading, setCriteria, criteria, setHasGraphUpdated }: IProps) => {
  const dispatch = useDispatch();
  const reportContent = useSelector(getReportContent);
  const isViewAboveMobile = useSelector(isViewportAboveMobile);

  useEffect(() => {
    const region = REGION_OPTIONS.find((option) => option.id === criteria.region);
    const regionType = REGION_TYPE_OPTIONS.find((option) => option.id === criteria.regionLoadOption);

    setGraphHeading(`${region && region.label} ${regionType && regionType.label}`);
  }, [criteria, setGraphHeading]);

  useEffect(() => {
    if (reportContent) {
      const filename = formatRcpdReportFilename(criteria);
      generateDownload(reportContent, `${filename}.csv`, 'text/csv');
      dispatch({ type: TYPES.CLEAN_UP_REPORT });
    }
  }, [reportContent, criteria, dispatch]);

  const updateCriteria = (key: string, filterOptionId: string) => {
    setHasGraphUpdated(false);
    setCriteria({ ...criteria, [key]: filterOptionId });
  };

  const getResults = () => {
    if (criteria.regionLoadOption === RegionLoadOption.REGION_LOAD_LAST_48HRS) {
      setHasGraphUpdated(true);
      return dispatch(actionGet48HrsRcpd(criteria));
    }

    if (criteria.regionLoadOption === RegionLoadOption.REGION_LOAD_LAST_2HRS) {
      setHasGraphUpdated(true);
      return dispatch(actionGet2HrsRcpd(criteria));
    }

    return dispatch(actionGetRcpdPeaks(criteria));
  };

  const handleReportDownload = () => {
    const finalCriteria: IRCPDCriteria = {
      ...criteria,
    };

    dispatch(actionGetRcpdReport(finalCriteria));
    dispatch(
      actionTrackReportDownload({
        reportType: ReportType.RCPD,
        criteria,
      }),
    );
  };

  // TODO
  // Load/RCPDSelectionCriteria needs a refactor
  // state is duplicated on multiple levels
  const filter = JSON.stringify(criteria)
  useEffect(() => {getResults()}, [filter])
  
  return (
    <SelectionCriteria>
      {/* buttonConfigs={buttonConfigs} */}
      <CriteriaAccordian
        initialOption={REGION_OPTIONS.find(({ id }) => id === criteria.region)}
        options={REGION_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('region', option.id)}
        updateCriteria={updateCriteria}
      />
      <CriteriaAccordian
        initialOption={REGION_TYPE_OPTIONS.find(({ id }) => id === criteria.regionLoadOption)}
        options={REGION_TYPE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('regionLoadOption', option.id)}
        updateCriteria={updateCriteria}
      />
      {criteria.regionLoadOption === RegionLoadOption.REGION_LOAD_PEAKS && (
        <CriteriaAccordian
          initialOption={REGION_PEAK_OPTIONS_DYNAMIC.find(({ id }) => id === criteria.yearRange)}
          options={REGION_PEAK_OPTIONS_DYNAMIC}
          onChange={(option: CriteriaOption) => updateCriteria('yearRange', option.id)}
          updateCriteria={updateCriteria}
        />
      )}
      <hr />
      {!(criteria.regionLoadOption === RegionLoadOption.REGION_LOAD_LAST_2HRS || !isViewAboveMobile) &&
        <Button className="Button--secondary--dark" label="Download CSV" onClick={handleReportDownload} />
      }
    </SelectionCriteria>
  );
};
