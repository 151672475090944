import SVGs from 'tools/utilities/svg-content';

// Environments
export const MOCK_ENVIRONMENTS = ['development', 'test'];
export const { NODE_ENV, REACT_APP_ENVIRONMENT, REACT_APP_FEATURE_BRANCH, REACT_APP_COMMIT_HASH } = process.env;
export const ENVIRONMENT = REACT_APP_ENVIRONMENT || NODE_ENV;

// Misc
export const ICONS = SVGs;

export const TRADING_INTERVAL_MINUTES = 30;
