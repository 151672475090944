import { useSelector } from 'react-redux';
import { subDays } from 'date-fns';

import './RCPDGraph.scss';

import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { getRCPD48hrData } from 'redux/modules/nodes/selectors';
import { RCPD_48HR_KEY_ITEMS } from 'redux/modules/nodes/constants';

import { formatDataForChart, formatLastUpdatedTime } from 'tools/utilities/charts';
import { FormattedMessage } from 'react-intl';
import { useDataPoller } from 'tools/hooks/useDataPoller';
import { actionGet48HrsRcpd } from 'redux/modules/nodes/actions';
import { IRCPDCriteria } from 'types';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Line,
  Tooltip,
  ReferenceLine,
  TooltipProps,
} from 'recharts';

import { formatTradingPeriodToTime } from 'tools/utilities/date';
import { scaleLinear } from 'd3-scale';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipWrapper, TooltipRow } from '../Tooltip/Tooltip';

const mobileHeight = 500;
const tabletHeight = 800;

/* Just so there is space between the max value and the top
 of the graph */
const Y_DOMAIN_OFFSET = 50;

const FIVE_MINUTES = 300000;

const Y_TRUNCATION_BUFFER = 40;

interface Props {
  criteria: IRCPDCriteria;
}
interface IDataPoint {
  date: Date;
  revenue_mw: number;
  scada_mw: number;
  timestamp: string;
  trading_period: number;
  value: number;
}

interface IGraphableDataPoint {
  trading_period: number;
  value_today: number | null;
  value_yesterday: number;
  yesterday_revenue_mw: number;
  max_peak: number; // max_peak is the same for both today and yesterday
  min_peak: number; // min_peak is the same for both today and yesterday
  avg_peak: number; // avg_peak is the same for both today and yesterday
}

// Ignore all ts file

export const RcpdGraph48hr = ({ criteria }: Props) => {
  const rcpd48hrData = useSelector(getRCPD48hrData);
  const isAboveTablet = useSelector(isViewportAboveTabletSelector);

  useDataPoller(actionGet48HrsRcpd, FIVE_MINUTES, criteria);

  if (!rcpd48hrData) {
    return null;
  }

  const lastUpdatedTime = formatLastUpdatedTime(new Date(rcpd48hrData.today[rcpd48hrData.today.length - 1].timestamp));

  const yesterdaysData: IDataPoint[] = formatDataForChart(rcpd48hrData.yesterday, 'scada_mw');
  const todaysData: IDataPoint[] = formatDataForChart(rcpd48hrData.today, 'scada_mw', (d) =>
    subDays(new Date(d.timestamp), 1),
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { max_peak, min_peak, avg_peak } = rcpd48hrData.region_totals[0];

  // Combine data points from today and yesterday into one array
  const graphableData: IGraphableDataPoint[] = yesterdaysData.map((yesterdayDataP: IDataPoint) => {
    const todayDataPoint = todaysData.find(
      (today: IDataPoint) => today.trading_period === yesterdayDataP.trading_period,
    );
    return {
      trading_period: yesterdayDataP.trading_period,
      value_today: todayDataPoint ? todayDataPoint.value : null,
      value_yesterday: yesterdayDataP.value,
      max_peak,
      min_peak,
      avg_peak,
      yesterday_revenue_mw: yesterdayDataP.revenue_mw,
    };
  });

  const maxYVal = graphableData.reduce((maxVal: number, dataPoint: IGraphableDataPoint) => {
    const maxValToday = dataPoint.value_today ? Math.max(maxVal, dataPoint.value_today) : maxVal;
    const maxValYesterday = Math.max(maxValToday, dataPoint.value_yesterday);
    const maxValRevenue = Math.max(maxValYesterday, dataPoint.yesterday_revenue_mw);
    const maxValMaxPeak = Math.max(maxValRevenue, dataPoint.max_peak);
    const maxValMinPeak = Math.max(maxValMaxPeak, dataPoint.min_peak);
    const maxValAvgPeak = Math.max(maxValMinPeak, dataPoint.avg_peak);
    return maxValAvgPeak;
  }, 0);

  const minYVal = graphableData.reduce((minVal: number, dataPoint: IGraphableDataPoint) => {
    const minValToday = dataPoint.value_today ? Math.min(minVal, dataPoint.value_today) : minVal;
    const minValYesterday = dataPoint.value_yesterday ? Math.min(minValToday, dataPoint.value_yesterday) : minValToday;
    const minValRevenue = dataPoint.yesterday_revenue_mw
      ? Math.min(minValYesterday, dataPoint.yesterday_revenue_mw)
      : minValYesterday;
    const minValMaxPeak = dataPoint.max_peak ? Math.min(minValRevenue, dataPoint.max_peak) : minValRevenue;
    const minValMinPeak = dataPoint.min_peak ? Math.min(minValMaxPeak, dataPoint.min_peak) : minValMaxPeak;
    const minValAvgPeak = dataPoint.avg_peak ? Math.min(minValMinPeak, dataPoint.avg_peak) : minValMinPeak;
    return minValAvgPeak;
  }, Number.POSITIVE_INFINITY);

  // Truncate graph for better readability. See https://transpower.atlassian.net/browse/EM6-54
  // Some values are null, which get converted to zero, so skip them for the minimum
  const yBaseline = minYVal ? minYVal - Y_TRUNCATION_BUFFER : 0;

  const yScale = scaleLinear()
    .domain([yBaseline, maxYVal + Y_DOMAIN_OFFSET])
    .range([400 * 0.75, 0]);

  const d3YTicks = yScale.ticks(400 / 90).map((d, i) => d);

  // One grid line every 3 hours (6 trading periods)
  const xTicks = graphableData
    .filter((d: IGraphableDataPoint) => d.trading_period % 6 === 0)
    .map((d: IGraphableDataPoint) => d.trading_period);

  return (
    <>
      <div className="RCPDGraph">
        <ResponsiveContainer
          width="99%"
          // height={isAboveTablet ? tabletHeight : mobileHeight}
          aspect={isAboveTablet ? 2.5 : undefined}
          height={isAboveTablet ? 'unset' : mobileHeight}
        >
          <ComposedChart margin={{ top: 20 }} data={graphableData}>
            <YAxis
              tick={{
                stroke: 'white',
                fontSize: '14px',
                strokeWidth: 0.75,
                fontFamily: 'Oswald Light oswald-light !important',
              }}
              ticks={d3YTicks}
              domain={[
                (dataMin: number) => dataMin - Y_TRUNCATION_BUFFER,
                (dataMax: number) => dataMax + Y_DOMAIN_OFFSET,
              ]}
              axisLine={false}
              tickLine={false}
              type="number"
            />
            <XAxis
              tickFormatter={(tick: number) => formatTradingPeriodToTime(tick)}
              tick={{
                stroke: 'white',
                fontSize: '14px',
                strokeWidth: 0.75,
                fontFamily: 'Oswald Light oswald-light !important',
              }}
              axisLine={false}
              tickLine={false}
              ticks={xTicks}
              domain={[1, 49]}
              type="number"
              dataKey="trading_period"
            />
            <Line
              strokeDasharray={5}
              strokeWidth={2}
              type="monotone"
              dot={false}
              dataKey="value_yesterday"
              stroke="#cbe612"
            />
            <Line key="max_peak" strokeWidth={2} type="monotone" dot={false} dataKey="max_peak" stroke="#cf0059" />
            <Line key="min_peak" strokeWidth={2} type="monotone" dot={false} dataKey="min_peak" stroke="#8552af" />
            <Line key="avg_peak" strokeWidth={2} type="monotone" dot={false} dataKey="avg_peak" stroke="#00a9a5" />
            <Line
              key="yesterday_revenue_mw"
              strokeWidth={2}
              type="monotone"
              dot={false}
              dataKey="yesterday_revenue_mw"
              stroke="#ed8b00"
            />
            {/* Make a reference line for each xTick */}
            {xTicks.map((tick: number) => (
              <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
            ))}
            {/* Make a reference line for each yTick */}
            {d3YTicks.map((tick: number) => (
              <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
            ))}
            <Area strokeWidth={2} type="monotone" dot={false} dataKey="value_today" fill="#B1E9E6" stroke="#B1E9E6" />
            <Tooltip content={CustomTooltip} />
          </ComposedChart>
        </ResponsiveContainer>
        <GraphKey useSingleColumnAtMobile keyItems={RCPD_48HR_KEY_ITEMS} />
        <p className="Dashboard-updated">
          <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
        </p>
      </div>
    </>
  );
};

const CustomTooltip = ({ payload }: TooltipProps<string, string>) => {
  if (!payload?.[0]) {
    return null;
  }
  const dataPoint: IGraphableDataPoint = payload[0].payload;
  return (
    <TooltipWrapper>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Trading Period:</strong>
        <span>{dataPoint.trading_period}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Reading:</strong>
        <span>{formatReadingWithMWOrEmpty(dataPoint.value_today)}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Yesterday:</strong>
        <span>{formatReadingWithMWOrEmpty(dataPoint.value_yesterday)}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Rev. Meter Yesterday:</strong>
        <span> {formatReadingWithMWOrEmpty(dataPoint.yesterday_revenue_mw)}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Max. TPM yearly Peak:</strong>
        <span> {formatReadingWithMWOrEmpty(dataPoint.max_peak)}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Avg. TPM yearly Peak:</strong>
        <span> {formatReadingWithMWOrEmpty(dataPoint.avg_peak)}</span>
      </TooltipRow>
      <TooltipRow>
        <strong style={{ marginRight: 20 }}>Min. TPM yearly Peak:</strong>
        <span> {formatReadingWithMWOrEmpty(dataPoint.min_peak)}</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};

// input: The reading in MW
// output the reading followed by MW eg 12 MW
// if the reading is null or undefined, return a dash
const formatReadingWithMWOrEmpty = (reading: number | null) => {
  if (reading === null || reading === undefined) {
    return '-';
  }
  return `${reading} MW`;
};
