import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';

import './TextInput.scss';

interface Props {
  id: string;
  label: string;
  type: string;
  placeholder: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
  value: string;
  className?: string;
  error?: string | boolean;
  showErrorOutLine?: boolean; // Case where we need the ability to show error outline if the input is 1 of 2 inputs
  isReadonly?: boolean;
  maxLength?: number; // Restricts number of characters that can be entered into the input field
  isPasswordReveal?: boolean;
}

export const TextInput = (props: Props) => {
  const {
    id,
    label,
    type,
    placeholder,
    onBlur = () => {},
    onChange,
    value,
    className,
    error,
    showErrorOutLine,
    isReadonly,
    maxLength,
  } = props;

  return (
    <div
      className={classNames('TextInput', className, {
        hasValue: !!value && !error,
        hasError: !!error || showErrorOutLine,
        isDisabled: isReadonly,
      })}
    >
      <label htmlFor={id}>
        <span className="TextInput-label">{label}</span>
        <span className="TextInput-inputWrapper">
          <input
            id={id}
            name={id}
            type={type}
            placeholder={placeholder}
            className="TextInput-input"
            onBlur={onBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value || '');
            }}
            value={value || ''}
            readOnly={isReadonly}
            disabled={isReadonly}
            min="0"
            maxLength={maxLength}
          />
        </span>

        {/* Error message */}
        {error && (
          <span className="TextInput-error">
            <Icon name="ic-error" className="TextInput-error-icon" size="sm" />
            {error}
          </span>
        )}
      </label>
    </div>
  );
};
