import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TYPES } from 'redux/modules/nodes/actions';
import { isLoadingIntervals as isLoadingIntervalsSelector, getIntervalsForTable } from 'redux/modules/nodes/selectors';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { REFERENCE_NODES, DEFAULT_PRICE_NODES } from 'redux/modules/nodes/constants';
import { NodeTable } from 'views/components/NodeTable/NodeTable';

export const Nodes = () => {
  const dispatch = useDispatch();
  const isLoadingIntervals = useSelector(isLoadingIntervalsSelector);
  const intervalsForTable = useSelector(getIntervalsForTable);

  useEffect(() => {
    dispatch({
      type: TYPES.GET_INTERVALS,
      payload: {
        ids: [...DEFAULT_PRICE_NODES, ...REFERENCE_NODES],
      },
    });
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        id: 'test',
        Header: 'Last 15 minutes ($/MWh)',
        columns: intervalsForTable && intervalsForTable.columns,
      },
    ],
    [intervalsForTable],
  );

  const data = useMemo(() => (intervalsForTable ? [...intervalsForTable.rows] : []), [intervalsForTable]);

  if (isLoadingIntervals) {
    return <p>Loading nodes...</p>;
  }

  if (intervalsForTable?.rows.length === 0) {
    return null;
  }

  const defaultNodesIntervals = data.filter((interval: any) => DEFAULT_PRICE_NODES.indexOf(interval.node) !== -1);
  const referenceNodesIntervals = data.filter((interval: any) => REFERENCE_NODES.indexOf(interval.node) !== -1);

  return (
    <div className="Container">
      <RouteTransition>
        <div className="Dashboard">
          <div className="Dashboard-content">
            <div className="Dashboard-content-head">
              <h3>Testing</h3>
            </div>
            <div className="Dashboard-content-wrapper">
              <NodeTable columns={columns} data={defaultNodesIntervals} />
              <NodeTable columns={columns} data={referenceNodesIntervals} />
            </div>
          </div>
        </div>
      </RouteTransition>
    </div>
  );
};
