import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';

import {
  NZ_SUMMARY_LAST_24_HRS_KEY_ITEMS,
  NZ_SUMMARY_LAST_24_LABEL_TO_COLOR,
} from 'redux/modules/nodes/constants';

import { scaleLinear } from 'd3-scale';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { FormattedMessage } from 'react-intl';
import { formatTradingPeriodToTime } from 'tools/utilities/date';
import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { getNzSummaryLast24Hrs } from 'redux/modules/nodes/selectors';
import { formatEndOfTradingPeriod, formatTimestamp } from 'tools/utilities/charts';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipRow, TooltipWrapper, BoldTooltipText } from '../Tooltip/Tooltip';

const MOBILE_HEIGHT = 620;

const CustomTooltip = ({ payload }: any) => {
  if (!payload?.[0]) {
    return null;
  }

  const { niMw, siMw, niMw1wk, siMw1wk, ni7DayMax, si7DayMax, timestamp, tradingPeriod } = payload?.[0]?.payload ?? {};

  return (
    <TooltipWrapper>
      <TooltipRow>
        <span>{timestamp && formatTimestamp(new Date(timestamp))} - TP{tradingPeriod}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="NI Load:" />
        <span>{niMw ? `${roundToDpWithCommas(niMw, 0)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="NI Load - 1 Wk:" />
        <span>{niMw1wk ? `${roundToDpWithCommas(niMw1wk, 0)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="Corresponding 7 Day NI Peak:" />
        <span>{ni7DayMax ? `${roundToDpWithCommas(ni7DayMax, 1)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="SI Load:" />
        <span>{siMw ? `${roundToDpWithCommas(siMw, 0)} MW` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="SI Load - 1 WK:" />
        <span>{siMw1wk ? `${roundToDpWithCommas(siMw1wk, 0)} MW` : '-'}</span>
      </TooltipRow>
       <TooltipRow>
        <BoldTooltipText style={{ marginRight: 20 }} text="Corresponding 7 Day SI Peak:" />
        <span>{si7DayMax ? `${roundToDpWithCommas(si7DayMax, 1)} MW` : '-'}</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};


export const NzIsLandLoadLast24Hr = () => {

  const nzSummaryLast24Hr = useSelector(getNzSummaryLast24Hrs);
  const isAboveTablet = useSelector(isViewportAboveTabletSelector);

  const data = nzSummaryLast24Hr?.items;
  if (!data || !data.length) {
    return null;
  }

  const lastUpdatedTime = formatEndOfTradingPeriod(new Date(data[data.length - 1].timestamp));

  const yValues = data.reduce((acc: any, {niMw, siMw, niMw1wk, siMw1wk, ni7DayMax, si7DayMax}: any) => ([
    ...acc,
    niMw,
    siMw,
    niMw1wk,
    siMw1wk,
    ni7DayMax,
    si7DayMax,
  ]), [])

  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)
  const offset = (yMax - yMin) / 10
  const yScale = scaleLinear().domain([yMin - offset, yMax + offset])
  const yTicks = yScale.ticks(4)
  const xTicks:any = _.uniq(data.filter(({tradingPeriod}: any) => tradingPeriod % 6 === 0).map(({tradingPeriod}: any) => tradingPeriod))

  return (
    <div>
      <ResponsiveContainer
        width="99%"
        aspect={isAboveTablet ? 1 : undefined}
        height={isAboveTablet ? 'unset' : MOBILE_HEIGHT}
      >
        <LineChart
          data={data}
          margin={{
            top: 24,
            right: 0,
            left: 0,
            bottom: 16,
          }}
        >
          <XAxis
            ticks={xTicks}
            tickFormatter={(tick: number) => formatTradingPeriodToTime(tick)}
            tickLine={false}
            axisLine={false}
            width={100}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            dataKey="tradingPeriod"
          />
          <YAxis
            ticks={yTicks}
            domain={['dataMin', 'auto']}
            tickLine={false}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            axisLine={false}
            type="number"
          />
          <Tooltip
            labelFormatter={(label: any, payload: any) => (
              <span>
                {moment(new Date(payload[0]?.payload?.timestamp)).format('DD/MM/YYYY')} - TP{payload[0]?.payload.tp}
              </span>
            )}
            content={CustomTooltip}
          />
          <Line type="linear" dataKey='niMw' stroke={NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.NI} dot={false} strokeWidth={2} />
          <Line type="linear" dataKey='siMw' stroke={NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.SI} dot={false} strokeWidth={2} />
          <Line type="linear" dataKey='niMw1wk' stroke={NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.NI} dot={false} strokeWidth={2} strokeDasharray="5 4" />
          <Line type="linear" dataKey='siMw1wk' stroke={NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.SI} dot={false} strokeWidth={2} strokeDasharray="5 4" />

          {xTicks.map((tick: number) => (
            <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
          {yTicks.map((tick: number) => (
            <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <GraphKey keyItems={NZ_SUMMARY_LAST_24_HRS_KEY_ITEMS} />
      <p className="Dashboard-updated">
        <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
      </p>
    </div>
  )

}