import { format } from 'date-fns';
import { DateFilterOption } from 'enums.d';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  isViewportAboveTablet as isViewportAboveTabletSelector,
  isViewportAboveDesktop as isViewportAboveDesktopSelector,
  isViewportAboveWidescreen as isViewportAboveWidescreenSelector,
  isViewportAboveUltraWidescreen as isViewportAboveUltraWidescreenSelector,
} from 'redux/modules/app/selectors';
import { COLOURS } from 'redux/modules/nodes/constants';
import { formatDateStringForDate } from './date';

export const GWAP_GRAPH_KEYS = [
  { colour: '#cf0059', fill: '#cf0059', label: 'Diesel/Oil', graphName: 'liq' },
  { colour: '#6388d7', fill: '#6388d7', label: 'Battery', graphName: 'bat' },
  { colour: '#ed8b00', fill: '#ed8b00', label: 'Coal', graphName: 'cg' },
  { colour: '#b1e9e6', fill: '#b1e9e6', label: 'Wind', graphName: 'win' },
  { colour: '#8552af', fill: '#8552af', label: 'Hydro', graphName: 'hyd' },
  { colour: '#C6C6C6', fill: '#C6C6C6', label: 'Gas', graphName: 'gas' },
  { colour: '#00a9a5', fill: '#00a9a5', label: 'Cogen', graphName: 'cog' },
  { colour: '#cbe612', fill: '#cbe612', label: 'Geothermal', graphName: 'geo' },
  { colour: '#FFD52D', fill: '#FFD52D', label: 'Solar', graphName: 'sol' },
];

export enum GwapTimeInterval {
  THIRTY_MINS = '30minute',
  DAILY = 'daily',
  MONTHLY = 'monthly',
}

interface IGwapDto {
  generation_mw: number;
  generation_type: any[];
  grid_zone_id: number;
  grid_zone_name: string;
  trading_date: string;
}

interface IFreeGwapRecord {
  grid_zone_id: string;
  time: string;
  bat_wap: number | undefined;
  bat_mw: number | undefined;
  cg_wap: number | undefined;
  cg_mw: number | undefined;
  cog_wap: number | undefined;
  cog_mw: number | undefined;
  gas_wap: number | undefined;
  gas_mw: number | undefined;
  geo_wap: number | undefined;
  geo_mw: number | undefined;
  hyd_wap: number | undefined;
  hyd_mw: number | undefined;
  win_wap: number | undefined;
  win_mw: number | undefined;
  sol_wap: number | undefined;
  sol_mw: number | undefined;
}

export enum GwapGenerationType {
  BAT = 'bat',
  CG = 'cg',
  COG = 'cog',
  GAS = 'gas',
  GEO = 'geo',
  HYD = 'hyd',
  WIN = 'win',
  LIQ = 'liq',
  SOL = 'sol',
}

export const ALL_GEN_TYPES = [
  GwapGenerationType.BAT,
  GwapGenerationType.CG,
  GwapGenerationType.COG,
  GwapGenerationType.GAS,
  GwapGenerationType.GEO,
  GwapGenerationType.HYD,
  GwapGenerationType.WIN,
  GwapGenerationType.LIQ,
  GwapGenerationType.SOL,
];

export enum GwapLocation {
  NORTHLAND = '1',
  AUCKLAND = '2',
  HAMILTON = '3',
  EDGECUMBE = '4',
  HAWKES_BAY = '5',
  TARANAKI = '6',
  BUNNYTHORPE = '7',
  WELLINGTON = '8',
  NELSON = '9',
  CHRISTCHURCH = '10',
  CANTERBURY = '11',
  WEST_COAST = '12',
  OTAGO = '13',
  SOUTHLAND = '14',
  ALL = '15',
}

export const ALL_GWAP_LOCATIONS = [
  GwapLocation.NORTHLAND,
  GwapLocation.AUCKLAND,
  GwapLocation.HAMILTON,
  GwapLocation.EDGECUMBE,
  GwapLocation.HAWKES_BAY,
  GwapLocation.TARANAKI,
  GwapLocation.BUNNYTHORPE,
  GwapLocation.WELLINGTON,
  GwapLocation.NELSON,
  GwapLocation.CHRISTCHURCH,
  GwapLocation.CANTERBURY,
  GwapLocation.WEST_COAST,
  GwapLocation.OTAGO,
  GwapLocation.SOUTHLAND,
];

export interface IGwapGenerationTypeOption {
  label: string;
  generationType: GwapGenerationType;
  color: string;
}

export const GWAP_GENERATION_TYPE_OPTIONS = [
  { label: 'Hydro', generationType: GwapGenerationType.HYD, color: '#8552af' },
  { label: 'Wind', generationType: GwapGenerationType.WIN, color: '#b1e9e6' },
  { label: 'Geothermal', generationType: GwapGenerationType.GEO, color: '#cbe612' },
  { label: 'Cogen', generationType: GwapGenerationType.COG, color: '#00a9a5' },
  { label: 'Battery', generationType: GwapGenerationType.BAT, color: '#6388d7' },
  { label: 'Gas', generationType: GwapGenerationType.GAS, color: '#C6C6C6' },
  { label: 'Coal', generationType: GwapGenerationType.CG, color: '#ed8b00' },
  { label: 'Diesel/Oil', generationType: GwapGenerationType.LIQ, color: '#cf0059' },
  { label: 'Solar', generationType: GwapGenerationType.SOL, color: '#ffd52d' },
];

export interface IDownshiftGwapGenerationTypeOption {
  label: string;
  generationType: GwapGenerationType;
  color: string;
}

export interface IGwapLocationOption {
  label: string;
  location: GwapLocation;
}

type ILabelForLocation = {
  [key in GwapLocation]?: string;
};

export const LABEL_FOR_LOCATION: ILabelForLocation = {
  [GwapLocation.NORTHLAND]: 'Northland',
  [GwapLocation.AUCKLAND]: 'Auckland',
  [GwapLocation.HAMILTON]: 'Hamilton',
  [GwapLocation.EDGECUMBE]: 'Edgecumbe',
  [GwapLocation.HAWKES_BAY]: "Hawke's Bay",
  [GwapLocation.TARANAKI]: 'Taranaki',
  [GwapLocation.BUNNYTHORPE]: 'Bunnythorpe',
  [GwapLocation.WELLINGTON]: 'Wellington',
  [GwapLocation.NELSON]: 'Nelson',
  [GwapLocation.CHRISTCHURCH]: 'Christchurch',
  [GwapLocation.CANTERBURY]: 'Canterbury',
  [GwapLocation.WEST_COAST]: 'West Coast',
  [GwapLocation.OTAGO]: 'Otago',
  [GwapLocation.SOUTHLAND]: 'Southland',
};

export const GWAP_LOCATION_OPTIONS: IGwapLocationOption[] = [
  { label: 'Northland', location: GwapLocation.NORTHLAND },
  { label: 'Auckland', location: GwapLocation.AUCKLAND },
  { label: 'Hamilton', location: GwapLocation.HAMILTON },
  { label: 'Edgecumbe', location: GwapLocation.EDGECUMBE },
  { label: "Hawke's Bay", location: GwapLocation.HAWKES_BAY },
  { label: 'Taranaki', location: GwapLocation.TARANAKI },
  { label: 'Bunnythorpe', location: GwapLocation.BUNNYTHORPE },
  { label: 'Wellington', location: GwapLocation.WELLINGTON },
  { label: 'Nelson', location: GwapLocation.NELSON },
  { label: 'Christchurch', location: GwapLocation.CHRISTCHURCH },
  { label: 'Canterbury', location: GwapLocation.CANTERBURY },
  { label: 'West Coast', location: GwapLocation.WEST_COAST },
  { label: 'Otago', location: GwapLocation.OTAGO },
  { label: 'Southland', location: GwapLocation.SOUTHLAND },
];

export const formatGwapXAxisDate = (data: any, interval?: GwapTimeInterval) => {
  if (interval !== GwapTimeInterval.THIRTY_MINS) {
    return moment(data).format('DD MMM');
  }
  return moment(data).format('HH:mm');
};

// Data coming in from the api needs to be flattened in preparation for graphing
// Nesting structure of API data can be seen in the mockRawData provided below
export const normaliseGwapData = (data: IGwapDto[]): IFreeGwapRecord[] => {
  const newData: any[] = [];

  data.map((dto: IGwapDto) => {
    let flatRecord = { time: dto.trading_date, grid_zone_id: dto.grid_zone_id };
    dto.generation_type.map((genType) => {
      flatRecord = { ...flatRecord, ...genType };
      return null;
    });
    newData.push(flatRecord);
    return null;
  });
  return newData;
};

const SUMMARY_DATE_FORMAT = 'do MMM';
const formatDateForGWapSummary = (date: string) => {
  const dateFormattedForDateParsing = formatDateStringForDate(date);
  const dateAsDate = new Date(dateFormattedForDateParsing);
  return format(dateAsDate, SUMMARY_DATE_FORMAT);
};

export const getGWapDateRange = (from: string | null, to: string | null) => {
  if (from && to) {
    const fromDateFormatted = formatDateForGWapSummary(from);
    const toDateFormatted = formatDateForGWapSummary(to);

    return `${fromDateFormatted} - ${toDateFormatted}`;
  }

  if (from) {
    const fromDateFormatted = formatDateForGWapSummary(from);
    return `at ${fromDateFormatted}`;
  }

  if (to) {
    const toDateFormatted = formatDateForGWapSummary(to);
    return `at ${toDateFormatted}`;
  }

  return null;
};

const getGwapLocationOption = (location: GwapLocation) =>
  GWAP_LOCATION_OPTIONS.find((gwapLocationOption: IGwapLocationOption) => gwapLocationOption.location === location);

export const getGwapLocationLabel = (location: GwapLocation) => getGwapLocationOption(location)?.label;

export const getGwapGenTypeLabel = (genType: GwapGenerationType) =>
  GWAP_GENERATION_TYPE_OPTIONS.find(
    (gwapGenTypeOption: IGwapGenerationTypeOption) => gwapGenTypeOption.generationType === genType,
  )?.label;

export const COLOUR_FOR_GEN_TYPE = {
  [GwapGenerationType.WIN]: COLOURS.WIND_TEAL,
  [GwapGenerationType.CG]: COLOURS.SOLAR_ORANGE,
  [GwapGenerationType.COG]: COLOURS.MINERAL_TEAL,
  [GwapGenerationType.LIQ]: COLOURS.CURRENT_PINK,
  [GwapGenerationType.GEO]: COLOURS.RADIOACTIVE_GREEN,
  [GwapGenerationType.HYD]: COLOURS.OIL_PURPLE,
  [GwapGenerationType.GAS]: '#C6C6C6',
  [GwapGenerationType.BAT]: '#6388d7',
  [GwapGenerationType.SOL]: '#FFD52D',
};

export const GWAP_BY_TYPE_SUMMARY_LABELS = [
  { label: 'Wind', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.WIN], generationType: GwapGenerationType.WIN },
  { label: 'Coal', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.CG], generationType: GwapGenerationType.CG },
  { label: 'Cogen', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.COG], generationType: GwapGenerationType.COG },
  { label: 'Diesel/Oil', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.LIQ], generationType: GwapGenerationType.LIQ },
  { label: 'Geothermal', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.GEO], generationType: GwapGenerationType.GEO },
  { label: 'Hydro', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.HYD], generationType: GwapGenerationType.HYD },
  { label: 'Gas', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.GAS], generationType: GwapGenerationType.GAS },
  { label: 'Battery', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.BAT], generationType: GwapGenerationType.BAT },
  { label: 'Solar', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.SOL], generationType: GwapGenerationType.SOL },
];

export const NZ_GENERATION_SUMMARY_LABELS = [
  { label: 'Wind', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.WIN], generationType: GwapGenerationType.WIN },
  { label: 'Coal', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.CG], generationType: GwapGenerationType.CG },
  { label: 'Cogen', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.COG], generationType: GwapGenerationType.COG },
  { label: 'Diesel/Oil', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.LIQ], generationType: GwapGenerationType.LIQ },
  { label: 'Geothermal', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.GEO], generationType: GwapGenerationType.GEO },
  { label: 'Hydro', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.HYD], generationType: GwapGenerationType.HYD },
  { label: 'Gas', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.GAS], generationType: GwapGenerationType.GAS },
  { label: 'Battery', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.BAT], generationType: GwapGenerationType.BAT },
  { label: 'Solar', colour: COLOUR_FOR_GEN_TYPE[GwapGenerationType.SOL], generationType: GwapGenerationType.SOL },
];

export interface IGwapDateFilterOption {
  value: DateFilterOption;
  label: string;
}

export const GWAP_DATE_FILTER_OPTIONS: IGwapDateFilterOption[] = [
  { value: DateFilterOption.YESTERDAY, label: 'Yesterday' },
  { value: DateFilterOption.PREVIOUS_3_DAYS, label: 'Last 3 days' },
  { value: DateFilterOption.PREVIOUS_7_DAYS, label: 'Last 7 days' },
  { value: DateFilterOption.MONTH_TO_DATE, label: 'Month to date' },
  { value: DateFilterOption.PREVIOUS_MONTH, label: 'Last month' },
  { value: DateFilterOption.YEAR_TO_DATE, label: 'Year to date' },
  { value: DateFilterOption.PREVIOUS_YEAR, label: 'Previous year' },
  { value: DateFilterOption.ALL, label: 'All' },
  { value: DateFilterOption.CUSTOM, label: 'Custom' },
];

export const GenTypeToString = {
  [GwapGenerationType.BAT]: 'Battery',
  [GwapGenerationType.COG]: 'Cogen',
  [GwapGenerationType.CG]: 'Coal',
  [GwapGenerationType.GAS]: 'Gas',
  [GwapGenerationType.GEO]: 'Geothermal',
  [GwapGenerationType.HYD]: 'Hydro',
  [GwapGenerationType.LIQ]: 'Diesel/Oil',
  [GwapGenerationType.WIN]: 'Wind',
  [GwapGenerationType.SOL]: 'Solar',
};

export const PaidGwapGraphMargins = (isViewportAboveTablet: boolean, isBottomGraph: boolean = false) => {
  if (isViewportAboveTablet) {
    return {
      top: 0,
      right: 24,
      left: 24,
      bottom: isBottomGraph ? 0 : 32,
    };
  }
  return {
    top: 0,
    right: 24,
    left: 0,
    bottom: 30,
  };
};

const usePaidGwapGraphHeight = () => {
  const isViewportAboveTablet = useSelector(isViewportAboveTabletSelector);

  if (isViewportAboveTablet) {
    return 246;
  }

  return 190;
};

const usePaidGwapGraphWidth = () => {
  const isViewportAboveTablet = useSelector(isViewportAboveTabletSelector);
  const isViewportAboveDesktop = useSelector(isViewportAboveDesktopSelector);
  const isViewportAboveWidescreen = useSelector(isViewportAboveWidescreenSelector);
  const isViewportAboveUltraWidescreen = useSelector(isViewportAboveUltraWidescreenSelector);

  if (isViewportAboveUltraWidescreen) {
    return 840;
  }

  if (isViewportAboveWidescreen) {
    return 600;
  }

  if (isViewportAboveDesktop) {
    return 600;
  }

  if (isViewportAboveTablet) {
    return 500;
  }

  return '100%';
};

export interface IGwapBarDimensions {
  width: number | string;
  height: number;
}

export const useGwapBarDimensions = (): IGwapBarDimensions => ({
  height: usePaidGwapGraphHeight(),
  width: usePaidGwapGraphWidth(),
});

export const getColourArrayForGenerationTypes = (genTypes: GwapGenerationType[]): string[] =>
  genTypes.map((genType: GwapGenerationType) => COLOUR_FOR_GEN_TYPE[genType]);

export const ALLOWED_INTERVALS_FOR_DATE_RANGE: Record<DateFilterOption, GwapTimeInterval[]> = {
  [DateFilterOption.ALL]: [GwapTimeInterval.MONTHLY],
  [DateFilterOption.PREVIOUS_YEAR]: [GwapTimeInterval.DAILY, GwapTimeInterval.MONTHLY],
  [DateFilterOption.YEAR_TO_DATE]: [GwapTimeInterval.DAILY, GwapTimeInterval.MONTHLY],
  [DateFilterOption.PREVIOUS_MONTH]: [GwapTimeInterval.THIRTY_MINS, GwapTimeInterval.DAILY],
  [DateFilterOption.MONTH_TO_DATE]: [GwapTimeInterval.THIRTY_MINS, GwapTimeInterval.DAILY],
  [DateFilterOption.PREVIOUS_7_DAYS]: [GwapTimeInterval.THIRTY_MINS],
  [DateFilterOption.PREVIOUS_3_DAYS]: [GwapTimeInterval.THIRTY_MINS],
  [DateFilterOption.YESTERDAY]: [GwapTimeInterval.THIRTY_MINS],
  [DateFilterOption.TODAY]: [GwapTimeInterval.THIRTY_MINS],
  [DateFilterOption.CUSTOM]: [GwapTimeInterval.MONTHLY, GwapTimeInterval.DAILY, GwapTimeInterval.THIRTY_MINS],
};

export const doesDateRangeHaveMonthOption = (dateFilter: DateFilterOption) =>
  ALLOWED_INTERVALS_FOR_DATE_RANGE[dateFilter].includes(GwapTimeInterval.MONTHLY);

export const doesDateRangeHaveDailyOption = (dateFilter: DateFilterOption) =>
  ALLOWED_INTERVALS_FOR_DATE_RANGE[dateFilter].includes(GwapTimeInterval.DAILY);

export const doesDateRangeHave30MOption = (dateFilter: DateFilterOption) =>
  ALLOWED_INTERVALS_FOR_DATE_RANGE[dateFilter].includes(GwapTimeInterval.THIRTY_MINS);

export const getGraphEntryForTimestamp = (timestamp: string, entries: any[]) =>
  !entries ? {} : entries.find((entry) => entry.timestamp === timestamp);
