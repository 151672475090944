// NOTE: This is a generated file, nothing you do here matters
// The source of the all.svg file is located at './src/all.svg'
// The script that generates this file is located at tools/svg-generator.js
// To rebuild this file run 'yarn run generate-svgs'
import React from 'react';

export default {
  'ic-add': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-add" />
    </svg>
  ),
  'ic-calendar': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-calendar" />
    </svg>
  ),
  'ic-chevron-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-chevron-blue" />
    </svg>
  ),
  'ic-chevron': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-chevron" />
    </svg>
  ),
  'ic-close': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-close" />
    </svg>
  ),
  'ic-collapse': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-collapse" />
    </svg>
  ),
  'ic-down-arrow': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-down-arrow" />
    </svg>
  ),
  'ic-error-warn': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use href="#ic-error-warn" />
    </svg>
  ),
  'ic-error': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use href="#ic-error" />
    </svg>
  ),
  'ic-expand': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-expand" />
    </svg>
  ),
  'ic-fail': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <use href="#ic-fail" />
    </svg>
  ),
  'ic-hide-password': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-hide-password" />
    </svg>
  ),
  'ic-indeterminate': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use href="#ic-indeterminate" />
    </svg>
  ),
  'ic-info': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use href="#ic-info" />
    </svg>
  ),
  'ic-key': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-key" />
    </svg>
  ),
  'ic-logo-large': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 26">
      <use href="#ic-logo-large" />
    </svg>
  ),
  'ic-logo-small-ems': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 20">
      <use href="#ic-logo-small-ems" />
    </svg>
  ),
  'ic-menu-constraints-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-constraints-normal" />
    </svg>
  ),
  'ic-menu-constraints-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-constraints-selected" />
    </svg>
  ),
  'ic-menu-generation-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-generation-normal" />
    </svg>
  ),
  'ic-menu-generation-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-generation-selected" />
    </svg>
  ),
  'ic-menu-home-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-home-normal" />
    </svg>
  ),
  'ic-menu-home-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-home-selected" />
    </svg>
  ),
  'ic-menu-load-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-load-normal" />
    </svg>
  ),
  'ic-menu-load-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-load-selected" />
    </svg>
  ),
  'ic-menu-logout': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-logout" />
    </svg>
  ),
  'ic-menu-report-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-report-normal" />
    </svg>
  ),
  'ic-menu-report-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-report-selected" />
    </svg>
  ),
  'ic-menu-reserves-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-reserves-normal" />
    </svg>
  ),
  'ic-menu-reserves-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-reserves-selected" />
    </svg>
  ),
  'ic-menu-rtp-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-rtp-normal" />
    </svg>
  ),
  'ic-menu-rtp-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-rtp-selected" />
    </svg>
  ),
  'ic-menu-wind-normal': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-wind-normal" />
    </svg>
  ),
  'ic-menu-wind-selected': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <use href="#ic-menu-wind-selected" />
    </svg>
  ),
  'ic-remove-all': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-remove-all" />
    </svg>
  ),
  'ic-remove': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-remove" />
    </svg>
  ),
  'ic-search': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use href="#ic-search" />
    </svg>
  ),
  'ic-see-password-white': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-see-password-white" />
    </svg>
  ),
  'ic-see-password': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-see-password" />
    </svg>
  ),
  'ic-small': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 20">
      <use href="#ic-small" />
    </svg>
  ),
  'ic-success': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <use href="#ic-success" />
    </svg>
  ),
  'ic-tick': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use href="#ic-tick" />
    </svg>
  ),
  'ic-triangle': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-triangle" />
    </svg>
  ),
  'ic-undo': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use href="#ic-undo" />
    </svg>
  ),
  'ic-up-arrow': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use href="#ic-up-arrow" />
    </svg>
  ),
  'illustration-large-oops': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 566 683">
      <use href="#illustration-large-oops" />
    </svg>
  ),
  'renewable-oval': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <use href="#renewable-oval" />
    </svg>
  ),
};
