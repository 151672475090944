import { ILegendRange, IKeyItem } from 'types.d';
import { GenerationType, Regions, RunType  } from 'enums.d';

export const { REACT_APP_API_BASE_URL } = process.env;

export const COLOURS = {
  MINERAL_TEAL: '#00A9A5',
  ICE_TEAL: '#6FD1CF',
  WIND_TEAL: '#B1E9E6',

  POWERSTATION_BLACK: '#003B59',
  DAM_DARK_BLUE: '#003B59',
  HEADER_BLUE: '#0C3345',

  OIL_PURPLE: '#8552AF',
  CURRENT_PINK: '#CF0059',
  BATTERY_RED: '#CF0025',
  SOLAR_ORANGE: '#ED8B00',
  ELECTRIC_YELLOW: '#FFD52D',
  RADIOACTIVE_GREEN: '#CBE612',

  LOAD_GRAPH_BLUE: '#0076A9',
  LOAD_GRAPH_GREEN: '#00A976',
};

/**
 * These are the nodes that are queried by default once the user has logged into the site.
 * The reference nodes are the ones that are taking as a guide to determine if a certain grid zone or other nodes are
 * either more or less expensive.
 * Default nodes are the "most popular ones" and are displayed by default since they are assumed to be useful to most users.
 */
export const REFERENCE_NODES = ['BEN2201', 'HAY2201', 'OTA2201'];

export const DEFAULT_PRICE_NODES = [
  'BRB0331',
  'OTA2201',
  'HAM2201',
  'WKM2201',
  'RDF2201',
  'TUI1101',
  'SFD2201',
  'BPE2201',
  'HAY2201',
  'KIK2201',
  'ISL2201',
  'DOB0661',
  'BEN2201',
  'INV2201',
  'HWB2201',
];

// Custom filtering selection when filtering price nodes
export const FTR_NODES = ['BEN2201', 'HAY2201', 'INV2201', 'ISL2201', 'KIK2201', 'OTA2201', 'RDF2201', 'WKM2201'];

export const DEFAULT_LOAD_NODES = [
  'MPE1101',
  'PEN0331',
  'HAM0331',
  'EDG0331',
  'RDF0331',
  'TUI1101',
  'SFD0331',
  'BPE0331',
  'CPK0331',
  'STK0331',
  'ISL0661',
  'GYM0661',
  'OAM0331',
  'INV0331',
  'HWB0331',
];

export const HVDC_NODES = ['DCN3502', 'DCN3503', 'DCS3502', 'DCS3503'];

export const STROKE_LINE_STYLES = {
  strokeDasharray: '5px',
  strokeWidth: '1px',
  fill: 'none',
};

const KEY_STROKE_LINE_STYLES = {
  strokeDasharray: '3px',
  strokeWidth: '1px',
  fill: 'none',
};

export const PRICE_RANGES: ILegendRange[] = [
  {
    colour: '#8552af',
    label: '< $50',
    max: 49.9999,
    order: 1,
  },
  {
    textColour: '#002030',
    colour: '#b1e9e6',
    label: '$50 - $100',
    max: 100,
    order: 4,
  },
  {
    colour: '#00a9a5',
    label: '$100 - $150',
    max: 150,
    order: 2,
  },
  {
    colour: '#cbe612',
    textColour: '#002030',
    label: '$150 - $200',
    max: 200,
    order: 5,
  },
  {
    colour: '#ed8b00',
    label: '$200 - $250',
    max: 250,
    order: 3,
  },
  {
    colour: '#cf0059',
    label: '> $250',
    max: Infinity,
    order: 6,
  },
];

export const LOAD_RANGES: ILegendRange[] = [
  {
    colour: '#8552af',
    label: '0 - 20%',
    max: 20,
    order: 1,
  },
  {
    colour: '#b1e9e6',
    label: '21 - 40%',
    max: 40,
    order: 4,
  },
  {
    colour: '#00a9a5',
    label: '41 - 60%',
    max: 60,
    order: 2,
  },
  {
    colour: '#cbe612',
    textColour: '#002030',
    label: '61 - 80%',
    max: 80,
    order: 5,
  },
  {
    colour: '#ed8b00',
    label: '81 - 100%',
    max: 200,
    order: 3,
  },
  {
    colour: '#cf0059',
    label: '> 100%',
    max: Infinity,
    order: 6,
  },
];

export const RCPD_48HR_KEY_ITEMS: IKeyItem[] = [
  {
    colour: '#cf0059',
    fill: '#cf0059',
    label: 'Max. TPM yearly peak',
  },
  {
    colour: '#00a9a5',
    fill: '#00a9a5',
    label: 'Avg. TPM yearly peak',
  },
  {
    colour: '#8552af',
    fill: '#8552af',
    label: 'Min. TPM yearly peak',
  },
  {
    colour: '#b1e9e6',
    fill: '#b1e9e6',
    label: 'Today',
  },
  {
    colour: '#cbe612',
    fill: 'none',
    label: 'Yesterday',
    style: KEY_STROKE_LINE_STYLES,
  },
  {
    colour: '#ed8b00',
    fill: '#ed8b00',
    label: 'Revenue meter yesterday',
  },
];

export const RCPD_2HR_KEY_ITEMS: IKeyItem[] = [
  {
    colour: '#b1e9e6',
    fill: '#b1e9e6',
    label: 'Current Load',
  },
  {
    colour: '#ed8b00',
    fill: '#ed8b00',
    label: 'Current Trading Period Average Load',
  },
];

export const NZ_SUMMARY_LAST_24_LABEL_TO_COLOR: { [keyof: string]: string } = {
  NI: '#ed8b00',
  SI: '#B1E9E6',
};

export const NZ_SUMMARY_LAST_24_HRS_KEY_ITEMS: IKeyItem[] = [
  {
    label: 'NI',
    colour: NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.NI,
    fill: NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.NI,
  },
  {
    label: 'NI - 1 week',
    colour: NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.NI,
    style: KEY_STROKE_LINE_STYLES,
    fill: 'none',
  },
  {
    label: 'SI',
    colour: NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.SI,
    fill: NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.SI,
  },
  {
    label: 'SI - 1 week',
    colour: NZ_SUMMARY_LAST_24_LABEL_TO_COLOR.SI,
    style: KEY_STROKE_LINE_STYLES,
    fill: 'none',
  },
];

export const HVDC_LAST_24_COLORS: { [keyof: string]: string } = {
  SOUTH_TO_NORTH: '#B1E9E6',
  NORTH_TO_SOUTH: '#CF0059',
};

export const HVDC_LAST_24_HRS_KEY_ITEMS: IKeyItem[] = [
  {
    label: 'North Flow',
    colour: HVDC_LAST_24_COLORS.SOUTH_TO_NORTH,
    fill: HVDC_LAST_24_COLORS.SOUTH_TO_NORTH,
  },
  {
    label: 'South Flow',
    colour: HVDC_LAST_24_COLORS.NORTH_TO_SOUTH,
    fill: HVDC_LAST_24_COLORS.NORTH_TO_SOUTH,
  },
];

export const GENERATION_BY_TYPE_LAST_24_HRS_COLORS: { [keyof: string]: string } = {
  [GenerationType.HYDRO]: '#8552AF',
  [GenerationType.GEOTHERMAL]: '#CBE612',
  [GenerationType.GAS]: '#C6C6C6',
  [GenerationType.DIESEL_OIL]: '#CF0059',
  [GenerationType.WIND]: '#B1E9E6',
  [GenerationType.CO_GEN]: '#00a9a5',
  [GenerationType.COAL_GAS]: '#ED8B00',
  [GenerationType.SOLAR]: '#FFD52D',
  [GenerationType.BATTERY]: '#6388d7',
};

export const GENERATION_BY_TYPE_LAST_24_HRS_KEY_ITEMS: IKeyItem[] = [
  {
    key: GenerationType.HYDRO,
    label: 'Hydro',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.HYDRO],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.HYDRO],
  },
  {
    key: GenerationType.GEOTHERMAL,
    label: 'Geothermal',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.GEOTHERMAL],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.GEOTHERMAL],
  },
  {
    key: GenerationType.GAS,
    label: 'Gas',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.GAS],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.GAS],
  },
  {
    key: GenerationType.DIESEL_OIL,
    label: 'Diesel/Oil',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.DIESEL_OIL],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.DIESEL_OIL],
  },
  {
    key: GenerationType.WIND,
    label: 'Wind',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.WIND],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.WIND],
  },
  {
    key: GenerationType.CO_GEN,
    label: 'Cogen',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.CO_GEN],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.CO_GEN],
  },
  {
    key: GenerationType.COAL_GAS,
    label: 'Coal',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.COAL_GAS],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.COAL_GAS],
  },
  {
    key: GenerationType.SOLAR,
    label: 'Solar',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.SOLAR],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.SOLAR],
  },
  {
    key: GenerationType.BATTERY,
    label: 'Battery',
    colour: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.BATTERY],
    fill: GENERATION_BY_TYPE_LAST_24_HRS_COLORS[GenerationType.BATTERY],
  },
];

export const RESIDUAL_COLORS: { [keyof: string]: string } = {
  [`${Regions.NORTH_ISLAND}_${RunType.NRS}`]: COLOURS.OIL_PURPLE,
  [`${Regions.NORTH_ISLAND}_${RunType.PRS}`]: COLOURS.MINERAL_TEAL,
  [`${Regions.NORTH_ISLAND}_${RunType.RTD}`]: COLOURS.LOAD_GRAPH_GREEN,
  [`${Regions.NORTH_ISLAND}_${RunType.WDS}`]: COLOURS.ELECTRIC_YELLOW,

  [`${Regions.SOUTH_ISLAND}_${RunType.NRS}`]: COLOURS.ICE_TEAL,
  [`${Regions.SOUTH_ISLAND}_${RunType.PRS}`]: COLOURS.CURRENT_PINK,
  [`${Regions.SOUTH_ISLAND}_${RunType.RTD}`]: '#C6C6C6',
  [`${Regions.SOUTH_ISLAND}_${RunType.WDS}`]: COLOURS.SOLAR_ORANGE,

  [`${Regions.NEW_ZEALAND}_${RunType.NRS}`]: COLOURS.BATTERY_RED,
  [`${Regions.NEW_ZEALAND}_${RunType.PRS}`]: COLOURS.WIND_TEAL,
  [`${Regions.NEW_ZEALAND}_${RunType.RTD}`]: COLOURS.LOAD_GRAPH_BLUE,
  [`${Regions.NEW_ZEALAND}_${RunType.WDS}`]: COLOURS.RADIOACTIVE_GREEN,
};

export const RESIDUAL_LABELS: { [keyof: string]: string } = {
  [`${Regions.NORTH_ISLAND}_${RunType.NRS}`]: 'North Island NRS',
  [`${Regions.NORTH_ISLAND}_${RunType.PRS}`]: 'North Island PRS',
  [`${Regions.NORTH_ISLAND}_${RunType.RTD}`]: 'North Island RTD',
  [`${Regions.NORTH_ISLAND}_${RunType.WDS}`]: 'North Island WDS',

  [`${Regions.SOUTH_ISLAND}_${RunType.NRS}`]: 'South Island NRS',
  [`${Regions.SOUTH_ISLAND}_${RunType.PRS}`]: 'South Island PRS',
  [`${Regions.SOUTH_ISLAND}_${RunType.RTD}`]: 'South Island RTD',
  [`${Regions.SOUTH_ISLAND}_${RunType.WDS}`]: 'South Island WDS',

  [`${Regions.NEW_ZEALAND}_${RunType.NRS}`]: 'Nationwide NRS',
  [`${Regions.NEW_ZEALAND}_${RunType.PRS}`]: 'Nationwide PRS',
  [`${Regions.NEW_ZEALAND}_${RunType.RTD}`]: 'Nationwide RTD',
  [`${Regions.NEW_ZEALAND}_${RunType.WDS}`]: 'Nationwide WDS',
}

export const REGION_LABELS: { [keyof: string]: string } = {
  [Regions.NORTH_ISLAND]: 'North Island',
  [Regions.SOUTH_ISLAND]: 'South Island',
  [Regions.NEW_ZEALAND]: 'Nationwide',
}

export const RESERVES_LAST_24_COLORS: { [keyof: string]: string } = {
  CLEARED: '#B1E9E6',
  OFFERED: '#00A9A5',
};

export const RESERVES_LAST_24_HRS_KEY_ITEMS: IKeyItem[] = [
  {
    label: 'Cleared',
    colour: RESERVES_LAST_24_COLORS.CLEARED,
    fill: RESERVES_LAST_24_COLORS.CLEARED,
  },
  {
    label: 'Offered',
    colour: RESERVES_LAST_24_COLORS.OFFERED,
    fill: RESERVES_LAST_24_COLORS.OFFERED,
  },
];

export const FREE_PRICE_GRAPH_KEYS: IKeyItem[] = [
  {
    label: 'OTA2201',
    colour: COLOURS.BATTERY_RED,
    fill: COLOURS.BATTERY_RED,
  },
  {
    label: 'WKM2201',
    colour: COLOURS.SOLAR_ORANGE,
    fill: COLOURS.SOLAR_ORANGE,
  },
  {
    label: 'HAY2201',
    colour: COLOURS.ELECTRIC_YELLOW,
    fill: COLOURS.ELECTRIC_YELLOW,
  },
  {
    label: 'KIK2201',
    colour: COLOURS.MINERAL_TEAL,
    fill: COLOURS.MINERAL_TEAL,
  },
  {
    label: 'ISL2201',
    colour: COLOURS.WIND_TEAL,
    fill: COLOURS.WIND_TEAL,
  },
  {
    label: 'BEN2201',
    colour: COLOURS.OIL_PURPLE,
    fill: COLOURS.OIL_PURPLE,
  },
];

export const MIN_TRADING_PERIOD = 1;
export const MAX_TRADING_PERIOD = 50;

// he BEN, HAY and OTA
// export const TRADER_PRICE_NODE_OPTIONS = ['BEN2201', 'HAY2201', 'OTA2201'];

// The above in alphabetical order
export const TRADER_PRICE_NODE_OPTIONS = ['BEN2201', 'HAY2201', 'OTA2201'];

export const TRADER_LOAD_NODE_OPTIONS = [
  'BPE0331',
  'CPK0331',
  'EDG0331',
  'GYM0661',
  'HAM0331',
  'HWB0331',
  'INV0331',
  'ISL0661',
  'MPE1101',
  'OAM0331',
  'PEN0331',
  'RDF0331',
  'SFD0331',
  'STK0331',
  'TUI1101',
];

export const TRADER_LOAD_REGION_OPTIONS = [
  'Nationwide', // default option
  'North Island',
  'South Island',
  'Upper North Island',
  'Lower North Island',
  'Upper South Island',
  'Lower South Island',
];
