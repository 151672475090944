import { useState, useEffect } from 'react';
import moment from 'moment';

export const useHalfHourTimer = () => {
  const [shouldFlip, setShouldFlip] = useState(false);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setShouldFlip((prev) => !prev);
    }, getTimeToNextHalfHour());

    return () => {
      clearTimeout(intervalId);
    };
  }, []);

  const getTimeToNextHalfHour = () => {
    const now = moment();
    const halfHour = moment()
      .startOf('hour')
      .add(Math.ceil(now.minutes() / 30) * 30, 'minutes');
    return halfHour.diff(now);
  };

  return shouldFlip;
};
