import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { useLoadForecast } from 'tools/hooks/useLoadForecast';
import { ToggleState, useLoadForecastFilter } from 'tools/hooks/useLoadForecastFilter';
import { getTradingPeriodForDate } from 'tools/utilities/date';
import {
  BoldTooltipText,
  TooltipColorKey,
  TooltipKeyArea,
  TooltipRow,
  TooltipValueArea,
  TooltipWrapper,
} from 'views/components/Tooltip/Tooltip';
import { isViewportAboveDesktop as isViewportAboveDesktopSelector } from '../../../../redux/modules/app/selectors';
import { InfoTip } from '../../InfoTip/InfoTip';
import { Toggle } from '../../Toggle/Toggle';
import { NodeSelection } from '../NodeSelection';
import { RTPLegend } from '../RTP/RTPLegend';
import { TitleArea } from '../TitleArea';
import styles from './LoadForecast.module.scss';
import {
  COMING_SOON_TEXT,
  COMING_SOON_TITLE,
  isContentReady,
  LEGEND_ITEMS,
  LOAD_FORECAST_COLOURS,
  OPTIONS,
} from './LoadForecast.util';

export const LoadForecast = () => {
  const currentTime = moment().valueOf();
  const { loadForecast, firstReadingTimestamp, xTicks, isFetchingRegion, isFetchingNode, updated } = useLoadForecast();
  const isViewportAboveDesktop = useSelector(isViewportAboveDesktopSelector);

  const comingSoon = false;
  const {
    setSelectedLoadNode,
    selectedLoadNode,
    selectedLoadRegion,
    setSelectedLoadRegion,
    toggleValue,
    setToggleValue,
  } = useLoadForecastFilter();

  const selectedOption = toggleValue === ToggleState.REGIONS ? selectedLoadRegion : selectedLoadNode;
  const setSelectedOption = toggleValue === ToggleState.REGIONS ? setSelectedLoadRegion : setSelectedLoadNode;

  if (comingSoon) {
    return (
      <div>
        <h2>{COMING_SOON_TITLE}</h2>
        <p>{COMING_SOON_TEXT}</p>
      </div>
    );
  }

  if (!isContentReady(isFetchingNode, isFetchingRegion, toggleValue)) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <TitleArea
        title="Predicted load (MW)"
        infoTip={
          <InfoTip content="Showing load forecasts from the five most recent iterations of the Non Responsive Schedule Long (NRSL) overlaid against each other, and comparing to the actual load." />
        }
        className={styles.titleArea}
      >
        <div className={styles.titleChildren}>
          <Toggle<ToggleState>
            value1={ToggleState.REGIONS}
            value2={ToggleState.NODES}
            currentValue={toggleValue}
            onClick={(value) => {
              setToggleValue(value);
            }}
            className={styles.toggle}
          />
          <NodeSelection
            className={styles.nodeSelection}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            options={OPTIONS[toggleValue]}
          />
        </div>
      </TitleArea>

      <ResponsiveContainer
        aspect={isViewportAboveDesktop ? 1.5 : undefined}
        width="99%"
        height={isViewportAboveDesktop ? undefined : 392}
      >
        <LineChart data={loadForecast}>
          <CartesianGrid opacity={0.5} strokeDasharray="4 3" vertical={false} />
          <XAxis
            minTickGap={15}
            ticks={xTicks}
            tickFormatter={(tick) => getTradingPeriodForDate(new Date(tick)).toString()}
            axisLine={false}
            tickLine={false}
            domain={['dataMin', 'dataMax']}
            stroke="#fff"
            type="number"
            dataKey="timestamp"
          />
          <YAxis
            tickCount={7}
            tickFormatter={(value: any, index: number) => (index === 0 ? ' ' : value)}
            domain={['dataMin', 'auto']}
            tickLine={false}
            stroke="#fff"
            type="number"
            axisLine={false}
          />

          <Line
            connectNulls
            stroke={LOAD_FORECAST_COLOURS.loadActual}
            strokeWidth={2}
            dot={false}
            dataKey="loadActual"
          />
          <Line
            stroke={LOAD_FORECAST_COLOURS.loadForecast1}
            type="monotone"
            strokeDasharray="5 4"
            strokeWidth={2}
            connectNulls
            dot={false}
            dataKey="loadForecast1"
          />
          <Line
            stroke={LOAD_FORECAST_COLOURS.loadForecast2}
            type="monotone"
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            connectNulls
            dataKey="loadForecast2"
          />
          <Line
            stroke={LOAD_FORECAST_COLOURS.loadForecast3}
            type="monotone"
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            dataKey="loadForecast3"
            connectNulls
          />
          <Line
            stroke={LOAD_FORECAST_COLOURS.loadForecast4}
            type="monotone"
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            dataKey="loadForecast4"
            connectNulls
          />
          <Line
            stroke={LOAD_FORECAST_COLOURS.loadForecast5}
            type="monotone"
            strokeDasharray="5 4"
            strokeWidth={2}
            dot={false}
            dataKey="loadForecast5"
            connectNulls
          />

          <ReferenceArea
            x1={firstReadingTimestamp}
            x2={currentTime}
            strokeOpacity={0.3}
            fill="#87E6E5"
            fillOpacity={0.3}
            style={{
              fill: 'url(#currentLoadSoFar)',
            }}
          />
          <ReferenceLine x={currentTime} stroke="#ffffff" />
          <defs>
            <linearGradient id="currentLoadSoFar" x1="1" y1="0.5" x2="0" y2="0.5">
              <stop offset="30%" stopColor="#87E6E5" stopOpacity={1} />
              <stop offset="95%" stopColor="#0C3345" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Tooltip content={CustomTooltip} />
        </LineChart>
      </ResponsiveContainer>
      <RTPLegend legendItems={LEGEND_ITEMS} />
      <div>Last updated {moment(updated).format('ddd DD MMM YYYY, HH:mm')}</div>
    </div>
  );
};

const CustomTooltip = ({ payload }: TooltipProps<string, string>) => {
  const { forecastRuntimes } = useLoadForecast();

  if (!payload?.[0]) {
    return null;
  }

  const dataPoint: {
    timestamp: number;
    loadActual?: number;
    loadForecast1?: number;
    loadForecast2?: number;
    loadForecast3?: number;
    loadForecast4?: number;
    loadForecast5?: number;
  } = payload[0].payload;

  return (
    <TooltipWrapper isLarge>
      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 3 }} text="Time" />
        <span>{moment(dataPoint.timestamp).format('ddd DD MMM YYYY, HH:mm')}</span>
      </TooltipRow>

      <TooltipRow>
        <BoldTooltipText style={{ marginRight: 3 }} text="Trading period" />
        <span>{getTradingPeriodForDate(new Date(dataPoint.timestamp))}</span>
      </TooltipRow>

      {!dataPoint.loadActual ? (
        <></>
      ) : (
        <TooltipRow>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <TooltipColorKey color={LOAD_FORECAST_COLOURS.loadActual} />
            <BoldTooltipText style={{ marginRight: 3 }} text="Actual load" />
          </span>
          <span>{dataPoint?.loadActual ? `${Math.round(dataPoint.loadActual! * 100) / 100} MW` : '-'}</span>
        </TooltipRow>
      )}

      {/* If any of the forecasts are available show a <hr> */}
      {dataPoint.loadForecast1 ||
      dataPoint.loadForecast2 ||
      dataPoint.loadForecast3 ||
      dataPoint.loadForecast4 ||
      dataPoint.loadForecast5 ? (
        <>
          <hr />
          <TooltipRow>Forecasts</TooltipRow>
        </>
      ) : (
        <></>
      )}

      {!dataPoint.loadForecast1 ? (
        <></>
      ) : (
        <TooltipRow>
          <TooltipKeyArea>
            <TooltipColorKey color={LOAD_FORECAST_COLOURS.loadForecast1} />
            <BoldTooltipText
              style={{ marginRight: 3 }}
              text={`NRSL ${moment(forecastRuntimes.loadForecast1).format('HH:mm')}`}
            />
            <span>{moment(forecastRuntimes.loadForecast1).format('DD MMM')}</span>
          </TooltipKeyArea>

          <TooltipValueArea>
            <span>{dataPoint?.loadForecast1 ? `${Math.round(dataPoint.loadForecast1! * 100) / 100} MW` : '-'}</span>
          </TooltipValueArea>
        </TooltipRow>
      )}

      {!dataPoint.loadForecast2 ? (
        <></>
      ) : (
        <TooltipRow>
          <TooltipKeyArea>
            <TooltipColorKey color={LOAD_FORECAST_COLOURS.loadForecast2} />
            <BoldTooltipText
              style={{ marginRight: 3 }}
              text={`NRSL ${moment(forecastRuntimes.loadForecast2).format('HH:mm')}`}
            />
            <span>{moment(forecastRuntimes.loadForecast2).format('DD MMM')}</span>
          </TooltipKeyArea>
          <TooltipValueArea>
            <span>{dataPoint?.loadForecast2 ? `${Math.round(dataPoint.loadForecast2! * 100) / 100} MW` : '-'}</span>
          </TooltipValueArea>
        </TooltipRow>
      )}

      {!dataPoint.loadForecast3 ? (
        <></>
      ) : (
        <TooltipRow>
          <TooltipKeyArea>
            <TooltipColorKey color={LOAD_FORECAST_COLOURS.loadForecast3} />
            <BoldTooltipText
              style={{ marginRight: 3 }}
              text={`NRSL ${moment(forecastRuntimes.loadForecast3).format('HH:mm')}`}
            />
            <span>{moment(forecastRuntimes.loadForecast3).format('DD MMM')}</span>
          </TooltipKeyArea>
          <TooltipValueArea>
            {dataPoint?.loadForecast3 ? `${Math.round(dataPoint.loadForecast3! * 100) / 100} MW` : '-'}
          </TooltipValueArea>
        </TooltipRow>
      )}

      {!dataPoint.loadForecast4 ? (
        <></>
      ) : (
        <TooltipRow>
          <TooltipKeyArea>
            <TooltipColorKey color={LOAD_FORECAST_COLOURS.loadForecast4} />
            <BoldTooltipText
              style={{ marginRight: 3 }}
              text={`NRSL ${moment(forecastRuntimes.loadForecast4).format('HH:mm')}`}
            />
            <span>{moment(forecastRuntimes.loadForecast4).format('DD MMM')}</span>
          </TooltipKeyArea>
          <TooltipValueArea>
            <span>{dataPoint?.loadForecast4 ? `${Math.round(dataPoint.loadForecast4! * 100) / 100} MW` : '-'}</span>
          </TooltipValueArea>
        </TooltipRow>
      )}

      {!dataPoint.loadForecast5 ? (
        <></>
      ) : (
        <TooltipRow>
          <TooltipKeyArea>
            <TooltipColorKey color={LOAD_FORECAST_COLOURS.loadForecast5} />
            <BoldTooltipText
              style={{ marginRight: 3 }}
              text={`NRSL ${moment(forecastRuntimes.loadForecast5).format('HH:mm')}`}
            />
            <span>{moment(forecastRuntimes.loadForecast5).format('DD MMM')}</span>
          </TooltipKeyArea>
          <TooltipValueArea>
            <span>{dataPoint?.loadForecast5 ? `${Math.round(dataPoint.loadForecast5! * 100) / 100} MW` : '-'}</span>
          </TooltipValueArea>
        </TooltipRow>
      )}
    </TooltipWrapper>
  );
};
