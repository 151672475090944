import { ISession } from 'types.d';
import { TYPES, SessionActions, LoginError, ResetPasswordError, ForgotPasswordError } from './actions';

export interface ISessionState {
  user?: ISession;
  isLoading: boolean;
  isLoggedIn: boolean;
  error?: any;
  hasSentForgottenPasswordRequest?: boolean;
  hasResetPassword?: boolean;

  forgotPasswordError?: ForgotPasswordError;
  resetPasswordError?: ResetPasswordError;
  loginError?: LoginError;
}

const initialState: ISessionState = {
  isLoading: true,
  isLoggedIn: false,
  hasResetPassword: false,
  hasSentForgottenPasswordRequest: false,
};

export const sessionReducer = (state = initialState, action: SessionActions) => {
  switch (action.type) {
    case TYPES.VERIFY_SESSION:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.VERIFY_SESSION_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        error: null,
        isLoggedIn: true,
        isLoading: false,
      };
    case TYPES.VERIFY_SESSION_FAIL:
      return {
        ...state,
        error: action.payload.error,
        isLoggedIn: false,
        isLoading: false,
      };

    case TYPES.LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        isLoggedIn: true,
        user: action.payload.user,
      };
    case TYPES.LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        loginError: action.payload.error,
      };

    case TYPES.LOGOUT:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
      };
    case TYPES.LOGOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case TYPES.FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasSentForgottenPasswordRequest: true,
      };
    case TYPES.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        forgotPasswordError: action.payload,
      };

    case TYPES.RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasResetPassword: true,
      };
    case TYPES.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        resetPasswordError: action.payload.resetPasswordError,
      };

    default:
      return state;
  }
};
