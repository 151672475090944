import React from 'react';
import { Login as LoginComponent } from 'views/components/Login/Login';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';

export const Login = () => (
  <div className="Container">
    <RouteTransition>
      <LoginComponent />
    </RouteTransition>
  </div>
);
