import { formatEndOfTradingPeriod } from 'tools/utilities/charts';
import { FormattedMessage } from 'react-intl';
import { useCarbonEmissions } from 'tools/hooks/useCarbonEmissions';

import styles from './RenewableEnergyPie.module.scss';

const round5 = (x: number) => (x % 5 >= 2.5 ? x - (x % 5) + 5 : x - (x % 5));

export const RenewableEnergyPie = () => {
  const { latestCarbonEmissionRecord, isLoading } = useCarbonEmissions();
  const { nzRenewable, timestamp } = latestCarbonEmissionRecord;
  const lastUpdatedTime = timestamp && !isLoading ? formatEndOfTradingPeriod(new Date(timestamp)) : 'Date not found';

  return (
    <div className={styles.wrapper}>
      <div className={styles.graphWrapper}>
        <img
          alt={`Renewable energy dial at ${nzRenewable} percent.`}
          src={`/assets/graphs/renewable-dial-${round5(nzRenewable)}.svg`}
          style={{ width: '230px' }}
        />
        <div className={styles.percentageText}>
          {Math.round(nzRenewable)}
          <span className={styles.percentageSymbol}> %</span>
        </div>
      </div>
      <p className="Dashboard-updated">
        <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
      </p>
    </div>
  );
};
