import axios from 'axios';
import { getUserToken } from 'redux/modules/session/sagas';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getHeaders = async () => {
  const token = await getUserToken();

  return {
    Authorization: `Bearer ${token}`,
  };
};

export const makeGetRequest = async <T>(url: string, data?: any) =>
  axios.get<T>(`${BASE_URL}/${url}`, {
    data,
    headers: await getHeaders(),
  });

export const makeUnauthorisedGetRequest = async <T>(url: string, data?: any) =>
  axios.get<T>(`${BASE_URL}/${url}`, {
    data,
  });
