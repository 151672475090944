import React, { ReactNode, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useWindForecastData, WindDataPointNullable } from '../../../tools/hooks/useWindForecastData';
import { InfoTip } from '../../components/InfoTip/InfoTip';
import { TitleArea } from '../../components/TradingComponents/TitleArea';
import { WindForecastGraph } from './WindForecastGraph';
import styles from './WindForecast.module.scss';

export const WindForecastGraphWrapper = () => {
  const { data: actualData, lastUpdated, isLoading } = useWindForecastData();

  // Following two are for testing purposes
  const [isUsingTestZeroData, setIsUsingTestZeroData] = useState(false);
  function createTestZeroData(data: WindDataPointNullable[]) {
    if (data.length === 0) return data;

    const result = structuredClone(data);
    const datum = result[Math.floor(result.length / 2)];

    datum.offered = 0;
    datum.discrepancy = 0;
    datum.percentile_10 = 0;
    datum.percentile_50 = 0;
    datum.percentile_90 = 0;
    datum.percentileRange = [0, 0];

    return result;
  }

  return (
    <Tile>
      <TitleArea
        title="North Island wind generation forecast (MW)"
        infoTip={
          <InfoTip content="Showing potential wind generation forecasts against offered generation from North Island wind farms" />
        }
      />
      <hr className={styles.hr} />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <WindForecastGraph
          data={isUsingTestZeroData ? createTestZeroData(actualData) : actualData}
          lastUpdated={lastUpdated}
          isLoading={isLoading}
        />
        {['development', 'test'].includes(process.env.REACT_APP_ENVIRONMENT ?? '') && (
          <button type="button" onClick={() => setIsUsingTestZeroData((prevState) => !prevState)}>
            {isUsingTestZeroData ? 'Remove' : 'Create'} zero value
          </button>
        )}
      </ErrorBoundary>
    </Tile>
  );
};

function Tile({ children }: { children: ReactNode }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.tile}>{children}</div>
    </div>
  );
}

const ErrorFallback = (_props: any) => (
  <div className={styles.replacementText}>There was a problem getting data for this graph.</div>
);
