import 'core-js/es/string/includes';
import 'core-js/es/string/starts-with';
import 'core-js/es/weak-set';

import 'react-app-polyfill/ie11';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { AnimatePresence } from 'framer-motion';
import { FlagsProvider } from 'flagged';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from 'redux/main-reducer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { unregister } from 'registerServiceWorker';
import { RecoilRoot } from 'recoil';

import { Nodes } from 'views/pages/Nodes/Nodes';
import { StyleGuide } from 'views/components/StyleGuide/StyleGuide';

import 'style/app.scss';

import { enNZ } from 'tools/i18n/en-nz';

import { SecureRoute } from 'views/enhancers/SecureRoute/SecureRoute';
import { PublicRoute } from 'views/enhancers/PublicRoute/PublicRoute';

import { Dashboard } from 'views/pages/Dashboard/Dashboard';
import { Layout } from 'views/components/Layout/Layout';
import { Login } from 'views/pages/Login/Login';
import { Reports } from 'views/pages/Reports/Reports';
import { ForgotPassword } from 'views/pages/ForgotPassword/ForgotPassword';
import { ResetPassword } from 'views/pages/ResetPassword/ResetPassword';
import { Maintenance } from 'views/pages/Maintenance/Maintenance';
import { PageNotFound } from 'views/pages/PageNotFound/PageNotFound';
import { Load } from 'views/pages/Load/Load';
import { Residuals } from 'views/pages/Residuals/Residuals';
import { CustomNodeSelection } from 'views/pages/CustomNodeSelection/CustomNodeSelection';
import { FreeDashboard } from 'views/pages/Dashboard/FreeDashboard';
import { GWAP } from 'views/pages/GWAP/GWAP';
import { featureFlagState } from 'tools/utilities/featureFlags';
import { ResizeProvider } from 'tools/providers/ResizeProvider';
import { TradingPage } from 'views/pages/Trader/TradingPage';
import { ReactComponent as SVGs } from './all.svg';
import { WindForecastGraphWrapper } from './views/pages/WindForecast/WindForecastGraphWrapper';
import { ROUTE_PATHS } from './constants/routes';

// import 'vendor/index';
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const IS_TRADING_DASH_FEATURE_ENABLED = process.env.REACT_APP_FEATURE_FLAG_TRADING_DASHBOARD === 'on';

render(
  <>
    <SVGs aria-hidden="true" />
    <Provider store={store}>
      <RecoilRoot>
        <ResizeProvider>
          <QueryClientProvider client={queryClient}>
            <FlagsProvider features={featureFlagState}>
              <IntlProvider locale="en-NZ" messages={enNZ}>
                <Router>
                  <Layout>
                    <AnimatePresence>
                      <Switch>
                        <PublicRoute exact path={ROUTE_PATHS.HOME}>
                          <FreeDashboard />
                        </PublicRoute>
                        <PublicRoute exact path={ROUTE_PATHS.WIND_FORECAST_PUBLIC}>
                          <WindForecastGraphWrapper />
                        </PublicRoute>

                        <PublicRoute exact path={ROUTE_PATHS.LOGIN}>
                          <Login />
                        </PublicRoute>
                        <PublicRoute exact path={ROUTE_PATHS.FORGOT_PASSWORD}>
                          <ForgotPassword />
                        </PublicRoute>
                        <PublicRoute exact path={ROUTE_PATHS.RESET_PASSWORD}>
                          <ResetPassword />
                        </PublicRoute>

                        <SecureRoute exact path={ROUTE_PATHS.DASHBOARD}>
                          <Dashboard />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.FREE_DASHBOARD}>
                          <FreeDashboard />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.GWAP}>
                          <GWAP />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.CUSTOM_NODE_SELECTION}>
                          <CustomNodeSelection />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.REPORTS}>
                          <Reports />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.RCPD}>
                          <Load />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.RESIDUALS}>
                          <Residuals />
                        </SecureRoute>
                        <SecureRoute exact path={ROUTE_PATHS.WIND_FORECAST}>
                          <WindForecastGraphWrapper />
                        </SecureRoute>

                        <SecureRoute exact path="/nodes">
                          <Nodes />
                        </SecureRoute>
                        {/* Save for release of trading page :) */}
                        {IS_TRADING_DASH_FEATURE_ENABLED && (
                          <SecureRoute exact path={ROUTE_PATHS.TRADING}>
                            <TradingPage />
                          </SecureRoute>
                        )}

                        <Route exact path="/styleguide">
                          <StyleGuide />
                        </Route>

                        <Route>
                          <PageNotFound />
                        </Route>
                        <Route>
                          <Maintenance />
                        </Route>
                      </Switch>
                    </AnimatePresence>
                  </Layout>
                </Router>
              </IntlProvider>
            </FlagsProvider>
          </QueryClientProvider>
        </ResizeProvider>
      </RecoilRoot>
    </Provider>
  </>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);

// registerServiceWorker();
unregister(); // To fix issue #191
