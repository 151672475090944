import {
  INodePriceInterval,
  INode,
  IScadaReportCriteria,
  IPriceReportCriteria,
  IReservesReportCriteria,
  IArcFlowsReportCriteria,
  IRCPDCriteria,
  RCPD48hrData,
  RCPD2hrData,
  INodeLoad,
  ResidualItem,
} from 'types.d';
import { NodeType } from 'enums.d';

export enum TYPES {
  GET_INTERVALS = '[NODES]-GET_INTERVALS',
  GET_INTERVALS_SUCCESS = '[NODES]-GET_INTERVALS_SUCCESS',
  GET_INTERVALS_FAIL = '[NODES]-GET_INTERVALS_FAIL',

  GET_NODES_DETAILS = '[NODES]-GET_NODES_DETAILS',
  GET_NODES_DETAILS_SUCCESS = '[NODES]-GET_NODES_DETAILS_SUCCESS',
  GET_NODES_DETAILS_FAIL = '[NODES]-GET_NODES_DETAILS_FAIL',

  UPDATE_CURRENT_NODE_TYPE = '[NODES]-UPDATE_CURRENT_NODE_TYPE',
  UPDATE_CURRENT_NODES = '[NODES]-UPDATE_CURRENT_NODES',

  GET_PRICE_NODES = '[NODES]-GET_PRICE_NODES',
  GET_PRICE_NODES_SUCCESS = '[NODES]-GET_PRICE_NODES_SUCCESS',
  GET_PRICE_NODES_FAIL = '[NODES]-GET_PRICE_NODES_FAIL',

  GET_GENERATION_NODES = '[NODES]-GET_GENERATION_NODES',
  GET_GENERATION_NODES_SUCCESS = '[NODES]-GET_GENERATION_NODES_SUCCESS',
  GET_GENERATION_NODES_FAIL = '[NODES]-GET_GENERATION_NODES_FAIL',

  GET_LOAD_NODES = '[NODES]-GET_LOAD_NODES',
  GET_LOAD_NODES_SUCCESS = '[NODES]-GET_LOAD_NODES_SUCCESS',
  GET_LOAD_NODES_FAIL = '[NODES]-GET_LOAD_NODES_FAIL',

  GET_SCADA_REPORT = '[NODES]-GET_SCADA_REPORT',
  GET_SCADA_REPORT_SUCCESS = '[NODES]-GET_SCADA_REPORT_SUCCESS',
  GET_SCADA_REPORT_FAIL = '[NODES]-GET_SCADA_REPORT_FAIL',

  GET_PRICE_REPORT = '[NODES]-GET_PRICE_REPORT',
  GET_PRICE_REPORT_SUCCESS = '[NODES]-GET_PRICE_REPORT_SUCCESS',
  GET_PRICE_REPORT_FAIL = '[NODES]-GET_PRICE_REPORT_FAIL',

  GET_RESERVES_REPORT = '[NODES]-GET_RESERVES_REPORT',
  GET_RESERVES_REPORT_SUCCESS = '[NODES]-GET_RESERVES_REPORT_SUCCESS',
  GET_RESERVES_REPORT_FAIL = '[NODES]-GET_RESERVES_REPORT_FAIL',

  GET_ARC_FLOWS_REPORT = '[NODES]-GET_ARC_FLOWS_REPORT',
  GET_ARC_FLOWS_REPORT_SUCCESS = '[NODES]-GET_ARC_FLOWS_REPORT_SUCCESS',
  GET_ARC_FLOWS_REPORT_FAIL = '[NODES]-GET_ARC_FLOWS_REPORT_FAIL',

  GET_RCPD_REPORT = '[NODES]-GET_RCPD_REPORT',
  GET_RCPD_REPORT_SUCCESS = '[NODES]-GET_RCPD_REPORT_SUCCESS',
  GET_RCPD_REPORT_FAIL = '[NODES]-GET_RCPD_REPORT_FAIL',

  GET_RCPD_48HRS = '[NODES]-GET_RCPD_48HRS',
  GET_RCPD_48HRS_SUCCESS = '[NODES]-GET_RCPD_48HRS_SUCCESS',
  GET_RCPD_48HRS_FAIL = '[NODES]-GET_RCPD_48HRS_FAIL',

  GET_RCPD_2HRS = '[NODES]-GET_RCPD_2HRS',
  GET_RCPD_2HRS_SUCCESS = '[NODES]-GET_RCPD_2HRS_SUCCESS',
  GET_RCPD_2HRS_FAIL = '[NODES]-GET_RCPD_2HRS_FAIL',

  GET_RCPD_PEAKS = '[NODES]-GET_RCPD_PEAKS',
  GET_RCPD_PEAKS_SUCCESS = '[NODES]-GET_RCPD_PEAKS_SUCCESS',
  GET_RCPD_PEAKS_FAIL = '[NODES]-GET_RCPD_PEAKS_FAIL',

  SET_SELECTED_NODES = '[NODES]-SET_SELECTED_NODES',

  SET_DOWNLOAD_VISIBLE = '[NODES]-SET_DOWNLOAD_VISIBLE',

  VALIDATE_DATE_RANGE = '[NODES]-VALIDATE_DATE_RANGE',

  CLEAN_UP_REPORT = '[NODES]-CLEAN_UP_REPORT',

  GET_HVDC_LAST_24 = '[NODES]-GET_HVDC_LAST_24',
  GET_HVDC_LAST_24_SUCCESS = '[NODES]-GET_HVDC_LAST_24_SUCCESS',
  GET_HVDC_LAST_24_FAIL = '[NODES]-GET_HVDC_LAST_24_FAIL',

  GET_NZ_RESERVES_LAST_24 = '[NODES]-GET_NZ_RESERVES_LAST_24',
  GET_NZ_RESERVES_LAST_24_SUCCESS = '[NODES]-GET_NZ_RESERVES_LAST_24_SUCCESS',
  GET_NZ_RESERVES_LAST_24_FAIL = '[NODES]-GET_NZ_RESERVES_LAST_24_FAIL',

  GET_NZ_SUMMARY_LAST_24 = '[NODES]-GET_NZ_SUMMARY_LAST_24',
  GET_NZ_SUMMARY_LAST_24_SUCCESS = '[NODES]-GET_NZ_SUMMARY_LAST_24_SUCCESS',
  GET_NZ_SUMMARY_LAST_24_FAIL = '[NODES]-GET_NZ_SUMMARY_LAST_24_FAIL',

  GET_GENERATION_BY_TYPE_LAST_24 = '[NODES]-GET_GENERATION_BY_TYPE_LAST_24',
  GET_GENERATION_BY_TYPE_LAST_24_SUCCESS = '[NODES]-GET_GENERATION_BY_TYPE_LAST_24_SUCCESS',
  GET_GENERATION_BY_TYPE_LAST_24_FAIL = '[NODES]-GET_GENERATION_BY_TYPE_LAST_24_FAIL',

  GET_RESIDUALS = '[NODES]-GET_RESIDUALS',
  GET_RESIDUALS_SUCCESS = '[NODES]-GET_RESIDUALS_SUCCESS',
  GET_RESIDUALS_FAIL = '[NODES]-GET_RESIDUALS_FAIL',

  GET_RECENT_LOAD = '[NODES]-GET_RECENT_LOAD',
  GET_RECENT_LOAD_SUCCESS = '[NODES]-GET_RECENT_LOAD_SUCCESS',
  GET_RECENT_LOAD_FAIL = '[NODES]-GET_RECENT_LOAD_FAIL',

  SET_REPORT_DOWNLOAD_ATTEMPTED = '[DASHBOARD]-SET_REPORT_DOWNLOAD_ATTEMPTED',
}

export type NodesActions =
  | IGetIntervals
  | IGetIntervalsSuccess
  | IGetIntervalsFail
  | IGetNodesDetails
  | IGetNodesDetailsSuccess
  | IGetNodesDetailsFail
  | IUpdateCurrentNodeType
  | IUpdateCurrentNodes
  | IGetPriceNodes
  | IGetPriceNodesSuccess
  | IGetGenerationNodes
  | IGetGenerationNodesSuccess
  | IGetGenerationNodesFail
  | IGetLoadNodes
  | IGetLoadNodesSuccess
  | IGetLoadNodesFail
  | IGetScadaReport
  | IGetScadaReportSuccess
  | IGetScadaReportFail
  | IGetPriceReport
  | IGetPriceReportSuccess
  | IGetPriceReportFail
  | IGetReservesReport
  | IGetReservesReportSuccess
  | IGetReservesReportFail
  | IGetArcFlowsReport
  | IGetArcFlowsReportSuccess
  | IGetArcFlowsReportFail
  | IGetRcpdReport
  | IGetRcpdReportSuccess
  | IGetRcpdReportFail
  | ICleanUpReport
  | IGetRcpd2Hrs
  | IGetRcpd2HrsSuccess
  | IGetRcpd2HrsFail
  | IGetRcpd48Hrs
  | IGetRcpd48HrsSuccess
  | IGetRcpd48HrsFail
  | IGetRcpdPeaks
  | IGetRcpdPeaksSuccess
  | IGetRcpdPeaksFail
  | IValidateDateRange
  | ISetSelectedNodes
  | ISetDownloadVisible
  | IGetHvdcLast24
  | IGetHvdcLast24Success
  | IGetHvdcLast24Fail
  | IGetNzReservesLast24
  | IGetNzReservesLast24Success
  | IGetNzReservesLast24Fail
  | IGetsNzSummaryLast24
  | IGetsNzSummaryLast24Success
  | IGetsNzSummaryLast24Fail
  | IGetGenerationByType
  | IGetGenerationByTypeSuccess
  | IGetGenerationByTypeFail
  | IGetResiduals
  | IGetResidualsSuccess
  | IGetResidualsFail
  | IGetRecentLoad
  | IGetRecentLoadSuccess
  | IGetRecentLoadFail
  | ISetReportDownloadAttempted;

export interface ISetReportDownloadAttempted {
  type: typeof TYPES.SET_REPORT_DOWNLOAD_ATTEMPTED;
  payload: {
    hasAttempted: boolean;
  };
}
export interface ISetDownloadVisible {
  type: typeof TYPES.SET_DOWNLOAD_VISIBLE;
  payload: {
    isVisible: boolean;
  };
}
export interface ISetSelectedNodes {
  type: typeof TYPES.SET_SELECTED_NODES;
  payload: {
    reportSelectedNodes: string[];
  };
}
export interface IValidateDateRange {
  type: typeof TYPES.VALIDATE_DATE_RANGE;
  payload: {
    isDateRangeValid: boolean;
  };
}

export interface IGetIntervals {
  type: typeof TYPES.GET_INTERVALS;
  payload: {
    ids: string[];
    isLoadingIntervals: boolean;
  };
}

export interface IGetIntervalsSuccess {
  type: typeof TYPES.GET_INTERVALS_SUCCESS;
  payload: {
    intervals: INodePriceInterval[];
  };
}

export interface IGetIntervalsFail {
  type: typeof TYPES.GET_INTERVALS_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetPriceNodes {
  type: typeof TYPES.GET_PRICE_NODES;
  payload: {
    nodes: string[];
  };
}

export interface IGetPriceNodesSuccess {
  type: typeof TYPES.GET_PRICE_NODES_SUCCESS;
  payload: {
    priceNodes: INode[];
  };
}

export interface IGetPriceNodesFail {
  type: typeof TYPES.GET_PRICE_NODES_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetGenerationNodes {
  type: typeof TYPES.GET_GENERATION_NODES;
}

export interface IGetGenerationNodesSuccess {
  type: typeof TYPES.GET_GENERATION_NODES_SUCCESS;
  payload: {
    generationNodes: INode[];
  };
}

export interface IGetGenerationNodesFail {
  type: typeof TYPES.GET_GENERATION_NODES_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetLoadNodes {
  type: typeof TYPES.GET_LOAD_NODES;
  payload: {
    nodes: string[];
  };
}

export interface IGetLoadNodesSuccess {
  type: typeof TYPES.GET_LOAD_NODES_SUCCESS;
  payload: {
    loadNodes: INode[];
  };
}

export interface IGetLoadNodesFail {
  type: typeof TYPES.GET_LOAD_NODES_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetNodesDetails {
  type: typeof TYPES.GET_NODES_DETAILS;
  payload: {
    ids: string[];
    nodeType?: NodeType;
    isLoadingDetails: boolean;
  };
}

export interface IGetNodesDetailsSuccess {
  type: typeof TYPES.GET_NODES_DETAILS_SUCCESS;
  payload: {
    nodesDetails: INode[];
  };
}

export interface IGetNodesDetailsFail {
  type: typeof TYPES.GET_NODES_DETAILS_FAIL;
  payload: {
    error: any;
  };
}

export interface IUpdateCurrentNodeType {
  type: typeof TYPES.UPDATE_CURRENT_NODE_TYPE;
  payload: {
    nodeType: NodeType.PRICE | NodeType.LOAD;
  };
}

export interface IUpdateCurrentNodes {
  type: typeof TYPES.UPDATE_CURRENT_NODES;
  payload: {
    nodes: string[];
  };
}

export interface IGetScadaReport {
  type: typeof TYPES.GET_SCADA_REPORT;
  payload: {
    criteria: IScadaReportCriteria;
    selectedNodes: string[];
    downloadAll: boolean;
  };
}

export interface IGetScadaReportSuccess {
  type: typeof TYPES.GET_SCADA_REPORT_SUCCESS;
  payload: {
    report: any;
  };
}

export interface IGetScadaReportFail {
  type: typeof TYPES.GET_SCADA_REPORT_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetPriceReport {
  type: typeof TYPES.GET_PRICE_REPORT;
  payload: {
    criteria: IPriceReportCriteria;
    selectedNodes: string[];
    downloadAll: boolean;
  };
}

export interface IGetPriceReportSuccess {
  type: typeof TYPES.GET_PRICE_REPORT_SUCCESS;
  payload: {
    report: any;
  };
}

export interface IGetPriceReportFail {
  type: typeof TYPES.GET_PRICE_REPORT_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetReservesReport {
  type: typeof TYPES.GET_RESERVES_REPORT;
  payload: {
    criteria: IReservesReportCriteria;
  };
}

export interface IGetReservesReportSuccess {
  type: typeof TYPES.GET_RESERVES_REPORT_SUCCESS;
  payload: {
    report: any;
  };
}

export interface IGetReservesReportFail {
  type: typeof TYPES.GET_RESERVES_REPORT_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetArcFlowsReport {
  type: typeof TYPES.GET_ARC_FLOWS_REPORT;
  payload: {
    criteria: IArcFlowsReportCriteria;
  };
}

export interface IGetArcFlowsReportSuccess {
  type: typeof TYPES.GET_ARC_FLOWS_REPORT_SUCCESS;
  payload: {
    report: any;
  };
}

export interface IGetArcFlowsReportFail {
  type: typeof TYPES.GET_ARC_FLOWS_REPORT_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetRcpdReport {
  type: typeof TYPES.GET_RCPD_REPORT;
  payload: {
    criteria: IRCPDCriteria;
  };
}

export interface IGetRcpdReportSuccess {
  type: typeof TYPES.GET_RCPD_REPORT_SUCCESS;
  payload: {
    report: any;
  };
}

export interface IGetRcpdReportFail {
  type: typeof TYPES.GET_RCPD_REPORT_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetRcpd48Hrs {
  type: typeof TYPES.GET_RCPD_48HRS;
  payload: {
    criteria: IRCPDCriteria;
  };
}

export interface IGetRcpd48HrsSuccess {
  type: typeof TYPES.GET_RCPD_48HRS_SUCCESS;
  payload: {
    rcpd48hrData: RCPD48hrData;
  };
}

export interface IGetRcpd48HrsFail {
  type: typeof TYPES.GET_RCPD_48HRS_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetRcpd2Hrs {
  type: typeof TYPES.GET_RCPD_2HRS;
  payload: {
    criteria: IRCPDCriteria;
  };
}

export interface IGetRcpd2HrsSuccess {
  type: typeof TYPES.GET_RCPD_2HRS_SUCCESS;
  payload: {
    rcpd2hrData: RCPD2hrData;
  };
}

export interface IGetRcpd2HrsFail {
  type: typeof TYPES.GET_RCPD_2HRS_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetRcpdPeaks {
  type: typeof TYPES.GET_RCPD_PEAKS;
  payload: {
    criteria: IRCPDCriteria;
  };
}

export interface IGetRcpdPeaksSuccess {
  type: typeof TYPES.GET_RCPD_PEAKS_SUCCESS;
  payload: {
    rcpdPeaks: any;
  };
}

export interface IGetRcpdPeaksFail {
  type: typeof TYPES.GET_RCPD_PEAKS_FAIL;
  payload: {
    error: any;
  };
}

export interface ICleanUpReport {
  type: typeof TYPES.CLEAN_UP_REPORT;
}

export interface IGetHvdcLast24 {
  type: TYPES.GET_HVDC_LAST_24;
  payload: {};
}

export interface IGetHvdcLast24Success {
  type: TYPES.GET_HVDC_LAST_24_SUCCESS;
  payload: {
    hvdcLast24Hrs: any;
  };
}

export interface IGetHvdcLast24Fail {
  type: TYPES.GET_HVDC_LAST_24_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetNzReservesLast24 {
  type: TYPES.GET_NZ_RESERVES_LAST_24;
  payload: {};
}

export interface IGetNzReservesLast24Success {
  type: TYPES.GET_NZ_RESERVES_LAST_24_SUCCESS;
  payload: {
    nzReservesLast24Hrs: any;
  };
}

export interface IGetNzReservesLast24Fail {
  type: TYPES.GET_NZ_RESERVES_LAST_24_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetsNzSummaryLast24 {
  type: TYPES.GET_NZ_SUMMARY_LAST_24;
  payload: {};
}

export interface IGetsNzSummaryLast24Success {
  type: TYPES.GET_NZ_SUMMARY_LAST_24_SUCCESS;
  payload: {
    nzSummaryLast24Hrs: any;
  };
}

export interface IGetsNzSummaryLast24Fail {
  type: TYPES.GET_NZ_SUMMARY_LAST_24_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetGenerationByType {
  type: TYPES.GET_GENERATION_BY_TYPE_LAST_24;
  payload: {};
}

export interface IGetGenerationByTypeSuccess {
  type: TYPES.GET_GENERATION_BY_TYPE_LAST_24_SUCCESS;
  payload: {
    generationByTypeLast24Hrs: any;
  };
}

export interface IGetGenerationByTypeFail {
  type: TYPES.GET_GENERATION_BY_TYPE_LAST_24_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetResidualsPayload {
  region: string[];
  runType: string[];
}

export interface IGetResiduals {
  type: TYPES.GET_RESIDUALS;
  payload: IGetResidualsPayload;
}

export interface IGetResidualsSuccess {
  type: TYPES.GET_RESIDUALS_SUCCESS;
  payload: {
    residuals: any;
  };
}

export interface IGetResidualsFail {
  type: TYPES.GET_RESIDUALS_FAIL;
  payload: {
    error: any;
  };
}

export interface IGetRecentLoad {
  type: TYPES.GET_RECENT_LOAD;
  payload: {
    nodes: string[];
  };
}

export interface IGetRecentLoadSuccess {
  type: TYPES.GET_RECENT_LOAD_SUCCESS;
  payload: {
    recentLoad: any;
  };
}

export interface IGetRecentLoadFail {
  type: TYPES.GET_RECENT_LOAD_FAIL;
  payload: {
    error: any;
  };
}

export function actionUpdateCurrentNodeType(nodeType: NodeType.PRICE | NodeType.LOAD): IUpdateCurrentNodeType {
  return {
    type: TYPES.UPDATE_CURRENT_NODE_TYPE,
    payload: {
      nodeType,
    },
  };
}

export function actionGetIntervals(ids: string[]): IGetIntervals {
  return {
    type: TYPES.GET_INTERVALS,
    payload: {
      ids,
      isLoadingIntervals: true,
    },
  };
}

export function actionGetPriceNodes(nodes: string[] = []): IGetPriceNodes {
  return {
    type: TYPES.GET_PRICE_NODES,
    payload: {
      nodes,
    },
  };
}

export function actionGetLoadNodes(nodes: string[] = []): IGetLoadNodes {
  return {
    type: TYPES.GET_LOAD_NODES,
    payload: {
      nodes,
    },
  };
}

export function actionGetScadaReport(
  criteria: IScadaReportCriteria,
  selectedNodes: string[],
  downloadAll: boolean,
): IGetScadaReport {
  return {
    type: TYPES.GET_SCADA_REPORT,
    payload: {
      criteria,
      selectedNodes,
      downloadAll,
    },
  };
}

export function actionGetPriceReport(
  criteria: IPriceReportCriteria,
  selectedNodes: string[],
  downloadAll: boolean,
): IGetPriceReport {
  return {
    type: TYPES.GET_PRICE_REPORT,
    payload: {
      criteria,
      selectedNodes,
      downloadAll,
    },
  };
}

export function actionGetReservesReport(criteria: IReservesReportCriteria): IGetReservesReport {
  return {
    type: TYPES.GET_RESERVES_REPORT,
    payload: {
      criteria,
    },
  };
}

export function actionGetArcFlowsReport(criteria: IArcFlowsReportCriteria): IGetArcFlowsReport {
  return {
    type: TYPES.GET_ARC_FLOWS_REPORT,
    payload: {
      criteria,
    },
  };
}

export function actionGet48HrsRcpd(criteria: IRCPDCriteria): IGetRcpd48Hrs {
  return {
    type: TYPES.GET_RCPD_48HRS,
    payload: {
      criteria,
    },
  };
}

export function actionGet2HrsRcpd(criteria: IRCPDCriteria): IGetRcpd2Hrs {
  return {
    type: TYPES.GET_RCPD_2HRS,
    payload: {
      criteria,
    },
  };
}

export function actionGetRcpdPeaks(criteria: IRCPDCriteria): IGetRcpdPeaks {
  return {
    type: TYPES.GET_RCPD_PEAKS,
    payload: {
      criteria,
    },
  };
}

export function actionGetRcpdReport(criteria: IRCPDCriteria): IGetRcpdReport {
  return {
    type: TYPES.GET_RCPD_REPORT,
    payload: {
      criteria,
    },
  };
}

export function actionCleanUpReport(): ICleanUpReport {
  return {
    type: TYPES.CLEAN_UP_REPORT,
  };
}

export function actionValidateDateRange(isValid: boolean): IValidateDateRange {
  return {
    type: TYPES.VALIDATE_DATE_RANGE,
    payload: {
      isDateRangeValid: isValid,
    },
  };
}

export function actionSetSelectedNodes(reportSelectedNodes: string[]): ISetSelectedNodes {
  return {
    type: TYPES.SET_SELECTED_NODES,
    payload: {
      reportSelectedNodes,
    },
  };
}

export function actionSetDownloadVisible(isVisible: boolean): ISetDownloadVisible {
  return {
    type: TYPES.SET_DOWNLOAD_VISIBLE,
    payload: {
      isVisible,
    },
  };
}

export function actionGetHvdcLast24(): IGetHvdcLast24 {
  return {
    type: TYPES.GET_HVDC_LAST_24,
    payload: {},
  };
}

export function actionGetHvdcLast24Success(hvdcLast24Hrs: any): IGetHvdcLast24Success {
  return {
    type: TYPES.GET_HVDC_LAST_24_SUCCESS,
    payload: {
      hvdcLast24Hrs,
    },
  };
}

export function actionGetHvdcLast24Fail(error: any): IGetHvdcLast24Fail {
  return {
    type: TYPES.GET_HVDC_LAST_24_FAIL,
    payload: {
      error,
    },
  };
}

export function actionGetNzReservesLast24(): IGetNzReservesLast24 {
  return {
    type: TYPES.GET_NZ_RESERVES_LAST_24,
    payload: {},
  };
}

export function actionGetNzReservesLast24Success(nzReservesLast24Hrs: any): IGetNzReservesLast24Success {
  return {
    type: TYPES.GET_NZ_RESERVES_LAST_24_SUCCESS,
    payload: {
      nzReservesLast24Hrs,
    },
  };
}

export function actionGetNzReservesLast24Fail(error: any): IGetNzReservesLast24Fail {
  return {
    type: TYPES.GET_NZ_RESERVES_LAST_24_FAIL,
    payload: {
      error,
    },
  };
}

export function actionGetNzSummaryLast24(): IGetsNzSummaryLast24 {
  return {
    type: TYPES.GET_NZ_SUMMARY_LAST_24,
    payload: {},
  };
}

export function actionGetsNzSummaryLast24Success(nzSummaryLast24Hrs: any): IGetsNzSummaryLast24Success {
  return {
    type: TYPES.GET_NZ_SUMMARY_LAST_24_SUCCESS,
    payload: {
      nzSummaryLast24Hrs,
    },
  };
}

export function actionGetsNzSummaryLast24Fail(error: any): IGetsNzSummaryLast24Fail {
  return {
    type: TYPES.GET_NZ_SUMMARY_LAST_24_FAIL,
    payload: {
      error,
    },
  };
}

export function actionGetGenerationByTypeLast24(): IGetGenerationByType {
  return {
    type: TYPES.GET_GENERATION_BY_TYPE_LAST_24,
    payload: {},
  };
}

export function actionGetGenerationByTypeLast24Success(generationByTypeLast24Hrs: any): IGetGenerationByTypeSuccess {
  return {
    type: TYPES.GET_GENERATION_BY_TYPE_LAST_24_SUCCESS,
    payload: {
      generationByTypeLast24Hrs,
    },
  };
}

export function actionGetGenerationByTypeLast24Fail(error: any): IGetGenerationByTypeFail {
  return {
    type: TYPES.GET_GENERATION_BY_TYPE_LAST_24_FAIL,
    payload: {
      error,
    },
  };
}

export function actionGetResiduals(payload: IGetResidualsPayload): IGetResiduals {
  return {
    type: TYPES.GET_RESIDUALS,
    payload,
  };
}

export function actionGetResidualsSuccess(residuals: ResidualItem[]): IGetResidualsSuccess {
  return {
    type: TYPES.GET_RESIDUALS_SUCCESS,
    payload: {
      residuals,
    },
  };
}

export function actionGetResidualsFail(error: any): IGetResidualsFail {
  return {
    type: TYPES.GET_RESIDUALS_FAIL,
    payload: {
      error,
    },
  };
}

export function actionGetRecentLoad(nodes: string[]): IGetRecentLoad {
  return {
    type: TYPES.GET_RECENT_LOAD,
    payload: {
      nodes,
    },
  };
}

export function actionGetRecentLoadSuccess(recentLoad: INodeLoad[]): IGetRecentLoadSuccess {
  return {
    type: TYPES.GET_RECENT_LOAD_SUCCESS,
    payload: {
      recentLoad,
    },
  };
}

export function actionGetRecentLoadFail(error: any): IGetRecentLoadFail {
  return {
    type: TYPES.GET_RECENT_LOAD_FAIL,
    payload: {
      error,
    },
  };
}

export function actionSetReportDownloadAttempted(hasAttempted: boolean): ISetReportDownloadAttempted {
  return {
    type: TYPES.SET_REPORT_DOWNLOAD_ATTEMPTED,
    payload: {
      hasAttempted,
    },
  };
}
