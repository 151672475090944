import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import './Header.scss';

import { useToggleBodyClass } from 'tools/hooks/useToggleClass';

import { isLoggedIn as isLoggedInSelector } from 'redux/modules/session/selectors';

import { Navigation } from 'views/components/Navigation/Navigation';
import { Icon } from 'views/components/Icon/Icon';
import { TradingPeriod } from 'views/components/TradingPeriod/TradingPeriod';
import useScrolling from 'tools/hooks/useScrolling';
import { Link } from 'react-router-dom';
import { version } from '../../../../package.json';
import { ROUTE_PATHS } from '../../../constants/routes';

export const Header = () => {
  const headerRef = useRef<HTMLElement | null>(null);
  const [showNav, setShowNav] = useState<boolean>(false);
  const isViewportMobile = useSelector((state: any) => state.browser.is.mobile);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const scrolling = useScrolling();

  useToggleBodyClass(showNav && isViewportMobile, ['noScroll', 'menuOpen']);

  useEffect(() => {
    if (!isViewportMobile && showNav) {
      setShowNav(false);
    }
  }, [isViewportMobile, showNav]);

  const closeNav = () => setShowNav(false);

  const headerClasses = classnames('Header', {
    'is-open': showNav,
    'Header--mobile': isViewportMobile,
    'is-pinned': !scrolling && isViewportMobile,
    'is-unpinned': scrolling && isViewportMobile,
  });

  const headerButtonClasses = classnames('Header-navTrigger', { 'is-active': showNav });

  const showVersion = process.env.REACT_APP_ENVIRONMENT === 'test' || process.env.REACT_APP_ENVIRONMENT === 'aws-uat';

  return (
    <header ref={headerRef} className={headerClasses}>
      <div className="Header-wrapper">
        <div className="Header-content">
          <div className="Header-content-inner">
            {/* {isLoggedIn && ( */}
            <button className={headerButtonClasses} type="button" onClick={() => setShowNav(!showNav)}>
              <span className="Header-navTrigger-box">
                <span className="Header-navTrigger-inner" />
              </span>
            </button>
            {/* )} */}
            <a href="/" className="Header-logo">
              <Icon name="ic-logo-large" className="Header-logo-image" />
            </a>
            <h1 className="Header-title">
              <FormattedMessage id="APP_TITLE" />
            </h1>
          </div>
          <div className="Header-content-right">
            {showVersion && (
              <p className="Header-subtitle">
                <span>Version: </span>
                <span id="tradingPeriod">{version}</span>
              </p>
            )}
            {!showNav && (
              <p className="Header-subtitle">
                <TradingPeriod />
              </p>
            )}
            {!isLoggedIn && (
              <Link to={ROUTE_PATHS.LOGIN} className="Button Header-login">
                <FormattedMessage id="LOG_IN" />
              </Link>
            )}
          </div>
        </div>
      </div>
      {isViewportMobile && <Navigation closeNav={closeNav} isActive={showNav} />}
    </header>
  );
};
