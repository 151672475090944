import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import './Footer.scss';
import { useSelector } from 'react-redux';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { isDownloadVisibleSelector } from 'redux/modules/nodes/selectors';
import { IReducerState } from 'redux/main-reducer';

import { Icon } from '../Icon/Icon';

export const Footer = () => {
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const isDownloadVisible = useSelector(isDownloadVisibleSelector);
  const isNavHidden = useSelector((state: IReducerState) => state.app.isNavHidden);

  const FooterText = () => (
    <p>
      EMS welcomes your feedback. Email any thoughts you have regarding our new site through to{' '}
      <strong>
        <a href="mailto:call@ems.co.nz">call@ems.co.nz</a>
      </strong>
      .
    </p>
  );

  return (
    <footer className={classnames('Footer', { isDownloadVisible, 'Footer--navHidden': isNavHidden })}>
      <Icon className="Footer-logo" name="ic-logo-small-ems" />

      <div className="Footer-textArea">
        {isViewAboveMobile && <FooterText />}

        <div className="Footer-linkArea">
          <a rel="noopener noreferrer" target="_blank" href="https://www.ems.co.nz/em6-carbon-methodology/">
            <h3>Carbon methodology</h3>
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.ems.co.nz/em6-privacy-policy/">
            <h3>Privacy Policy</h3>
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.ems.co.nz/em6-disclaimer/">
            <h3>Disclaimer</h3>
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.ems.co.nz/em6-api-integration-guide/">
            <h3>APIs</h3>
          </a>
        </div>
        {!isViewAboveMobile && <FooterText />}
      </div>

      <h2 className="ScreenReadersOnly">
        <FormattedMessage id="SITE_INFORMATION" />
      </h2>
    </footer>
  );
};
