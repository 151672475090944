import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { summaryApiStringSelector } from 'tools/state/paidGwapState';
import { makeGetRequest } from 'tools/utilities/ajax';
import { getColourArrayForGenerationTypes, GwapGenerationType } from 'tools/utilities/gwap';

export const useGwapSummary = () => {
  const apiString = useRecoilValue(summaryApiStringSelector);
  const { data } = useQuery<any>(['generationByLocation', apiString], () =>
    makeGetRequest(apiString).then((resp) => resp.data),
  );
  const generationSummaries = data?.items?.[0].generation_type || [];
  const reducedSumaries = generationSummaries.reduce(
    (summaries: any, summary: any) => ({
      ...summary,
      ...summaries,
    }),
    {},
  );

  const series = [
    reducedSumaries.bat_gwh ?? 0,
    reducedSumaries.cg_gwh ?? 0,
    reducedSumaries.cog_gwh ?? 0,
    reducedSumaries.gas_gwh ?? 0,
    reducedSumaries.geo_gwh ?? 0,
    reducedSumaries.hyd_gwh ?? 0,
    reducedSumaries.liq_gwh ?? 0,
    reducedSumaries.win_gwh ?? 0,
    reducedSumaries.sol_gwh ?? 0,
  ];

  const pieSummaryData = {
    labels: ['Battery', 'Coal', 'Cogen', 'Gas', 'Geothermal', 'Hydro', 'Diesel/Oil', 'Wind', 'Solar'],
    series,
    colors: getColourArrayForGenerationTypes([
      GwapGenerationType.BAT,
      GwapGenerationType.CG,
      GwapGenerationType.COG,
      GwapGenerationType.GAS,
      GwapGenerationType.GEO,
      GwapGenerationType.HYD,
      GwapGenerationType.LIQ,
      GwapGenerationType.WIN,
      GwapGenerationType.SOL,
    ]),
  };

  return { pieSummaryData, reducedSumaries };
};
