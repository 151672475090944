import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { useGwapSummary } from 'tools/hooks/PaidGwap/useGwapSummary';
import { paidDateRangeFilterState } from 'tools/state/paidGwapState';
import { getGWapDateRange } from 'tools/utilities/gwap';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import './ApexchartsOverrides.scss';
import styles from './GwapPieGraph.module.scss';

export const GwapPieGraph = () => {
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const [{ from, to }] = useRecoilState(paidDateRangeFilterState);
  const { pieSummaryData } = useGwapSummary();

  const options = {
    legend: {
      show: false,
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      style: { fontSize: '16px', fontFamily: 'Oswald Semibold' },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        hover: false,
        donut: {
          size: '55%',
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
        `<div classname='apexcharts-tooltip'>${
          pieSummaryData.labels[seriesIndex]
        }: <span classname='apexcharts-tooltip-bold'>${roundToDpWithCommas(series[seriesIndex], 2)} GWh </span></div>`,
    },
    type: 'donut',
    colors: pieSummaryData.colors,
    labels: pieSummaryData.labels,
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.heading}>Summary {getGWapDateRange(from, to)}</h3>
        <div className={styles.delimiter} />
      </div>
      <Chart
        animations={{ enabled: false }}
        className={styles.graph}
        options={options}
        series={pieSummaryData.series}
        type="donut"
        height={isViewAboveMobile ? '400' : '330'}
        // width={isViewAboveMobile ? '500' : '100%'}
      />
    </div>
  );
};
