import {
  IScadaReportCriteria,
  IPriceReportCriteria,
  IReservesReportCriteria,
  IArcFlowsReportCriteria,
  IRCPDCriteria,
  IEmissionsReportCriteria,
} from 'types.d';
import { format, subDays, subWeeks, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { DateFilterOption, EmissionsType, PriceRunType, ReservesIsland, ReservesSubtype } from 'enums.d';
import dayjs from 'dayjs';

const TIMESTAMP_FORMAT = 'hhmm';
const DATE_STRING_FORMAT = 'ddMMyyyy';
const PriceRunTypeForReportFilename: { [key in PriceRunType]?: string } = {
  [PriceRunType.FINAL]: 'F',
  [PriceRunType.INTERIM]: 'T',
  [PriceRunType.PROVISIONAL]: 'V',
  [PriceRunType.PRICE_RESPONSIVE]: 'PRS',
  [PriceRunType.NON_RESPONSE]: 'NRS',
};

const ReservesIslandCodeByCriteria: { [key in ReservesIsland]?: string } = {
  [ReservesIsland.ALL]: 'NZ',
  [ReservesIsland.NORTH_ISLAND]: 'NI',
  [ReservesIsland.SOUTH_ISLAND]: 'SI',
};

const setTimestampForReportFilename = () => format(new Date(), TIMESTAMP_FORMAT);
const formatDateForReportFilename = (date: Date) => format(date, DATE_STRING_FORMAT);
const formatDateStringForReportFilename = (date: string) => date.replace(/\//g, '');

const getTradingDatesFromCriteria = (
  criteria: IScadaReportCriteria | IPriceReportCriteria | IReservesReportCriteria | IEmissionsReportCriteria,
) => {
  const { dateOption } = criteria;

  if (dateOption === DateFilterOption.CUSTOM) {
    const { fromTradingDate, toTradingDate } = criteria;

    return {
      fromTradingDateFormatted: formatDateStringForReportFilename(fromTradingDate),
      toTradingDateFormatted: formatDateStringForReportFilename(toTradingDate),
    };
  }

  // dateOption === DateFilterOption.TODAY
  const today = new Date();
  let fromTradingDate = new Date();
  let toTradingDate = new Date();

  if (dateOption === DateFilterOption.YESTERDAY) {
    const yesterday = subDays(today, 1);

    fromTradingDate = yesterday;
    toTradingDate = yesterday;
  } else if (dateOption === DateFilterOption.PREVIOUS_7_DAYS) {
    fromTradingDate = subWeeks(today, 1);
  } else if (dateOption === DateFilterOption.MONTH_TO_DATE) {
    fromTradingDate = startOfMonth(today);
    toTradingDate = today;
  } else if (dateOption === DateFilterOption.PREVIOUS_MONTH) {
    fromTradingDate = startOfMonth(subMonths(today, 1));
    toTradingDate = endOfMonth(subMonths(today, 1));
  }

  return {
    fromTradingDateFormatted: formatDateForReportFilename(fromTradingDate),
    toTradingDateFormatted: formatDateForReportFilename(toTradingDate),
  };
};

export const formatScadaReportFileName = (criteria: IScadaReportCriteria) => {
  const { nodeType } = criteria;
  const timestamp = setTimestampForReportFilename();

  const { fromTradingDateFormatted, toTradingDateFormatted } = getTradingDatesFromCriteria(criteria);

  return `scada_${nodeType}_${fromTradingDateFormatted}_${toTradingDateFormatted}_${timestamp}`;
};

export const formatPriceReportFilename = (criteria: IPriceReportCriteria) => {
  const { priceOption, priceRunType } = criteria;
  const timestamp = setTimestampForReportFilename();

  const { fromTradingDateFormatted, toTradingDateFormatted } = getTradingDatesFromCriteria(criteria);
  const priceRunTypeFilenameFragment = PriceRunTypeForReportFilename[priceRunType]
    ? `${PriceRunTypeForReportFilename[priceRunType]}_`
    : '';

  if (priceOption === 'average') {
    return `${priceOption}_prices_${fromTradingDateFormatted}_${toTradingDateFormatted}_${timestamp}`;
  }

  return `${priceOption}_prices_${priceRunTypeFilenameFragment}${fromTradingDateFormatted}_${toTradingDateFormatted}_${timestamp}`;
};

const RESERVES_FILE_PREFIX_BY_SUB_TYPE = {
  [ReservesSubtype.AGGREGATED_RESERVES]: 'aggregated_reserves',
  [ReservesSubtype.RESERVE_AMOUNT_BY_SOURCE]: 'reserves_amount_source',
  [ReservesSubtype.RESERVES_EXTRACT]: 'reserves_extract',
};

export const formatReservesReportFilename = (criteria: IReservesReportCriteria) => {
  const { reservesIsland, reserveSubtype } = criteria;
  const { fromTradingDateFormatted, toTradingDateFormatted } = getTradingDatesFromCriteria(criteria);
  const timestamp = setTimestampForReportFilename();
  const prefix = RESERVES_FILE_PREFIX_BY_SUB_TYPE[reserveSubtype];
  return `${prefix}_${ReservesIslandCodeByCriteria[reservesIsland]}_${fromTradingDateFormatted}_${toTradingDateFormatted}_${timestamp}`;
};

export const formatArcFlowsReportFilename = (criteria: IArcFlowsReportCriteria) => {
  const timestamp = setTimestampForReportFilename();
  const { fromTradingDate, dateOption } = criteria;

  let fromTradingDateFormatted;

  if (dateOption === DateFilterOption.YESTERDAY) {
    const today = new Date();
    fromTradingDateFormatted = format(subDays(today, 1), DATE_STRING_FORMAT);
  } else if (dateOption === DateFilterOption.CUSTOM) {
    fromTradingDateFormatted = formatDateStringForReportFilename(fromTradingDate);
  }

  return `arc_flows_${fromTradingDateFormatted}_${fromTradingDateFormatted}_${timestamp}`;
};

export const formatRcpdReportFilename = (criteria: IRCPDCriteria) => {
  const { region, regionLoadOption, yearRange } = criteria;

  return `rcpd_${region}_${regionLoadOption}_${yearRange}`;
};

export const formatEmissionsReportFilename = (criteria: IEmissionsReportCriteria) => {
  const { emissionsType, toTradingDate, fromTradingDate } = criteria;
  const fromTradingDateFormatted = dayjs(fromTradingDate, 'DD/MM/YYYY').format('DDMMYYYY');
  const toTradingDateFormatted = dayjs(toTradingDate, 'DD/MM/YYYY').format('DDMMYYYY');
  const timestamp = setTimestampForReportFilename();

  if (emissionsType === EmissionsType.NZ) {
    return `nz_emissions_${fromTradingDateFormatted}_${toTradingDateFormatted}_${timestamp}.csv`;
  }

  return `generation_emissions_${fromTradingDateFormatted}_${toTradingDateFormatted}_${timestamp}.csv`;
};
