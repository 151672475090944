import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isLoggedIn as isLoggedInSelector } from 'redux/modules/session/selectors';
import { isDuringOutage } from 'tools/utilities/isDuringOutagePeriod';
import './CTATile.scss';
import { ROUTE_PATHS } from '../../../constants/routes';

const REDIRECT_URL = 'https://www.ems.co.nz/services/em6';

const LOGGED_IN_CTA = 'Get more insights such as in-depth generation data, arc flow reports and emissions information.';

export const CTATile = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const showOutageText = isDuringOutage();
  return (
    <div className="CTATile-wrapper">
      <div className="CTATile">
        {showOutageText ? (
          <>
            <p>
              EM6 dashboards and APIs will not be available between 9am and approximately 2pm on 16 August 2022 due to a
              planned outage for site maintenance.
            </p>
            <p>Data will be backfilled when the service returns, apologies for any inconvenience.</p>
          </>
        ) : (
          <>
            {isLoggedIn ? (
              <p>{LOGGED_IN_CTA}</p>
            ) : (
              <>
                <p>Access and monitor New Zealand&#39;s energy in real time.</p>
                <p>We provide flexible packages and rates, to support your business activities.</p>
              </>
            )}{' '}
            <a href={REDIRECT_URL} className="Button CTATile-enquireLink" rel="noreferrer">
              <FormattedMessage id="ENQUIRE_TODAY" />
            </a>
          </>
        )}
        {!isLoggedIn && (
          <p className="CTATile-loginText-mobile">
            Already have an account?{' '}
            <Link to={ROUTE_PATHS.LOGIN} className="CTATile-loginLink">
              <FormattedMessage id="LOG_IN" />
            </Link>
          </p>
        )}
      </div>
      {!isLoggedIn && (
        <div className="CTATile-loginTextWrapper">
          <p className="CTATile-loginText-desktop">Already have an account? </p>
          <Link to={ROUTE_PATHS.LOGIN} className="CTATile-loginLink">
            <FormattedMessage id="LOG_IN" />
          </Link>
        </div>
      )}
    </div>
  );
};
