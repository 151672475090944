import React from 'react';
import { motion } from 'framer-motion';

import './RouteTransition.scss';

interface IRouteTransition {
  children: React.ReactNode;
}

export const RouteTransition = ({ children }: IRouteTransition) => (
  <motion.div
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3, delay: 0.3 }}
    className="RouteTransition"
  >
    {children}
  </motion.div>
);
