import { useHistory } from 'react-router-dom';
import { Button } from '../Button/Button';
import styles from './AccessRestrictedInline.module.scss';

interface IAccessRestrictedInlineProps {
  message: string;
  emailUrl: string;
  canGoBack?: boolean;
}

export const AccessRestrictedInline = ({ message, emailUrl, canGoBack = false }: IAccessRestrictedInlineProps) => {
  const attemptEmailTo = () => {
    document.location.href = emailUrl;
  };

  const history = useHistory();
  const close = () => {
    history.goBack();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>Restricted Access</h1>
        <p className={styles.access_restricted_message}>{message}</p>
        <div className={styles.access_restricted_actions_wrapper}>
          <div className={styles.buttonArea}>
            <Button onClick={attemptEmailTo} label="Request access" />
          </div>
          {canGoBack && <Button className="Button Button--secondary--dark" onClick={close} label="Cancel" />}
        </div>
      </div>
    </div>
  );
};
