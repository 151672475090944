import { UserAccessScope } from 'constants/sessionConstants';
import { LoadFilterProvider } from 'tools/providers/LoadFilterProvider';
import { RTPFilterProvider } from 'tools/providers/RTPFilterProvider';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { LoadForecast } from 'views/components/TradingComponents/LoadForecast/LoadForecast';
import { RTP } from '../../components/TradingComponents/RTP/RTP';
import styles from './TradingPage.module.scss';

export const TradingPage = () => (
  // TODO update the access scope
  <AccessRestrictionWrapper isInline accessScopeRequired={UserAccessScope.TRADING}>
    <div className="Container--fullWidth">
      <div className={styles.wrapper}>
        <RTPFilterProvider>
          <div className={styles.tile}>
            <RTP />
          </div>
        </RTPFilterProvider>
        <LoadFilterProvider>
          <div className={styles.tile}>
            <LoadForecast />
          </div>
        </LoadFilterProvider>
      </div>
    </div>
  </AccessRestrictionWrapper>
);
