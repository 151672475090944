import { useSelector } from 'react-redux';
import { isViewportAboveMobile as isViewportAboveMobileSelector } from 'redux/modules/app/selectors';

const LABEL_WIDTH = 117;
const LABEL_HEIGHT = 44;
const LABEL_WIDTH_HALVED = LABEL_WIDTH / 2;

const NODE_ID_FONT_SIZE = 14;
const NODE_PRICE_FONT_SIZE = 16;
const NODE_PRICE_FONT_SIZE_SMALL = 28;
const NODE_ID_Y_OFFSET = 5;
const NODE_PRICE_Y_OFFSET = 15;
const NODE_PRICE_Y_OFFSET_SMALL = -12;

const LABEL_OUTER_CIRCLE_RADIUS = 10;
const LABEL_OUTER_CIRCLE_RADIUS_SMALL = 16;
const LABEL_INNER_CIRCLE_RADIUS = 4.5;
const LABEL_INNER_CIRCLE_RADIUS_SMALL = 6;
const LABEL_LINE_STROKE_WIDTH = 1;
const LABEL_LINE_STROKE_WIDTH_SMALL = 2;
const LABEL_LINE_MAX_LENGTH = 75;
const LABEL_LINE_MIN_LENGTH = 20;
const LABEL_RECT_BORDER_RADIUS = 8;

const ARROW_PADDING = 8;
export interface ILabelDimensions {
  scaledLabelWidth: number;
  scaledLabelHeight: number;
  scaledNodeIdFontSize: number;
  scaledNodePriceFontSize: number;
  scaledNodeIdYOffset: number;
  scaledNodePriceYOffset: number;
  scaledLabelOuterCircleRadius: number;
  scaledLabelInnerCircleRadius: number;
  scaledLabelLineStrokeWidth: number;
  scaledLabelRectBorderRadius: number;
  scaledLabelRectYOffset: number;
  scaledArrowWidth: number;
  scaledArrowPadding: number;
  offSet: number;
  delta: number;
}

export const useLabelDimensions = (transformation: d3.ZoomTransform): ILabelDimensions => {
  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);

  let nodePriceFontSize = NODE_PRICE_FONT_SIZE_SMALL;
  let nodePriceYOffset = NODE_PRICE_Y_OFFSET_SMALL;
  let labelInnerCircleRadius = LABEL_INNER_CIRCLE_RADIUS_SMALL;
  let labelOuterCircleRadius = LABEL_OUTER_CIRCLE_RADIUS_SMALL;
  let labelLineStrokeWidth = LABEL_LINE_STROKE_WIDTH_SMALL;

  if (isViewportAboveMobile) {
    nodePriceFontSize = NODE_PRICE_FONT_SIZE;
    nodePriceYOffset = NODE_PRICE_Y_OFFSET;
    labelInnerCircleRadius = LABEL_INNER_CIRCLE_RADIUS;
    labelOuterCircleRadius = LABEL_OUTER_CIRCLE_RADIUS;
    labelLineStrokeWidth = LABEL_LINE_STROKE_WIDTH;
  }

  const { k: scale } = transformation;
  const scaledLabelOuterCircleRadius = labelOuterCircleRadius / scale;
  const scaledLabelHeight = LABEL_HEIGHT / scale;
  const offSet = (Math.random() * (LABEL_LINE_MAX_LENGTH - LABEL_LINE_MIN_LENGTH) + LABEL_LINE_MIN_LENGTH) / scale;
  const delta = isViewportAboveMobile ? offSet + LABEL_WIDTH / scale : offSet + LABEL_WIDTH_HALVED / scale;

  return {
    scaledLabelWidth: LABEL_WIDTH / scale,
    scaledLabelHeight,
    scaledNodeIdFontSize: NODE_ID_FONT_SIZE / scale,
    scaledNodePriceFontSize: nodePriceFontSize / scale,
    scaledNodeIdYOffset: NODE_ID_Y_OFFSET / scale,
    scaledNodePriceYOffset: nodePriceYOffset / scale,
    scaledLabelOuterCircleRadius,
    scaledLabelInnerCircleRadius: labelInnerCircleRadius / scale,
    scaledLabelLineStrokeWidth: labelLineStrokeWidth / scale,
    scaledLabelRectBorderRadius: LABEL_RECT_BORDER_RADIUS / scale,
    scaledLabelRectYOffset: scaledLabelHeight / 2,
    scaledArrowWidth: 2 * scaledLabelOuterCircleRadius + (ARROW_PADDING * 2) / scale,
    scaledArrowPadding: ARROW_PADDING / scale,
    offSet,
    delta,
  };
};
