// Since some urls might come from a CMS or  API,
// isUrlInternal can be used to see if the url is pointing to one of our internal routes
// The following function makes the assumption that all our routes will match
// one of the following urls: "/" "/home" "/my-page" "/my-nested/page" "/blog-post/1"

const isLinkRegex = /^\/(\s|[a-zA-Z&\d-/]*)$/;

export function isUrlInternal(url: string = ''): boolean {
  const cleanURL = url.replace(/\s/g, '');

  // Handle hash param
  // if (cleanURL.includes('#')) {
  //   const splitUrl = cleanURL.split('#');

  //   return !!splitUrl[0].match(isLinkRegex);
  // }

  return !!cleanURL.match(isLinkRegex);
}
