import React from 'react';

import { ResetPassword as ResetPasswordComponent } from 'views/components/ResetPassword/ResetPassword';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';

export const ResetPassword = () => (
  <div className="Container">
    <RouteTransition>
      <ResetPasswordComponent />
    </RouteTransition>
  </div>
);
