import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';

import {
  GENERATION_BY_TYPE_LAST_24_HRS_COLORS,
  GENERATION_BY_TYPE_LAST_24_HRS_KEY_ITEMS,
} from 'redux/modules/nodes/constants';

import { IKeyItem } from 'types.d';
import { scaleLinear } from 'd3-scale';
import { GenerationType } from 'enums.d';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { FormattedMessage } from 'react-intl';
import { formatTradingPeriodToTime } from 'tools/utilities/date';
import { getGenerationByType } from 'redux/modules/nodes/selectors';
import { isViewportAboveTablet as isViewportAboveTabletSelector } from 'redux/modules/app/selectors';
import { formatEndOfTradingPeriod, formatTimestamp } from 'tools/utilities/charts';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipRow, TooltipWrapper, BoldTooltipText } from '../Tooltip/Tooltip';

const GENERATION_TYPES = [
  GenerationType.HYDRO,
  GenerationType.GEOTHERMAL,
  GenerationType.GAS,
  GenerationType.DIESEL_OIL,
  GenerationType.WIND,
  GenerationType.CO_GEN,
  GenerationType.COAL_GAS,
  GenerationType.SOLAR,
  GenerationType.BATTERY,
]

const MOBILE_HEIGHT = 620;

const CustomTooltip = ({ payload, series }: any) => {
  if (!payload?.[0]) {
    return null;
  }

  const {timestamp, tradingPeriod, ...data} = payload?.[0]?.payload ?? {};

  return (
    <TooltipWrapper>
      <TooltipRow>
        <span>{timestamp && formatTimestamp(new Date(timestamp))} - TP{tradingPeriod}</span>
      </TooltipRow>

      {
        series.map((g: string) => {
          const name = g === 'Liquid' ? 'Diesel/Oil' : g
          return (
            <Fragment key={g}>
              <TooltipRow>
                <BoldTooltipText style={{ marginRight: 20 }} text={name} />
                <span>{data[g] || data[g] === 0 ? `${roundToDpWithCommas(data[g], 1)} MW` : '-'}</span>
              </TooltipRow>
              <TooltipRow>
                <BoldTooltipText style={{ marginRight: 20 }} text={`${name} Capacity`} />
                <span>{data[`${g}Capacity`] ? `${roundToDpWithCommas(data[`${g}Capacity`], 0)} MW` : '-'}</span>
              </TooltipRow>
            </Fragment>
        )})
      }

    </TooltipWrapper>
  );
};


export const GenerationByTypeLast24Hr = () => {

  const generationByType = useSelector(getGenerationByType);
  const isAboveTablet = useSelector(isViewportAboveTabletSelector);
  const [hiddenSeries, setHiddenSeries] = useState<any>([])
  const visibleSeries = GENERATION_TYPES.filter(key => !hiddenSeries.includes(key))

  const data = generationByType?.items;
  if (!data || !data.length) {
    return null;
  }

  const lastUpdatedTime = formatEndOfTradingPeriod(new Date(data[data.length - 1].timestamp));

  const generationData = data.map(({generationType, ...rest}: any) => ({
    ...(generationType || []).reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.generationTypeName]: curr.generationMw,
      [`${curr.generationTypeName}Capacity`]: curr.generationCapacityMw,
    }), {}),
    ...rest,
  }))

  const yValues = generationData.reduce((acc: any, curr: any) => ([
    ...acc,
    ...visibleSeries.map(g => curr[g] || 0),
  ]), [])

  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)
  const offset = (yMax - yMin) / 10
  const yScale = scaleLinear().domain([yMin - offset, yMax + offset])
  const yTicks = yScale.ticks(6)
  const xTicks:any = _.uniq(generationData.filter(({tradingPeriod}: any) => tradingPeriod % 6 === 0).map(({tradingPeriod}: any) => tradingPeriod))

  const handleKeySelect = (item: IKeyItem) => {
    const { key } = item
    const series = hiddenSeries.includes(key) ? hiddenSeries.filter((h: any) => h !== key) : hiddenSeries.concat(key)
    setHiddenSeries(series)
  }

  return (
    <div>
      <ResponsiveContainer
        width="99%"
        aspect={isAboveTablet ? 1 : undefined}
        height={isAboveTablet ? 'unset' : MOBILE_HEIGHT}
      >
        <LineChart
          data={generationData}
          margin={{
            top: 24,
            right: 0,
            left: 0,
            bottom: 16,
          }}
        >
          <XAxis
            ticks={xTicks}
            tickFormatter={(tick: number) => formatTradingPeriodToTime(tick)}
            tickLine={false}
            axisLine={false}
            width={100}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            dataKey="tradingPeriod"
          />
          <YAxis
            ticks={yTicks}
            domain={['dataMin', 'auto']}
            tickLine={false}
            tick={{
              stroke: 'white',
              fontSize: '14px',
              strokeWidth: 0.75,
              fontFamily: 'Oswald Light oswald-light !important',
            }}
            axisLine={false}
            type="number"
          />
          <Tooltip
            labelFormatter={(label: any, payload: any) => (
              <span>
                {moment(payload[0]?.payload?.timestamp).format('DD/MM/YYYY')} - TP{payload[0]?.payload.tp}
              </span>
            )}
            content={args => CustomTooltip({...args, series: visibleSeries})}
          />

          {GENERATION_TYPES.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={GENERATION_BY_TYPE_LAST_24_HRS_COLORS[g]}
              dot={false}
              strokeWidth={2}
              hide={hiddenSeries.includes(g)}
            />
          )}

          {xTicks.map((tick: number) => (
            <ReferenceLine key={tick} x={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
          {yTicks.map((tick: number) => (
            <ReferenceLine key={tick} y={tick} stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <GraphKey
        keyItems={GENERATION_BY_TYPE_LAST_24_HRS_KEY_ITEMS}
        onClick={handleKeySelect}
        hiddenSeries={hiddenSeries}
        columns={3}
      />
      <p className="Dashboard-updated">
        <FormattedMessage id="LAST_UPDATED" values={{ date: lastUpdatedTime }} />
      </p>
    </div>
  )

}