import {
  INodePriceInterval,
  INode,
  RCPD2hrData,
  RCPD48hrData,
  INodeLoad,
  HVDCLast24HrsData,
  RCPDPeaksData,
  NZReservesLast24HrsData,
  NZSummaryLast24HrsData,
  GenerationByTypeData,
  ResidualsData,
} from 'types.d';
import { NodeType, ReportType } from 'enums.d';

import { TYPES, NodesActions } from './actions';
import { DEFAULT_PRICE_NODES, DEFAULT_LOAD_NODES } from './constants';

export interface INodesState {
  currentNodes: string[];
  currentPriceNodes: string[];
  currentNodesDetails: INode[];
  currentNodeType?: NodeType;
  reportDownloadStartTime?: number;
  reportDownloadTime?: number;
  reportToDownload?: ReportType;
  intervals: INodePriceInterval[];
  isLoadingIntervals: boolean;
  isLoadingDetails: boolean;
  isLoadingNodes: boolean;
  isLoadingReport: boolean;
  isLoadingRCPDData: boolean;
  isLoadingResiduals: boolean;
  isValidDateRange: boolean;
  isDownloadVisible: boolean;
  priceNodes: INode[];
  loadNodes: INode[];
  reportSelectedNodes: string[];
  generationNodes: INode[];
  isReportDownloaded: boolean;
  reportContent?: any;
  rcpd2hrData?: RCPD2hrData;
  rcpd48hrData?: RCPD48hrData;
  rcpdPeaks?: RCPDPeaksData;
  hvdcLast24Hrs?: HVDCLast24HrsData;
  nzReservesLast24Hrs?: NZReservesLast24HrsData;
  nzSummaryLast24Hrs?: NZSummaryLast24HrsData;
  generationByTypeLast24Hrs?: GenerationByTypeData;
  residuals?: ResidualsData;
  recentLoad: INodeLoad[];
  hasAttemptedReportDownload: boolean;
}

export const initialState: INodesState = {
  currentNodes: DEFAULT_LOAD_NODES,
  currentPriceNodes: DEFAULT_PRICE_NODES,
  currentNodesDetails: [],
  currentNodeType: NodeType.LOAD,
  intervals: [],
  isLoadingIntervals: false,
  isLoadingDetails: false,
  isLoadingNodes: false,
  isLoadingReport: false,
  isLoadingRCPDData: false,
  isLoadingResiduals: false,
  isValidDateRange: false,
  isDownloadVisible: false,
  priceNodes: [],
  reportSelectedNodes: [],
  generationNodes: [],
  loadNodes: [],
  rcpd2hrData: undefined,
  rcpd48hrData: undefined,
  rcpdPeaks: undefined,
  hvdcLast24Hrs: undefined,
  nzReservesLast24Hrs: undefined,
  nzSummaryLast24Hrs: undefined,
  generationByTypeLast24Hrs: undefined,
  residuals: undefined,
  recentLoad: [],
  isReportDownloaded: false,
  hasAttemptedReportDownload: false,
};

// const NODE_TYPE_TO_NODES = {
//   [NodeType.LOAD]: DEFAULT_LOAD_NODES,
//   [NodeType.PRICE]: DEFAULT_PRICE_NODES,
//   default: DEFAULT_PRICE_NODES,
// };

export const nodesReducer = (state = initialState, action: NodesActions) => {
  switch (action.type) {
    case TYPES.GET_INTERVALS:
      return {
        ...state,
        isLoadingIntervals: true,
      };
    case TYPES.GET_INTERVALS_SUCCESS:
      return {
        ...state,
        isLoadingIntervals: false,
        intervals: action.payload.intervals,
      };
    case TYPES.GET_INTERVALS_FAIL:
      return {
        ...state,
        isLoadingIntervals: false,
        error: action.payload,
      };
    case TYPES.GET_NODES_DETAILS:
      return {
        ...state,
        isLoadingDetails: true,
      };
    case TYPES.GET_NODES_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        currentNodesDetails: action.payload.nodesDetails,
      };
    case TYPES.GET_NODES_DETAILS_FAIL:
      return {
        ...state,
        isLoadingDetails: false,
        error: action.payload,
      };
    case TYPES.UPDATE_CURRENT_NODE_TYPE:
      return {
        ...state,
        currentNodeType: action.payload.nodeType,
        currentNodes: action.payload.nodeType === NodeType.PRICE ? state.currentPriceNodes : DEFAULT_LOAD_NODES,
      };
    case TYPES.UPDATE_CURRENT_NODES:
      return {
        ...state,
        currentNodes: action.payload.nodes,
        currentPriceNodes: action.payload.nodes,
      };
    case TYPES.GET_PRICE_NODES:
      return {
        ...state,
        isLoadingNodes: true,
      };
    case TYPES.GET_PRICE_NODES_SUCCESS:
      return {
        ...state,
        priceNodes: action.payload.priceNodes,
        isLoadingNodes: false,
      };
    case TYPES.GET_GENERATION_NODES:
      return {
        ...state,
        isLoadingNodes: true,
      };
    case TYPES.GET_GENERATION_NODES_SUCCESS:
      return {
        ...state,
        generationNodes: action.payload.generationNodes,
        isLoadingNodes: false,
      };
    case TYPES.GET_LOAD_NODES:
      return {
        ...state,
        isLoadingNodes: true,
      };
    case TYPES.GET_LOAD_NODES_SUCCESS:
      return {
        ...state,
        loadNodes: action.payload.loadNodes,
        isLoadingNodes: false,
      };
    case TYPES.GET_SCADA_REPORT:
    case TYPES.GET_PRICE_REPORT:
    case TYPES.GET_RESERVES_REPORT:
    case TYPES.GET_ARC_FLOWS_REPORT:
      return {
        ...state,
        reportDownloadStartTime: new Date().getTime(),
        isLoadingReport: true,
      };
    case TYPES.GET_SCADA_REPORT_SUCCESS:
    case TYPES.GET_PRICE_REPORT_SUCCESS:
    case TYPES.GET_RESERVES_REPORT_SUCCESS:
    case TYPES.GET_ARC_FLOWS_REPORT_SUCCESS:
    case TYPES.GET_RCPD_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
        isReportDownloaded: true,
        reportDownloadTime: new Date().getTime() - (state.reportDownloadStartTime || 0),
        reportDownloadStartTime: undefined,
        reportContent: action.payload.report,
      };
    case TYPES.GET_SCADA_REPORT_FAIL:
    case TYPES.GET_PRICE_REPORT_FAIL:
    case TYPES.GET_RESERVES_REPORT_FAIL:
    case TYPES.GET_ARC_FLOWS_REPORT_FAIL:
      return {
        ...state,
        isLoadingReport: false,
        reportDownloadStartTime: undefined,
        error: action.payload.error,
      };
    case TYPES.CLEAN_UP_REPORT:
      return {
        ...state,
        isReportDownloaded: false,
        reportContent: null,
      };
    case TYPES.GET_RCPD_2HRS:
    case TYPES.GET_RCPD_48HRS:
      return {
        ...state,
        isLoadingRCPDData: true,
      };
    case TYPES.GET_RCPD_2HRS_SUCCESS:
      return {
        ...state,
        isLoadingRCPDData: false,
        rcpd2hrData: action.payload.rcpd2hrData,
      };
    case TYPES.GET_RCPD_48HRS_SUCCESS:
      return {
        ...state,
        isLoadingRCPDData: false,
        rcpd48hrData: action.payload.rcpd48hrData,
      };
    case TYPES.GET_RCPD_PEAKS_SUCCESS:
      return {
        ...state,
        isLoadingRCPDData: false,
        rcpdPeaks: action.payload.rcpdPeaks,
      };
    case TYPES.GET_RCPD_2HRS_FAIL:
    case TYPES.GET_RCPD_48HRS_FAIL:
    case TYPES.GET_RCPD_PEAKS_FAIL:
      return {
        ...state,
        error: action.payload.error,
      };
    case TYPES.VALIDATE_DATE_RANGE:
      return {
        ...state,
        isValidDateRange: action.payload.isDateRangeValid,
      };
    case TYPES.SET_SELECTED_NODES:
      return {
        ...state,
        reportSelectedNodes: action.payload.reportSelectedNodes,
      };
    case TYPES.SET_DOWNLOAD_VISIBLE:
      return {
        ...state,
        isDownloadVisible: action.payload.isVisible,
      };
    case TYPES.GET_HVDC_LAST_24:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.GET_HVDC_LAST_24_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hvdcLast24Hrs: action.payload.hvdcLast24Hrs,
      };
    case TYPES.GET_HVDC_LAST_24_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case TYPES.GET_NZ_RESERVES_LAST_24:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.GET_NZ_RESERVES_LAST_24_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nzReservesLast24Hrs: action.payload.nzReservesLast24Hrs,
      };
    case TYPES.GET_NZ_RESERVES_LAST_24_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case TYPES.GET_NZ_SUMMARY_LAST_24:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.GET_NZ_SUMMARY_LAST_24_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nzSummaryLast24Hrs: action.payload.nzSummaryLast24Hrs,
      };
    case TYPES.GET_NZ_SUMMARY_LAST_24_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case TYPES.GET_GENERATION_BY_TYPE_LAST_24:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.GET_GENERATION_BY_TYPE_LAST_24_SUCCESS:
      return {
        ...state,
        isLoading: false,
        generationByTypeLast24Hrs: action.payload.generationByTypeLast24Hrs,
      };
    case TYPES.GET_GENERATION_BY_TYPE_LAST_24_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case TYPES.GET_RESIDUALS:
      return {
        ...state,
        isLoadingResiduals: true,
      };
    case TYPES.GET_RESIDUALS_SUCCESS:
      return {
        ...state,
        isLoadingResiduals: false,
        residuals: action.payload.residuals,
      };
    case TYPES.GET_RESIDUALS_FAIL:
      return {
        ...state,
        isLoadingResiduals: false,
        error: action.payload.error,
      };
    case TYPES.GET_RECENT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.GET_RECENT_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recentLoad: action.payload.recentLoad,
      };
    case TYPES.GET_RECENT_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case TYPES.SET_REPORT_DOWNLOAD_ATTEMPTED:
      return {
        ...state,
        hasAttemptedReportDownload: action.payload.hasAttempted,
      };
    default:
      return state;
  }
};
