export const ROUTE_PATHS = {
  HOME: '/',
  WIND_FORECAST: '/wind',
  WIND_FORECAST_PUBLIC: '/ni-wind-forecast',
  GWAP: '/gwap',
  RCPD: '/load',
  RESIDUALS: '/residual',
  REPORTS: '/reports',
  CUSTOM_NODE_SELECTION: '/customise-nodes',
  FREE_DASHBOARD: '/price',
  DASHBOARD: '/premium',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  TRADING: '/trading',
};
