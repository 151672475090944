const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUM_REGEX = /(?=.*\d)/;
const LOWERCASE_CHAR_REGEX = /(?=.*[a-z])/;
const UPPERCASE_CHAR_REGEX = /(?=.*[A-Z])/;

export const PERMISSIVE_PASSWORD_ENVIRONMENTS = ['development', 'netlify-dev', 'uat'];
export const MINIMUM_PASSWORD_LENGTH =
  PERMISSIVE_PASSWORD_ENVIRONMENTS.indexOf(process.env.REACT_APP_ENVIRONMENT || '') !== -1 ? 9 : 14;
export const validateEmail = (input: string | null) => EMAIL_REGEX.test(input || '');

/**
 * As per requirement the password must at least comply with three of the following:
 * - lower case characters (a-z)
 * - uppercase characters (A-Z)
 * - digits (0-9)
 * - punctuation and special characters
 * See requirement here: https://docs.google.com/spreadsheets/d/1JizNQ2u0VDFS9mkszR38Dwlet60wyt6T5ScXR7QlWQY/edit?ts=5ee95870#gid=1116945343
 * (em3)
 * @param input string password entered by the user
 */
export const validatePassword = (input: string) => {
  if (input.length < MINIMUM_PASSWORD_LENGTH) {
    return false;
  }

  const hasNumber = NUM_REGEX.test(input);
  const hasLowerCaseCharacter = LOWERCASE_CHAR_REGEX.test(input);
  const hasUppercaseCharacter = UPPERCASE_CHAR_REGEX.test(input);

  return hasNumber && hasLowerCaseCharacter && hasUppercaseCharacter;
};
