import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  geoGenerator: d3.GeoPath<any, d3.GeoPermissibleObjects>;
  gridZones: any;
  transformation?: d3.ZoomTransform;
  hasCustomisedNodes: boolean; // Stop the animation when entering the page for the first time
}

export const MapLayer = React.memo(({ hasCustomisedNodes, geoGenerator, gridZones, transformation }: Props) => (
  <motion.g
    id="Map-gridZones"
    animate={{
      x: transformation?.x,
      y: transformation?.y,
      scale: transformation?.k,
    }}
    transition={{ duration: hasCustomisedNodes ? 0.75 : 0 }}
  >
    {gridZones.map((zone: any, index: number) => {
      const d = geoGenerator(zone);

      return d && <path key={zone.id} d={d} fill="#fff" />;
    })}
  </motion.g>
));
