import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Button/Button';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { Icon } from '../../components/Icon/Icon';

import './PageNotFound.scss';

export const PageNotFound = () => (
  <RouteTransition>
    <div className="PageNotFound">
      <div className="PageNotFound-wrapper">
        <div className="PageNotFound-content">
          <h1 className="PageNotFound-title">
            Oops! <br />
            Something went wrong.{' '}
          </h1>
          <p className="PageNotFound-text">
            <FormattedMessage id="404" />
          </p>
          <Button className="Button" url="/" label="Back to home" />
        </div>
        <div className="PageNotFound-illustration">
          <Icon name="illustration-large-oops" className="Icon" />
        </div>
      </div>
    </div>
  </RouteTransition>
);
