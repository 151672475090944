import classnames from 'classnames';
import { useRecoilState } from 'recoil';
import { useGwapSummary } from 'tools/hooks/PaidGwap/useGwapSummary';
import { paidDateRangeFilterState } from 'tools/state/paidGwapState';
import { GenTypeToString, getGWapDateRange, GwapGenerationType } from 'tools/utilities/gwap';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import styles from './GwapSummaryTable.module.scss';

const TableHeader = () => (
  <div className={styles.tableHeaderArea}>
    <div className={styles.tableHeader} style={{ textAlign: 'left' }}>
      Gen Type
    </div>
    <div className={styles.tableHeader} style={{ textAlign: 'right' }}>
      Avg GWAP
    </div>
    <div className={styles.tableHeader} style={{ textAlign: 'right' }}>
      Energy/GWh
    </div>
  </div>
);

const TableRow = ({
  genType,
  avgPrice,
  avgGwh,
  isDark,
  isPlaceholder = false,
}: {
  genType: GwapGenerationType;
  avgPrice: number;
  avgGwh: number;
  isDark: boolean;
  isPlaceholder?: boolean;
}) => {
  const avgPriceDecimalPlaces = 2;
  const avgGwDecimalPlaces = avgGwh < 1 ? 2 : 1;

  return (
    <div className={classnames([styles.tableRow, isDark && styles.tableRowDark])}>
      <div style={{ textAlign: 'left', color: isPlaceholder ? '#ffffff00' : 'white' }}>
        {!isPlaceholder ? GenTypeToString[genType] : '-'}
      </div>
      <div style={{ textAlign: 'right' }}>
        {!isPlaceholder ? `$${roundToDpWithCommas(avgPrice, avgPriceDecimalPlaces)}` : ''}
      </div>
      <div style={{ textAlign: 'right' }}>
        {!isPlaceholder ? `${roundToDpWithCommas(avgGwh, avgGwDecimalPlaces)}` : ''}
      </div>
    </div>
  );
};

const TableContent = () => {
  const { reducedSumaries } = useGwapSummary();
  return (
    <div className={styles.tableContentArea}>
      <TableRow
        key="LIQ"
        genType={GwapGenerationType.LIQ}
        avgPrice={reducedSumaries.liq_gwap}
        avgGwh={reducedSumaries.liq_gwh}
        isDark={false}
      />
      <TableRow
        key="BAT"
        genType={GwapGenerationType.BAT}
        avgPrice={reducedSumaries.bat_gwap}
        avgGwh={reducedSumaries.bat_gwh}
        isDark
      />
      <TableRow
        key="CG"
        genType={GwapGenerationType.CG}
        avgPrice={reducedSumaries.cg_gwap}
        avgGwh={reducedSumaries.cg_gwh}
        isDark={false}
      />
      <TableRow
        key="WIN"
        genType={GwapGenerationType.WIN}
        avgPrice={reducedSumaries.win_gwap}
        avgGwh={reducedSumaries.win_gwh}
        isDark
      />
      <TableRow
        key="HYD"
        genType={GwapGenerationType.HYD}
        avgPrice={reducedSumaries.hyd_gwap}
        avgGwh={reducedSumaries.hyd_gwh}
        isDark={false}
      />
      <TableRow
        key="GAS"
        genType={GwapGenerationType.GAS}
        avgPrice={reducedSumaries.gas_gwap}
        avgGwh={reducedSumaries.gas_gwh}
        isDark
      />
      <TableRow
        key="COG"
        genType={GwapGenerationType.COG}
        avgPrice={reducedSumaries.cog_gwap}
        avgGwh={reducedSumaries.cog_gwh}
        isDark={false}
      />
      <TableRow
        key="GEO"
        genType={GwapGenerationType.GEO}
        avgPrice={reducedSumaries.geo_gwap}
        avgGwh={reducedSumaries.geo_gwh}
        isDark
      />
      <TableRow
        key="SOL"
        genType={GwapGenerationType.SOL}
        avgPrice={reducedSumaries.sol_gwap}
        avgGwh={reducedSumaries.sol_gwh}
        isDark
      />
    </div>
  );
};

export const GwapSummaryTable = () => {
  const [{ from, to }] = useRecoilState(paidDateRangeFilterState);

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.heading}>Summary {getGWapDateRange(from, to)}</h3>
        <div className={styles.delimiter} />
      </div>
      <div className={styles.tableContainer}>
        <TableHeader />
        <TableContent />
      </div>
    </div>
  );
};
