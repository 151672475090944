import classnames from 'classnames';
import PropTypes from 'prop-types';

export const Radio = (props) => {
  const { className, id, isChecked, isDisabled, isReadOnly, label, labelClassName, name, value, onChange } = props;

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const inputClassName = classnames(className, { 'is-selected': isChecked });

  return (
    <>
      <label className={inputClassName} htmlFor={id}>
        <input
          checked={isChecked}
          className="Radio-input"
          disabled={isDisabled}
          id={id}
          name={name}
          onChange={handleOnChange}
          readOnly={isReadOnly}
          type="radio"
          value={value}
        />
        <span className={labelClassName}>{label}</span>
      </label>
    </>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.shape({})]),
};

Radio.defaultProps = {
  className: 'Radio',
  value: null,
  isChecked: false,
  isDisabled: false,
  onChange: null,
  labelClassName: 'Radio-label',
  isReadOnly: false,
};
