import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip } from 'recharts';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isViewportAboveMobile, isViewportAboveTablet } from 'redux/modules/app/selectors';
import { useGenerationByLocation } from 'tools/hooks/PaidGwap/useGeneretationByLocation';
import { ResizeState } from 'tools/providers/ResizeProvider';
import { gwapGenTypeFilterState } from 'tools/state/gwapState';
import {
  gwapHoveredTimestamp,
  gwapLocationFilterAsLocationsSelector,
  gwapTimeIntervalState,
  isAtLeastOneFilterNotSelectedSelector,
} from 'tools/state/paidGwapState';
import {
  COLOUR_FOR_GEN_TYPE,
  formatGwapXAxisDate,
  getGraphEntryForTimestamp,
  GwapGenerationType,
  GwapLocation,
  GWAP_GENERATION_TYPE_OPTIONS,
  GWAP_LOCATION_OPTIONS,
  LABEL_FOR_LOCATION,
  PaidGwapGraphMargins,
  useGwapBarDimensions,
} from 'tools/utilities/gwap';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { GWAPDelimiter } from 'views/pages/GWAP/GWAPDelimiter';
import { CTATextBox, LegendBox, LegendBoxRow } from '../Legend/LegendBox';
import { TooltipRow, TooltipWrapper } from '../Tooltip/Tooltip';
import styles from './GenerationByLocationGraph.module.scss';
import { GraphNotice } from './GraphNotice';
import { GwapChartErrorBoundary } from './GwapChartErrorBoundary';

const LEGEND_SPLICE_LIMIT = 12;

const CustomTooltip = ({ payload }: { payload: any }) => {
  const locationFilters = useRecoilValue(gwapLocationFilterAsLocationsSelector);
  const genTypeFilters = useRecoilValue(gwapGenTypeFilterState);
  const hoveredData = payload;

  if (!payload) {
    return <></>;
  }

  const legendValues = hoveredData
    ? locationFilters.reduce(
        (reduced: any[], location: GwapLocation) => [
          ...reduced,
          ...genTypeFilters
            .filter((entry: any) => hoveredData[`${LABEL_FOR_LOCATION[location]?.toLowerCase()}_${entry}_wap`])
            .map((genType: GwapGenerationType) => (
              <TooltipRow key={`${LABEL_FOR_LOCATION[location]?.toLowerCase()}_${genType}_wap`}>
                <span>
                  {LABEL_FOR_LOCATION[location]} {genType.toUpperCase()}
                </span>
                <span>
                  {roundToDpWithCommas(hoveredData[`${LABEL_FOR_LOCATION[location]?.toLowerCase()}_${genType}_wap`], 2)}
                </span>
              </TooltipRow>
              //   {
              //   color: COLOUR_FOR_GEN_TYPE[genType],
              //   label: `${LABEL_FOR_LOCATION[location]} ${genType.toUpperCase()}`,
              //   value: hoveredData[`${LABEL_FOR_LOCATION[location].toLowerCase()}_${genType}_mw`],
              // }
            )),
        ],
        [],
      )
    : [];
  const slicedLegendValues = legendValues.slice(0, 8);

  return <TooltipWrapper>{slicedLegendValues.map((legendValue) => legendValue)}</TooltipWrapper>;
};

export const GenerationByLocationGraph = () => {
  const isViewAboveMobile = useSelector(isViewportAboveMobile);
  const isViewAboveTablet = useSelector(isViewportAboveTablet);
  const { generationByLocation, isFetching } = useGenerationByLocation();
  const setHoveredimestamp = useSetRecoilState(gwapHoveredTimestamp);
  const isResizing = useRecoilValue(ResizeState);
  const isOneFilterNotSelected = useRecoilValue(isAtLeastOneFilterNotSelectedSelector);
  const isDataAvailable = generationByLocation && generationByLocation.length > 0 && !isOneFilterNotSelected;
  const timeInterval = useRecoilValue(gwapTimeIntervalState);
  const { width, height } = useGwapBarDimensions();

  // The bottom graph is 78px larger than the others over mobile so need special snowflake sizing.
  const adjustedHeight = isViewAboveMobile ? height + 78 : height;

  const GenerationByLocationChartWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.heading}>GWAP by location ($/MWh)</h3>
        <GWAPDelimiter />
      </div>
      <div className={styles.graphArea}>
        <GwapChartErrorBoundary>{children}</GwapChartErrorBoundary>
      </div>
    </div>
  );

  const renderTooltip = useCallback(
    (data: any) => {
      if (!data.payload) {
        return null;
      }

      try {
        const { timestamp } = data?.payload?.[0]?.payload ?? {};
        setHoveredimestamp(timestamp);
        if (isViewAboveTablet) {
          return '';
        }
        return <CustomTooltip payload={data?.payload[0]?.payload} />;
      } catch (error) {
        // console.error(error);
        return null;
      }
    },
    [isViewAboveTablet, setHoveredimestamp],
  );

  if (isFetching || isResizing) {
    return (
      <GenerationByLocationChartWrapper>
        <GraphNotice message="Loading graph data..." width={width} height={height} />
        {isViewAboveTablet && <Legend graphData={generationByLocation} />}
      </GenerationByLocationChartWrapper>
    );
  }

  if (!isDataAvailable) {
    return (
      <GenerationByLocationChartWrapper>
        <GraphNotice width={width} height={height} />
        {isViewAboveTablet && <Legend graphData={generationByLocation} />}
      </GenerationByLocationChartWrapper>
    );
  }

  return (
    <GenerationByLocationChartWrapper>
      <ResponsiveContainer width={width} height={adjustedHeight}>
        <LineChart
          throttleDelay={50}
          syncId={1}
          width={844}
          data={generationByLocation}
          margin={PaidGwapGraphMargins(isViewAboveTablet, true)}
        >
          <CartesianGrid strokeOpacity={0.3} vertical={false} strokeDasharray="3 1" />
          <XAxis
            tickFormatter={(date: any) => formatGwapXAxisDate(date, timeInterval)}
            minTickGap={isViewAboveMobile ? 100 : 75}
            tick={{ stroke: 'white', fontSize: '12px', strokeWidth: 0.75 }}
            dataKey="timestamp"
          />
          <YAxis
            tickFormatter={(value: any, index: number) => roundToDpWithCommas(value, 0)}
            domain={[
              0,
              // (dataMax: number) => dataMax - (dataMax % 5) + 10,
              (dataMax: any) => {
                if (!isFinite(dataMax)) {
                  return 1;
                }

                return dataMax - (dataMax % 5) + 10;
              },
            ]}
            tick={{ stroke: 'white', fontSize: '12px', strokeWidth: 0.75 }}
          />
          <Tooltip content={renderTooltip} />

          {GWAP_GENERATION_TYPE_OPTIONS.map((generationOption) =>
            GWAP_LOCATION_OPTIONS.map((locationOption) => {
              const lineName = `${locationOption.label}_${generationOption.generationType}_wap`.toLowerCase();
              return (
                <Line
                  key={lineName}
                  type="linear"
                  dataKey={lineName}
                  dot={false}
                  stroke={generationOption.color}
                  fill={generationOption.color}
                />
              );
            }),
          )}
        </LineChart>
      </ResponsiveContainer>
      {isViewAboveTablet && <Legend graphData={generationByLocation} />}
    </GenerationByLocationChartWrapper>
  );
};

const Legend = ({ graphData }: { graphData: any[] }) => {
  const locationFilters = useRecoilValue(gwapLocationFilterAsLocationsSelector);
  const genTypeFilters = useRecoilValue(gwapGenTypeFilterState);

  const timestamp = useRecoilValue(gwapHoveredTimestamp);
  const hoveredData = getGraphEntryForTimestamp(timestamp, graphData);

  const legendValues = hoveredData
    ? locationFilters.reduce(
        (reduced: any[], location: GwapLocation) => [
          ...reduced,
          ...genTypeFilters
            .map((genType: GwapGenerationType) => ({
              color: COLOUR_FOR_GEN_TYPE[genType],
              label: `${LABEL_FOR_LOCATION[location]} ${genType.toUpperCase()}`,
              value: hoveredData[`${LABEL_FOR_LOCATION[location]?.toLowerCase()}_${genType}_wap`],
            }))
            .filter((entry: any) => entry.value),
        ],
        [],
      )
    : [];

  const hasTooManyRows = legendValues.length >= LEGEND_SPLICE_LIMIT;
  const slicedLegendValues = hasTooManyRows ? legendValues.slice(0, LEGEND_SPLICE_LIMIT - 1) : legendValues; // Need room for the CTA tile.
  // Colour comes from the gen type label from gen type + location and value comes from the actual record in the graph

  // Would be nice to have an output like this. colour, label, value

  // const filteredGenTypes = GWAP_BY_LOCATION_SUMMARY_LABELS.filter(
  //   (option) => genTypeFilters.includes(option.genType) && locationFilters.includes(option.location),
  // );
  // // Need this secondary array to maintain spacing in the summary table
  // const filteredOutGenTypes = GWAP_BY_LOCATION_SUMMARY_LABELS.filter(
  //   (option) => !genTypeFilters.includes(option.genType) || !locationFilters.includes(option.location),
  // );
  return (
    <LegendBox>
      {slicedLegendValues && slicedLegendValues.length > 0 ? (
        <>
          {slicedLegendValues.map((label, index) => (
            <div key={label.label}>
              <LegendBoxRow prefix="$" key={label.label} colour={label.color} label={label.label} value={label.value} />
            </div>
          ))}
          {hasTooManyRows && <CTATextBox ctaText="Download the report for all the data" />}
        </>
      ) : (
        <div className={styles.hoverCTA}>Please hover on the graph for specific readings</div>
      )}
      {/* {filteredOutGenTypes.map((label) => (
        <LegendBoxRow colour="#fff00" label="" value="" key={label.label} />
      ))} */}
      {/* Can always fill in these last ones based purely on the length of the first array or something */}
    </LegendBox>
  );
};
