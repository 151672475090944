import { createContext, useContext } from 'react';
import { TRADER_PRICE_NODE_OPTIONS } from 'redux/modules/nodes/constants';

interface IRTPFilterContext {
  selectedPriceNode: string;
  setSelectedPriceNode: (node: string) => void;
  selectedPriceNodeAsAPIString: string;
}

const defaultValue = {
  selectedPriceNode: TRADER_PRICE_NODE_OPTIONS[0],
  setSelectedPriceNode: () => {},
  selectedPriceNodeAsAPIString: '',
};

export const RTPFilterContext = createContext<IRTPFilterContext>(defaultValue);
export const useRTPFilter = () => useContext(RTPFilterContext);
