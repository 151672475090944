// @ts-nocheck
/* eslint-disable @typescript-eslint/naming-convention */
import { takeEvery, call, put } from 'redux-saga/effects';
import { formatApiData } from 'tools/utilities/data';

import { INodePriceInterval, INode, INodeLoad, ResidualItem } from 'types.d';
import { NodeType, ReportType } from 'enums.d';
import { AxiosResponse } from 'axios';
import {
  getIntervals as getIntervalsApi,
  getNodesDetails as getNodesDetailsApi,
  getScadaReport as getScadaReportApi,
  getPriceReport as getPriceReportApi,
  getReservesReport as getReservesReportApi,
  getArcFlowsReport as getArcFlowsReportApi,
  getRcpd48Hrs as getRcpd48HrsApi,
  getRcpd2Hrs as getRcpd2HrsApi,
  getRcpdPeaks as getRcpdPeaksApi,
  getRcpdReport as getRcpdReportApi,
  getHvdcLast24 as getHvdcLast24Api,
  getNzReservesLast24 as getNzReservesLast24Api,
  getNzSummaryLast24 as getsNzSummaryLast24Api,
  getGenerationByType as getGenerationByTypeApi,
  getRecentLoad as getRecentLoadApi,
  getResiduals as getResidualsApi,
} from './api';
import { actionTrackReportDownloadTime, ITrackReportDownloadTime } from '../analytics/actions';
import {
  IGetIntervals,
  IGetIntervalsSuccess,
  TYPES,
  IGetIntervalsFail,
  IGetNodesDetails,
  IGetNodesDetailsSuccess,
  IGetNodesDetailsFail,
  IGetPriceNodesSuccess,
  IGetPriceNodesFail,
  IGetGenerationNodesSuccess,
  IGetGenerationNodesFail,
  IGetLoadNodesSuccess,
  IGetLoadNodesFail,
  IGetScadaReport,
  IGetScadaReportSuccess,
  IGetScadaReportFail,
  IGetPriceReport,
  IGetPriceReportSuccess,
  IGetPriceReportFail,
  IGetReservesReport,
  IGetReservesReportSuccess,
  IGetReservesReportFail,
  IGetArcFlowsReport,
  IGetArcFlowsReportSuccess,
  IGetArcFlowsReportFail,
  IGetRcpdReport,
  IGetRcpdReportSuccess,
  IGetRcpdReportFail,
  IGetRcpd48Hrs,
  IGetRcpd48HrsSuccess,
  IGetRcpd48HrsFail,
  IGetRcpd2Hrs,
  IGetRcpd2HrsSuccess,
  IGetRcpd2HrsFail,
  IGetRcpdPeaks,
  IGetRcpdPeaksSuccess,
  IGetRcpdPeaksFail,
  IGetHvdcLast24Success,
  actionGetHvdcLast24Success,
  IGetHvdcLast24Fail,
  actionGetHvdcLast24Fail,
  IGetNzReservesLast24Success,
  actionGetNzReservesLast24Success,
  IGetNzReservesLast24Fail,
  actionGetNzReservesLast24Fail,
  IGetsNzSummaryLast24Success,
  actionGetsNzSummaryLast24Success,
  IGetsNzSummaryLast24Fail,
  actionGetsNzSummaryLast24Fail,
  IGetGenerationByTypeSuccess,
  actionGetGenerationByTypeLast24Success,
  IGetGenerationByTypeFail,
  actionGetGenerationByTypeLast24Fail,
  IGetRecentLoad,
  IGetRecentLoadSuccess,
  IGetRecentLoadFail,
  actionGetRecentLoadSuccess,
  actionGetRecentLoadFail,
  IGetPriceNodes,
  IGetLoadNodes,
  IGetResiduals,
  IGetResidualsSuccess,
  IGetResidualsFail,
  actionGetResidualsSuccess,
  actionGetResidualsFail,
} from './actions';

function* getIntervals(action: IGetIntervals) {
  const { ids } = action.payload;

  try {
    const response: AxiosResponse<unknown> = yield call(getIntervalsApi, ids);
    const payload = formatApiData((response as any).data.items) as INodePriceInterval[];

    yield put<IGetIntervalsSuccess>({
      type: TYPES.GET_INTERVALS_SUCCESS,
      payload: {
        intervals: payload,
      },
    });
  } catch (error) {
    yield put<IGetIntervalsFail>({ type: TYPES.GET_INTERVALS_FAIL, payload: { error } });
  }
}

function* getNodesDetails(action: IGetNodesDetails) {
  const { ids, nodeType } = action.payload;

  try {
    const response = yield call(getNodesDetailsApi, ids, nodeType);
    const payload = formatApiData(response.data.items) as INode[];

    yield put<IGetNodesDetailsSuccess>({
      type: TYPES.GET_NODES_DETAILS_SUCCESS,
      payload: {
        nodesDetails: payload,
      },
    });
  } catch (error) {
    yield put<IGetNodesDetailsFail>({ type: TYPES.GET_NODES_DETAILS_FAIL, payload: { error } });
  }
}

function* getPriceNodes(action: IGetPriceNodes) {
  const nodes = action.payload ? action?.payload.nodes : [];

  try {
    const response = yield call(getNodesDetailsApi, nodes, NodeType.PRICE);
    const payload = formatApiData(response.data.items) as INode[];

    yield put<IGetPriceNodesSuccess>({
      type: TYPES.GET_PRICE_NODES_SUCCESS,
      payload: {
        priceNodes: payload,
      },
    });
  } catch (error) {
    yield put<IGetPriceNodesFail>({ type: TYPES.GET_PRICE_NODES_FAIL, payload: { error } });
  }
}

function* getGenerationNodes() {
  try {
    const response = yield call(getNodesDetailsApi, [], NodeType.GENERATION);
    const payload = formatApiData(response.data.items) as INode[];

    yield put<IGetGenerationNodesSuccess>({
      type: TYPES.GET_GENERATION_NODES_SUCCESS,
      payload: {
        generationNodes: payload,
      },
    });
  } catch (error) {
    yield put<IGetGenerationNodesFail>({ type: TYPES.GET_GENERATION_NODES_FAIL, payload: { error } });
  }
}

function* getLoadNodes(action: IGetLoadNodes) {
  const nodes = action.payload ? action.payload.nodes : [];

  try {
    const response = yield call(getNodesDetailsApi, nodes, NodeType.LOAD);
    const payload = formatApiData(response.data.items) as INode[];

    yield put<IGetLoadNodesSuccess>({
      type: TYPES.GET_LOAD_NODES_SUCCESS,
      payload: {
        loadNodes: payload,
      },
    });
  } catch (error) {
    yield put<IGetLoadNodesFail>({ type: TYPES.GET_LOAD_NODES_FAIL, payload: { error } });
  }
}

function* getScadaReport(action: IGetScadaReport) {
  const { criteria, selectedNodes, downloadAll } = action.payload;

  try {
    const response = yield call(getScadaReportApi, criteria, selectedNodes, downloadAll);

    yield put<IGetScadaReportSuccess>({
      type: TYPES.GET_SCADA_REPORT_SUCCESS,
      payload: {
        report: response.data,
      },
    });

    yield put<ITrackReportDownloadTime>(actionTrackReportDownloadTime(ReportType.SCADA));
  } catch (error) {
    yield put<IGetScadaReportFail>({ type: TYPES.GET_SCADA_REPORT_FAIL, payload: { error } });
  }
}

function* getPriceReport(action: IGetPriceReport) {
  const { criteria, selectedNodes, downloadAll } = action.payload;

  try {
    const response = yield call(getPriceReportApi, criteria, selectedNodes, downloadAll);

    yield put<IGetPriceReportSuccess>({
      type: TYPES.GET_PRICE_REPORT_SUCCESS,
      payload: {
        report: response.data,
      },
    });

    yield put<ITrackReportDownloadTime>(actionTrackReportDownloadTime(ReportType.PRICE));
  } catch (error) {
    yield put<IGetPriceReportFail>({ type: TYPES.GET_PRICE_REPORT_FAIL, payload: { error } });
  }
}

function* getReservesReport(action: IGetReservesReport) {
  const { criteria } = action.payload;

  try {
    const response = yield call(getReservesReportApi, criteria);

    yield put<IGetReservesReportSuccess>({
      type: TYPES.GET_RESERVES_REPORT_SUCCESS,
      payload: {
        report: response.data,
      },
    });

    yield put<ITrackReportDownloadTime>(actionTrackReportDownloadTime(ReportType.RESERVES));
  } catch (error) {
    yield put<IGetReservesReportFail>({ type: TYPES.GET_RESERVES_REPORT_FAIL, payload: { error } });
  }
}

function* getArcFlowsReport(action: IGetArcFlowsReport) {
  const { criteria } = action.payload;

  try {
    const response = yield call(getArcFlowsReportApi, criteria);

    yield put<IGetArcFlowsReportSuccess>({
      type: TYPES.GET_ARC_FLOWS_REPORT_SUCCESS,
      payload: {
        report: response.data,
      },
    });

    yield put<ITrackReportDownloadTime>(actionTrackReportDownloadTime(ReportType.ARC_FLOWS));
  } catch (error) {
    yield put<IGetArcFlowsReportFail>({ type: TYPES.GET_ARC_FLOWS_REPORT_FAIL, payload: { error } });
  }
}

function* getRcpdReport(action: IGetRcpdReport) {
  const { criteria } = action.payload;

  try {
    const response = yield call(getRcpdReportApi, criteria);

    yield put<IGetRcpdReportSuccess>({
      type: TYPES.GET_RCPD_REPORT_SUCCESS,
      payload: {
        report: response.data,
      },
    });
  } catch (error) {
    yield put<IGetRcpdReportFail>({ type: TYPES.GET_RCPD_REPORT_FAIL, payload: { error } });
  }
}

function* getRcpd48Hrs(action: IGetRcpd48Hrs) {
  const { criteria } = action.payload;

  try {
    const response = yield call(getRcpd48HrsApi, criteria);

    yield put<IGetRcpd48HrsSuccess>({
      type: TYPES.GET_RCPD_48HRS_SUCCESS,
      payload: {
        rcpd48hrData: response.data,
      },
    });
  } catch (error) {
    yield put<IGetRcpd48HrsFail>({ type: TYPES.GET_RCPD_48HRS_FAIL, payload: { error } });
  }
}

function* getRcpd2Hrs(action: IGetRcpd2Hrs) {
  const { criteria } = action.payload;

  try {
    const response = yield call(getRcpd2HrsApi, criteria);

    yield put<IGetRcpd2HrsSuccess>({
      type: TYPES.GET_RCPD_2HRS_SUCCESS,
      payload: {
        rcpd2hrData: response.data,
      },
    });
  } catch (error) {
    yield put<IGetRcpd2HrsFail>({ type: TYPES.GET_RCPD_2HRS_FAIL, payload: { error } });
  }
}

function* getRcpdPeaks(action: IGetRcpdPeaks) {
  const { criteria } = action.payload;

  try {
    const response = yield call(getRcpdPeaksApi, criteria);
    const { rcpd_region_id, cm_year } = response.data;

    yield put<IGetRcpdPeaksSuccess>({
      type: TYPES.GET_RCPD_PEAKS_SUCCESS,
      payload: {
        rcpdPeaks: {
          rcpdRegionId: rcpd_region_id,
          cmYear: cm_year,
          items: formatApiData(response.data.items),
        },
      },
    });
  } catch (error) {
    yield put<IGetRcpdPeaksFail>({ type: TYPES.GET_RCPD_PEAKS_FAIL, payload: { error } });
  }
}

function* getHvdcLast24(/* action: IGetHvdcLast24 */) {
  try {
    const response = yield call(getHvdcLast24Api);
    yield put<IGetHvdcLast24Success>(
      actionGetHvdcLast24Success({
        ...response.data,
        items: formatApiData(response.data.items),
      }),
    );
  } catch (error) {
    yield put<IGetHvdcLast24Fail>(actionGetHvdcLast24Fail(error));
  }
}

function* getNzReservesLast24() {
  try {
    const response = yield call(getNzReservesLast24Api);
    yield put<IGetNzReservesLast24Success>(
      actionGetNzReservesLast24Success({
        ...response.data,
        items: formatApiData(response.data.items),
      }),
    );
  } catch (error) {
    yield put<IGetNzReservesLast24Fail>(actionGetNzReservesLast24Fail(error));
  }
}

function* getsNzSummaryLast24() {
  try {
    const response = yield call(getsNzSummaryLast24Api);
    yield put<IGetsNzSummaryLast24Success>(
      actionGetsNzSummaryLast24Success({
        ...response.data,
        items: formatApiData(response.data.items),
      }),
    );
  } catch (error) {
    yield put<IGetsNzSummaryLast24Fail>(actionGetsNzSummaryLast24Fail(error));
  }
}

function* getGenerationByType(/* action: IGetRecentLoad */) {
  try {
    const response = yield call(getGenerationByTypeApi); // Check what the data would be.
    yield put<IGetGenerationByTypeSuccess>(
      actionGetGenerationByTypeLast24Success({
        ...response.data,
        items: response.data.items.map((item: any) => ({
          timestamp: item.timestamp,
          tradingPeriod: item.trading_period,
          islandid: item.islandid,
          generationType: formatApiData(item.generation_type),
        })),
      }),
    );
  } catch (error) {
    yield put<IGetGenerationByTypeFail>(actionGetGenerationByTypeLast24Fail(error));
  }
}

function* getResiduals(action: IGetResiduals) {
  try {
    const response = yield call(getResidualsApi, action.payload);
    yield put<IGetResidualsSuccess>(
      actionGetResidualsSuccess({
        ...response.data,
        items: formatApiData(response.data.items),
      }),
    );
  } catch (error) {
    yield put<IGetResidualsFail>(actionGetResidualsFail(error));
  }
}

function* getRecentLoad(action: IGetRecentLoad) {
  const { nodes } = action.payload;

  try {
    const response = yield call(getRecentLoadApi, nodes); // Check what the data would be.
    const formattedResponse = formatApiData(response.data.items) as INodeLoad[];
    yield put<IGetRecentLoadSuccess>(actionGetRecentLoadSuccess(formattedResponse));
  } catch (error) {
    yield put<IGetRecentLoadFail>(actionGetRecentLoadFail(error));
  }
}

export function* nodesSaga() {
  yield takeEvery(TYPES.GET_INTERVALS, getIntervals);
  yield takeEvery(TYPES.GET_NODES_DETAILS, getNodesDetails);
  yield takeEvery(TYPES.GET_PRICE_NODES, getPriceNodes);
  yield takeEvery(TYPES.GET_GENERATION_NODES, getGenerationNodes);
  yield takeEvery(TYPES.GET_LOAD_NODES, getLoadNodes);
  yield takeEvery(TYPES.GET_SCADA_REPORT, getScadaReport);
  yield takeEvery(TYPES.GET_PRICE_REPORT, getPriceReport);
  yield takeEvery(TYPES.GET_RESERVES_REPORT, getReservesReport);
  yield takeEvery(TYPES.GET_ARC_FLOWS_REPORT, getArcFlowsReport);
  yield takeEvery(TYPES.GET_RCPD_REPORT, getRcpdReport);
  yield takeEvery(TYPES.GET_RCPD_48HRS, getRcpd48Hrs);
  yield takeEvery(TYPES.GET_RCPD_2HRS, getRcpd2Hrs);
  yield takeEvery(TYPES.GET_RCPD_PEAKS, getRcpdPeaks);
  yield takeEvery(TYPES.GET_HVDC_LAST_24, getHvdcLast24);
  yield takeEvery(TYPES.GET_NZ_RESERVES_LAST_24, getNzReservesLast24);
  yield takeEvery(TYPES.GET_NZ_SUMMARY_LAST_24, getsNzSummaryLast24);
  yield takeEvery(TYPES.GET_GENERATION_BY_TYPE_LAST_24, getGenerationByType);
  yield takeEvery(TYPES.GET_RESIDUALS, getResiduals);
  yield takeEvery(TYPES.GET_RECENT_LOAD, getRecentLoad);
}
