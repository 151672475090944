import React, { useState } from 'react';

import './NodeSearch.scss';

import { Icon } from 'views/components/Icon/Icon';

interface IProps {
  onChange: (value: string) => void;
}

export const NodeSearch = ({ onChange }: IProps) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const clearInput = () => {
    setValue('');
    onChange('');
  };

  return (
    <div className="NodeSearch">
      <label htmlFor="nodeSearchInput">Search nodes</label>
      <input
        id="nodeSearchInput"
        value={value}
        onChange={handleChange}
        className="NodeSearch-input"
        placeholder="Search nodes e.g. HAY2201 or Haywards"
      />
      <Icon className="NodeSearch-searchIcon" name="ic-search" size="sm" />
      {value && (
        <button onClick={clearInput} className="NodeSearch-clearInput" type="button">
          <Icon name="ic-remove" size="md" />
          <span className="ScreenReadersOnly">Clear input</span>
        </button>
      )}
    </div>
  );
};
