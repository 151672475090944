import { DateFilterOption, EmissionsType, GenerationType } from 'enums.d';

import { getNodesSelectedString } from 'tools/utilities/getNodesSelectedString';

import { CriteriaOption, UpdateFiltersFunc } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';

import { SelectionCriteria } from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { CustomDateSelection } from 'views/components/SelectionCriteria/CustomDateSelection/CustomDateSelection';

import { useEmissionsReport } from 'tools/hooks/useEmissionsReport';
import { SelectionDownload } from '../SelectionCriteria/SelectionDownload/SelectionDownload';

const EMISSIONS_TYPES: CriteriaOption[] = [
  { label: 'NZ Emissions', id: EmissionsType.NZ },
  { label: 'Generation Emissions', id: EmissionsType.GENERATION },
];
interface IProps {
  selectedGenerationTypes: GenerationType[];
  areAllNodesSelected: boolean;
}

export const EmissionsSelectionCriteria = ({ selectedGenerationTypes, areAllNodesSelected }: IProps) => {
  const {
    isLoadingReport,
    emissionsType,
    dateOption,
    fromTradingDate,
    toTradingDate,
    setFromTradingDate,
    setToTradingDate,
    setEmissionsType,
    setDateOption,
    attemptReportDownload,
  } = useEmissionsReport();

  const GENERATION_DATE_OPTIONS: CriteriaOption[] = [
    { label: 'Today', id: DateFilterOption.TODAY },
    { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
    // { label: 'Previous 3 Days', id: DateFilterOption.PREVIOUS_3_DAYS },
    { label: 'Previous 7 Days', id: DateFilterOption.PREVIOUS_7_DAYS },
    // { label: 'Month to Date', id: DateFilterOption.MONTH_TO_DATE },
    { label: 'Previous Month', id: DateFilterOption.PREVIOUS_MONTH },
    { label: 'Year to Date', id: DateFilterOption.YEAR_TO_DATE },
    { label: 'Previous Year', id: DateFilterOption.PREVIOUS_YEAR },
    {
      id: DateFilterOption.CUSTOM,
      label: 'Custom',
      body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
        <CustomDateSelection
          updateFilters={updateFilters}
          resetState={resetState}
          defaultStartDate={fromTradingDate}
          defaultEndDate={toTradingDate}
          canBeFutureDate={false}
        />
      ),
    },
  ];

  const EMISSIONS_DATE_OPTIONS: CriteriaOption[] = [
    { label: 'Today', id: DateFilterOption.TODAY },
    { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
    // { label: 'Previous 3 Days', id: DateFilterOption.PREVIOUS_3_DAYS },
    { label: 'Previous 7 Days', id: DateFilterOption.PREVIOUS_7_DAYS },
    // { label: 'Month to Date', id: DateFilterOption.MONTH_TO_DATE },
    { label: 'Previous Month', id: DateFilterOption.PREVIOUS_MONTH },
    { label: 'Year to Date', id: DateFilterOption.YEAR_TO_DATE },
    { label: 'Previous Year', id: DateFilterOption.PREVIOUS_YEAR },
    {
      id: DateFilterOption.CUSTOM,
      label: 'Custom',
      body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
        <CustomDateSelection
          updateFilters={updateFilters}
          resetState={resetState}
          defaultStartDate={fromTradingDate}
          defaultEndDate={toTradingDate}
          canBeFutureDate={false}
        />
      ),
    },
  ];

  const dateOptions = emissionsType === EmissionsType.GENERATION ? GENERATION_DATE_OPTIONS : EMISSIONS_DATE_OPTIONS;

  const handleCustomDateChange = (key: string, filterOptionId: string) => {
    if (key === 'fromTradingDate' && filterOptionId) {
      setFromTradingDate(filterOptionId);
    } else if (key === 'toTradingDate' && filterOptionId) {
      setToTradingDate(filterOptionId);
    }
  };

  return (
    <SelectionCriteria>
      <CriteriaAccordian
        initialOption={EMISSIONS_TYPES.find(({ id }) => id === emissionsType)}
        options={EMISSIONS_TYPES}
        onChange={(option: CriteriaOption) => setEmissionsType(option.id as any as EmissionsType)}
        updateCriteria={() => {}}
      />

      <CriteriaAccordian
        // key={`${criteria.priceOption}${criteria.priceRunType}`}
        initialOption={dateOptions.find(({ id }) => id === dateOption)}
        options={dateOptions}
        onChange={(option: CriteriaOption) => setDateOption(option.id as any as DateFilterOption)}
        updateCriteria={handleCustomDateChange}
        headerIcon="ic-calendar"
      />

      <SelectionDownload
        isLoading={isLoadingReport}
        isDisabled={isLoadingReport}
        isVisibleOnMobile={emissionsType === EmissionsType.GENERATION ? selectedGenerationTypes.length > 0 : true}
        downloadAction={attemptReportDownload}
        needsSelectedNodesForDownload={
          selectedGenerationTypes.length === 0 && emissionsType === EmissionsType.GENERATION
        }
        text={
          emissionsType === EmissionsType.GENERATION
            ? getNodesSelectedString(selectedGenerationTypes.length, areAllNodesSelected)
            : undefined
        }
        forceCorrectDateForDownload={dateOption === DateFilterOption.CUSTOM}
      />
    </SelectionCriteria>
  );
};
