import React from 'react';

import './NodeSelection.scss';

import { GWAP_GENERATION_TYPE_OPTIONS } from 'tools/utilities/gwap';
import { useEmissionsReport } from 'tools/hooks/useEmissionsReport';
import styles from './GenerationTypeSelection.module.scss';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox/IndeterminateCheckbox';

interface IProps {
  renderHeader: () => React.ReactNode;
}

export const GenerationTypeSelection = ({ renderHeader }: IProps) => {
  const { selectedGenerationTypes, toggleNode } = useEmissionsReport();

  return (
    <div className={styles.wrapper}>
      {renderHeader()}
      <div className={styles.delimeter} />
      <div className={styles.rows}>
        {GWAP_GENERATION_TYPE_OPTIONS.map((genType: any) => (
          <div className={styles.row} key={genType.generationType}>
            <button className={styles.button} type="button" onClick={() => toggleNode(genType.generationType)}>
              {genType.label}
            </button>

            <IndeterminateCheckbox
              onToggle={() => toggleNode(genType.generationType)}
              isSelected={selectedGenerationTypes.includes(genType.generationType)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
