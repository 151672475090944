import _ from 'lodash';
import moment from 'moment/moment';
import { useQuery } from 'react-query';
import { makeUnauthorisedGetRequest } from '../utilities/ajax';

const ONE_MINUTE = 60 * 1000;
const queryConfigs = {
  staleTime: 1,
  refetchInterval: ONE_MINUTE,
  refetchIntervalInBackground: true,
  refetchOnWindowFocus: true,
};

// Use this function if you want to print the output at any point within a `_.flow()` pipeline.
//
// const printInFlow = (message: string) => (a: any) => {
//   console.log(message, a);
//   return a;
// };

type RawWindForecastDataPoint = {
  timestamp: string; // ISO 8601
  percentile_90: number;
  percentile_50: number;
  percentile_10: number;
  offered: number | null;
  shortfall: number | null;
};

export type WindForecastDataPoint = {
  timestamp: number; // Unix timestamp
  percentile_90: number;
  percentile_50: number;
  percentile_10: number;
  percentileRange: [number, number];
  offered: number | null;
  discrepancy: number | null;
};

export type WindDataPoint = WindForecastDataPoint & HistoricalWindGenerationData;
export type WindDataPointNullable = { [K in keyof WindDataPoint]: WindDataPoint[K] | null } & { timestamp: number };

export function transformWindForecastData(
  rawWindForecastDataPoints: RawWindForecastDataPoint[] = [],
): WindForecastDataPoint[] {
  return rawWindForecastDataPoints.map(
    ({ timestamp, percentile_90, percentile_50, percentile_10, offered, shortfall }) => ({
      timestamp: moment(new Date(timestamp)).valueOf(),
      percentile_90,
      percentile_50,
      percentile_10,
      offered,
      percentileRange: [percentile_10, percentile_90],
      discrepancy: shortfall,
    }),
  );
}

interface RawWindHistoricalData {
  generationmw: number;
  timestamp: string; // ISO 8601, e.g. "2023-03-23T21:00:00Z"
  trading_period: number;
}

function transformHistoricalData(rawWindHistoricalData: RawWindHistoricalData[] = []): HistoricalWindGenerationData[] {
  return rawWindHistoricalData.map((data) => ({
    ...data,
    timestamp: moment(data.timestamp).valueOf(),
  }));
}

export type HistoricalWindGenerationData = {
  timestamp: number; // Unix timestamp
  generationmw: number;
  trading_period: number;
};

function transformData(
  historicalData: HistoricalWindGenerationData[],
  forecastData: WindForecastDataPoint[],
): WindDataPointNullable[] {
  return _.flow(makeNullable, sort)([...historicalData, ...forecastData]);

  function makeNullable(arr: (HistoricalWindGenerationData | WindForecastDataPoint)[]): WindDataPointNullable[] {
    return arr.map((item) => ({
      discrepancy: null,
      generationmw: null,
      offered: null,
      percentileRange: null,
      percentile_10: null,
      percentile_50: null,
      percentile_90: null,
      trading_period: null,
      ...item,
    }));
  }

  function sort(arr: WindDataPointNullable[]) {
    return new Array(...arr).sort((a, b) => a.timestamp - b.timestamp);
  }
}

export const useWindForecastData = () => {
  const { data: rawHistoricalData } = useQuery<{
    items: RawWindHistoricalData[];
    updated: number;
  }>(
    ['windHistoricalData'],
    () =>
      makeUnauthorisedGetRequest(`ni_wind_generation`).then((resp: any) => ({
        ...resp.data,
        updated: moment().valueOf(),
      })),
    queryConfigs,
  );

  const { data: rawForecastData } = useQuery<{
    items: any[];
    updated: number;
  }>(
    ['windForecastData'],
    () =>
      makeUnauthorisedGetRequest(`windforecast`).then((resp: any) => ({
        ...resp.data,
        updated: moment().valueOf(),
      })),
    queryConfigs,
  );

  const historicalData = transformHistoricalData(rawHistoricalData?.items);
  const forecastData = transformWindForecastData(rawForecastData?.items);

  return {
    data: transformData(historicalData, forecastData),
    lastUpdated:
      rawHistoricalData?.updated &&
      rawForecastData?.updated &&
      Math.max(rawHistoricalData?.updated, rawForecastData?.updated),
    isLoading: !rawHistoricalData || !rawForecastData,
  };
};
