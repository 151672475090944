import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import './ForgotPassword.scss';

import { validateEmail } from 'tools/utilities/validators';

import { actionForgotPassword, ForgotPasswordError, FORGOT_PASSWORD_ERROR_CODES } from 'redux/modules/session/actions';
import {
  hasSentForgottenPasswordRequest as hasSentForgottenPasswordRequestSelector,
  getForgotPasswordError,
  isLoading as isLoadingSelector,
} from 'redux/modules/session/selectors';

import { Button } from 'views/components/Button/Button';
import { TextInput } from 'views/components/TextInput/TextInput';
import { FormContainer } from 'views/components/FormContainer/FormContainer';
import { Icon } from 'views/components/Icon/Icon';
import { ROUTE_PATHS } from '../../../constants/routes';

const getAPIErrorMessage = (error: ForgotPasswordError): string => {
  switch (error.code) {
    case FORGOT_PASSWORD_ERROR_CODES.USER_NOT_FOUND_EXCEPTION:
      return 'FORGOT_PASSWORD_UNREGISTERED_EMAIL';
    default:
      return 'FORGOT_PASSWORD_UNREGISTERED_EMAIL';
  }
};

type FormValues = {
  email: string;
};

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('');

  const dispatch = useDispatch();

  const forgotPasswordError = useSelector(getForgotPasswordError);
  const hasSentForgottenPasswordRequest = useSelector(hasSentForgottenPasswordRequestSelector);
  const isLoading = useSelector(isLoadingSelector);

  const { handleSubmit, errors, control, formState } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = ({ email: formEmail }) => {
    setEmail(formEmail);
    dispatch(actionForgotPassword(formEmail));
  };

  return (
    <div className="ForgotPassword">
      <FormContainer>
        {hasSentForgottenPasswordRequest ? (
          <>
            <Icon name="ic-success" className="Icon Form-mainIcon" />
            <h2 className="Form-title">
              <FormattedMessage id="EMAIL_SENT" />
            </h2>
            <p className="Form-text">
              <FormattedMessage id="WEVE_SENT_A_LINK" values={{ userEmail: email }} />
            </p>
            <p className="Form-text">
              <FormattedMessage
                id="IF_YOU_DO_NOT_RECEIVE_A_LINK"
                values={{
                  // @ts-ignore
                  a: (...chunks: any) => <a href="mailto:call@ems.co.nz">{chunks}</a>,
                }}
              />
            </p>
            <Button className="Button" buttonType="button" label="Back to login" url={ROUTE_PATHS.LOGIN} />
          </>
        ) : (
          <>
            <h2 className="Form-title">
              <FormattedMessage id="FORGOT_PASSWORD" />
            </h2>
            <form className="Form-form" onSubmit={handleSubmit(onSubmit)}>
              <p className="Form-text">
                <FormattedMessage id="ENTER_YOUR_EMAIL" />
              </p>
              <Controller
                control={control}
                name="email"
                render={({ onChange, onBlur, value }) => (
                  <TextInput
                    id="email"
                    label="Email"
                    type="email"
                    value={value}
                    placeholder="e.g. name@domain.co.nz"
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.email?.message}
                    showErrorOutLine={!!forgotPasswordError}
                  />
                )}
                rules={{
                  required: 'Please enter your email address.',
                  validate: (value) => validateEmail(value) || 'Please enter a valid email address.',
                }}
              />
              {forgotPasswordError && formState.isSubmitted && (
                <p className="Login-error">
                  <FormattedMessage id={getAPIErrorMessage(forgotPasswordError)} />
                  <Icon name="ic-error" className="Login-error-icon Icon--sm" />
                </p>
              )}
              <Button
                className="ForgotPassword-sendLinkButton"
                buttonType="submit"
                label="Send link"
                isLoading={isLoading}
              />
              <Button
                className="Button Button--secondary"
                buttonType="button"
                label="Back to login"
                url={ROUTE_PATHS.LOGIN}
              />
            </form>
          </>
        )}
      </FormContainer>
    </div>
  );
};
