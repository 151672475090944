import moment from 'moment';
import { useQuery } from 'react-query';
import { makeGetRequest } from 'tools/utilities/ajax';
import { getTpAsMillis, getTradingPeriodForDate } from 'tools/utilities/date';
import { ToggleState, useLoadForecastFilter } from './useLoadForecastFilter';

interface IRecentLoadResponse {
  node_id: string;
  max_7_day: number;
  scada_mw_current: number;
  mw_change: number;
  pct_7_day: number;
  timestamp: string;
  trading_period: number;
  trading_date: string;
}

interface IActualRegionLoadResponse {
  region: string;
  max_7_day: number;
  scada_mw_current: number;
  mw_change: number;
  pct_7_day: number;
  timestamp: string;
  trading_period: number;
  trading_date: string;
}

interface ILoadForecastResponseForecastItemDto {
  timestamp: string;
  tradingperiod: number;
  load_mw: number;
  node_id: string;
  runtype: string;
}

interface ILoadForecastResponseItem {
  run_time: string;
  forecasts: ILoadForecastResponseForecastItemDto[];
}

interface IGraphableData {
  timestamp: number; // milliseconds
  tradingPeriod: number;
  timestampString: string;
  loadActual?: number;
  loadForecast1?: number;
  loadForecast2?: number;
  loadForecast3?: number;
  loadForecast4?: number;
  loadForecast5?: number;
}

export interface IScadaResponse {
  country: 'NZ';
  island: 'NI';
  node_id: string;
  node_name: string;
  trading_date: string; // DD/MM/YYYY
  daily_energy_mw: number;
  mean_energy_mw: number;
  tp1_mw: number | null;
  tp2_mw: number | null;
  tp3_mw: number | null;
  tp4_mw: number | null;
  tp5_mw: number | null;
  tp6_mw: number | null;
  tp7_mw: number | null;
  tp8_mw: number | null;
  tp9_mw: number | null;
  tp10_mw: number | null;
  tp11_mw: number | null;
  tp12_mw: number | null;
  tp13_mw: number | null;
  tp14_mw: number | null;
  tp15_mw: number | null;
  tp16_mw: number | null;
  tp17_mw: number | null;
  tp18_mw: number | null;
  tp19_mw: number | null;
  tp20_mw: number | null;
  tp21_mw: number | null;
  tp22_mw: number | null;
  tp23_mw: number | null;
  tp24_mw: number | null;
  tp25_mw: number | null;
  tp26_mw: number | null;
  tp27_mw: number | null;
  tp28_mw: number | null;
  tp29_mw: number | null;
  tp30_mw: number | null;
  tp31_mw: number | null;
  tp32_mw: number | null;
  tp33_mw: number | null;
  tp34_mw: number | null;
  tp35_mw: number | null;
  tp36_mw: number | null;
  tp37_mw: number | null;
  tp38_mw: number | null;
  tp39_mw: number | null;
  tp40_mw: number | null;
  tp41_mw: number | null;
  tp42_mw: number | null;
  tp43_mw: number | null;
  tp44_mw: number | null;
  tp45_mw: number | null;
  tp46_mw: number | null;
  tp47_mw: number | null;
  tp48_mw: number | null;
  tp49_mw: number | null;
  tp50_mw: number | null;
}

const TICK_TP_INTERVAL = 6;

const mapServerResponseActualToDto = (
  response: IRecentLoadResponse[] | IActualRegionLoadResponse[],
): IGraphableData[] =>
  response.map((item) => ({
    timestamp: moment(item.timestamp).valueOf(),
    tradingPeriod: item.trading_period,
    timestampString: item.timestamp,
    loadActual: item.scada_mw_current,
  }));

const getTickTimestampsForInterval = (records: IGraphableData[]): number[] =>
  // Filter through keeping only the records that are in a trading period that is a multiple of the tick interval.
  // then map those records to their timestamp.
  records
    .filter((record) => {
      const tradingPeriod = getTradingPeriodForDate(moment(record.timestampString).toDate());
      return tradingPeriod % TICK_TP_INTERVAL === 0;
    })
    .map((record) => moment(record.timestampString).valueOf());

// Takes a list of forecast each with multiple predicted readings for future times
// outputs a list of records with a timestamp and the 5 forecasts for that timestamp
const convertToGraphableData = (records: ILoadForecastResponseItem[]): IGraphableData[] => {
  const condensedRecordsMap: Map<string, IGraphableData> = new Map();
  records.forEach((record) => {
    record.forecasts?.forEach((forecast) => {
      const existingRecord = condensedRecordsMap.get(forecast.timestamp);
      const forecastIndex = records.indexOf(record) + 1;
      if (existingRecord) {
        // If there is an existing record then combine the two records into one.
        const combinedRecord = {
          ...existingRecord,
          [`loadForecast${forecastIndex}`]: forecast.load_mw,
        };

        // Add the combined record to the map.
        condensedRecordsMap.set(forecast.timestamp, combinedRecord);
      } else {
        condensedRecordsMap.set(forecast.timestamp, {
          timestamp: moment(forecast.timestamp).valueOf(),
          timestampString: forecast.timestamp,
          [`loadForecast${forecastIndex}`]: forecast.load_mw,
          tradingPeriod: forecast.tradingperiod,
        });
      }
    });
  });
  return Array.from(condensedRecordsMap.values());
};

// Takes the forecast data and returns a map of the forecast to forecastRuntime that the forecast was generated at. e.g loadForecast1: 1234422112
const getForecastRuntimes = (records: ILoadForecastResponseItem[]): { [key: string]: number } => {
  const forecastRunTimes: { [key: string]: number } = {};
  records.forEach((record) => {
    const forecastIndex = records.indexOf(record) + 1;
    forecastRunTimes[`loadForecast${forecastIndex}`] = moment(record.run_time).valueOf();
  });
  return forecastRunTimes;
};

const convertScadaResponseToGraphableData = (scadaResponse: IScadaResponse): IGraphableData[] => {
  const graphableData: IGraphableData[] = [];

  const tpKeys = Object.keys(scadaResponse).filter((key) => key.includes('tp'));
  const isYesterday = moment(scadaResponse.trading_date, 'DD/MM/YYYY').isBefore(moment(), 'day');

  tpKeys.forEach((key) => {
    const tpNumber = parseInt(key.replace('tp', '').replace('_mw', ''), 10);
    // Util method assumes today's date
    // if it was yesterday then remove 24 hours
    const timestamp = isYesterday
      ? moment(getTpAsMillis(tpNumber)).subtract(1, 'day').valueOf()
      : getTpAsMillis(tpNumber);

    // Ignore null values
    if (scadaResponse[key as keyof IScadaResponse] === null) {
      return;
    }

    graphableData.push({
      timestamp,
      loadActual: scadaResponse[key as keyof IScadaResponse] as number,
      tradingPeriod: tpNumber,
      timestampString: moment(timestamp).toLocaleString(),
    });
  });

  return graphableData;
};

interface IUseLoadForecast {
  loadForecast: IGraphableData[];
  firstReadingTimestamp: number;
  xTicks: number[];
  forecastRuntimes: {
    [key: string]: number;
  };
  isFetchingRegion: boolean;
  isFetchingNode: boolean;
  updated: number;
}

/**
 * Aligns records by timestamp.
 *
 * Fixes a bug where the actual data and forecast data weren't matching up correctly when the
 * user hovered with their mouse.
 *
 * @param records
 */
function deduplicateRecords(records: IGraphableData[]) {
  // Combines records with the same timestamp
  const result: Map<number, IGraphableData> = new Map();
  for (const record of records) {
    const existingRecord = result.get(record.timestamp);
    result.set(record.timestamp, {
      ...existingRecord,
      ...record,
    });
  }
  return Array.from(result.values());
}

const getActualNodeLoadData = (
  rawScadaLoadData: { items: any[]; updated: number } | undefined,
  rawRecentLoadData: { items: IRecentLoadResponse[]; updated: number } | undefined,
) => {
  /**
   * The SCADA load data has the long-term actual data, but doesn't have the data for the one or two most recent
   * trading periods.
   * The recent load API has the most recent trading periods that the SCADA API lacks, which is why we combine the two.
   */
  const convertedScadaLoadData = rawScadaLoadData?.items.map(convertScadaResponseToGraphableData);
  const flattenedScadaLoadData = convertedScadaLoadData?.reduce((acc, val) => [...acc, ...val], []) ?? [];
  const transformedPreviousData = mapServerResponseActualToDto(rawRecentLoadData?.items ?? []);
  return [...flattenedScadaLoadData, ...transformedPreviousData];
};

const getActualRegionLoadData = (
  rawActualRegionLoadData: { items: IActualRegionLoadResponse[]; updated: number } | undefined,
) => mapServerResponseActualToDto(rawActualRegionLoadData?.items ?? []);

const getPredictedLoadData = (
  toggleValue: ToggleState,
  rawPredictedRegionLoadData: { items: ILoadForecastResponseItem[]; updated: number } | undefined,
  rawPredictedNodeLoadData: { items: ILoadForecastResponseItem[]; updated: number } | undefined,
) =>
  toggleValue === ToggleState.REGIONS
    ? convertToGraphableData(rawPredictedRegionLoadData?.items || [])
    : convertToGraphableData(rawPredictedNodeLoadData?.items || []);

const getActualLoadData = (
  toggleValue: ToggleState,
  rawActualRegionLoadData: { items: IActualRegionLoadResponse[]; updated: number } | undefined,
  rawScadaLoadData: { items: any[]; updated: number } | undefined,
  rawRecentLoadData: { items: IRecentLoadResponse[]; updated: number } | undefined,
) =>
  toggleValue === ToggleState.REGIONS
    ? getActualRegionLoadData(rawActualRegionLoadData)
    : getActualNodeLoadData(rawScadaLoadData, rawRecentLoadData);

/**
 * Converts a string to the acronym abbreviation.
 * For example, 'Upper North Island' => 'UNI'.
 * @param val string to abbreviate
 */
const abbreviate = (val: string) =>
  val
    .split(' ')
    .reduce((acc, word: string) => acc + word[0], '')
    .toUpperCase();
const nodeToApiString = (node: string) => (node === 'All nodes' ? '' : node);
const regionToApiString = (region: string) => (region === 'Nationwide' ? 'NZ' : abbreviate(region));

export const useLoadForecast = (): IUseLoadForecast => {
  // go back 24 hours and forward 36 hours
  const dateFrom = moment().subtract(18, 'hours');
  const dateTo = moment().add(36, 'hours');
  const { selectedLoadNode, selectedLoadRegion, toggleValue } = useLoadForecastFilter();

  const nodeApiString = nodeToApiString(selectedLoadNode);
  const regionApiString = regionToApiString(selectedLoadRegion);

  const { data: rawPredictedNodeLoadData } = useQuery<{
    items: ILoadForecastResponseItem[];
    updated: number;
  }>(['rawPredictedNodeLoadData', nodeApiString], () =>
    makeGetRequest(
      `loadforecast/predictions/?from_trading_date=${dateFrom.format('DD/MM/YYYY')}&to_trading_date=${dateTo.format(
        'DD/MM/YYYY',
      )}&run_type=L&node_id=${nodeApiString}`,
    ).then((resp: any) => ({
      ...resp.data,
      updated: moment().valueOf(),
    })),
  );

  const { data: rawPredictedRegionLoadData } = useQuery<{
    items: ILoadForecastResponseItem[];
    updated: number;
  }>(['rawPredictedRegionLoadData', regionApiString], () =>
    makeGetRequest(
      `loadforecast/regions/?from_trading_date=${dateFrom.format('DD/MM/YYYY')}&to_trading_date=${dateTo.format(
        'DD/MM/YYYY',
      )}&run_type=L&node_id=${regionApiString}`,
    ).then((resp: any) => ({
      ...resp.data,
      updated: moment().valueOf(),
    })),
  );

  const { data: rawActualRegionLoadData } = useQuery<{
    items: IActualRegionLoadResponse[];
    updated: number;
  }>(['rawActualRegionLoadData', regionApiString], () =>
    makeGetRequest(`actualload/regions/?region=${regionApiString}`).then((resp: any) => ({
      ...resp.data,
      updated: moment().valueOf(),
    })),
  );

  const { data: rawRecentLoadData } = useQuery<{
    items: IRecentLoadResponse[];
    updated: number;
  }>(['rawRecentLoadData', nodeApiString], () =>
    makeGetRequest(`recent_load/${nodeApiString}`).then((resp: any) => ({
      ...resp.data,
      updated: moment().valueOf(),
    })),
  );

  const { data: rawScadaLoadData } = useQuery<{
    items: any[];
    updated: number;
  }>(['rawScadaLoadData', nodeApiString], () =>
    makeGetRequest(
      // Substitute the node id for the node id you want to get data for.
      // and trading date range
      `scada_load/${nodeApiString}?from_trading_date=${dateFrom.format('DD/MM/YYYY')}&to_trading_date=${dateTo.format(
        'DD/MM/YYYY',
      )}`,
    ).then((resp: any) => ({
      ...resp.data,
      updated: moment().valueOf(),
    })),
  );

  const combinedGraphableData = [
    ...getActualLoadData(toggleValue, rawActualRegionLoadData, rawScadaLoadData, rawRecentLoadData),
    ...getPredictedLoadData(toggleValue, rawPredictedRegionLoadData, rawPredictedNodeLoadData),
  ];

  // Add the previousData to the graphable data.
  const sortedGraphableData = combinedGraphableData.sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp)) || 0);
  // Trim to only be within the date range.
  const trimmedData = sortedGraphableData.filter((item) => moment(item.timestamp).isBetween(dateFrom, dateTo));
  const deduplicatedActualData = deduplicateRecords(trimmedData);

  const xTicks = getTickTimestampsForInterval(trimmedData);

  // Safely get the first reading timestamp.
  const firstReadingTimestamp = trimmedData[0]?.timestamp ?? moment().valueOf();

  // Get forecast runtimes
  const forecastNodeRuntimes = getForecastRuntimes(rawPredictedNodeLoadData?.items ?? []);
  const forecastRegionRuntimes = getForecastRuntimes(rawPredictedRegionLoadData?.items ?? []);
  const forecastRuntimes = toggleValue === ToggleState.REGIONS ? forecastRegionRuntimes : forecastNodeRuntimes;

  return {
    loadForecast: deduplicatedActualData,
    firstReadingTimestamp,
    xTicks,
    forecastRuntimes,
    isFetchingRegion: !rawActualRegionLoadData?.items || !rawPredictedRegionLoadData?.items,
    isFetchingNode: !rawPredictedNodeLoadData?.items || !rawRecentLoadData?.items || !rawScadaLoadData?.items,
    updated: Math.max(
      rawActualRegionLoadData?.updated || Number.NEGATIVE_INFINITY,
      rawPredictedRegionLoadData?.updated || Number.NEGATIVE_INFINITY,
      rawRecentLoadData?.updated || Number.NEGATIVE_INFINITY,
      rawScadaLoadData?.updated || Number.NEGATIVE_INFINITY,
      rawPredictedNodeLoadData?.updated || Number.NEGATIVE_INFINITY,
    ),
  };
};
