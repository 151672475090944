import Tippy from '@tippyjs/react';
import { Icon } from '../Icon/Icon';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export const InfoTip = ({ label, content }: { label?: string; content?: React.ReactNode }) => {
  const contentToRender = label ? (
    <p
      style={{
        padding: '8px',
        color: '#003b59',
        lineHeight: '18px',
        fontSize: '14px',
        paddingBottom: '16px',
        margin: '0',
      }}
    >
      {label}
    </p>
  ) : (
    content ?? <p>No label provided.</p>
  );

  return (
    <Tippy maxWidth={224} placement="bottom" theme="light" content={contentToRender}>
      <span className='info-tip' tabIndex={-1}>
        <Icon name="ic-info" size="sm" />
      </span>
    </Tippy>
  );
};
