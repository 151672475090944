import { useEffect } from 'react';
import lodash from 'lodash';
import { atom, useSetRecoilState } from 'recoil';
// A custom hook that detects if the window is currently being resized
// Returns a single value 'isBeingResized'
// the method is debounced to 0.2s after which it returns to being false.

export const ResizeState = atom({ key: 'ResizeState', default: false });

export const ResizeProvider = ({ children }: { children: React.ReactNode }) => {
  const setIsBeingResized = useSetRecoilState(ResizeState);

  useEffect(() => {
    const reset = lodash.debounce(() => {
      setIsBeingResized(false);
    }, 200);

    const handleResize = () => {
      setIsBeingResized(true);
      reset();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsBeingResized]);

  return children;
};
