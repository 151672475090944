// @ts-nocheck

import { takeEvery, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import {
  TYPES,
  ILoginSuccess,
  ILoginFail,
  ILogin,
  ILogoutSuccess,
  ILogoutFail,
  IVerifySessionSuccess,
  IVerifySessionFail,
  IForgotPassword,
  IForgotPasswordSuccess,
  IForgotPasswordFail,
  IResetPassword,
  IResetPasswordSuccess,
  IResetPasswordFail,
  actionResetPasswordFail,
  actionResetPasswordSuccess,
  actionVerifySessionSuccess,
  actionVerifySessionFail,
  actionLogoutSuccess,
  actionLogoutFail,
  actionLoginSuccess,
} from './actions';
import {
  ITrackFailedLogin,
  actionTrackFailedLogin,
  actionTrackPasswordReset,
  ITrackPasswordReset,
} from '../analytics/actions';

Auth.configure({
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || 'ap-southeast-2_YTNAljE7R',
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '2q6vrnnnthti42r2onaq27le19',
});

export const getUserToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

const setUpUser = async () => {
  const userInfo = await Auth.currentUserInfo();
  // const userToken = await getUserToken();
  return {
    id: userInfo.attributes.sub,
    name: userInfo.username,
    email: userInfo.attributes.email,
    token: await getUserToken(),
    accessScope: await (await Auth.currentSession()).getAccessToken().payload['cognito:groups'],
  };
};

function* verifySession() {
  try {
    const currentSession = yield Auth.currentSession();

    if (currentSession.isValid()) {
      const user = yield setUpUser();
      yield put<IVerifySessionSuccess>(actionVerifySessionSuccess(user));
    }
  } catch (e) {
    yield put<IVerifySessionFail>(actionVerifySessionFail(e));
  }
}

function* login(action: ILogin) {
  const { email, password } = action.payload;

  try {
    yield Auth.signIn(email, password);
    const user = yield setUpUser();

    yield put<ILoginSuccess>(actionLoginSuccess(user));
  } catch (error) {
    yield put<ILoginFail>({ type: TYPES.LOGIN_FAIL, payload: { error } });
    yield put<ITrackFailedLogin>(actionTrackFailedLogin(email));
  }
}

function* logout() {
  try {
    yield Auth.signOut();

    yield put<ILogoutSuccess>(actionLogoutSuccess());
  } catch (error) {
    yield put<ILogoutFail>(actionLogoutFail(error));
  }
}

function* forgotPassword(action: IForgotPassword) {
  const { email } = action.payload;

  try {
    yield Auth.forgotPassword(email);
    yield put<IForgotPasswordSuccess>({ type: TYPES.FORGOT_PASSWORD_SUCCESS });
  } catch (error) {
    yield put<IForgotPasswordFail>({ type: TYPES.FORGOT_PASSWORD_FAIL, payload: { error } });
  }
}

function* resetPassword(action: IResetPassword) {
  const { email, code, password } = action.payload;

  try {
    yield Auth.forgotPasswordSubmit(email, code, password);
    yield put<IResetPasswordSuccess>(actionResetPasswordSuccess());
    yield put<ITrackPasswordReset>(actionTrackPasswordReset(email));
  } catch (error) {
    yield put<IResetPasswordFail>(actionResetPasswordFail(error));
  }
}

export function* sessionSaga() {
  yield takeEvery(TYPES.VERIFY_SESSION, verifySession);
  yield takeEvery(TYPES.LOGIN, login);
  yield takeEvery(TYPES.LOGOUT, logout);
  yield takeEvery(TYPES.FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(TYPES.RESET_PASSWORD, resetPassword);
}
