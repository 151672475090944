import { useRecoilState, useRecoilValue } from 'recoil';
import { gwapGenTypeFilterState, gwapLocationFilterState } from 'tools/state/gwapState';
import { useGwapDownload } from 'tools/hooks/PaidGwap/useGwapDownload';
import { paidFilterErrorsState } from 'tools/state/paidGwapState';
import { Button } from '../Button/Button';
import styles from './GwapDropDownFilters.module.scss';
import { GwapDateRangeFilter } from './GwapDateRangeFilter';
import { GwapGenTypeFilter } from './GwapGenTypeFilter';
import { GwapLocationFilter } from './GwapLocationFilter';

export const GwapDropDownFilters = () => {
  const [generationTypesSelected, setSelectedGenerationTypes] = useRecoilState(gwapGenTypeFilterState);
  const [selectedLocations, setSelectedLocations] = useRecoilState(gwapLocationFilterState);

  return (
    <div className={styles.wrapper}>
      <GwapDateRangeFilter />
      <GwapGenTypeFilter
        generationTypesSelected={generationTypesSelected}
        setSelectedGenerationTypes={setSelectedGenerationTypes}
      />
      <GwapLocationFilter
        setSelectedLocations={setSelectedLocations}
        selectedLocations={selectedLocations}
        isMultiple
      />
      <DownloadButton />
    </div>
  );
};

const DownloadButton = () => {
  const errors = useRecoilValue(paidFilterErrorsState);
  const isDownloadDisabled = errors.length > 0;
  const { attemptDownload } = useGwapDownload();
  return <Button isDisabled={isDownloadDisabled} onClick={attemptDownload} label="Download Report" />;
};
