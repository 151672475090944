import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IReservesReportCriteria } from 'types.d';
import {
  DateFilterOption,
  ReservesIsland,
  ReservesPowerType,
  ReservesReservesType,
  ReportType,
  ReservesSubtype,
} from 'enums.d';

import { CriteriaOption, UpdateFiltersFunc } from 'views/components/SelectionCriteria/SelectionCriteriaTypes';
import { actionGetReservesReport, TYPES } from 'redux/modules/nodes/actions';

import { SelectionCriteria } from 'views/components/SelectionCriteria/SelectionCriteria';
import { CriteriaAccordian } from 'views/components/SelectionCriteria/CriteriaAccordian/CriteriaAccordian';
import { CustomDateSelection } from 'views/components/SelectionCriteria/CustomDateSelection/CustomDateSelection';
import { actionTrackReportDownload } from 'redux/modules/analytics/actions';
import { formatReservesReportFilename } from 'tools/utilities/report';
import { generateDownload } from 'tools/utilities/file';
import { getReportContent } from 'redux/modules/nodes/selectors';
import { SelectionDownload } from '../SelectionCriteria/SelectionDownload/SelectionDownload';

const AGGREGATED_DATE_OPTIONS: CriteriaOption[] = [
  { label: 'Today', id: DateFilterOption.TODAY },
  { label: 'Yesterday', id: DateFilterOption.YESTERDAY },
  {
    id: DateFilterOption.CUSTOM,
    label: 'Custom',
    body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
      <CustomDateSelection updateFilters={updateFilters} resetState={resetState} />
    ),
  },
];
const RESERVES_DATE_OPTIONS: CriteriaOption[] = [
  { label: 'Last month', id: DateFilterOption.PREVIOUS_MONTH },
  {
    id: DateFilterOption.CUSTOM,
    label: 'Custom',
    body: (resetState: () => void, updateFilters: UpdateFiltersFunc) => (
      <CustomDateSelection updateFilters={updateFilters} resetState={resetState} />
    ),
  },
];

const RESERVES_ISLAND_OPTIONS: CriteriaOption[] = [
  { label: 'All NZ', id: ReservesIsland.ALL },
  { label: 'North Island', id: ReservesIsland.NORTH_ISLAND },
  { label: 'South Island', id: ReservesIsland.SOUTH_ISLAND },
];

const AGGREGATED_RESERVES_POWER_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'All Power Types', id: ReservesPowerType.ALL },
  { label: 'Interruptible', id: ReservesPowerType.INTERRUPTIBLE },
  {
    label: 'Partly Loaded Spinning Reserve',
    id: ReservesPowerType.PARTLY_LOADED_SPINNING_RESERVE,
  },
  {
    label: 'Tail Water Depressed Reserve',
    id: ReservesPowerType.TAIL_WATER_DEPRESSED_RESERVE,
  },
];

const RESERVES_POWER_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'All Power Types', id: ReservesPowerType.ALL },
  { label: 'Interruptible', id: ReservesPowerType.INTERRUPTIBLE },
  {
    label: 'Reserves',
    id: ReservesPowerType.RESERVE,
  },
];

const RESERVES_RESERVE_TYPE_OPTIONS: CriteriaOption[] = [
  { label: 'All Reserve Types', id: ReservesReservesType.ALL },
  { label: 'Fast', id: ReservesReservesType.FAST },
  { label: 'Sustained', id: ReservesReservesType.SUSTAINED },
];

const RESERVE_SUBTYPE_OPTIONS: CriteriaOption[] = [
  { label: 'Aggregated Reserves', id: ReservesSubtype.AGGREGATED_RESERVES },
  { label: 'Reserve Amount by Source - restricted', id: ReservesSubtype.RESERVE_AMOUNT_BY_SOURCE },
  { label: 'Reserves Extract - restricted', id: ReservesSubtype.RESERVES_EXTRACT },
];

const DEFAULT_CRITERIA = {
  reserveSubtype: ReservesSubtype.AGGREGATED_RESERVES,
  dateOption: DateFilterOption.TODAY,
  fromTradingDate: '',
  toTradingDate: '',
  reservesIsland: ReservesIsland.ALL,
  reservesPowerType: ReservesPowerType.ALL,
  reservesReserveType: ReservesReservesType.ALL,
};

export const ReservesSelectionCriteria = () => {
  const [criteria, setCriteria] = useState<IReservesReportCriteria>(DEFAULT_CRITERIA);

  const dispatch = useDispatch();
  const reportContent = useSelector(getReportContent);

  // When the criteria changes, update date option
  // When reserveSubtype is Aggregated Reserves, update the date option to Today
  // Otherwise make it last month
  useEffect(() => {
    if (criteria.reserveSubtype === ReservesSubtype.AGGREGATED_RESERVES) {
      setCriteria((prevCriteria) => ({
        ...prevCriteria,
        dateOption: DateFilterOption.TODAY,
      }));
    } else {
      setCriteria((prevCriteria) => ({
        ...prevCriteria,
        dateOption: DateFilterOption.PREVIOUS_MONTH,
      }));
    }
  }, [criteria.reserveSubtype]);

  useEffect(() => {
    if (reportContent) {
      const filename = formatReservesReportFilename(criteria);
      generateDownload(reportContent, `${filename}.csv`, 'text/csv');
      dispatch({ type: TYPES.CLEAN_UP_REPORT });
    }
  }, [reportContent, criteria, dispatch]);

  const dateOptions =
    criteria.reserveSubtype === ReservesSubtype.AGGREGATED_RESERVES ? AGGREGATED_DATE_OPTIONS : RESERVES_DATE_OPTIONS;

  const powerTypeOptions =
    criteria.reserveSubtype === ReservesSubtype.AGGREGATED_RESERVES
      ? AGGREGATED_RESERVES_POWER_TYPE_OPTIONS
      : RESERVES_POWER_TYPE_OPTIONS;

  const setDefaultsForReserveSubType = (reserveSubtype: ReservesSubtype) => {
    switch (reserveSubtype) {
      case ReservesSubtype.AGGREGATED_RESERVES:
        setCriteria((prevCriteria: IReservesReportCriteria) => ({
          ...prevCriteria,
          dateOption: DateFilterOption.TODAY,
          reservesIsland: ReservesIsland.ALL,
          reservesPowerType: ReservesPowerType.ALL,
          reservesReserveType: ReservesReservesType.ALL,
        }));
        break;
      case ReservesSubtype.RESERVE_AMOUNT_BY_SOURCE:
        setCriteria((prevCriteria: IReservesReportCriteria) => ({
          ...prevCriteria,
          dateOption: DateFilterOption.PREVIOUS_MONTH,
          reservesIsland: ReservesIsland.ALL,
          reservesPowerType: ReservesPowerType.ALL,
          reservesReserveType: ReservesReservesType.ALL,
        }));
        break;
      case ReservesSubtype.RESERVES_EXTRACT:
        setCriteria((prevCriteria: IReservesReportCriteria) => ({
          ...prevCriteria,
          dateOption: DateFilterOption.PREVIOUS_MONTH,
          reservesIsland: ReservesIsland.ALL,
          reservesPowerType: ReservesPowerType.ALL,
          reservesReserveType: ReservesReservesType.ALL,
        }));
    }
  };

  useEffect(() => {
    setDefaultsForReserveSubType(criteria.reserveSubtype);
  }, [criteria.reserveSubtype]);

  const updateCriteria = (key: string, filterOptionId: string) => {
    setCriteria({ ...criteria, [key]: filterOptionId });
  };

  const handleReportDownload = () => {
    dispatch(actionGetReservesReport(criteria));
    dispatch(
      actionTrackReportDownload({
        reportType: ReportType.RESERVES,
        criteria,
      }),
    );
  };

  return (
    <SelectionCriteria>
      <CriteriaAccordian
        initialOption={RESERVE_SUBTYPE_OPTIONS.find(({ id }) => id === criteria.reserveSubtype)}
        options={RESERVE_SUBTYPE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('reserveSubtype', option.id)}
        updateCriteria={updateCriteria}
      />
      {/* Need to have a conditional render here due to the way the criteria accordion is programmed and the inability
      to reset the initial option when reserve subtypes change. Not the prettiest but it works. */}
      <CriteriaAccordian
        key={`date_${criteria.reserveSubtype}`}
        resetToDefaultOnOptionsChange
        options={dateOptions}
        onChange={(option: CriteriaOption) => updateCriteria('dateOption', option.id)}
        updateCriteria={updateCriteria}
        headerIcon="ic-calendar"
      />

      <CriteriaAccordian
        key={`island_${criteria.reserveSubtype}`}
        resetToDefaultOnOptionsChange
        options={RESERVES_ISLAND_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('reservesIsland', option.id)}
        updateCriteria={updateCriteria}
      />
      <CriteriaAccordian
        key={`powertype_${criteria.reserveSubtype}`}
        resetToDefaultOnOptionsChange
        options={powerTypeOptions}
        onChange={(option: CriteriaOption) => updateCriteria('reservesPowerType', option.id)}
        updateCriteria={updateCriteria}
      />
      <CriteriaAccordian
        key={`reserves_${criteria.reserveSubtype}`}
        initialOption={RESERVES_RESERVE_TYPE_OPTIONS.find(({ id }) => id === criteria.reservesReserveType)}
        options={RESERVES_RESERVE_TYPE_OPTIONS}
        onChange={(option: CriteriaOption) => updateCriteria('reservesReserveType', option.id)}
        updateCriteria={updateCriteria}
      />
      <SelectionDownload
        isVisibleOnMobile
        needsSelectedNodesForDownload={false}
        forceCorrectDateForDownload={criteria.dateOption === DateFilterOption.CUSTOM}
        downloadAction={handleReportDownload}
      />
    </SelectionCriteria>
  );
};
