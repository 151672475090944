import classnames from 'classnames';
import { useMultipleSelection, useSelect } from 'downshift';
import { getLocationSummary } from 'tools/hooks/useFreeGwap';
import { ALL_GWAP_LOCATIONS, GwapLocation, GWAP_LOCATION_OPTIONS, IGwapLocationOption } from 'tools/utilities/gwap';
import { Icon } from '../Icon/Icon';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox/IndeterminateCheckbox';
import styles from './GwapDropDownFilters.module.scss';

interface ILocationFilterWrapperProps {
  selectedLocations: GwapLocation[];
  setSelectedLocations: (locations: GwapLocation[]) => void;
  isMultiple?: boolean;
}

export const GwapLocationFilter = ({
  selectedLocations,
  setSelectedLocations,
  isMultiple,
}: ILocationFilterWrapperProps) => {
  if (isMultiple) {
    return (
      <GwapLocationFilterMultiple selectedLocations={selectedLocations} setSelectedLocations={setSelectedLocations} />
    );
  }

  return <GwapLocationFilterSingle selectedLocations={selectedLocations} setSelectedLocations={setSelectedLocations} />;
};

interface ILocationFilterProps {
  selectedLocations: GwapLocation[];
  setSelectedLocations: (locations: GwapLocation[]) => void;
}

const GwapLocationFilterMultiple = ({ selectedLocations, setSelectedLocations }: ILocationFilterProps) => {
  const { getDropdownProps } = useMultipleSelection<{ location: GwapLocation; label: string }>();

  const toggleAll = () => {
    if (selectedLocations.length < GWAP_LOCATION_OPTIONS.length) {
      setSelectedLocations(ALL_GWAP_LOCATIONS);
    } else {
      setSelectedLocations([]);
    }
  };

  const removeLocationFilter = (locationSelected: GwapLocation) => {
    // If all selected, deselect all except one selected
    if (selectedLocations.length === GWAP_LOCATION_OPTIONS.length) {
      setSelectedLocations([locationSelected]);
    } else {
      setSelectedLocations(selectedLocations.filter((loc: GwapLocation) => loc !== locationSelected));
    }
  };

  const addLocationFilter = (locationSelected: GwapLocation) => {
    setSelectedLocations([locationSelected, ...selectedLocations]);
  };

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect<IGwapLocationOption>({
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: GWAP_LOCATION_OPTIONS,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: state.highlightedIndex,
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem: selected }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!selected) {
            break;
          }

          if (selectedLocations.includes(selected.location)) {
            removeLocationFilter(selected.location);
          } else {
            addLocationFilter(selected.location);
          }
          break;
        default:
          break;
      }
    },
  });

  const areAllLocationsSelected = selectedLocations.length === GWAP_LOCATION_OPTIONS.length;

  return (
    <div className={styles.downshift_wrapper}>
      <div {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}>
        <button type="button" className={styles.multi_select_button}>
          {getLocationSummary(selectedLocations)}
          <div className={classnames([styles.arrow, isOpen && styles.arrow_open])}>
            <Icon name="ic-chevron" size="sm" />
          </div>
        </button>
      </div>

      <div
        className={classnames([styles.downshift_dropdown_content, !isOpen && styles.dropdown_is_closed])}
        {...getMenuProps()}
      >
        {isOpen && (
          <>
            <div
              className={classnames([styles.downshift_dropdown_option, styles.downshift_dropdown_all_option])}
              key="All locations"
            >
              All locations
              <IndeterminateCheckbox isSelected={areAllLocationsSelected} onSelect={toggleAll} onRemove={toggleAll} />
            </div>
            {GWAP_LOCATION_OPTIONS.map((locationOption: IGwapLocationOption, index) => {
              const isSelected = selectedLocations.includes(locationOption.location) || areAllLocationsSelected;

              return (
                <div
                  className={styles.downshift_dropdown_option}
                  key={locationOption.label}
                  {...getItemProps({ item: locationOption, index })}
                >
                  {locationOption.label}
                  <IndeterminateCheckbox isSelected={isSelected} onSelect={() => {}} onRemove={() => {}} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

const SINGLE_SELECT_GWAP_LOCATION_OPTIONS = [
  { label: 'All locations', location: GwapLocation.ALL },
  ...GWAP_LOCATION_OPTIONS,
];

const GwapLocationFilterSingle = ({ selectedLocations, setSelectedLocations }: ILocationFilterProps) => {
  const selectLocationFilter = (locationSelected: GwapLocation) => {
    if (locationSelected === GwapLocation.ALL) {
      setSelectedLocations(ALL_GWAP_LOCATIONS);
      return;
    }

    setSelectedLocations([locationSelected]);
  };

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect<IGwapLocationOption>({
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: SINGLE_SELECT_GWAP_LOCATION_OPTIONS,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem: selected }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!selected) {
            break;
          }

          selectLocationFilter(selected.location);
          break;
        default:
          break;
      }
    },
  });

  return (
    <div className={styles.downshift_wrapper}>
      <div {...getToggleButtonProps()}>
        <button type="button" className={styles.multi_select_button}>
          {getLocationSummary(selectedLocations)}
          <div className={classnames([styles.arrow, isOpen && styles.arrow_open])}>
            <Icon name="ic-chevron" size="sm" />
          </div>
        </button>
      </div>
      <div
        className={classnames([
          styles.downshift_dropdown_content,
          styles.overlay,
          !isOpen && styles.dropdown_is_closed,
        ])}
        {...getMenuProps()}
      >
        {isOpen && (
          <>
            {SINGLE_SELECT_GWAP_LOCATION_OPTIONS.map((locationOption: IGwapLocationOption, index) => (
              <div
                className={`${styles.downshift_dropdown_option} ${styles.downshift_dropdown_single_option}`}
                key={locationOption.label}
                {...getItemProps({ item: locationOption, index })}
              >
                {locationOption.label}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
