import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';

import './MaskedInput.scss';

interface IProps {
  className?: string;
  id: string;
  isLabelHidden?: boolean;
  label: string;
  mask: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  type?: string;
  isErrorShowing?: boolean;
  value?: string;
  defaultValue?: string;
}

export const MaskedInput = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    className,
    id,
    isLabelHidden,
    label,
    mask,
    placeholder,
    onChange,
    onBlur,
    type = 'text',
    isErrorShowing = false,
    value,
    defaultValue,
  } = props;

  const componentClassName = classnames('MaskedInput', className, { 'has-label': !isLabelHidden });
  const inputClassName = classnames('MaskedInput-input', { isErrorShowing });

  return (
    <div className={componentClassName}>
      <label className="MaskedInput-label" htmlFor={id}>
        {label}
      </label>
      <InputMask
        className={inputClassName}
        type={type}
        id={id}
        mask={mask}
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e)}
        value={value}
        onBlur={onBlur}
        defaultValue={defaultValue}
        ref={ref as any}
      />
    </div>
  );
});
