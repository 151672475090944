import types from 'redux/modules/app/types';
import { TYPES } from 'redux/modules/app/actions';

export const initialState = {
  isLoading: false,
  isMenuOpen: false,
  isNavHidden: false,
};

const reducerApp = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SHOW_NAV:
      return {
        ...state,
        isNavHidden: false,
      };
    case TYPES.HIDE_NAV:
      return {
        ...state,
        isNavHidden: true,
      };
    case types.TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    default:
      return state;
  }
};

export default reducerApp;
