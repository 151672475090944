import moment from 'moment';

/**
 * Returns the date in a _parse to date_ friendly format (i.e.: 01/01/2020 returns 2020-01-01)
 * @param date Date in a DD/MM/YYYY format
 * @param separator In case "/" is not the one being used.
 * @returns Date in a format ready to be parsed to Date.
 */
export const formatDateStringForDate = (date: string, separator = '/') => date.split(separator).reverse().join('-');

export const getTradingPeriodForDate = (date: Date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  let tradingPeriod = hour * 2 + 1;

  if (minutes >= 30) {
    tradingPeriod += 1;
  }
  return tradingPeriod;
};

// Should format trading periods to time strings
// tp: 2 => 1 AM
// tp: 48 => 12 AM
export const formatTradingPeriodToTime = (tp: number) => {
  const hour = Math.floor((tp + 1) / 2);
  const ampm = hour < 12 ? 'AM' : 'PM';
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;
  return `${hour12} ${ampm}`;
};

// Formats milliseconds to format HH:MM Pm/Am (i.e.: 1:30 PM)
export const formatMillisecondsToTime = (ms: number) => {
  // Use moment js
  const date = new Date(ms);
  return moment(date).format('h:mm A');
};

export const getArrayOf15MinuteIntervalsAsMillisForLast2Hours = () => {
  const now = new Date();
  const nowAtNext15MinuteInterval = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes() + 15 - (now.getMinutes() % 15),
    0,
    0,
  );
  const nowMillis = nowAtNext15MinuteInterval.getTime();
  const twoHoursAgo = nowMillis - 2 * 60 * 60 * 1000;
  const intervals = [];
  for (let i = twoHoursAgo; i < nowMillis; i += 15 * 60 * 1000) {
    intervals.push(i);
  }
  return intervals;
};

export const getTpAsMillis = (tp: number) => {
  const date = new Date();
  const hour = Math.floor((tp + -1) / 2);
  const minutes = ((tp + 1) % 2) * 30;
  const millis = date.setHours(hour, minutes, 0, 0);
  return millis;
};

// Todo make this recalculate every now and then
export const getCurrentTradingPeriod = () => getTradingPeriodForDate(new Date());
export const getPreviousTradingPeriod = () => (getCurrentTradingPeriod() === 0 ? 48 : getCurrentTradingPeriod() - 1);
export const getNextTradingPeriod = () => (getCurrentTradingPeriod() + 1) % 48;
export const getCurrentTradingPeriodStart = () => getTpAsMillis(getCurrentTradingPeriod());
export const getCurrentTradingPeriodEnd = () => getTpAsMillis(getNextTradingPeriod());
export const getPreviousTradingPeriodStart = () => getTpAsMillis(getPreviousTradingPeriod());
export const getNextTradingPeriodEnd = () => getTpAsMillis((getNextTradingPeriod() + 1) % 48);
export const getCurrentTime = () => moment().valueOf();
