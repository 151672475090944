import { isViewportAboveMobile as isViewportAboveMobileSelector } from 'redux/modules/app/selectors';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { useFeature } from 'flagged';

interface ISecondaryNavigationItemProps {
  active: boolean;
  featureFlag?: FeatureFlags;
  label: string;
  mobileLabel: string;
  id: string;
  setActiveTab: (activeTab: string) => void;
}

export const SecondaryNavigationItem = ({
  active,
  id,
  label,
  mobileLabel,
  featureFlag,
  setActiveTab,
}: ISecondaryNavigationItemProps) => {
  const isFeatureEnabled = useFeature(featureFlag ?? '');

  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);

  if (!!featureFlag && !isFeatureEnabled) {
    return null;
  }

  const itemClassName = classNames('SecondaryNavigation-listItem', { 'is-active': active });

  return (
    <li className={itemClassName} key={id}>
      <button onClick={() => setActiveTab(id)} type="button">
        {isViewportAboveMobile ? label : mobileLabel}
      </button>
    </li>
  );
};
