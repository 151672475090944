import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import { TYPES } from 'redux/modules/app/actions';

import { Header } from 'views/components/Header/Header';
import { Navigation } from 'views/components/Navigation/Navigation';
import { IReducerState } from 'redux/main-reducer';
import { ROUTE_PATHS } from '../../../constants/routes';
import { Footer } from '../Footer/Footer';

interface IProps {
  children: React.ReactNode;
}

const PAGES_WITH_NAV = [
  ROUTE_PATHS.DASHBOARD,
  ROUTE_PATHS.RCPD,
  ROUTE_PATHS.RESIDUALS,
  ROUTE_PATHS.REPORTS,
  ROUTE_PATHS.GWAP,
  '/dashboard-new',
  ROUTE_PATHS.TRADING,
  ROUTE_PATHS.FREE_DASHBOARD,
  ROUTE_PATHS.FREE_DASHBOARD,
  ROUTE_PATHS.HOME,
  ROUTE_PATHS.WIND_FORECAST,
  ROUTE_PATHS.WIND_FORECAST_PUBLIC,
];

export const Layout = ({ children }: IProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isViewportMobile = useSelector((state: IReducerState) => state.browser.is.mobile);
  const isNavHidden = useSelector((state: IReducerState) => state.app.isNavHidden);

  useEffect(() => {
    if (PAGES_WITH_NAV.includes(location.pathname)) {
      dispatch({
        type: TYPES.SHOW_NAV,
      });
    } else {
      dispatch({
        type: TYPES.HIDE_NAV,
      });
    }

    dispatch({
      type: TYPES.LOCATION_CHANGE,
    });
  }, [location, dispatch]);

  const mainContentClassName = classNames('Main-content', { navHidden: isNavHidden });

  return (
    <main className="Main">
      <Header />
      <div className={mainContentClassName}>
        {!isViewportMobile && !isNavHidden && <Navigation />}

        {children}
      </div>
      <Footer />
    </main>
  );
};
