import { atom } from 'recoil';
import { ALL_GEN_TYPES, ALL_GWAP_LOCATIONS } from 'tools/utilities/gwap';

export const gwapLocationFilterState = atom({
  key: 'gwapLocationFilterState',
  default: ALL_GWAP_LOCATIONS,
});

export const gwapGenTypeFilterState = atom({
  key: 'gwapGenTypeFilterState',
  default: ALL_GEN_TYPES,
});
