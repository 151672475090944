import classnames from 'classnames';
import { ReactNode } from 'react';
import styles from './Shared.module.scss';

interface IProps {
  title: string;
  infoTip?: JSX.Element;
  className?: string;
  children?: ReactNode;
}
export const TitleArea = ({ title, infoTip, className, children }: IProps) => (
  <div className={classnames(styles.titleArea, className)}>
    <div className={classnames(styles.titleAreaTooltipWrapper, { [styles.withoutNodeSelection]: !children })}>
      <h2>{title}</h2>
      {infoTip}
    </div>

    {children}
  </div>
);
