import moment from 'moment';
import { format, addMinutes } from 'date-fns';
import { TRADING_INTERVAL_MINUTES } from '../../redux/modules/app/constants';

const LAST_UPDATED_TIME_FORMAT_WITH_TIME = 'EEE d MMM yyy, HH:mm';
const TIMESTAMP_FORMAT = 'dd/MM/yyyy';
const DEFAULT_DATE_CALLBACK = (d: any) => new Date(d.timestamp);

export const formatDataForChart = (
  data: any,
  valueField: string,
  dateCallback: (d: any) => Date = DEFAULT_DATE_CALLBACK,
) =>
  data.map((datum: any) => ({
    ...datum,
    date: dateCallback ? dateCallback(datum) : DEFAULT_DATE_CALLBACK(datum),
    value: datum[valueField],
  }));

export const formatLastUpdatedTime = (date: Date) => format(date, LAST_UPDATED_TIME_FORMAT_WITH_TIME);
export const formatTimestamp = (date: Date) => format(date, TIMESTAMP_FORMAT);
export const formatEndOfTradingPeriod = (date: Date) =>
  format(addMinutes(date, TRADING_INTERVAL_MINUTES), LAST_UPDATED_TIME_FORMAT_WITH_TIME);
