import './SecondaryNavigation.scss';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import { SecondaryNavigationItem } from './SecondaryNavigationItem';

const NAV_ITEMS = [
  {
    id: 'scada',
    label: 'SCADA',
    mobileLabel: 'SCADA',
  },
  {
    id: 'price',
    label: 'Price',
    mobileLabel: 'Price',
  },
  {
    id: 'reserves',
    label: 'Reserves',
    mobileLabel: 'Reserves',
  },
  {
    id: 'arcFlows',
    label: 'Arc Flows',
    mobileLabel: 'Arc Flows',
  },
  {
    id: 'emissions',
    label: 'NZ Emissions',
    mobileLabel: 'Emissions',
    featureFlag: FeatureFlags.NZ_EMISSIONS_REPORT,
  },
];

interface IProps {
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
}

export const SecondaryNavigation = ({ activeTab, setActiveTab }: IProps) => (
  <nav className="SecondaryNavigation">
    <ul className="SecondaryNavigation-list">
      {NAV_ITEMS.map((navItemProps) => (
        <SecondaryNavigationItem {...navItemProps} active={navItemProps.id === activeTab} setActiveTab={setActiveTab} />
      ))}
    </ul>
  </nav>
);
