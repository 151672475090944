import classNames from 'classnames';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './MicroSecondaryNav.module.scss';

export interface IMicroRoute {
  url: string;
  label: string;
}

interface IProps {
  primaryRoute: IMicroRoute;
  secondaryRoute: IMicroRoute;
  onClick?: () => void;
}

export const MicroSecondaryRouteNav = (props: IProps) => {
  const { primaryRoute, secondaryRoute, onClick } = props;
  const location = useLocation();

  const delayOnClick = useCallback(() => {
    setTimeout(() => {
      onClick && onClick();
    }, 300);
  }, [onClick]);

  return (
    <div className={styles.wrapper}>
      <Link
        onClick={delayOnClick}
        className={classNames(styles.base, styles.primaryLink, {
          [styles.active]: location.pathname === primaryRoute.url,
        })}
        to={primaryRoute.url}
      >
        {primaryRoute.label}
      </Link>
      <Link
        onClick={delayOnClick}
        className={classNames(styles.base, styles.secondaryLink, {
          [styles.active]: location.pathname === secondaryRoute.url,
        })}
        to={secondaryRoute.url}
      >
        {secondaryRoute.label}
      </Link>
    </div>
  );
};
