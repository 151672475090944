import { useState } from 'react';
import { TRADER_LOAD_NODE_OPTIONS, TRADER_LOAD_REGION_OPTIONS } from 'redux/modules/nodes/constants';
import { LoadForecastFilterContext, ToggleState } from 'tools/hooks/useLoadForecastFilter';

export const LoadFilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedLoadNode, setSelectedLoadNode] = useState(TRADER_LOAD_NODE_OPTIONS[0]);
  const [selectedLoadRegion, setSelectedLoadRegion] = useState(TRADER_LOAD_REGION_OPTIONS[0]);
  const [toggleValue, setToggleValue] = useState<ToggleState>(ToggleState.REGIONS);

  const value = {
    selectedLoadNode,
    setSelectedLoadNode,
    selectedLoadRegion,
    setSelectedLoadRegion,
    toggleValue,
    setToggleValue,
  };
  return <LoadForecastFilterContext.Provider value={value}>{children}</LoadForecastFilterContext.Provider>;
};
