import { useFeature } from 'flagged';
import { useMemo } from 'react';

import { useQuery } from 'react-query';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { gwapGenTypeFilterState, gwapLocationFilterState } from 'tools/state/gwapState';
import { makeUnauthorisedGetRequest } from 'tools/utilities/ajax';
import { FeatureFlags } from 'tools/utilities/featureFlags';
import {
  GwapLocation,
  ALL_GEN_TYPES,
  normaliseGwapData,
  GwapGenerationType,
  getGwapLocationLabel,
  getGwapGenTypeLabel,
  ALL_GWAP_LOCATIONS,
  GWAP_LOCATION_OPTIONS,
} from 'tools/utilities/gwap';

//  This was being used to create a string that included multiple grid_zone_ids
// Due to a change in scope this function is currently not really needed as only ever a single grid zone
// id will be requested from the API at a time. It might be useful to keep this in case scope changes to include
// Multiple location filtering again.
const generateQueryStringForLocations = (locations: GwapLocation[]): string =>
  isAllLocationsSelected(locations) ? '?grid_zone_id=' : `?grid_zone_id=${locations.map((loc, index) => loc)}`;

const isNoGenTypesSelected = (genTypes: GwapGenerationType[]) => genTypes.length === 0;
const isAllGenTypesSelected = (genTypes: GwapGenerationType[]) => ALL_GEN_TYPES.length === genTypes.length;
const isAllLocationsSelected = (locations: GwapLocation[]) =>
  locations.length === 0 || ALL_GWAP_LOCATIONS.length === locations.length;

const isMultipleGenTypesSelected = (genTypes: GwapGenerationType[]) => genTypes.length > 1;

export const getGenTypeSummary = (genTypes: GwapGenerationType[]) => {
  if (isAllGenTypesSelected(genTypes)) {
    return 'All generation types';
  }

  if (isMultipleGenTypesSelected(genTypes)) {
    return 'Multiple generation types';
  }

  if (isNoGenTypesSelected(genTypes)) {
    return 'Select generation types';
  }

  return getGwapGenTypeLabel(genTypes[0]);
};

/**
 * This method returns the appropriate label for the locations selected.
 * Since the Free dashboard only allows for one locations to be selected,
 * the implementation assumes that if there's any location selected, then
 * that's the one that should be used in the label.
 * @param locations The locations currently selected
 * @returns The label to be used for the locations selection
 */
export const getLocationSummary = (locations: GwapLocation[]) => {
  if (locations.length === GWAP_LOCATION_OPTIONS.length) {
    return 'All locations';
  }

  if (locations.length > 1) {
    return 'Multiple locations';
  }

  if (locations.length === 0) {
    return 'Select locations';
  }

  return getGwapLocationLabel(locations[0]);
};

export const getGenTypeAndLocationSummary = (genTypes: GwapGenerationType[], locations: GwapLocation[]) => {
  const hasAllGenTypeSelected = isAllGenTypesSelected(genTypes);
  const hasAllLocationsSelected = isAllLocationsSelected(locations);
  const hasSeveralGenTypesSelected = genTypes.length > 1;
  const hasSeveralLocationsSelected = locations.length > 1;
  const hasOneGenTypeSelected = genTypes.length === 1;
  const hasOneLocationSelected = locations.length === 1;
  const hasNoGenTypesSelected = isNoGenTypesSelected(genTypes);
  const hasNoLocationsSelected = locations.length === 0;

  let locationText = '';
  let genTypeText = '';

  if (hasAllGenTypeSelected) {
    genTypeText = 'All types';
  }

  if (hasSeveralGenTypesSelected) {
    genTypeText = 'Multiple types';
  }

  if (hasNoGenTypesSelected) {
    genTypeText = 'Select types';
  }

  if (hasOneGenTypeSelected) {
    genTypeText = getGwapGenTypeLabel(genTypes[0]) || '';
  }

  if (hasAllLocationsSelected) {
    locationText = 'all locations';
  }

  if (hasSeveralLocationsSelected) {
    locationText = 'multiple locations';
  }

  if (hasNoLocationsSelected) {
    locationText = 'select locations';
  }

  if (hasOneLocationSelected) {
    locationText = getGwapLocationLabel(locations[0]) || '';
  }

  return `${genTypeText}, ${locationText}`;
};

const freeGwapGraphDataState = atom({ key: 'FreeGwapGraphDataState', default: [] as any[] });

const ONE_MINUTE = 60000;
export const FIVE_MINUTES = 300000;

export const useFreeGwap = () => {
  const [activeGenTypeFilters, setActiveGenTypeFilters] = useRecoilState<GwapGenerationType[]>(gwapGenTypeFilterState);
  const [activeLocationFilters, setActiveLocationFilters] = useRecoilState<GwapLocation[]>(gwapLocationFilterState);
  const isUsingTestRefreshTimes = useFeature(FeatureFlags.TEST_REFRESH_TIMES);

  const genTypeSummary = getGenTypeSummary(activeGenTypeFilters);
  const locationSummary = getLocationSummary(activeLocationFilters);
  const genTypeAndLocationSummary = getGenTypeAndLocationSummary(activeGenTypeFilters, activeLocationFilters);

  const setGwapGraphData = useSetRecoilState(freeGwapGraphDataState);

  const { data } = useQuery<any>(
    ['FreeGwapData', activeLocationFilters],
    () =>
      makeUnauthorisedGetRequest(`free/price${generateQueryStringForLocations(activeLocationFilters)}`).then(
        (resp) => resp.data,
      ),
    {
      staleTime: 1,
      /**
       * To make testing more efficient we set a feature flag, REACT_APP_FEATURE_FLAG_USE_TEST_REFRESH_TIMES,
       * when it's on, we set the refresh to a minute, instead of half an hour as per requirements.
       */
      refetchInterval: isUsingTestRefreshTimes ? ONE_MINUTE : FIVE_MINUTES,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );

  const normalisedData = normaliseGwapData(data?.items ?? []);

  const changeGenTypeFilter = (genTypesSelected: GwapGenerationType[]) => {
    setActiveGenTypeFilters(genTypesSelected);
  };

  const changeLocationFilter = (selectedLocations: GwapLocation[]) => {
    setActiveLocationFilters(selectedLocations);
  };

  const generationFilteredData = useMemo(
    () =>
      normalisedData.map((record: any) =>
        activeGenTypeFilters.reduce(
          (filteredRecord, activeFilter) => {
            const wapKey = `${activeFilter}_wap`;
            const mwKey = `${activeFilter}_mwh`;
            return {
              ...filteredRecord,
              [wapKey]: record[wapKey],
              [mwKey]: record[mwKey],
            };
          },
          { timestamp: record.time, grid_zone_id: record.grid_zone_id },
        ),
      ),
    [activeGenTypeFilters, normalisedData],
  );

  setGwapGraphData(generationFilteredData);

  return {
    freeGwapData: normalisedData ?? [],
    filteredGwapData: generationFilteredData,
    changeGenTypeFilter,
    changeLocationFilter,
    activeGenTypeFilters,
    activeLocationFilters,
    genTypeSummary,
    locationSummary,
    genTypeAndLocationSummary,
  };
};
