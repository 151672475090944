export enum FreeDashComponentId {
  MAP,
  GWAP_GRAPH,
  PRICE_GRAPH,
  CARBON_EMISSIONS,
  RENEWABLE_PIE,
  CTA,
}

export const TOOLTIP_FOR_FREEDASH_COMPONENTS = {
  [FreeDashComponentId.MAP]: 'No tooltip',
  [FreeDashComponentId.GWAP_GRAPH]: (
    <p
      style={{
        padding: '8px',
        color: '#003b59',
        lineHeight: '18px',
        fontSize: '14px',
        paddingBottom: '16px',
        margin: '0',
      }}
    >
      Generation Weighted Average Price, or GWAP, shows the average price by generation type based on both volume
      generated and price. It is important because it provides insight into the trend and value of electricity
      generation. The y axis shows the total MW generated for the selected generation type/s. The x axis is the previous
      6 months and the hover labels show the daily average of the half hourly GWAP for each generation type and/or
      location.
    </p>
  ),
  [FreeDashComponentId.PRICE_GRAPH]: 'No tooltip',
  [FreeDashComponentId.CARBON_EMISSIONS]: (
    <p
      style={{
        padding: '8px',
        color: '#003b59',
        lineHeight: '18px',
        fontSize: '14px',
        paddingBottom: '16px',
        margin: '0',
      }}
    >
      The NZ carbon intensity NZ at time t is found by weighting the carbon intensity factor for each fuel type C
      <sub>i</sub> by the generation of that fuel type G<sub>t</sub>. This is then divided by the NZ national grid
      demand D<sub>t</sub> to derive the carbon intensity for NZ. <br />
      <br /> NZ<sub>t</sub> = (C<sub>i</sub> × G<sub>t</sub>) / D<sub>t</sub> <br />
      <br /> For more information see our <strong>carbon methodology</strong> in the footer link below
    </p>
  ),
  [FreeDashComponentId.RENEWABLE_PIE]: (
    <p
      style={{
        padding: '8px',
        color: '#003b59',
        lineHeight: '18px',
        fontSize: '14px',
        paddingBottom: '16px',
        margin: '0',
      }}
    >
      The total NZ renewable generation will treat Hydro, Geothermal, Wind and Solar as renewable energy sources. The
      sum of renewable generation is divided by the total <strong>grid connected NZ generation</strong> to determine the
      overall renewable percentage.
      <br />
      <br /> For more information see our <strong>carbon methodology</strong> in the footer link below.
    </p>
  ),
  [FreeDashComponentId.CTA]: 'No tooltip',
};
