import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { motion } from 'framer-motion';

import { TYPES } from 'redux/modules/session/actions';
import { isLoggedIn as isLoggedInSelector, isLoading as isLoadingSelector } from 'redux/modules/session/selectors';
import { LoadingPanel } from 'views/components/LoadingPanel/LoadingPanel';

interface ISecureRouteProps {
  children: React.ReactNode;
  [keyof: string]: any;
}

export const SecureRoute = ({ children, location, ...rest }: ISecureRouteProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    dispatch({ type: TYPES.VERIFY_SESSION });
  }, [dispatch]);

  if (isLoading) {
    return (
      <motion.div exit={{ opacity: 0 }} initial={{ opacity: 1 }} animate={{ opacity: 1 }} className="LoadingPanelOuter">
        <LoadingPanel />
      </motion.div>
    );
  }

  if (isLoggedIn) {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
};
