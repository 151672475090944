import React, { useState, useEffect } from 'react';

import './Reports.scss';

import { SecondaryNavigation } from 'views/components/SecondaryNavigation/SecondaryNavigation';
import { ReportType } from 'enums.d';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { useDispatch } from 'react-redux';
import { TYPES, actionSetSelectedNodes } from 'redux/modules/nodes/actions';
import { ScadaReports } from './ScadaReports';
import { PriceReports } from './PriceReports';
import { ReservesReports } from './ReservesReports';
import { ArcFlowsReports } from './ArcFlowsReports';
import { EmissionsReports } from './EmissionsReports';

export const Reports = () => {
  const [activeTab, setActiveTab] = useState<string>(ReportType.SCADA);
  const dispatch = useDispatch();

  useEffect(() => {
    // Get all nodes for all types on initial load.
    dispatch({
      type: TYPES.GET_LOAD_NODES,
    });
    dispatch({
      type: TYPES.GET_GENERATION_NODES,
    });
    dispatch({
      type: TYPES.GET_PRICE_NODES,
    });
  }, [dispatch]);

  const changeActiveTab = (newTab: string) => {
    dispatch(actionSetSelectedNodes([]));
    setActiveTab(newTab);
  };

  const renderReportContent = () => {
    switch (activeTab) {
      case ReportType.PRICE:
        return <PriceReports />;
      case ReportType.EMISSIONS:
        return <EmissionsReports />;
      case ReportType.RESERVES:
        return <ReservesReports />;
      case ReportType.ARC_FLOWS:
        return <ArcFlowsReports />;
      default:
        return <ScadaReports />;
    }
  };

  return (
    <div className="Container">
      <RouteTransition>
        <div className="Reports">
          <SecondaryNavigation activeTab={activeTab} setActiveTab={changeActiveTab} />
          {renderReportContent()}
        </div>
      </RouteTransition>
    </div>
  );
};
