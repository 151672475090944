/* eslint-disable import/no-cycle */
import React from 'react';
import * as d3 from 'd3';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { isViewportAboveMobile as isViewportAboveMobileSelector } from 'redux/modules/app/selectors';

import { getNodeStyle } from 'views/components/NodeTable/NodeTable';
import { isPriceNodes as isPriceNodesSelector } from 'redux/modules/nodes/selectors';
import { ILegendRange } from 'types.d';
import { v4 } from 'uuid';
import { getCoordinates } from '../map-utilities';
import { IMapNode } from '../Map';
import { ILabelDimensions, useLabelDimensions } from '../hooks/useLabelDimensions';
import { useLegendRangesConfig } from '../hooks/useLegendRangeConfig';
import { useMapNodesLabelOffsets } from '../hooks/useMapNodesLabelOffsets';

const ZOOM_TRANSITION_DURATION = 0.75; // 750ms (framer-motion uses seconds)
interface ILabelsProps {
  nodes: IMapNode[];
  projection: d3.GeoProjection;
  transformation: d3.ZoomTransform;
  hasCustomisedNodes: boolean; // Stop the animation when entering the page for the first time
}

export const Labels = ({ transformation, nodes, hasCustomisedNodes, projection }: ILabelsProps) => {
  const labelDimensions = useLabelDimensions(transformation);
  const { ranges } = useLegendRangesConfig();
  const isPriceNodes = useSelector(isPriceNodesSelector);

  const NodeComponent = isPriceNodes ? PriceNode : LoadNode;
  const { x: translateX, y: translateY, k: scale } = transformation;

  return (
    <motion.g
      className="Map-labels"
      animate={{
        translateX,
        translateY,
        scale,
      }}
      transition={{
        duration: hasCustomisedNodes ? ZOOM_TRANSITION_DURATION : 0,
      }}
    >
      {nodes.map((node: IMapNode) => (
        <NodeComponent
          key={v4()}
          transformation={transformation}
          projection={projection}
          node={node}
          labelDimensions={labelDimensions}
          ranges={ranges}
        />
      ))}
    </motion.g>
  );
};

interface INodeProps {
  transformation: d3.ZoomTransform;
  projection: d3.GeoProjection;
  node: IMapNode;
  labelDimensions: ILabelDimensions;
  ranges: ILegendRange[];
}

const PriceNode = ({ node, labelDimensions, projection, ranges }: INodeProps) => {
  const intl = useIntl();
  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);
  const { x, y } = getCoordinates(node, projection);
  const {
    scaledLabelWidth,
    scaledLabelHeight,
    scaledNodeIdFontSize,
    scaledNodePriceFontSize,
    scaledNodeIdYOffset,
    scaledNodePriceYOffset,
    scaledLabelOuterCircleRadius,
    scaledLabelInnerCircleRadius,
    scaledLabelLineStrokeWidth,
    scaledLabelRectBorderRadius,
    scaledLabelRectYOffset,
    scaledArrowWidth,
    offSet,
    delta,
  } = labelDimensions;

  const { lineXOffset, rectXOffset, textXOffset } = useMapNodesLabelOffsets({
    x,
    y,
    offSet,
    labelWidth: scaledLabelWidth,
    arrowWidth: scaledArrowWidth,
    delta,
  });

  const { backgroundColor, color } = getNodeStyle(node.valueForLabelStyle || 0, ranges);

  return (
    <g
      key={node.nodeId}
      id={node.nodeId}
      className="Map-label"
      onMouseOver={() => d3.select(`#${node.nodeId}`).raise()}
      onFocus={() => d3.select(`#${node.nodeId}`).raise()}
    >
      <circle className="Map-label-outerCircle" r={scaledLabelOuterCircleRadius} fill={backgroundColor} cx={x} cy={y} />
      <line
        id={`Line-${node.nodeId}`}
        className="Map-label-line"
        x1={x}
        y1={y}
        x2={x + lineXOffset}
        y2={y}
        strokeWidth={scaledLabelLineStrokeWidth}
        stroke={backgroundColor}
      />
      <circle className="Map-label-innerCircle" r={scaledLabelInnerCircleRadius} fill="#fff" cx={x} cy={y} />
      <rect
        className="Map-label-rect"
        x={x + rectXOffset}
        y={y - scaledLabelRectYOffset}
        width={scaledLabelWidth}
        height={scaledLabelHeight}
        rx={scaledLabelRectBorderRadius}
        fill={backgroundColor}
        filter="url(#dropshadow)"
      />
      {isViewportAboveMobile ? (
        <>
          <text
            fontSize={scaledNodeIdFontSize}
            className="Map-svg-textNodeId"
            fill={color}
            x={x + textXOffset}
            y={y - scaledNodeIdYOffset}
            textAnchor="left"
          >
            {node.nodeId}
          </text>
          <text
            fontSize={scaledNodePriceFontSize}
            textAnchor="left"
            className="Map-svg-textPrice"
            fill={color}
            x={x + textXOffset}
            y={y + scaledNodePriceYOffset}
          >
            {intl.formatNumber(node.value, {
              currency: 'NZD',
              currencyDisplay: 'symbol',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
            })}
          </text>
        </>
      ) : (
        <text
          className="Map-svg-textPrice"
          textAnchor="middle"
          fill={color}
          x={x + textXOffset}
          y={y - scaledNodePriceYOffset}
          fontSize={scaledNodePriceFontSize}
        >
          {intl.formatNumber(node.value, {
            currency: 'NZD',
            currencyDisplay: 'symbol',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
          })}
        </text>
      )}
    </g>
  );
};

const LoadNode = ({ node, labelDimensions, projection, ranges }: INodeProps) => {
  const isViewportAboveMobile = useSelector(isViewportAboveMobileSelector);
  const { x, y } = getCoordinates(node, projection);
  const {
    scaledLabelWidth,
    scaledLabelHeight,
    scaledNodeIdFontSize,
    scaledNodePriceFontSize,
    scaledNodeIdYOffset,
    scaledNodePriceYOffset,
    scaledLabelOuterCircleRadius,
    scaledLabelInnerCircleRadius,
    scaledLabelLineStrokeWidth,
    scaledLabelRectBorderRadius,
    scaledLabelRectYOffset,
    scaledArrowWidth,
    scaledArrowPadding,
    offSet,
    delta,
  } = labelDimensions;

  const { lineXOffset, rectXOffset, textXOffset } = useMapNodesLabelOffsets({
    x,
    y,
    offSet,
    labelWidth: scaledLabelWidth,
    arrowWidth: scaledArrowWidth,
    delta,
  });

  const arrowOffset = x + rectXOffset + scaledLabelOuterCircleRadius + scaledArrowPadding;

  const { backgroundColor, color } = getNodeStyle(node.valueForLabelStyle || 0, ranges);

  return (
    <g
      key={node.nodeId}
      id={node.nodeId}
      className="Map-label"
      onMouseOver={() => d3.select(`#${node.nodeId}`).raise()}
      onFocus={() => d3.select(`#${node.nodeId}`).raise()}
    >
      <circle className="Map-label-outerCircle" r={scaledLabelOuterCircleRadius} fill={backgroundColor} cx={x} cy={y} />
      <line
        id={`Line-${node.nodeId}`}
        className="Map-label-line"
        x1={x}
        y1={y}
        x2={x + lineXOffset}
        y2={y}
        strokeWidth={scaledLabelLineStrokeWidth}
        stroke={backgroundColor}
      />
      <circle className="Map-label-innerCircle" r={scaledLabelInnerCircleRadius} fill="#fff" cx={x} cy={y} />
      <rect
        className="Map-label-rect"
        x={x + rectXOffset}
        y={y - scaledLabelRectYOffset}
        width={scaledLabelWidth}
        height={scaledLabelHeight}
        rx={scaledLabelRectBorderRadius}
        fill={backgroundColor}
        filter="url(#dropshadow)"
      />
      {isViewportAboveMobile ? (
        <>
          <g transform={`rotate(${node.valueChange && node.valueChange >= 0 ? '180' : '0'}, ${arrowOffset},${y})`}>
            <circle fill={color} r={scaledLabelOuterCircleRadius} cx={arrowOffset} cy={y} />
            <line
              strokeWidth={scaledLabelLineStrokeWidth * 1.5}
              stroke={backgroundColor}
              strokeLinecap="round"
              x1={arrowOffset - scaledLabelOuterCircleRadius / 2}
              y1={y - scaledLabelOuterCircleRadius * 0.66 + scaledLabelOuterCircleRadius * 0.33}
              x2={arrowOffset}
              y2={y + scaledLabelOuterCircleRadius * 0.33}
            />
            <line
              strokeWidth={scaledLabelLineStrokeWidth * 1.5}
              stroke={backgroundColor}
              strokeLinecap="round"
              x1={arrowOffset}
              y1={y + scaledLabelOuterCircleRadius * 0.33}
              x2={arrowOffset + scaledLabelOuterCircleRadius / 2}
              y2={y - scaledLabelOuterCircleRadius * 0.66 + scaledLabelOuterCircleRadius * 0.33}
            />
          </g>

          <text
            fontSize={scaledNodeIdFontSize}
            className="Map-svg-textNodeId"
            fill={color}
            x={x + textXOffset}
            y={y - scaledNodeIdYOffset}
            textAnchor="left"
          >
            {node.nodeId}
          </text>
          <text
            fontSize={scaledNodePriceFontSize}
            textAnchor="left"
            className="Map-svg-textPrice"
            fill={color}
            x={x + textXOffset}
            y={y + scaledNodePriceYOffset}
          >
            {`${node.value} MW`}
          </text>
        </>
      ) : (
        <text
          className="Map-svg-textPrice"
          textAnchor="middle"
          fill={color}
          x={x + textXOffset}
          y={y - scaledNodePriceYOffset}
          fontSize={scaledNodePriceFontSize}
        >
          {`${node.value} MW`}
        </text>
      )}
    </g>
  );
};
