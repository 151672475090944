import { useSelector } from 'react-redux';
import { PRICE_RANGES, LOAD_RANGES } from 'redux/modules/nodes/constants';
import { isPriceNodes as isPriceNodesSelector } from 'redux/modules/nodes/selectors';

export const useLegendRangesConfig = () => {
  const isPriceNodes = useSelector(isPriceNodesSelector);

  if (isPriceNodes) {
    return {
      ranges: PRICE_RANGES,
      labelId: 'PRICE_MAP_LEGEND',
    };
  }

  return {
    ranges: LOAD_RANGES,
    labelId: 'LOAD_MAP_LEGEND',
  };
};
