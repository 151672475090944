import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { gwapGenTypeFilterState, gwapLocationFilterState } from 'tools/state/gwapState';
import { gwapTimeIntervalState, paidDateRangeFilterState } from 'tools/state/paidGwapState';
import { makeGetRequest } from 'tools/utilities/ajax';
import { generateDownload } from 'tools/utilities/file';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const useGwapDownload = () => {
  const { to, from } = useRecoilValue(paidDateRangeFilterState);
  const gridZones = useRecoilValue(gwapLocationFilterState).join(',');
  const genTypes = useRecoilValue(gwapGenTypeFilterState).join(',').toUpperCase();
  const interval = useRecoilValue(gwapTimeIntervalState);
  const formattedFromDate = dayjs(from, 'DD/MM/YYYY').format('DDMMYY');
  const formattedToDate = dayjs(to, 'DD/MM/YYYY').format('DDMMYY');
  const formattedTime = dayjs().format('HHmm');
  const url = `gen_region_price_csv/?interval=${interval}&grid_zone_id=${gridZones}&generation_type_id=${genTypes}&from_trading_date=${from}&to_trading_date=${to}`;

  const filename = `gwap_${formattedFromDate}_${formattedToDate}_${formattedTime}.csv`;

  const attemptDownload = async () => {
    const reportData = await makeGetRequest(url).then((resp) => resp.data);
    generateDownload(`${reportData}`, `${filename}`, 'text/csv');
  };
  return { attemptDownload };
};
