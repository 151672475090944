import { UserAccessScope } from 'constants/sessionConstants';
import { useSelector } from 'react-redux';
import { getUserAccessScope } from 'redux/modules/session/selectors';
import { AccessRestrictedInline } from '../AccessRestrictedInline/AccessRestrictedInline';

interface IAccessRestrictionWrapperProps {
  accessScopeRequired: UserAccessScope;
  children: React.ReactNode;
  isInline?: boolean;
  canGoBack?: boolean;
}

const DEFAULT_ACCESS_SCOPE_MESSAGE =
  'Your current subscription does not allow access to this report. Please contact EMS to change your subscription.';

const USER_ACCESS_SCOPE_TO_SERVICE_MAP: { [key in UserAccessScope]: string } = {
  [UserAccessScope.FREE]: '',
  [UserAccessScope.PREMIUM_DASH]: 'Premium dashboard',
  [UserAccessScope.GWAP]: 'GWAP Dashboard',
  [UserAccessScope.CARBON]: 'NZ Emissions reports',
  [UserAccessScope.SCADA]: 'SCADA reports',
  [UserAccessScope.PRICE]: 'Price reports',
  [UserAccessScope.ARC_FLOWS]: 'Arc flows reports',
  [UserAccessScope.RESERVES]: 'Reserves reports',
  [UserAccessScope.RCPD]: 'RCPD',
};

const getAccessMessageForAccessScope = (accessScope: UserAccessScope) => {
  const serviceName = USER_ACCESS_SCOPE_TO_SERVICE_MAP[accessScope];
  return `mailto:call@ems.co.nz?subject=em6 | Data Access Request&body=Hi, I do not have access to the '${serviceName}' service in em6. Please contact me to arrange access and confirm pricing.`;
};

const ERROR_MESSAGE_FOR_ACCESS_SCOPE: { [key in UserAccessScope]: string } = {
  [UserAccessScope.FREE]: DEFAULT_ACCESS_SCOPE_MESSAGE,
  [UserAccessScope.PREMIUM_DASH]: DEFAULT_ACCESS_SCOPE_MESSAGE,
  [UserAccessScope.GWAP]: DEFAULT_ACCESS_SCOPE_MESSAGE,
  [UserAccessScope.CARBON]: DEFAULT_ACCESS_SCOPE_MESSAGE,
  [UserAccessScope.SCADA]:
    'The SCADA service provides the MW generation, load, and HVDC transfer at each node in the NZ transmission network as captured by the Remote Terminal Units (RTU). The SCADA generation, load and HVDC data is averaged over the half hour. If you require access to this, please contact call@ems.co.nz',
  [UserAccessScope.PRICE]:
    'The Price service shows the market price calculated by Scheduling Pricing and Dispatch (SPD) software. Final and Provisional prices are created daily from the daily metered energy data using Revenue Meter Load Data. If you require access to this, please contact call@ems.co.nz',
  [UserAccessScope.TRADING]:
    'Your current subscription does not allow access to the Trading page graphs. To get access or arrange a trial, contact EMS',
  [UserAccessScope.ARC_FLOWS]:
    'The Arc Flow Data report describes the MW flow scheduled along an arc in the transmission network. This information is created by the Transpower SPD application and transferred to em6, when a Final or Provisional schedule has resolved the day after each market day. This data is available at the end of every final and provisional pricing schedule. If you require access to this, please contact call@ems.co.nz',
  [UserAccessScope.RESERVES]:
    'Access to the Reserves Reports is available on request. The reports available are: Aggregated Reserves (Total MW cleared and Total MW offered), Reserves Amount by Source (Quantity, Price &  Amount to be paid to each participant), Reserves Extract (Averaged Quantity and Price). If you require access to these reports please contact call@ems.co.nz',
  [UserAccessScope.RCPD]:
    'The Regional Coincident Peak Demand (RCPD) service provides an estimate of near real time SCADA load data tracking against the minimum, average and maximum load per region. It is used by network companies and wholesale electricity purchasers to manage load and reduce interconnection charges. If you require access to this, please contact call@ems.co.nz',
};

export const AccessRestrictionWrapper = ({
  accessScopeRequired,
  children,
  isInline = false,
  canGoBack = false,
}: IAccessRestrictionWrapperProps) => {
  const userAccessScope = useSelector(getUserAccessScope);

  if (userAccessScope.includes(accessScopeRequired)) {
    return <>{children}</>;
  }

  const message = ERROR_MESSAGE_FOR_ACCESS_SCOPE[accessScopeRequired];
  const emailUrl = getAccessMessageForAccessScope(accessScopeRequired);

  return <AccessRestrictedInline canGoBack={canGoBack} emailUrl={emailUrl} message={message} />;
};
