import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';

import { GWAPDelimiter } from 'views/pages/GWAP/GWAPDelimiter';
import {
  generationByLocationLoadingState,
  gwapByTypeGraphDataState,
  gwapByTypeSlice,
  gwapFilterDrawerVisibleState,
  gwapHoveredTimestamp,
  gwapTimeIntervalState,
} from 'tools/state/paidGwapState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSelector } from 'react-redux';
import { isViewportAboveTablet } from 'redux/modules/app/selectors';
import { usePaidGwapGraphDimensions } from 'tools/hooks/PaidGwap/usePaidGwapGraphDimensions';
import { gwapGenTypeFilterState } from 'tools/state/gwapState';
import moment from 'moment';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { useGwapBarDimensions, GwapTimeInterval, GWAP_BY_TYPE_SUMMARY_LABELS } from 'tools/utilities/gwap';
import { LegendBox, LegendBoxRow } from '../Legend/LegendBox';
import { GwapTimeFilterToggle } from '../Filters/GwapTimeFilterToggle';
import { GwapGraph } from '../GwapGraph/GwapGraph';
import styles from './PaidGwapGraph.module.scss';

export const PaidGWAPGraph = () => (
  <div className={styles.wrapper}>
    <GraphTitle />
    <GraphBody />
  </div>
);

const GraphBody = () => {
  const isViewAboveTablet = useSelector(isViewportAboveTablet);
  const chartDimensions = usePaidGwapGraphDimensions();
  const barDimensions = useGwapBarDimensions();
  const data = useRecoilValue(gwapByTypeGraphDataState);
  const { isFetching } = useRecoilValue(generationByLocationLoadingState);

  return (
    <div className={styles.graph_container}>
      <GwapGraph
        isFetching={isFetching}
        data={data}
        chartDimensions={chartDimensions}
        barDimensions={barDimensions}
        showXAxis={!isViewAboveTablet}
        hideHover={isViewAboveTablet}
      >
        {isViewAboveTablet && <Legend graphData={data} />}
      </GwapGraph>
    </div>
  );
};

const getGranulaTimestampe = (timeInterval: GwapTimeInterval, timestamp: string) => {
  if (!timestamp) {
    return '';
  }

  if (timeInterval !== GwapTimeInterval.THIRTY_MINS) {
    return '';
  }

  return moment(timestamp).format('HH:mm');
};

const getFormattedTimestampForInterval = (timestampString: string, timeInterval: GwapTimeInterval) => {
  if (!timestampString) {
    return '';
  }
  if (timeInterval === GwapTimeInterval.MONTHLY) {
    return moment(timestampString).format('MMM YYYY');
  }
  return moment(timestampString).format('dddd, Do MMM');
};

const GraphTitle = () => {
  const isViewAboveTablet = useSelector(isViewportAboveTablet);
  const { width } = usePaidGwapGraphDimensions();
  const hoveredTimestamp = useRecoilValue(gwapHoveredTimestamp);
  const interval = useRecoilValue(gwapTimeIntervalState);
  const formatedTimestamp = getFormattedTimestampForInterval(hoveredTimestamp, interval);
  const timeInterval = useRecoilValue(gwapTimeIntervalState);

  const granulaTimestamp = getGranulaTimestampe(timeInterval, hoveredTimestamp);
  const setFilterDrawerVisible = useSetRecoilState(gwapFilterDrawerVisibleState);
  const onClickFilterButton = () => {
    setFilterDrawerVisible(true);
  };
  return (
    <div>
      <div className={styles.headingArea}>
        <div
          style={{
            paddingLeft: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width,
            paddingRight: '24px',
          }}
        >
          <h3 className={styles.heading}>GWAP by type ($/MWh)</h3>
          <GwapTimeFilterToggle />
        </div>
        {isViewAboveTablet && (
          <div className={styles.secondTitleArea}>
            <h3>
              {formatedTimestamp} {granulaTimestamp}
            </h3>

            <button className={styles.filterDownloadButton} onClick={onClickFilterButton} type="button">
              Filter & Download
            </button>
          </div>
        )}
      </div>
      <GWAPDelimiter />
    </div>
  );
};

const Legend = ({ graphData }: { graphData: any[] }) => {
  const genTypeFilters = useRecoilValue(gwapGenTypeFilterState);
  const filteredGenTypes = GWAP_BY_TYPE_SUMMARY_LABELS.filter((option) =>
    genTypeFilters.includes(option.generationType),
  );
  // Need this secondary array to maintain spacing in the summary table
  const filteredOutGenTypes = GWAP_BY_TYPE_SUMMARY_LABELS.filter(
    (option) => !genTypeFilters.includes(option.generationType),
  );
  // const timestamp = useRecoilValue(gwapHoveredTimestamp);
  // const hoveredData = getGraphEntryForTimestamp(timestamp, graphData);
  const hoveredData: any = useRecoilValue(gwapByTypeSlice);
  return (
    <LegendBox>
      {!hoveredData && <div className={styles.hoverCTA}>Please hover on the graph for specific readings</div>}
      {hoveredData &&
        filteredGenTypes.map((label) => (
          <LegendBoxRow
            colour={label.colour}
            label={label.label}
            prefix="$"
            value={roundToDpWithCommas(hoveredData[`${label.generationType}_wap`], 2)}
            key={label.label}
          />
        ))}
      {hoveredData &&
        filteredOutGenTypes.map((label) => (
          <LegendBoxRow isPlaceholder colour="#fff00" label="" value="" key={label.label} />
        ))}
    </LegendBox>
  );
};
