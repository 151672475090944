import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router';

import { TYPES } from 'redux/modules/session/actions';
import { isLoggedIn as isLoggedInSelector } from 'redux/modules/session/selectors';
import { ROUTE_PATHS } from '../../../constants/routes';

interface IPublicRouteProps {
  children: React.ReactNode;
  [keyof: string]: any;
}

export const PublicRoute = ({ children, location, ...rest }: IPublicRouteProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    dispatch({ type: TYPES.VERIFY_SESSION });
  }, [dispatch]);

  if (!isLoggedIn) {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Redirect
      to={{
        pathname: ROUTE_PATHS.DASHBOARD,
        state: { from: location },
      }}
    />
  );
};
