import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import './ResetPassword.scss';

import { validatePassword, MINIMUM_PASSWORD_LENGTH } from 'tools/utilities/validators';

import {
  isLoading as isLoadingSelector,
  isLoggedIn as isLoggedInSelector,
  hasResetPassword as hasResetPasswordSelector,
  getResetPasswordError,
} from 'redux/modules/session/selectors';
import { actionResetPassword, ResetPasswordError, RESET_PASSWORD_ERROR_CODES } from 'redux/modules/session/actions';

import { Button } from 'views/components/Button/Button';
import { PasswordInput } from 'views/components/PasswordInput/PasswordInput';
import { FormContainer } from 'views/components/FormContainer/FormContainer';
import { Icon } from 'views/components/Icon/Icon';
import { ROUTE_PATHS } from '../../../constants/routes';

const getAPIErrorMessage = (error: ResetPasswordError): string => {
  switch (error.code) {
    case RESET_PASSWORD_ERROR_CODES.EXPIRED_CODE_EXCEPTION:
      return 'RESET_PASSWORD_EXPIRED_CODE_ERROR';
    default:
      return 'RESET_PASSWORD_EXPIRED_CODE_ERROR';
  }
};

type FormValues = {
  password: string;
  confirmedPassword: string;
};

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const isLoading = useSelector(isLoadingSelector);
  const hasResetPassword = useSelector(hasResetPasswordSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const resetPasswordError = useSelector(getResetPasswordError);

  const { handleSubmit, errors, control, getValues, formState } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      confirmedPassword: '',
    },
  });

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  const { code, email } = queryString.parse(location.search);

  /**
   * If there's no code in the URL then there's no point
   * on being on this page.
   */
  if (!code && !email) {
    return <Redirect to={ROUTE_PATHS.LOGIN} />;
  }

  const onSubmit: SubmitHandler<FormValues> = ({ password }) => {
    dispatch(actionResetPassword(email as string, password, code as string));
  };

  const hasLinkExpired = resetPasswordError?.code === RESET_PASSWORD_ERROR_CODES.EXPIRED_CODE_EXCEPTION;

  if (hasResetPassword || hasLinkExpired) {
    return (
      <div className="ResetPassword">
        <FormContainer>
          <Icon name={hasResetPassword ? 'ic-success' : 'ic-fail'} className="Icon Form-mainIcon" />
          <p className="Form-title">
            <FormattedMessage id={hasResetPassword ? 'PASSWORD_RESET' : 'LINK_EXPIRED'} />
          </p>
          {hasLinkExpired && (
            <p className="Form-text">
              <FormattedMessage id="YOUR_LINK_HAS_EXPIRED" />
            </p>
          )}
          <Button
            className="Button"
            buttonType="button"
            label={hasResetPassword ? 'Back to login' : 'Forgot password'}
            url={hasResetPassword ? '/login' : '/forgot-password'}
          />
        </FormContainer>
      </div>
    );
  }

  return (
    <div className="ResetPassword">
      <FormContainer>
        <>
          <h2 className="Form-title">
            <FormattedMessage id="RESET_PASSWORD" />
          </h2>
          <form className="Form-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="password"
              render={({ onChange, onBlur, value }) => (
                <PasswordInput
                  placeholder={`at least ${MINIMUM_PASSWORD_LENGTH} characters`}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="password"
                  label="New password"
                  value={value}
                  error={errors.password?.message}
                  showErrorOutLine={errors.confirmedPassword?.type === 'matches'}
                  allowHints
                />
              )}
              rules={{
                required: 'Please enter your new password.',
                validate: (value) =>
                  validatePassword(value) || intl.formatMessage({ id: 'PASSWORD_REQUIREMENTS_ERROR' }),
              }}
            />
            <Controller
              control={control}
              name="confirmedPassword"
              render={({ onChange, onBlur, value }) => (
                <PasswordInput
                  placeholder="Re-enter new password"
                  onChange={onChange}
                  onBlur={onBlur}
                  id="confirmedPassword"
                  label="Repeat password"
                  value={value}
                  error={errors.confirmedPassword?.message}
                />
              )}
              rules={{
                required: 'Please confirm your password.',
                validate: {
                  validFormat: (value) =>
                    validatePassword(value) || intl.formatMessage({ id: 'PASSWORD_REQUIREMENTS_ERROR' }),
                  matches: (value) =>
                    getValues('password') === value || intl.formatMessage({ id: 'PASSWORD_MATCH_ERROR' }),
                },
              }}
            />
            {resetPasswordError && formState.isSubmitted && (
              <p className="Login-error">
                <FormattedMessage id={getAPIErrorMessage(resetPasswordError)} />
                <Icon name="ic-error" className="Login-error-icon Icon--sm" />
              </p>
            )}
            <Button className="Button" label="Reset password" buttonType="submit" isLoading={isLoading} />
          </form>
        </>
      </FormContainer>
    </div>
  );
};
