/* eslint-disable import/no-cycle */
import { useMemo } from 'react';
import * as d3 from 'd3';
import * as turf from '@turf/turf';
import { FormattedMessage } from 'react-intl';

import { useFreeRegions } from 'tools/hooks/useFreeRegions';
import { PRICE_RANGES } from 'redux/modules/nodes/constants';
import grid from './GridZones.json';

import { getBoundingBox, MAP_WIDTH, MAP_HEIGHT } from './map-utilities';

import styles from './FreeMap.module.scss';

import { Legend } from './Legend/Legend';
import { MapLayer } from './MapLayer/MapLayer';
import { Labels } from './Labels/Labels';

export const FreeMap = () => {
  const { priceRegions, formattedDate } = useFreeRegions();

  const projection = useMemo(() => d3.geoMercator(), []);
  const geoGenerator = useMemo(() => d3.geoPath().projection(projection), [projection]);

  const { features: gridFeatures } = grid as any;
  const gridFixed = useMemo(
    () =>
      // @ts-ignore
      gridFeatures.map((feature: any) => turf.rewind(feature, { reverse: true })),
    [gridFeatures],
  );

  projection.fitSize([MAP_WIDTH, MAP_HEIGHT], {
    type: 'FeatureCollection',
    features: gridFixed,
  });

  const [[x0, y0], [x1, y1]] = useMemo(
    () => getBoundingBox(priceRegions || [], projection),
    [priceRegions, projection],
  );

  const scale = useMemo(
    () => Math.min(8, 0.9 / Math.max((x1 - x0) / MAP_WIDTH, (y1 - y0) / MAP_HEIGHT)),
    [x0, y0, x1, y1],
  );
  const transformation = useMemo(
    () =>
      d3.zoomIdentity
        .translate(MAP_WIDTH / 2, MAP_HEIGHT / 2)
        .scale(scale)
        .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
    [x0, y0, x1, y1, scale],
  );

  if (!priceRegions || !priceRegions.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <svg className={styles.svg} id="mapSVG" viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`}>
        <defs>
          <filter id="dropshadow">
            <feDropShadow dx="0" dy="0.2" stdDeviation="0.4" floodColor="#000" floodOpacity="0.3" />
          </filter>
        </defs>
        <MapLayer
          transformation={transformation}
          regions={priceRegions}
          ranges={PRICE_RANGES}
          gridZones={gridFixed}
          geoGenerator={geoGenerator}
        />
        <Labels transformation={transformation} regions={priceRegions} ranges={PRICE_RANGES} projection={projection} />
      </svg>
      <Legend ranges={PRICE_RANGES} />
      <p className="Dashboard-updated Dashboard-updated--Map">
        <FormattedMessage id="LAST_UPDATED" values={{ date: formattedDate }} />
      </p>
    </div>
  );
};
