import { DateFilterOption } from 'enums.d';
import { atom, selector } from 'recoil';
import { getFromAndToTradingDates } from 'redux/modules/nodes/api';
import { ALL_GWAP_LOCATIONS, GwapTimeInterval } from 'tools/utilities/gwap';
import { gwapGenTypeFilterState, gwapLocationFilterState } from './gwapState';

export const gwapFilterDrawerVisibleState = atom({ key: 'drawerVisibleState', default: false });

export const gwapTimeIntervalState = atom({ key: 'GwapTimeIntervalState', default: GwapTimeInterval.MONTHLY });

interface IPaidDateRangeFilterState {
  selectedOption: DateFilterOption;
  from: string | null;
  to: string | null;
  error?: string;
}

export const DefaultDateRangeFilterState = () => {
  const defaultRange = DateFilterOption.YEAR_TO_DATE;
  const defaultFromAndToDates = getFromAndToTradingDates(defaultRange);

  return {
    selectedOption: defaultRange,
    from: defaultFromAndToDates.fromTradingDate,
    to: defaultFromAndToDates.toTradingDate,
  };
};

export const paidFilterErrorsState = atom<string[]>({
  key: 'paidFilterErrorsState',
  default: [],
});

export const paidDateRangeFilterState = atom<IPaidDateRangeFilterState>({
  key: 'paidDateRangeFilterState',
  default: DefaultDateRangeFilterState(),
});

export const isAtLeastOneFilterNotSelectedSelector = selector({
  key: 'isAtLeastOneFilterNotSelected',
  get: ({ get }) => {
    const genTypeFilter = get(gwapGenTypeFilterState);
    const locationFilter = get(gwapLocationFilterState);
    return genTypeFilter.length === 0 || locationFilter.length === 0;
  },
});

export const gwapHoveredTimestamp = atom({ key: 'gwapHoveredTimestamp', default: '' });

export const gwapByTypeGraphDataState = atom({ key: 'gwapByTypeGraphDataState', default: [] as any[] });

export const gwapByTypeSlice = atom({ key: 'gwapHistoricGenSliceState', default: {} });

// Transform the array of label locations pairs into just a locations array
export const gwapLocationFilterAsLocationsSelector = selector({
  key: 'gwapLocationFilterAsLocationsSelector',
  get: ({ get }) => get(gwapLocationFilterState),
});

// Partial API strings
const gwapApiDateStringSelector = selector({
  key: 'gwapApiDateStringSelector',
  get: ({ get }) => {
    const dateRange = get(paidDateRangeFilterState);
    const startDateString = dateRange.from;
    const endDateString = dateRange.to;
    return `&from_trading_date=${startDateString}&to_trading_date=${endDateString}`;
  },
});
const gwapApiLocationStringSelector = selector({
  key: 'gwapApiLocationStringSelector',
  get: ({ get }) => {
    const locations = get(gwapLocationFilterAsLocationsSelector);

    /**
     * If there's no location selected, then all are selected.
     * This allows for the _Generation by location_ chart to always show data.
     */
    if (!locations || locations.length === 0) {
      return ALL_GWAP_LOCATIONS.join(',');
    }

    const locationShortCodesString = locations.join(',');
    return locationShortCodesString;
  },
});
const gwapApiGenTypeStringSelector = selector({
  key: 'gwapApiGenTypeStringSelector',
  get: ({ get }) => {
    const genTypes = get(gwapGenTypeFilterState);
    const genTypesAsString = genTypes.join(',').toUpperCase();
    return `generation_type_id=${genTypesAsString}`;
  },
});

// Fully formed API Strings
export const historicGenTypesApiStringSelector = selector({
  key: 'gwapHistoricGenTypeApiStringSelector',
  get: ({ get }) => {
    const genTypesString = get(gwapApiGenTypeStringSelector);
    const dateRangeString = get(gwapApiDateStringSelector);
    const timeIntervalString = get(gwapTimeIntervalState);
    return `historic_generation_type/?${genTypesString}&interval=${timeIntervalString}${dateRangeString}`;
  },
});
export const generationByLocationApiStringSelector = selector({
  key: 'generationByLocationApiStringSelector',
  get: ({ get }) => {
    const genTypesString = get(gwapApiGenTypeStringSelector);
    const dateRangeString = get(gwapApiDateStringSelector);
    const timeIntervalString = get(gwapTimeIntervalState);
    const locationString = get(gwapApiLocationStringSelector);
    return `gen_region_price?grid_zone_id=${locationString}&interval=${timeIntervalString}${dateRangeString}&${genTypesString}`;
  },
});
export const summaryApiStringSelector = selector({
  key: 'summaryApiStringSelector',
  get: ({ get }) => {
    const dateRangeString = get(gwapApiDateStringSelector);
    return `gen_region_price/summary?${dateRangeString}`;
  },
});

interface ILoadingState {
  isFetching: boolean;
  isFinishedLoadingWithoutResult: boolean;
}
export const generationByLocationLoadingState = atom<ILoadingState>({
  key: 'generationByLocationLoadingState',
  default: { isFetching: true, isFinishedLoadingWithoutResult: false },
});
