/* eslint-disable @typescript-eslint/naming-convention */
import { ISession } from 'types.d';

export enum TYPES {
  VERIFY_SESSION = '[SESSION]-VERIFY_SESSION',
  VERIFY_SESSION_SUCCESS = '[SESSION]-VERIFY_SESSION_SUCCESS',
  VERIFY_SESSION_FAIL = '[SESSION]-VERIFY_SESSION_FAIL',

  LOGIN = '[SESSION]-LOGIN',
  LOGIN_SUCCESS = '[SESSION]-LOGIN_SUCCESS',
  LOGIN_FAIL = '[SESSION]-LOGIN_FAIL',

  LOGOUT = '[SESSION]-LOGOUT',
  LOGOUT_SUCCESS = '[SESSION]-LOGOUT_SUCCESS',
  LOGOUT_FAIL = '[SESSION]-LOGOUT_FAIL',

  FORGOT_PASSWORD = '[SESSION]-FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = '[SESSION]-FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL = '[SESSION]-FORGOT_PASSWORD_FAIL',

  RESET_PASSWORD = '[SESSION]-RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = '[SESSION]-RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL = '[SESSION]-RESET_PASSWORD_FAIL',
}

export type SessionActions =
  | IVerifySession
  | IVerifySessionSuccess
  | IVerifySessionFail
  | ILogin
  | ILoginSuccess
  | ILoginFail
  | ILogout
  | ILogoutSuccess
  | ILogoutFail
  | IForgotPassword
  | IForgotPasswordSuccess
  | IForgotPasswordFail
  | IResetPassword
  | IResetPasswordSuccess
  | IResetPasswordFail;

interface IVerifySession {
  type: typeof TYPES.VERIFY_SESSION;
}

export interface IVerifySessionSuccess {
  type: typeof TYPES.VERIFY_SESSION_SUCCESS;
  payload: {
    user: any;
    isLoggedIn: boolean;
  };
}

export interface IVerifySessionFail {
  type: typeof TYPES.VERIFY_SESSION_FAIL;
  payload: {
    error: any;
    isLoggedIn: boolean;
  };
}

export interface ILogin {
  type: typeof TYPES.LOGIN;
  payload: {
    email: string;
    password: string;
  };
}

export interface ILoginSuccess {
  type: typeof TYPES.LOGIN_SUCCESS;
  payload: {
    user: ISession;
  };
}

export enum LOGIN_ERROR_CODES {
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
}

export type LoginError = {
  code: LOGIN_ERROR_CODES;
  message: string;
  name: string;
};

export interface ILoginFail {
  type: typeof TYPES.LOGIN_FAIL;
  payload: {
    error: LoginError;
  };
}

interface ILogout {
  type: typeof TYPES.LOGOUT;
}

export interface ILogoutSuccess {
  type: typeof TYPES.LOGOUT_SUCCESS;
}

export interface ILogoutFail {
  type: typeof TYPES.LOGOUT_FAIL;
  payload: {
    error: any;
  };
}

export enum FORGOT_PASSWORD_ERROR_CODES {
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
}

export type ForgotPasswordError = {
  code: FORGOT_PASSWORD_ERROR_CODES;
  message: string;
  name: string;
};

export interface IForgotPassword {
  type: typeof TYPES.FORGOT_PASSWORD;
  payload: {
    email: string;
  };
}

export interface IForgotPasswordSuccess {
  type: typeof TYPES.FORGOT_PASSWORD_SUCCESS;
}

export interface IForgotPasswordFail {
  type: typeof TYPES.FORGOT_PASSWORD_FAIL;
  payload: {
    error: ForgotPasswordError;
  };
}

export enum RESET_PASSWORD_ERROR_CODES {
  EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
}

export type ResetPasswordError = {
  code: RESET_PASSWORD_ERROR_CODES;
  message: string;
  name: string;
};

export interface IResetPassword {
  type: typeof TYPES.RESET_PASSWORD;
  payload: {
    email: string;
    password: string;
    code: string;
  };
}

export interface IResetPasswordSuccess {
  type: typeof TYPES.RESET_PASSWORD_SUCCESS;
  payload: {
    hasResetPassword: boolean;
  };
}

export interface IResetPasswordFail {
  type: typeof TYPES.RESET_PASSWORD_FAIL;
  payload: {
    resetPasswordError: any;
  };
}

export function actionVerifySession(): IVerifySession {
  return {
    type: TYPES.VERIFY_SESSION,
  };
}

export function actionVerifySessionSuccess(user: ISession): IVerifySessionSuccess {
  return {
    type: TYPES.VERIFY_SESSION_SUCCESS,
    payload: {
      user,
      isLoggedIn: true,
    },
  };
}

export function actionVerifySessionFail(error: any): IVerifySessionFail {
  return {
    type: TYPES.VERIFY_SESSION_FAIL,
    payload: {
      error,
      isLoggedIn: false,
    },
  };
}

export function actionLogin({ email, password }: { email: string; password: string }): ILogin {
  return {
    type: TYPES.LOGIN,
    payload: {
      email,
      password,
    },
  };
}

export function actionLoginSuccess(user: ISession): ILoginSuccess {
  return {
    type: TYPES.LOGIN_SUCCESS,
    payload: {
      user,
    },
  };
}

export function actionLoginFail(error: any): ILoginFail {
  return {
    type: TYPES.LOGIN_FAIL,
    payload: {
      error,
    },
  };
}

export function actionLogout(): ILogout {
  return {
    type: TYPES.LOGOUT,
  };
}

export function actionLogoutSuccess(): ILogoutSuccess {
  return {
    type: TYPES.LOGOUT_SUCCESS,
  };
}

export function actionLogoutFail(error: any): ILogoutFail {
  return {
    type: TYPES.LOGOUT_FAIL,
    payload: {
      error,
    },
  };
}

export function actionForgotPassword(email: string): IForgotPassword {
  return {
    type: TYPES.FORGOT_PASSWORD,
    payload: {
      email,
    },
  };
}

export function actionForgotPasswordSuccess(): IForgotPasswordSuccess {
  return {
    type: TYPES.FORGOT_PASSWORD_SUCCESS,
  };
}

export function actionForgotPasswordFail(error: any): IForgotPasswordFail {
  return {
    type: TYPES.FORGOT_PASSWORD_FAIL,
    payload: {
      error,
    },
  };
}

export function actionResetPassword(email: string, password: string, code: string): IResetPassword {
  return {
    type: TYPES.RESET_PASSWORD,
    payload: {
      email,
      password,
      code,
    },
  };
}

export function actionResetPasswordSuccess(): IResetPasswordSuccess {
  return {
    type: TYPES.RESET_PASSWORD_SUCCESS,
    payload: {
      hasResetPassword: true,
    },
  };
}

export function actionResetPasswordFail(resetPasswordError: any): IResetPasswordFail {
  return {
    type: TYPES.RESET_PASSWORD_FAIL,
    payload: {
      resetPasswordError,
    },
  };
}
