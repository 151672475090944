import { ISessionState } from './reducers';

export const isLoading = ({ session }: { session: ISessionState }) => session.isLoading;

export const isLoggedIn = ({ session }: { session: ISessionState }) => session.isLoggedIn;
export const getLoginError = ({ session }: { session: ISessionState }) => session.loginError;

export const getUser = ({ session }: { session: ISessionState }) => session.user;
export const getUserAccessScope = ({ session }: { session: ISessionState }) => session.user?.accessScope || [];

export const hasSentForgottenPasswordRequest = ({ session }: { session: ISessionState }) =>
  session.hasSentForgottenPasswordRequest;
export const getForgotPasswordError = ({ session }: { session: ISessionState }) => session.forgotPasswordError;

export const hasResetPassword = ({ session }: { session: ISessionState }) => session.hasResetPassword;
export const getResetPasswordError = ({ session }: { session: ISessionState }) => session.resetPasswordError;
