import { DateFilterOption, EmissionsType } from 'enums.d';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { atom, useRecoilState } from 'recoil';
import { getFromAndToTradingDates } from 'redux/modules/nodes/api';
import { makeGetRequest } from 'tools/utilities/ajax';
import { generateDownload } from 'tools/utilities/file';
import { GwapGenerationType, GWAP_GENERATION_TYPE_OPTIONS } from 'tools/utilities/gwap';
import { formatEmissionsReportFilename } from 'tools/utilities/report';
import { IEmissionsReportCriteria } from 'types';

const defaultState: IEmissionsReportCriteria = {
  emissionsType: EmissionsType.GENERATION,
  selectedGenerationTypes: [],
  dateOption: DateFilterOption.TODAY,
  fromTradingDate: '',
  toTradingDate: '',
};

const reportDataState = atom({ key: 'emissionsReportDataState', default: '' });

const emissionsReportState = atom({
  key: 'emissionsReportState',
  default: defaultState,
});

const firstLoadState = atom({ key: 'firstLoadState', default: true });

export const useEmissionsReport = () => {
  const [emissionsState, setEmissionsState] = useRecoilState(emissionsReportState);
  const [reportState, setReportState] = useRecoilState(reportDataState);
  const [areAllNodesSelected, setAreAllNodesSelected] = useState<boolean>(false);
  const [areSomeNodesSelected, setAreSomeNodesSelected] = useState<boolean>(false);
  const [isFirstLoad, setFirstLoad] = useRecoilState(firstLoadState);
  const { emissionsType, selectedGenerationTypes, dateOption, fromTradingDate, toTradingDate } = emissionsState;

  const setDateOption = useCallback(
    (dOpt: DateFilterOption) => {
      if (dOpt !== DateFilterOption.CUSTOM) {
        const { fromTradingDate: fDate, toTradingDate: tDate } = getFromAndToTradingDates(dOpt);
        setEmissionsState({ ...emissionsState, dateOption: dOpt, fromTradingDate: fDate, toTradingDate: tDate });
      } else {
        setEmissionsState({ ...emissionsState, dateOption: dOpt });
      }
    },
    [emissionsState, setEmissionsState],
  );

  useEffect(() => {
    if (GWAP_GENERATION_TYPE_OPTIONS.length > 0 && isFirstLoad) {
      setDateOption(DateFilterOption.TODAY);
      setFirstLoad(false);
    }
  }, [isFirstLoad, setDateOption, setFirstLoad]);

  useEffect(() => {
    setAreAllNodesSelected(selectedGenerationTypes.length === GWAP_GENERATION_TYPE_OPTIONS.length);
    setAreSomeNodesSelected(selectedGenerationTypes.length > 0);
  }, [selectedGenerationTypes]);

  const setEmissionsType = useCallback(
    (eType: EmissionsType) => {
      setEmissionsState({ ...emissionsState, emissionsType: eType });
    },
    [emissionsState, setEmissionsState],
  );

  const setSelectedGenerationTypes = useCallback(
    (sTypes: GwapGenerationType[]) => {
      setEmissionsState({ ...emissionsState, selectedGenerationTypes: sTypes });
    },
    [emissionsState, setEmissionsState],
  );

  const selectNode = useCallback(
    (nodeId: string) => {
      const newSelectedGenTypeState = [...selectedGenerationTypes, nodeId];
      setSelectedGenerationTypes(newSelectedGenTypeState);
    },
    [selectedGenerationTypes, setSelectedGenerationTypes],
  );
  const removeNode = useCallback(
    (nodeId: string) => {
      setSelectedGenerationTypes(selectedGenerationTypes.filter((node) => node !== nodeId));
    },
    [selectedGenerationTypes, setSelectedGenerationTypes],
  );

  const toggleNode = useCallback(
    (nodeId: string) => {
      if (selectedGenerationTypes.includes(nodeId)) {
        removeNode(nodeId);
      } else {
        selectNode(nodeId);
      }
    },
    [removeNode, selectNode, selectedGenerationTypes],
  );

  const toggleAllNodes = useCallback(() => {
    setSelectedGenerationTypes(
      areAllNodesSelected ? [] : GWAP_GENERATION_TYPE_OPTIONS.map((node) => node.generationType),
    );
  }, [areAllNodesSelected, setSelectedGenerationTypes]);

  const setFromTradingDate = useCallback(
    (fDate: string) => {
      setEmissionsState({ ...emissionsState, fromTradingDate: fDate });
    },
    [emissionsState, setEmissionsState],
  );

  const setToTradingDate = useCallback(
    (tDate: string) => {
      setEmissionsState({ ...emissionsState, toTradingDate: tDate });
    },
    [emissionsState, setEmissionsState],
  );

  // Date time is always set and defaults to something.

  // Changing the options in the drop downs changes the

  // When its time to download one simply puts all the download data into a file

  // Only thing that changes is that when we go to having gen types they need to be in the url too. That is okay though because the url is ALWAYS
  // built using the currently selected options

  const generateGenTypeUrlExtension = () => {
    if (emissionsType === EmissionsType.NZ || selectedGenerationTypes.length === 0) {
      return '';
    }

    const genTypeExtensions = selectedGenerationTypes.join(',').toUpperCase();

    return `&generation_type_id=${genTypeExtensions}`;
  };

  const generateUrl = () => {
    const apiPrefix =
      emissionsType === EmissionsType.GENERATION ? '/historic_generation_type_csv' : '/carbon_intensity_csv';

    const genTypeSuffix = generateGenTypeUrlExtension();
    const formedUrl = `${apiPrefix}/?from_trading_date=${fromTradingDate}&to_trading_date=${toTradingDate}${genTypeSuffix}`;
    return formedUrl;
  };

  const dataUrl = generateUrl();

  const { data, isLoading } = useQuery<any>(['EmissionsReportCSV', dataUrl], () =>
    makeGetRequest(dataUrl).then((resp) => resp.data),
  );

  setReportState(data);

  const attemptReportDownload = useCallback(async () => {
    const filename = formatEmissionsReportFilename(emissionsState);
    generateDownload(reportState, `${filename}`, 'text/csv');
  }, [emissionsState, reportState]);

  return {
    emissionsType,
    selectedGenerationTypes,
    dateOption,
    fromTradingDate,
    toTradingDate,
    areAllNodesSelected,
    areSomeNodesSelected,
    isLoadingReport: isLoading,
    setEmissionsType,
    setSelectedGenerationTypes,
    setDateOption,
    setFromTradingDate,
    setToTradingDate,
    selectNode,
    removeNode,
    toggleAllNodes,
    toggleNode,
    attemptReportDownload,
  };
};
