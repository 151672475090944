import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isViewportAboveMobile } from 'redux/modules/app/selectors';
import { gwapTimeIntervalState, paidDateRangeFilterState } from 'tools/state/paidGwapState';
import {
  doesDateRangeHave30MOption,
  doesDateRangeHaveDailyOption,
  doesDateRangeHaveMonthOption,
  GwapTimeInterval,
} from 'tools/utilities/gwap';
import styles from './GwapTimeFilterToggle.module.scss';

const FilterOption = ({ label, interval }: { label: string; interval: GwapTimeInterval }) => {
  const [timeInterval, setTimeInterval] = useRecoilState(gwapTimeIntervalState);
  const isActive = interval === timeInterval;

  const onClick = () => {
    setTimeInterval(interval);
  };

  return (
    <button onClick={onClick} type="button" className={classNames([styles.option, isActive && styles.active])}>
      {label}
    </button>
  );
};

export const GwapTimeFilterToggle = () => {
  const isViewpAboveMobile = useSelector(isViewportAboveMobile);
  const { selectedOption } = useRecoilValue(paidDateRangeFilterState);

  const has30mOption = doesDateRangeHave30MOption(selectedOption);
  const hasDailyOption = doesDateRangeHaveDailyOption(selectedOption);
  const hasMonthlyOption = doesDateRangeHaveMonthOption(selectedOption);
  return (
    <div className={styles.wrapper}>
      {has30mOption && <FilterOption label="30m" interval={GwapTimeInterval.THIRTY_MINS} />}
      {hasDailyOption && <FilterOption label={isViewpAboveMobile ? 'Daily' : 'D'} interval={GwapTimeInterval.DAILY} />}
      {hasMonthlyOption && (
        <FilterOption label={isViewpAboveMobile ? 'Monthly' : 'M'} interval={GwapTimeInterval.MONTHLY} />
      )}
    </div>
  );
};
