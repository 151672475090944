export enum DateFilterOption {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  PREVIOUS_3_DAYS = 'prev3Days',
  PREVIOUS_7_DAYS = 'prev7Days',
  MONTH_TO_DATE = 'monthToDate',
  PREVIOUS_MONTH = 'previousMonth',
  YEAR_TO_DATE = 'yearToDate',
  PREVIOUS_YEAR = 'previousYear',
  ALL = 'all',
  CUSTOM = 'custom',
}

// ========
// Report - Scada
// ========
export enum NodeType {
  PRICE = 'price',
  LOAD = 'load',
  GENERATION = 'generation',
  HVDC = 'hvdc',
}

export enum HVDCGranularity {
  POLE_2_POLE_3 = 'pole2pole3',
  TOTAL_FLOWS = 'totalFlows',
}

// =======
// Report - Price
// =======
export enum PriceOption {
  AVERAGE = 'average',
  MARKET = 'market',
}

export enum PriceRunType {
  PROVISIONAL = 'provisional',
  INTERIM = 'interim',
  FINAL = 'final',
  PRICE_RESPONSIVE = 'price-responsive',
  NON_RESPONSE = 'non-response',
  LATEST = 'latest',
}

// =====
// Report - Reserves
// =====
export enum ReservesIsland {
  ALL = 'all',
  NORTH_ISLAND = 'NI',
  SOUTH_ISLAND = 'SI',
}

export enum ReservesPowerType {
  ALL = 'all',
  INTERRUPTIBLE = 'I',
  RESERVE = 'R',
  PARTLY_LOADED_SPINNING_RESERVE = 'P',
  TAIL_WATER_DEPRESSED_RESERVE = 'T',
}

export enum ReservesReservesType {
  ALL = 'all',
  FAST = 'F',
  SUSTAINED = 'S',
}

// TODO figure out what the short codes should be so that they are compatible with the APIs
export enum ReservesSubtype {
  AGGREGATED_RESERVES = 'aggregatedReserves',
  RESERVE_AMOUNT_BY_SOURCE = 'reserveAmountBySource',
  RESERVES_EXTRACT = 'reservesExtract',
}

// =====
// Report - Arc Flow
// =====
export enum ArcFlowRunType {
  LATEST = 'L',
  PROVISIONAL = 'V',
  INTERIM = 'T',
  FINAL = 'F',
}

// ======
// RCPD
// ======
export enum Regions {
  UPPER_NORTH_ISLAND = 'UNI',
  LOWER_NORTH_ISLAND = 'LNI',
  UPPER_SOUTH_ISLAND = 'USI',
  LOWER_SOUTH_ISLAND = 'LSI',
  NORTH_ISLAND = 'NI',
  SOUTH_ISLAND = 'SI',
  NEW_ZEALAND = 'NZ',
}

export enum RegionLoadOption {
  REGION_LOAD_LAST_48HRS = '48hrs',
  REGION_LOAD_LAST_2HRS = '2hrs',
  REGION_LOAD_PEAKS = 'peaks',
}

export enum RunType {
  NRS = 'NRS',
  PRS = 'PRS',
  RTD = 'RTD',
  WDS = 'WDS',
}

export enum ReportType {
  SCADA = 'scada',
  PRICE = 'price',
  EMISSIONS = 'emissions',
  RESERVES = 'reserves',
  ARC_FLOWS = 'arcFlows',
  RCPD = 'rcpd',
}

export enum GenerationType {
  BATTERY = 'Battery',
  CO_GEN = 'Co-Gen',
  COAL_GAS = 'Coal',
  GAS = 'Gas',
  GEOTHERMAL = 'Geothermal',
  HYDRO = 'Hydro',
  WIND = 'Wind',
  DIESEL_OIL = 'Liquid',
  SOLAR = 'Solar',
}

// ======
// Report - Emissions
// ======
export enum EmissionsType {
  NZ = 'NZ',
  GENERATION = 'GENERATION',
}
