import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { Icon, IconName } from 'views/components/Icon/Icon';
import { isUrlInternal } from 'tools/utilities/isUrlInternal';
import { Spinner } from '../Spinner/Spinner';

interface IProps {
  label: string;

  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  url?: string;

  className?: string;
  icon?: IconName;
  iconClass?: string;
  id?: string;
  isIconOnly?: boolean;

  // Button only fields
  buttonType?: 'submit' | 'reset' | 'button';
  disabledClass?: string;
  isDisabled?: boolean;
  isFauxDisabled?: boolean;
  isLoading?: boolean;
  role?: string;
  title?: string;
  loadingMessage?: string;
}

/**
 * Button Component, configurable to produce a <button>,
 * <Link> or <a> tag.
 */
export const Button = (props: IProps) => {
  const handleClick = (proxy: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onClick } = props;

    if (onClick) {
      onClick(proxy);
    }
  };

  const {
    buttonType = 'button',
    className,
    disabledClass,
    icon,
    // iconClass,
    isIconOnly,
    id,
    isDisabled,
    isFauxDisabled,
    isLoading,
    label,
    role,
    title,
    url,
    loadingMessage,
  } = props;

  const labelClassName = classnames({ 'u-hidden': isIconOnly });

  const innerContent = (
    <>
      {icon && <Icon name={icon} className={`icon icon-${icon}`} />}
      <span className={labelClassName}>{label}</span>
    </>
  );

  if (url) {
    // Setup the common props for all variations
    const setupProps = {
      className,
      role,
      id,
      title,
    };

    // Remove any white space from the url that may have been added
    // accidentally in the cms or API
    const cleanUrl = url.trim();

    const isInternalLink = isUrlInternal(cleanUrl);

    // If internal link then use the Link component so we don't
    // refresh the entire SPA
    if (isInternalLink) {
      return (
        <Link {...setupProps} to={cleanUrl} role={role}>
          {innerContent}
        </Link>
      );
    }

    // Otherwise ALWAYS open it in a new tab
    if (isInternalLink === false) {
      return (
        <a {...setupProps} href={cleanUrl} target="_blank" rel="noopener noreferrer">
          {innerContent}
        </a>
      );
    }
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      className={classnames(className, 'Button', {
        [disabledClass || 'Button is-disabled']: isDisabled,
        [disabledClass || 'Button is-disabled']: isFauxDisabled,
        'is-loading': isLoading,
        'has-icon': icon,
      })}
      onClick={handleClick}
      disabled={isDisabled}
      title={title}
      role={role}
      id={id}
    >
      {isLoading && loadingMessage && (
        <div>
          <span className={`${labelClassName} ScreenReadersOnly`}>{label}</span>
        </div>
      )}

      {isLoading && <Spinner />}

      {!isLoading && innerContent}
    </button>
  );
};
