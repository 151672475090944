import classnames from 'classnames';
import { useMultipleSelection, useSelect } from 'downshift';
import { getGenTypeSummary } from 'tools/hooks/useFreeGwap';
import {
  ALL_GEN_TYPES,
  GwapGenerationType,
  GWAP_GENERATION_TYPE_OPTIONS,
  IDownshiftGwapGenerationTypeOption,
  IGwapGenerationTypeOption,
} from 'tools/utilities/gwap';
import { Icon } from '../Icon/Icon';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox/IndeterminateCheckbox';
import styles from './GwapDropDownFilters.module.scss';

interface IGwapGenTypeFilterProps {
  generationTypesSelected: GwapGenerationType[];
  setSelectedGenerationTypes: (genTypeSelected: GwapGenerationType[]) => void;
  isOverlay?: boolean;
}

export const GwapGenTypeFilter = ({
  generationTypesSelected,
  setSelectedGenerationTypes,
  isOverlay,
}: IGwapGenTypeFilterProps) => {
  const { getDropdownProps } = useMultipleSelection<IGwapGenerationTypeOption>();

  const toggleAll = () => {
    if (generationTypesSelected.length < ALL_GEN_TYPES.length) {
      setSelectedGenerationTypes(ALL_GEN_TYPES);
    } else {
      setSelectedGenerationTypes([]);
    }
  };

  const removeGenTypeFilter = (genTypeSelected: GwapGenerationType) => {
    // If all selected, deselect all except one selected
    if (generationTypesSelected.length === ALL_GEN_TYPES.length) {
      setSelectedGenerationTypes([genTypeSelected]);
    } else {
      const generationTypesSelectedUpdated = generationTypesSelected.filter(
        (generationType: GwapGenerationType) => generationType !== genTypeSelected,
      );

      setSelectedGenerationTypes(generationTypesSelectedUpdated);
    }
  };

  const addGenTypeFilter = (genTypeSelected: GwapGenerationType) => {
    const selectedGenTypeUpdated = [genTypeSelected, ...generationTypesSelected];
    setSelectedGenerationTypes(selectedGenTypeUpdated);
  };

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect<IDownshiftGwapGenerationTypeOption>({
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: GWAP_GENERATION_TYPE_OPTIONS,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: state.highlightedIndex,
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem: selected }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!selected) {
            break;
          }

          if (generationTypesSelected.includes(selected.generationType)) {
            removeGenTypeFilter(selected.generationType);
          } else {
            addGenTypeFilter(selected.generationType);
          }
          break;
        default:
          break;
      }
    },
  });

  const areAllGenTypesSelected = generationTypesSelected.length === ALL_GEN_TYPES.length;

  return (
    <div className={styles.downshift_wrapper}>
      <div {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}>
        <button type="button" className={styles.multi_select_button}>
          {getGenTypeSummary(generationTypesSelected)}
          {/* {getLocationDropdownSummary(generationTypesSelected)} */}
          <div className={classnames([styles.arrow, isOpen && styles.arrow_open])}>
            <Icon name="ic-chevron" size="sm" />
          </div>
        </button>
      </div>
      <div
        className={classnames([
          styles.downshift_dropdown_content,
          isOverlay && styles.overlay,
          !isOpen && styles.dropdown_is_closed,
        ])}
        {...getMenuProps()}
      >
        {isOpen && (
          <>
            <div
              className={classnames([styles.downshift_dropdown_option, styles.downshift_dropdown_all_option])}
              key="All generation types"
            >
              All generation types
              <IndeterminateCheckbox isSelected={areAllGenTypesSelected} onSelect={toggleAll} onRemove={toggleAll} />
            </div>
            {GWAP_GENERATION_TYPE_OPTIONS.map((genTypeOption, index) => {
              const isSelected =
                generationTypesSelected.includes(genTypeOption.generationType) || areAllGenTypesSelected;

              return (
                <div
                  className={styles.downshift_dropdown_option}
                  key={genTypeOption.label}
                  {...getItemProps({ item: genTypeOption, index })}
                >
                  <span className={styles.colorLabel}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '28px',
                        height: '4px',
                        borderRadius: '2px',
                        backgroundColor: genTypeOption.color,
                        marginRight: '16px',
                      }}
                    />
                    {genTypeOption.label}
                  </span>
                  <IndeterminateCheckbox isSelected={isSelected} onSelect={() => {}} onRemove={() => {}} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
