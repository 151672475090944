import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { gwapFilterDrawerVisibleState } from 'tools/state/paidGwapState';
import { Icon } from '../Icon/Icon';
import { GwapDropDownFilters } from './GwapDropdownFilters';
import styles from './GwapFilterDrawer.module.scss';

export const GwapFilterDrawer = () => {
  const [isVisible, setVisible] = useRecoilState(gwapFilterDrawerVisibleState);
  const drawerRef = useRef<HTMLDivElement>(null);
  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ x: 300 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 300, opacity: 0 }}
          transition={{ duration: 0.3 }}
          ref={drawerRef}
          className={styles.wrapper}
        >
          <div className={styles.headingArea}>
            Filter graph
            <button onClick={closeDrawer} className={styles.closeButton} type="button">
              <Icon name="ic-close" size="sm" />
            </button>
          </div>
          <GwapDropDownFilters />
          <p className={styles.info}>Graphs will auto update based on your selection</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
