import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './Load.scss';

import { IRCPDCriteria } from 'types.d';
import { Regions, RegionLoadOption } from 'enums.d';

import { actionGet48HrsRcpd } from 'redux/modules/nodes/actions';

import { RcpdGraph48hr } from 'views/components/Graphs/RcpdGraph48hr';
import { RcpdGraph2hr } from 'views/components/Graphs/RcpdGraph2hr';
import { RCPDSelectionCriteria, REGION_PEAK_OPTIONS_DYNAMIC } from 'views/components/RCPDSelectionCriteria/RCPDSelectionCriteria';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { RCPDPeaksTable } from 'views/components/RCPDPeaksTable/RCPDPeaksTable';
import { AccessRestrictionWrapper } from 'views/components/AccessRestrictionWrapper/AccessRestrictionWrapper';
import { UserAccessScope } from 'constants/sessionConstants';
import { ErrorBoundary } from 'react-error-boundary';

export const Load = () => {
  const [graphHeading, setGraphHeading] = useState<string>('Upper North Island Region Load - last 48hrs');
  const [criteria, setCriteria] = useState<IRCPDCriteria>({
    region: Regions.UPPER_NORTH_ISLAND,
    regionLoadOption: RegionLoadOption.REGION_LOAD_LAST_48HRS,
    yearRange: REGION_PEAK_OPTIONS_DYNAMIC[0]?.id,
  });
  const [hasGraphUpdated, setHasGraphUpdated] = useState<boolean>(false);

  const dispatch = useDispatch();

  // Initialise the RCPD graph with UNI/48hr data
  useEffect(() => {
    dispatch(
      actionGet48HrsRcpd({
        region: Regions.UPPER_NORTH_ISLAND,
        regionLoadOption: RegionLoadOption.REGION_LOAD_LAST_48HRS,
        yearRange: '2020-2021',
      }),
    );
    setHasGraphUpdated(true);
  }, [dispatch, setHasGraphUpdated]);

  return (
    <div className="Container--fullWidth">
      <AccessRestrictionWrapper canGoBack isInline accessScopeRequired={UserAccessScope.RCPD}>
        <RouteTransition>
          <div className="Load">
            <div className="box1">
              <RCPDSelectionCriteria
                criteria={criteria}
                setCriteria={setCriteria}
                setGraphHeading={setGraphHeading}
                setHasGraphUpdated={setHasGraphUpdated}
              />
            </div>
            {criteria.regionLoadOption !== RegionLoadOption.REGION_LOAD_PEAKS && hasGraphUpdated && (
              <div className="LoadGraph">
                <h2 className="LoadGraph-title">{`${graphHeading} (MW)`}</h2>
                <div className="LoadGraph-graph">
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {criteria.regionLoadOption === RegionLoadOption.REGION_LOAD_LAST_48HRS ? (
                      <RcpdGraph48hr criteria={criteria} />
                    ) : (
                      <RcpdGraph2hr criteria={criteria} />
                    )}
                  </ErrorBoundary>
                </div>
              </div>
            )}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {criteria.regionLoadOption === RegionLoadOption.REGION_LOAD_PEAKS && (
                <RCPDPeaksTable region={criteria.region} />
              )}
            </ErrorBoundary>
          </div>
        </RouteTransition>
      </AccessRestrictionWrapper>
    </div>
  );
};

const ErrorFallback = (props: any) => <div>There was a problem getting data for this graph.</div>;
