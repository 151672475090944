import moment from 'moment';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts';
import { useRecoilValue } from 'recoil';
import { FREE_PRICE_GRAPH_KEYS } from 'redux/modules/nodes/constants';
import { useFreePriceData } from 'tools/hooks/useFreePriceData';
import { ResizeState } from 'tools/providers/ResizeProvider';
import { getTradingPeriodForDate } from 'tools/utilities/date';
import { roundToDpWithCommas } from 'tools/utilities/numberFormat';
import { GraphKey } from '../GraphKey/GraphKey';
import { TooltipRow, TooltipWrapper } from '../Tooltip/Tooltip';
import styles from './FreePriceGraph.module.scss';

const CustomTooltip = (data: any) => {
  const { payload } = data;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { BEN2201, HAY2201, ISL2201, KIK2201, OTA2201, WKM2201, timestamp } = payload?.[0]?.payload ?? {};

  const tp = getTradingPeriodForDate(new Date(timestamp));

  return (
    <TooltipWrapper>
      <TooltipRow>
        <strong>Trading Period: </strong>
        <span>{tp}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>OTA2201</strong>
        <span>{OTA2201 ? `$${roundToDpWithCommas(OTA2201, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>WKM2201</strong>
        <span>{WKM2201 ? `$${roundToDpWithCommas(WKM2201, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>HAY2201</strong>
        <span>{HAY2201 ? `$${roundToDpWithCommas(HAY2201, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>KIK2201</strong>
        <span>{KIK2201 ? `$${roundToDpWithCommas(KIK2201, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>ISL2201</strong>
        <span>{ISL2201 ? `$${roundToDpWithCommas(ISL2201, 2)}` : '-'}</span>
      </TooltipRow>
      <TooltipRow>
        <strong>BEN2201</strong>
        <span>{BEN2201 ? `$${roundToDpWithCommas(BEN2201, 2)}` : '-'}</span>
      </TooltipRow>
    </TooltipWrapper>
  );
};

export const FreePriceGraph = () => {
  const { normalisedData, isLoading } = useFreePriceData();
  const isBeingResized = useRecoilValue(ResizeState);
  // const isLoading = !normalisedData;

  return (
    <div className={styles.FreePriceGraph} data-testid="FreePriceGraph">
      {isLoading || isBeingResized ? null : (
        <ResponsiveContainer width="99%" height={370}>
          <LineChart
            // width={500}
            // height={300}
            data={normalisedData}
            margin={{
              top: 24,
              right: 0,
              left: 0,
              bottom: 16,
            }}
          >
            <CartesianGrid strokeOpacity={0.3} vertical={false} strokeDasharray="3 1" />
            <XAxis
              tickLine={false}
              axisLine={false}
              width={100}
              tick={{
                stroke: 'white',
                fontSize: '14px',
                strokeWidth: 0.75,
                fontFamily: 'Oswald Light oswald-light !important',
              }}
              dataKey="name"
              minTickGap={50}
            />
            <YAxis
              tickFormatter={(value: any, index: number) => roundToDpWithCommas(value, 0)}
              tickCount={8}
              domain={[
                (dataMin: any) => Math.max(dataMin - (dataMin % 5) - 10, 0),
                (dataMax: any) => dataMax - (dataMax % 5) + 10,
              ]}
              tickLine={false}
              tick={{
                stroke: 'white',
                fontSize: '14px',
                strokeWidth: 0.75,
                fontFamily: 'Oswald Light oswald-light !important',
              }}
              axisLine={false}
            />
            <Tooltip
              labelFormatter={(label: any, payload: any) => (
                <span>
                  {moment(new Date(payload[0]?.payload?.timestamp)).format('DD/MM/YYYY')} - TP{payload[0]?.payload.tp}
                </span>
              )}
              content={CustomTooltip}
            />
            {FREE_PRICE_GRAPH_KEYS.map((key, value) => (
              <Line key={key.label} type="linear" dataKey={key.label} stroke={key.fill} dot={false} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
      <GraphKey classname="GraphKey-price" keyItems={FREE_PRICE_GRAPH_KEYS} />
    </div>
  );
};

// const SkeletonGraphLoader = () => (
//   <ContentLoader width="100%" height="100%" viewBox="0 0 450 400" backgroundColor="#f0f0f0" foregroundColor="#dedede">
//     <rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
//     <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
//     <rect x="42" y="77" rx="10" ry="10" width="388" height="417" />
//   </ContentLoader>
// );
