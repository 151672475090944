import classnames from 'classnames';
import styles from './Toggle.module.scss';

interface IProps<T extends string> {
  value1: T;
  value2: T;
  currentValue: T;
  onClick?: (value: T) => void;
  className?: string;
}

export const Toggle = <T extends string>({ value1, value2, onClick, currentValue, className }: IProps<T>) => (
  <div className={classnames(styles.wrapper, className)}>
    <button
      type="button"
      onClick={() => onClick?.(value1)}
      className={classnames(styles.base, styles.value1, {
        [styles.active]: currentValue === value1,
      })}
    >
      {value1}
    </button>
    <button
      type="button"
      onClick={() => onClick?.(value2)}
      className={classnames(styles.base, styles.value2, {
        [styles.active]: currentValue === value2,
      })}
    >
      {value2}
    </button>
  </div>
);
