import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTable, HeaderGroup } from 'react-table';
import classnames from 'classnames';

import './NodeSelection.scss';

import { IReducerState } from 'redux/main-reducer';

import { NodeSearch } from 'views/components/NodeSearch/NodeSearch';
import { IndeterminateCheckbox } from 'views/components/IndeterminateCheckbox/IndeterminateCheckbox';
import { FormattedMessage } from 'react-intl';

const NODE_TYPES_TO_LABEL: any = {
  load: 'Load',
  generation: 'Generation',
  hvdc: 'HVDC',
  price: 'Price',
};

interface IProps {
  onSelect: (nodeId: string) => void;
  onRemove: (nodeId: string) => void;
  selectedNodes?: string[];
  renderHeader: () => React.ReactNode;
  nodeData: any[];
  hasCustomIcon?: boolean;
  nodeType: string;
  hideSelectionColumn?: boolean;
  hideSearch?: boolean;
}

interface NodeHeaderGroup {
  nodeId: string;
  nodeName: string;
}

export const NodeSelection = ({
  selectedNodes = [],
  onSelect,
  onRemove,
  renderHeader,
  nodeData,
  hasCustomIcon,
  nodeType,
  hideSelectionColumn,
  hideSearch,
}: IProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const isViewportMobile = useSelector((state: IReducerState) => state.browser.is.mobile);

  const columns = useMemo(
    () => [
      {
        Header: `${NODE_TYPES_TO_LABEL[nodeType]} Nodes`,
        accessor: 'nodeId',
      },
      {
        Header: 'Name',
        accessor: 'nodeName',
      },
      {
        id: 'selection',
        Header: renderHeader,
        Cell: ({ row }: any) => (
          <div style={{ paddingRight: '40px' }}>
            <IndeterminateCheckbox
              hasCustomIcon={hasCustomIcon}
              isSelected={selectedNodes.some((node: string) => node === row.original.nodeId)}
              row={row}
              onSelect={onSelect}
              onRemove={onRemove}
            />
          </div>
        ),
      },
    ],
    [selectedNodes, onSelect, renderHeader, hasCustomIcon, nodeType, onRemove],
  );

  const data = useMemo(() => {
    const nodesFiltered = nodeData.filter(({ nodeId, nodeName }) => {
      const filterTermLowerCase = searchTerm.toLowerCase();

      return (
        nodeId.toLowerCase().startsWith(filterTermLowerCase) || nodeName.toLowerCase().startsWith(filterTermLowerCase)
      );
    });

    return nodesFiltered;
  }, [searchTerm, nodeData]);

  const handleNodeSearch = (value: string) => {
    setSearchTerm(value);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
  });

  const selectionClassNames = classnames('NodeSelection', {
    isDownloadVisible: selectedNodes && selectedNodes.length > 0,
  });

  return (
    <div className={selectionClassNames}>
      {!hideSearch && <NodeSearch onChange={handleNodeSearch} />}

      {data.length === 0 ? (
        <p className="NodeSelection-noResults">
          <FormattedMessage id="NO_NODES_FOUND" />
        </p>
      ) : (
        <div className="NodeSelection-tableWrapper">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: HeaderGroup<NodeHeaderGroup>) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    // Hide the name column on mobile
                    const shouldHideColumn = column.id === 'nodeName' && isViewportMobile;
                    // || (column.id === 'selection' && hideSelectionColumn);

                    if (hideSelectionColumn && column.id === 'selection') {
                      return <th key={column.id}> </th>;
                    }
                    return !shouldHideColumn && <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row);
                const rowClassName = classnames('NodeSelection-tableRow', {
                  'is-selected': selectedNodes.some((node: string) => node === row.original.nodeId),
                });

                return (
                  <tr key={row.original.nodeId} className={rowClassName} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      const shouldHideColumn = cell.column.id === 'nodeName' && isViewportMobile;
                      // || (cell.column.id === 'selection' && hideSelectionColumn);

                      if (hideSelectionColumn && cell.column.id === 'selection') {
                        return <td key={cell.column.id} />;
                      }

                      return !shouldHideColumn && <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
