import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Button/Button';
import { RouteTransition } from 'views/components/RouteTransition/RouteTransition';
import { Icon } from '../../components/Icon/Icon';

import './Maintenance.scss';

export const Maintenance = () => (
  <div className="Container">
    <RouteTransition>
      <div className="Maintenance">
        <div className="Maintenance-wrapper">
          <div className="Maintenance-content">
            <h1 className="Maintenance-title">
              Oops! <br />
              Something went wrong.{' '}
            </h1>
            <p className="Maintenance-text">
              <FormattedMessage id="SITE_DOWN_FOR_MAINTENANCE" />
            </p>
            <Button className="Button" url="/" label="Back to home" />
          </div>
          <div className="Maintenance-illustration">
            <Icon name="illustration-large-oops" className="Icon" />
          </div>
        </div>
      </div>
    </RouteTransition>
  </div>
);
